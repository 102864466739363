import React, { useContext, useState, useEffect, Fragment } from 'react'

import { UrlServicesContext } from 'components/UrlServicesContext'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from '@react-pdf/renderer'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import font from 'assets/font/Roboto-Bold.ttf'
import format from 'date-fns/format'
import Moment from 'moment'

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  body: {
    padding: 25,
  },
  table: {
    display: 'table',
    width: '100%',
    borderWidth: 0,
    marginTop: 5,
  },
  tableHeader: {
    display: 'table',
    width: '100%',
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableDinamic: {
    display: 'table',
    borderLeftColor: 'rgba(255, 255, 255, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(255, 255, 255, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
    marginTop: 25,
  },
  tableRow: {
    //margin: 'auto',
    flexDirection: 'row',
    width: '100%',
  },
  tableRowTop: {
    marginTop: 20,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
  },
  tableRowBottom: {
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(255, 255, 255, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
  },
  tableCol1Header: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 200,
  },
  tableCol1HeaderAll: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: '100%',
    textAlign: "justify"
  },
  tableCol1HeaderDinamic: {
    width: '100%',
    borderWidth: 0,
    padding: 10,
    backgroundColor: 'rgb(242, 242, 242)',
  },
  tableCol1HeaderLeft: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 400,
  },
  tableColTitle: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: '100%',
    color: 'blue',
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Roboto-Bold',
    margin: '10px 0px 5px 0',
  },
  tableCol1HeaderRight: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 100,
  },
  tableCol1: {
    borderLeftColor: 'rgba(255, 255, 255, 1)',
    borderTopColor: 'rgba(255, 255, 255, 1)',
    borderRightColor: 'rgba(255, 255, 255, 1)',
    borderBottomColor: 'rgba(255, 255, 255, 1)',
    borderWidth: 1,
  },
  tableCol: {
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: 'Roboto-Bold',
  },
  tableCellHeaderDinamic: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#000000',
    fontFamily: 'Roboto-Bold',
  },
  tableResult: {
    marginBottom: 10,
  },
  lineBottom: {
    marginTop: 5,
    backgroundColor: '#000',
    padding: 1,
    borderRadius: 10,
    width: 100,
  },
  titlePage: {
    fontSize: 14,
    marginBottom: 5,
    fontFamily: 'Roboto-Bold',
  },
  tableCellHeader0: {
    fontSize: 8,
    borderLeftColor: 'red',
    borderTopColor: 'red',
    borderRightColor: 'red',
    borderBottomColor: 'red',
    borderWidth: 1,
    textAlign: 'justify',
    padding: '4px 7px',
    fontFamily: 'Roboto-Bold',
    margin: '5px 0',
  },
  tableCellHeader2: {
    fontSize: 8,
    width: 295,
    borderLeftColor: '#000',
    borderTopColor: '#000',
    borderRightColor: '#fff',
    borderBottomColor: '#fff',
    borderWidth: 1,
    textAlign: 'center',
    padding: '2px 3px 3px -15px',
    fontFamily: 'Roboto-Bold',
    marginBottom: '-5px',
  },
  tableCellHeader4: {
    fontSize: 10,
    width: 149,
    borderLeftColor: '#000',
    borderTopColor: '#000',
    borderRightColor: '#fff',
    borderBottomColor: '#fff',
    borderWidth: 1,
    textAlign: 'center',
    padding: '2px 3px 3px -15px',
    fontFamily: 'Roboto-Bold',
    marginBottom: '-5px',
    color: 'gray',
  },
  tableCellHeader4_0: {
    fontSize: 10,
    width: 149,
    borderLeftColor: '#000',
    borderTopColor: '#000',
    borderRightColor: '#000',
    borderBottomColor: '#fff',
    borderWidth: 1,
    textAlign: 'center',
    padding: '2px 3px 3px -15px',
    fontFamily: 'Roboto-Bold',
    marginBottom: '-5px',
    color: 'gray',
  },
  tableCellData2: {
    padding: 3,
    width: 398,
    fontSize: 10,
    fontFamily: 'Roboto-Bold',
  },
  tableCellData2_0: {
    padding: 3,
    width: 401,
    fontSize: 10,
    fontFamily: 'Roboto-Bold',
  },
  tableCellData4: {
    padding: '2px 3px 3px -25px',
    width: 200,
    textAlign: 'center',
    fontSize: 8,
    fontFamily: 'Roboto-Bold',
  },
  colHeader: {
    borderLeftColor: '#000',
    borderTopColor: '#000',
    borderRightColor: '#fff',
    borderBottomColor: '#fff',
    borderWidth: 1,
  },
  colHeader_0: {
    borderLeftColor: '#000',
    borderTopColor: '#000',
    borderRightColor: '#000',
    borderBottomColor: '#fff',
    borderWidth: 1,
  },
  colHeader_1: {
    borderLeftColor: '#fff',
    borderTopColor: '#000',
    borderRightColor: '#000',
    borderBottomColor: '#000',
    borderWidth: 1,
  },
  colHeader_1_0: {
    borderLeftColor: '#000',
    borderTopColor: '#000',
    borderRightColor: '#000',
    borderBottomColor: '#000',
    borderWidth: 1,
  },
})

Font.register({
  family: 'Roboto-Bold',
  src: font,
})

function PrintPDFResultsApplicantsTestColor(props) {
  const [rows1, setRows1] = useState([])
  const [rows2, setRows2] = useState([])
  const [openBackdrop, setoOpenBackdrop] = useState(true)
  const [movilidad, setMovilidad] = React.useState('')
  const [dataTable, setDataTable] = React.useState([])
  const [diagnosticos, setdiagnosticos] = React.useState('')
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [nameApplicant, setNameApplicant] = useState('')
  const [dateApplicant, setDateApplicant] = useState('')

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  let params = queryString.parse(props.location.search)

  useEffect(() => {
    const dataValue = {
      testId: params.test,
      jobId: params.job,
      userId: params.user,
    }

    axios
      .post(`${urlServices}reports/individual/test-color`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        setRows1([
          response.data.resultsTpm.resPer1,
          response.data.resultsTpm.resPer2,
          response.data.resultsTpm.resPer3,
          response.data.resultsTpm.resPer4,
          response.data.resultsTpm.resPer5,
          response.data.resultsTpm.resPer6,
          response.data.resultsTpm.resPer7,
          response.data.resultsTpm.resPer8,
        ])

        setRows2([
          response.data.resultsTpm.resAd1,
          response.data.resultsTpm.resAd2,
          response.data.resultsTpm.resAd3,
          response.data.resultsTpm.resAd4,
          response.data.resultsTpm.resAd5,
          response.data.resultsTpm.resAd6,
          response.data.resultsTpm.resAd7,
          response.data.resultsTpm.resAd8,
        ])

        setNameApplicant(
          response.data.resultsTpm.user.name +
            ' ' +
            response.data.resultsTpm.user.lastName
        )
        setDateApplicant(
          Moment(response.data.resultsTpm.createdAt).format('DD-MM-YYYY')
        )

        setMovilidad(response.data.dataResults.movilidad)
        setDataTable(response.data.dataResults.dataTable)
        setdiagnosticos(response.data.dataResults.diagnosticos)
        //console.log(response.data)

        setoOpenBackdrop(false)
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setoOpenBackdrop(false)
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  if (returnLogin) {
    return <Redirect to="/" />
  }

  if (openBackdrop) {
    return (
      <Fragment>
        <Backdrop
          style={{ zIndex: '9999', color: '#FFFFFF' }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fragment>
    )
  } else {
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page style={styles.body} size="A4" wrap>
            <View style={{ marginBottom: 10 }}>
              <Text style={styles.titlePage}>EL TEST DE COLORES</Text>
              <Text style={styles.lineBottom}></Text>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Nombre del candidato:
                  <Text style={styles.tableCell}> {' ' + nameApplicant}</Text>
                </Text>
              </View>
              <View style={styles.tableCol1HeaderRight}>
                <Text style={styles.tableCellHeader}>
                  Fecha:
                  <Text style={styles.tableCell}>{' ' + dateApplicant}</Text>
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Estos son los números asociados con las filas escogidas:
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1HeaderLeft}>
                <View style={[styles.table, styles.tableResult]}>
                  <View style={styles.tableRow}>
                    {rows1.map((row2) => {
                      return (
                        <View>
                          <Text style={styles.tableCellHeaderDinamic}>
                            {row2 + '     '}
                          </Text>
                        </View>
                      )
                    })}
                  </View>
                  <View style={styles.tableRow}>
                    {rows2.map((row2) => {
                      return (
                        <View>
                          <Text style={styles.tableCellHeaderDinamic}>
                            {row2 + '     '}
                          </Text>
                        </View>
                      )
                    })}
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  La movilidad de estos números es:{' '}
                  <Text style={styles.tableCell}>{movilidad}</Text>
                </Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCellHeader0}>
                  <Text>
                    {' '}
                    La movilidad es la característica que indica que tan terca o
                    voluble es una persona. Una movilidad de 0, denominada
                    rigidéz corresponde a una persona terca. Una movilidad ideal
                    y en menos grado una movilidad esperada, corresponde a una
                    persona que no es terca y está abierta a dialogar. Las
                    personas inestables son muy volubles.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColTitle}>
                <Text>FACTORES LABORALES DE LA PRUEBA DE COLORES</Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCellHeader2}>
                  <Text></Text>
                </View>
                <View style={styles.tableCellHeader4}>
                  <Text>PERSONALIDAD</Text>
                </View>
                <View style={styles.tableCellHeader4_0}>
                  <Text>ADAPTACION AL MEDIO</Text>
                </View>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData2}> Productividad:</Text>
                </View>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData4}>
                    {dataTable[0].rP1 + ' %'}
                  </Text>
                </View>
                <View style={styles.colHeader_0}>
                  <Text style={styles.tableCellData4}>
                    {dataTable[0].rA1 + ' %'}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData2}> Sociabilidad:</Text>
                </View>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData4}>
                    {' '}
                    {dataTable[2].rP3 + ' %'}
                  </Text>
                </View>
                <View style={styles.colHeader_0}>
                  <Text style={styles.tableCellData4}>
                    {dataTable[2].rA3 + ' %'}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData2}> Creatividad:</Text>
                </View>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData4}>
                    {dataTable[3].rP4 + ' %'}
                  </Text>
                </View>
                <View style={styles.colHeader_0}>
                  <Text style={styles.tableCellData4}>
                    {' '}
                    {dataTable[3].rA4 + ' %'}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData2}> Paciencia:</Text>
                </View>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData4}>
                    {dataTable[4].rP5 + ' %'}
                  </Text>
                </View>
                <View style={styles.colHeader_0}>
                  <Text style={styles.tableCellData4}>
                    {' '}
                    {dataTable[4].rA5 + ' %'}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData2}> Vigor:</Text>
                </View>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData4}>
                    {dataTable[5].rP6 + ' %'}
                  </Text>
                </View>
                <View style={styles.colHeader_0}>
                  <Text style={styles.tableCellData4}>
                    {' '}
                    {dataTable[5].rA6 + ' %'}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData2}> Participación:</Text>
                </View>
                <View style={styles.colHeader}>
                  <Text style={styles.tableCellData4}>
                    {dataTable[6].rP7 + ' %'}
                  </Text>
                </View>
                <View style={styles.colHeader_0}>
                  <Text style={styles.tableCellData4}>
                    {' '}
                    {dataTable[6].rA7 + ' %'}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.colHeader_1_0}>
                  <Text style={styles.tableCellData2_0}> Satisfacción:</Text>
                </View>
                <View style={styles.colHeader_1}>
                  <Text style={styles.tableCellData4}>
                    {dataTable[7].rP8 + ' %'}
                  </Text>
                </View>
                <View style={styles.colHeader_1}>
                  <Text style={styles.tableCellData4}>
                    {' '}
                    {dataTable[7].rA8 + ' %'}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColTitle}>
                <Text>DIAGN&Oacute;STICO DE LA PRUEBA DE COLORES</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  El reporte de la Prueba de Colores para{' '}
                  <Text style={styles.tableCell}>{nameApplicant}</Text>
                </Text>
              </View>
            </View>
            {diagnosticos[0] !== '' ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderAll}>
                  <Text style={styles.tableCellHeader}>
                    <Text style={styles.tableCell}>OBJETIVOS DESEADOS: </Text>
                    {diagnosticos[0]}
                  </Text>
                </View>
              </View>
            ) : (
              <View></View>
            )}
            {diagnosticos[1] !== '' || diagnosticos[2] !== '' ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderAll}>
                  <Text style={styles.tableCellHeader}>
                    <Text style={styles.tableCell}>SITUACIÓN PRESENTE: </Text>
                    {diagnosticos[1] + '' + diagnosticos[2]}
                  </Text>
                </View>
              </View>
            ) : (
              <View></View>
            )}
            {diagnosticos[3] !== '' ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderAll}>
                  <Text style={styles.tableCellHeader}>
                    <Text style={styles.tableCell}>
                      CONDUCTA INADECUADA ANTE LA SITUACIÓN PRESENTE:{' '}
                    </Text>
                    {diagnosticos[3]}
                  </Text>
                </View>
              </View>
            ) : (
              <View></View>
            )}

            {diagnosticos[4] !== '' ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderAll}>
                  <Text style={styles.tableCellHeader}>
                    <Text style={styles.tableCell}>
                      CARACTERÍSTICAS QUE PRODUCEN STRESS EN SITUACIONES
                      FISIOLÓGICAS:{' '}
                    </Text>
                    {diagnosticos[4]}
                  </Text>
                </View>
              </View>
            ) : (
              <View></View>
            )}
            {diagnosticos[5] !== '' ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderAll}>
                  <Text style={styles.tableCellHeader}>
                    <Text style={styles.tableCell}>
                      CARACTERÍSTICAS QUE PRODUCEN STRESS EN SITUACIONES
                      FISIOLÓGICAS:{' '}
                    </Text>
                    {diagnosticos[5]}
                  </Text>
                </View>
              </View>
            ) : (
              <View></View>
            )}
            {diagnosticos[6] !== '' ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderAll}>
                  <Text style={styles.tableCellHeader}>
                    <Text style={styles.tableCell}>
                      CARACTERÍSTICAS QUE PRODUCEN STRESS EN SITUACIONES
                      FISIOLÓGICAS:{' '}
                    </Text>
                    {diagnosticos[6]}
                  </Text>
                </View>
              </View>
            ) : (
              <View></View>
            )}
            {diagnosticos[7] !== '' ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderAll}>
                  <Text style={styles.tableCellHeader}>
                    <Text style={styles.tableCell}>
                      CONDUCTA QUE RESULTA DE LA TENSIÓN:{' '}
                    </Text>
                    {diagnosticos[7]}
                  </Text>
                </View>
              </View>
            ) : (
              <View></View>
            )}
            {diagnosticos[8] !== '' ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderAll}>
                  <Text style={styles.tableCellHeader}>                   
                      CONDUCTA QUE RESULTA DE LA TENSIÓN:{' '}                   
                    {diagnosticos[8]}
                  </Text>
                </View>
              </View>
            ) : (
              <View></View>
            )}
          </Page>
        </Document>
      </PDFViewer>
    )
  }
}
export default PrintPDFResultsApplicantsTestColor
