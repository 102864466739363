import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert } from '@material-ui/lab'
import Slide from '@material-ui/core/Slide'
import { Cancel, Apps } from '@material-ui/icons'
import NumberFormat from 'react-number-format'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import ViewProfilePrivilegesUserClient from 'views/dialog/ViewProfilePrivilegesUserClient'
import LoadGender from 'components/services/LoadGender'
import LoadProfile from 'components/services/LoadProfile'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function NewGestor(props) {
  const classes = useStyles()

  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [errorServer, setErrorServer] = useState(false)
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [privilegesResources, setPrivilegesResources] = useState([])
  const [disabled, setDisabled] = useState(true)
  const [OpenEnable, setOpenEnable] = useState(false)
  const [ProfileID, setProfileID] = useState('')
  const [rows, setRows] = useState([])
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    email: '',
    document_id: '',
    name: '',
    lastName: '',
    gender: '',
    genderData: '',
    birthdate: '',
    profile: '',
    profileData: '',
    errorEmail: false,
    errorDocument_id: false,
    errorLastName: false,
    errorName: false,
  })

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorEmail: false,
      errorDocument_id: false,
      errorLastName: false,
      errorName: false,
    })
    setOpenAlert(false)
  }

  const handleChangeDNI = (event) => {
    setValues({
      ...values,
      document_id: event.target.value,
      errorDocument_id: false,
    })
    setOpenAlert(false)

    DataListApplicant(event.target.value)
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const submitForm = (e) => {
    e.preventDefault()
    setOpenAlert(false)
    setErrorServer(false)

    if (rows.length === 0) {
      if (values.name.length === 0) {
        setValues({ ...values, errorName: true })
        setMensaje('El campo "Nombre" es obligatorio.')
        setOpenAlert(true)
        setError('error')
      } else if (values.lastName.length === 0) {
        setValues({ ...values, errorLastName: true })
        setMensaje('El campo "Apellidos" es obligatorio.')
        setOpenAlert(true)
        setError('error')
      } else if (values.document_id.length === 0) {
        setValues({ ...values, errorDocument_id: true })
        setMensaje('El campo "Documento" es obligatorio.')
        setOpenAlert(true)
        setError('error')
      } else if (values.email.length === 0) {
        setValues({ ...values, errorEmail: true })
        setMensaje('El campo "Correo electrónico" es obligatorio.')
        setOpenAlert(true)
        setError('error')
      } else {
        setoOpenBackdrop(true)

        const dataValue = {
          documentId: values.document_id,
          name: values.name,
          lastName: values.lastName,
          email: values.email !== '' ? values.email.toLowerCase().trim() : '',
          profileId: values.profile,
          admin: '0',
          accountId: localStorage.getItem('account_id'),
        }

        if (values.birthdate !== '') {
          dataValue.birthdate = values.birthdate
        }

        if (values.gender !== '') {
          dataValue.genderId = values.gender
        }

        axios
          .post(`${urlServices}users`, dataValue, {
            headers: {
              application: keyAplication,
              Authorization: 'Bearer ' + keyAuthorization,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setoOpenBackdrop(false)

              setValues({
                email: '',
                name: '',
                lastName: '',
                document_id: '',
                gender: '',
                genderData: '',
                birthdate: '',
                profile: '',
                profileData: '',
              })

              setOpenAlert(true)
              setMensaje('Registro guardado con éxito.')
              setError('success')

              SaveProfileResources(response.data.id)

              props.callBackRefresh()
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              setoOpenBackdrop(false)
              setTimeout(() => {
                localStorage.clear()
                setReturnLogin(true)
              }, 200)
            } else {
              setoOpenBackdrop(false)

              setMensaje('Error de conexión, intente más tarde.')
              setOpenAlert(true)
              setError('error')
              setErrorServer(true)
            }
          })
      }
    } else {
      setMensaje('La cédula ingresada ya se encuentra registrada.')
      setValues({ ...values, errorDocument_id: true })
      setOpenAlert(true)
      setError('error')
      setRows([])
    }
  }

  const DataListApplicant = (documentId) => {
    const search = async () => {
      await axios
        .get(`${urlServices}accounts/${localStorage.getItem('account_id')}`, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            let result = response.data.users.filter(
              (filtered) =>
                filtered.documentId === documentId &&
                Number(filtered.profileId) === 5
            )

            if (result.length > 0) {
              rows.push(result[0])
            }
          }
        })
        .catch((e) => {
          console.log(e)
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            setErrorServer(true)
          }
        })
    }

    search()
  }

  const SaveProfileResources = (id) => {
    if (privilegesResources.length > 0) {
      privilegesResources.map((row) => {
        const dataValue3 = {
          userId: id,
          resourceId: row,
        }

        axios
          .post(`${urlServices}users-resources`, dataValue3, {
            headers: {
              application: keyAplication,
              Authorization: 'Bearer ' + keyAuthorization,
            },
          })
          .then((response) => {})
          .catch((e) => {
            if (e.response.status === 401) {
              setTimeout(() => {
                localStorage.clear()
                setReturnLogin(true)
              }, 200)
            } else {
              console.log(e)
            }
          })
      })
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const callBackPrivileges = (data) => {
    setPrivilegesResources(data)
    setDisabled(false)
    setOpenEnable(false)
  }

  const openEnable = () => {
    setProfileID('5')
    setOpenEnable(true)
  }

  const handleDateChange = (e) => {
    setValues({
      ...values,
      birthdate: e.target.value,
    })
  }

  const getResponseGender = (pro) => {
    if (pro !== null) {
      setValues({
        ...values,
        gender: pro.id,
        genderData: pro,
      })
    } else {
      setValues({
        ...values,
        gender: '',
        genderData: pro,
      })
    }
  }

  const getResponseProfile = (pro) => {
    if (pro !== null) {
      setValues({
        ...values,
        profile: pro.id,
        profileData: pro,
      })
    } else {
      setValues({
        ...values,
        profile: '',
        profileData: pro,
      })
    }
  }

  const handleClosePrivileges = () => {
    setOpenEnable(false)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={3} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>Nuevo operador</div>
                <div className={classes.lineBottom}></div>
                <div className={classes.titleCardFilter}>
                  {' '}
                  <small>
                    Al registrar esta cuenta con correo electrónico, se le
                    enviará al correo una clave temporal para el inicio de
                    sesión. (Revisar tanto la bandeja de entrada como la del
                    spam).
                  </small>
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  name="name"
                  value={values.name}
                  onChange={handleChange('name')}
                  label="Nombres"
                  error={values.errorName}
                  className={classes.textField2}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange('lastName')}
                  label="Apellidos"
                  error={values.errorLastName}
                  className={classes.textField2}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberFormat
                  customInput={TextField}
                  name="document_id"
                  value={values.document_id}
                  onChange={(e) => handleChangeDNI(e)}
                  //prefix={"$"}
                  // thousandSeparator="."
                  //  decimalSeparator=","
                  allowNegative={false}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: 16,
                      marginTop: 25,
                    },
                  }}
                  label="Documento"
                  autoComplete="off"
                  margin="dense"
                  fullWidth
                  required
                  helperText="Cédula, RUC o pasaporte."
                  error={values.errorDocument_id}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  name="email"
                  value={values.email}
                  onChange={handleChange('email')}
                  label="Correo electrónico"
                  error={values.errorEmail}
                  className={classes.textField2}
                  fullWidth
                  //  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="date"
                  label="Fecha de nacimiento"
                  type="date"
                  defaultValue={''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handleDateChange(e)}
                  margin="dense"
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <LoadGender
                  callback={getResponseGender}
                  value={values.genderData}
                  error=""
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <LoadProfile
                  callback={getResponseProfile}
                  value={values.profileData}
                  operators={true}
                  error=""
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <br></br>
                <div className={classes.cardTitle}>
                  {' '}
                  <Apps className={classes.iconFilter} /> Privilegios que tiene
                  el perfil &nbsp;&nbsp;
                  <Button
                    className={classes.btnGreen}
                    onClick={() => openEnable()}
                  >
                    Privilegios
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                {errorServer ? (
                  <Alert severity="error">
                    Error en la consulta al servidor
                  </Alert>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <br></br>
                <center>
                  <Button
                    type="submit"
                    className={classes.buttonSubmit}
                    onClick={submitForm}
                    disabled={disabled}
                  >
                    Guardar
                  </Button>
                </center>
              </Grid>
            </Grid>
            {OpenEnable ? (
              <ViewProfilePrivilegesUserClient
                open={OpenEnable}
                callBack={callBackPrivileges}
                idprofile={ProfileID}
                exit={handleClosePrivileges}
              />
            ) : (
              ''
            )}
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === 'error' ? 'error' : 'success'}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
          <Backdrop
            style={{ zIndex: '9999', color: '#FFFFFF' }}
            open={openBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
