import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import { Cancel } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import axios from 'axios'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function DisableTest(props) {
  const classes = useStyles()
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [severity, setSeverity] = useState('success')
  const [returnLogin, setReturnLogin] = React.useState(false)

  const { urlServices, keyAplication } = useContext(UrlServicesContext)
  let keyAuthorization = localStorage.getItem('Session')

  let data = props.id

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleEnable = () => {
    setOpenAlert(false)
    const dataValue = {
      status: 0,
    }

    axios
      .put(`${urlServices}tests/${data.id}`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response2) => {
        setMensaje('Registro actualizado con éxito.')
        setSeverity('success')
        setOpenAlert(true)
        props.callBackRefresh()
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setMensaje('Error al registrar!!')
          setSeverity('error')
        }
      })
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} md={12}>
              <center>
                <Cancel style={{ fontSize: 50, color: 'ref' }} />
                <br></br>
                <div className={classes.titleCardFilter}>
                  ¿Desea deshabilitar esta prueba?
                </div>
                <br></br>
              </center>
            </Grid>
            <Grid item xs={12} md={12}>
              {openAlert ? (
                <Alert severity={severity === 'error' ? 'error' : 'success'}>
                  {mensaje}
                </Alert>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <center>
                <Button
                  className={classes.buttonSubmit2}
                  onClick={handleEnable}
                >
                  Deshabilitar
                </Button>
              </center>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
