import React, { Fragment, useState, useEffect, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import { Cancel, Person } from '@material-ui/icons'
import axios from 'axios'
import format from 'date-fns/format'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function EnableResults(props) {
  const classes = useStyles()

  const [errorServer, setErrorServer] = useState(false)
  const [loader, setLoader] = useState(true)
  const [score, setScore] = useState(0)
  const [totalScore, setTotalScore] = useState(0)
  const [rowsQuestion, setRowsQuestion] = useState([])
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [cargo, setCargo] = React.useState('')
  const [createdAt, setCreatedAt] = React.useState('')

  let dataTest = props.id
  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    axios
      .get(
        `${urlServices}tests-results/${dataTest.testId}/${dataTest.userId}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          let scoreTotal = 0

          response.data.question.map((items) => {
            items.testresults.map((items2) => {
              scoreTotal += parseFloat(items2.score)
            })
          })

          setRowsQuestion(response.data.question)
          setTotalScore(response.data.totalScore)
          setScore(scoreTotal.toFixed(2))
          setCargo(response.data.testjob[0].name)
          setCreatedAt(response.data.createdAt)
          setTotalQuestions(response.data.totalQuestions)

          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setErrorServer(true)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>
                  Reporte de prueba técnica
                </div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={classes.titleCardFilter}>
                  <Person className={classes.iconTheme} /> {dataTest.userNames}
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div className={classes.titleFilterAction}>
                  Correo: &nbsp;&nbsp;
                  {dataTest.userEmail !== ''
                    ? dataTest.userEmail
                    : 'SIN REGISTRAR'}
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={classes.titleFilterAction}>
                  Identificación: &nbsp;&nbsp;{dataTest.userDocument}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.titleFilterAction}>
                  Prueba: &nbsp;&nbsp;{dataTest.testName}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={classes.titleFilterAction}>
                  Puntaje total: &nbsp;&nbsp;
                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                    {score + ' / ' + totalScore}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={2}>
                <div className={classes.titleFilterAction}>
                  <center>
                    <span style={{ fontWeight: 'bold' }}>FINALIZADA</span>
                  </center>
                </div>
              </Grid>
              <Grid item xs={4} sm={4}>
                <div className={classes.titleFilterAction}>
                  Cargo: &nbsp;&nbsp;
                  <span style={{ fontWeight: 'bold' }}>{cargo}</span>
                </div>
              </Grid>
              <Grid item xs={4} sm={4}>
                <div className={classes.titleFilterAction}>
                  Fecha: &nbsp;&nbsp;
                  <span style={{ fontWeight: 'bold' }}>
                    {createdAt ? (
                      format(new Date(createdAt), 'dd-MM-yyyy')
                    ) : (
                      <></>
                    )}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={2}>
                <div className={classes.titleFilterAction}>
                  Preguntas: &nbsp;&nbsp;
                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                    {totalQuestions}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={2}>
                <div className={classes.titleFilterAction}>
                  Sin respuesta: &nbsp;&nbsp;
                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                    {totalQuestions - rowsQuestion.length}
                  </span>
                </div>
              </Grid>
              {errorServer ? (
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">
                    Error en la consulta con sel servidor.
                  </Alert>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
