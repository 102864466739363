import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'

import Alert from '@material-ui/lab/Alert'
import { Grid, Box, TextField } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { UrlServicesContext } from '../UrlServicesContext'
import axios from 'axios'
import Autocomplete from '@material-ui/lab/Autocomplete'

export default function LoadCities(props) {
  const [results, setResults] = useState([])
  const [resultsNew, setResultsNew] = useState([])
  const [error, setError] = useState(false)
  const [isLoadedd, setIsLoadedd] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let result

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    const consultar = async () => {
      await axios
        .get(`${urlServices}cities`, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then((response) => {
          setResults(response.data.data)
          setIsLoadedd(true)
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            setResults(null)
            setError(true)
            setIsLoadedd(false)
          }
        })
    }
    consultar()

    if (!props.disabled) {
      result = results.filter(
        (filtered) => Number(filtered.stateId) === props.idProvince
      )

      setResultsNew(result)
    }
  }, [urlServices, keyAplication, props.disabled, props.idProvince])

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      {error ? (
        <Alert severity="error">
          Error en la consulta de <b>CIUDADES</b>
        </Alert>
      ) : (
        ''
      )}
      {!isLoadedd ? (
        <Fragment>
          <Grid item xs={12} sm={12}>
            <center>
              <Box sx={{ width: '100%' }}>
                <Skeleton width="100%" height={35} />
              </Box>
            </center>
          </Grid>
        </Fragment>
      ) : (
        <Autocomplete
          id="combo-box-demo"
          options={resultsNew}
          value={props.value}
          onChange={(event, newValue) => {
            props.refresh(newValue)
          }}
          getOptionLabel={(option) => option.name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ciudad"
              error={props.error}
              required
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off', // disable autocomplete and autofill
              }}
              variant="outlined"
              margin="dense"
            />
          )}
          disabled={props.disabled}
          classes={{
            clearIndicatorDirty: 'none',
            popupIndicator: 'none',
          }}
        />
      )}
    </Fragment>
  )
}
