import React, { Fragment, useState, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import { CheckBox, Cancel } from '@material-ui/icons'
import Button from '@material-ui/core/Button'

import {
  cardBodyStyle,
  GreenCheckboxSelected,
} from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const GreenCheckbox = withStyles(GreenCheckboxSelected)((props) => (
  <Checkbox color="default" {...props} />
))

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Dessert (100g serving)',
  },
]

function EnhancedTableHead(props) {
  const { name } = props
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={false}
            colSpan={2}
          >
            <b>{name.name}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function ViewProfilePrivileges(props) {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [rowsModules, setRowsModules] = useState([])
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [selectedDifferent, setSelectedDifferent] = useState([])
  const [selectedIdProfile, setSelectedIdProfile] = useState([])
  const [idProfileResources, setIdProfileResources] = useState([])
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    axios
      .get(`${urlServices}modules`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response) => {
          let resoult = response.data.rows.sort(function (a, b) {
            if (a.order > b.order) {
              return 1
            }
            if (a.order < b.order) {
              return -1
            }
            // a must be equal to b
            return 0
          })
          setRowsModules(resoult)

          axios
            .get(`${urlServices}profiles-resources/profile/${props.id}`, {
              headers: {
                application: keyAplication,
                Authorization: 'Bearer ' + keyAuthorization,
              },
            })
            .then((response1) => {
              let rsl = [],
                rsl2 = []

              let result = response1.data.rows.filter(
                (filtered) => filtered.status !== 0
              )

              result.map((row) => {
                rsl.push(row.resourceId)
                rsl2.push({ id: row.id, resourceId: row.resourceId })
              })

              setSelected(rsl)
              setSelectedDifferent(rsl)
              setSelectedIdProfile(rsl2)
              setIdProfileResources(response1.data.rows)
              setLoader(false)
            })
            .catch((e) => {
              if (e.response.status === 404) {
                setLoader(false)
              } else if (e.response.status === 401) {
                setTimeout(() => {
                  localStorage.clear()
                  setReturnLogin(true)
                }, 200)
              } else {
                console.log(e.response.status)
              }
            })
        },
        (error) => {
          setError(true)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }, [])

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const handleSave = (pro) => (event) => {
    var different = [],
      deleteValue = []

    for (var i0 = 0; i0 < selected.length; i0++) {
      var found0 = false

      for (var j0 = 0; j0 < selectedDifferent.length; j0++) {
        // j < is missed;
        if (selected[i0] === selectedDifferent[j0]) {
          found0 = true
          break
        }
      }
      if (found0 === false) {
        different.push(selected[i0])
      }
    }

    for (var i = 0; i < selectedDifferent.length; i++) {
      var found = false

      for (var j = 0; j < selected.length; j++) {
        // j < is missed;
        if (selectedDifferent[i] === selected[j]) {
          found = true
          break
        }
      }
      if (found === false) {
        deleteValue.push(selectedDifferent[i])
      }
    }

    if (different.length > 0) {
      let result = []

      different.map((row) => {
        result = idProfileResources.filter(
          (filtered) => filtered.resourceId === row
        )

        if (result.length === 0) {
          CreateData(row)
        } else {
          for (var i = 0; i < idProfileResources.length; i++) {
            if (idProfileResources[i].resourceId === row) {
              UpdateData(idProfileResources[i].id)
            }
          }
        }
      })
    }

    if (deleteValue.length > 0) {
      let out = []

      for (var i = 0; i < selectedIdProfile.length; i++) {
        for (var j = 0; j < deleteValue.length; j++) {
          // j < is missed;
          if (selectedIdProfile[i].resourceId === deleteValue[j]) {
            out.push(selectedIdProfile[i])
          }
        }
      }

      DeleteData(out)
    }
    setMensaje('Registro guardado con éxito.')
    setOpenAlert(true)
    setSuccess('success')
  }

  const DeleteData = (data) => {
    data.map((row) => {
      axios
        .delete(`${urlServices}profiles-resources/${row.id}`, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then(
          (response) => {},
          (error) => {}
        )
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            console.log(e)
          }
        })
    })
  }

  const CreateData = (id) => {
    const dataValue = {
      profileId: props.id,
      resourceId: id,
    }

    axios
      .post(`${urlServices}profiles-resources`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response) => {},
        (error) => {}
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const UpdateData = (row) => {
    const dataValue = {
      status: 1,
    }

    axios
      .put(`${urlServices}profiles-resources/${row}`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response) => {},
        (error) => {}
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      aria-labelledby="customized-dialog-title"
      open={props.open}
      onClose={handleCloseDialog(true)}
      keepMounted
      scroll="body"
      style={{ zIndex: 100 }}
      PaperProps={{
        classes: { root: classes.DialogStyle },
      }}
    >
      <DialogContent dividers className={classes.styleDialogContent}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <CheckBox className={classes.iconWhite} /> Permisos
            </div>
          </CardHeader>
          <CardBody>
            <Grid container>
              {error ? (
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">
                    Error en la consulta con sel servidor.
                  </Alert>
                </Grid>
              ) : (
                ''
              )}
              {loader ? (
                <Grid item xs={12} sm={12}>
                  <Box sx={{ width: '100%' }}>
                    <Skeleton width="100%" height={150} />
                  </Box>
                </Grid>
              ) : (
                <Fragment>
                  {rowsModules.map((row1, i1) => {
                    return (
                      <Grid item xs={12} sm={3}>
                        <Paper className={classes.paper}>
                          <TableContainer>
                            <Table
                              aria-labelledby="tableTitle"
                              size="small"
                              aria-label="enhanced table"
                            >
                              <EnhancedTableHead name={row1} />
                              <TableBody>
                                {row1.resource
                                  .sort(function (a, b) {
                                    if (a.order > b.order) {
                                      return 1
                                    }
                                    if (a.order < b.order) {
                                      return -1
                                    }
                                    // a must be equal to b
                                    return 0
                                  })
                                  .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`
                                    let isItemSelected = isSelected(row.id)
                                    return (
                                      <Fragment>
                                        <TableRow
                                          hover
                                          onClick={(event) =>
                                            handleClick(event, row.id)
                                          }
                                          role="checkbox"
                                          aria-checked={isItemSelected}
                                          tabIndex={-1}
                                          key={row.id}
                                          selected={isItemSelected}
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <TableCell padding="checkbox">
                                            <GreenCheckbox
                                              checked={isItemSelected}
                                              inputProps={{
                                                'aria-labelledby': labelId,
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                          >
                                            {row.name}
                                          </TableCell>
                                        </TableRow>
                                      </Fragment>
                                    )
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </Grid>
                    )
                  })}
                </Fragment>
              )}
              <Grid item xs={12} sm={12}>
                <br></br>
                <center>
                  <Button
                    type="submit"
                    className={classes.buttonSubmit}
                    onClick={handleSave()}
                  >
                    Aceptar
                  </Button>
                </center>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleClose}
        >
          <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
            <Alert
              onClose={handleClose}
              severity={success === 'error' ? 'error' : 'success'}
              elevation={6}
              variant="filled"
            >
              {mensaje}
            </Alert>
          </Slide>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog(true)}>
          <Cancel />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
