import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from '@material-ui/core/styles'

// core components
import { Redirect } from 'react-router-dom'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, Assignment, GetApp, Today } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import axios from 'axios'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import Alert from '@material-ui/lab/Alert'
import {
  cardBodyStyle,
  themeCss,
} from 'assets/jss/material-dashboard-react/components/cardBodyStyle'
import { CSVLink } from 'react-csv'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Moment from 'moment'

import LoadJobsAccount from 'components/services/LoadJobsAccount'
import ExportReportGeneralJobsExcel from 'views/Print/ExportReportGeneralJobsExcel'

const defaultMaterialTheme = createTheme(themeCss)

const useStyles = makeStyles(cardBodyStyle)

export default function ReportGeneralFactors(props) {
  const classes = useStyles()

  const [rows, setRows] = useState([])
  const [rowsJobUser, setRowsJobUser] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [resultTotal, setResultTotal] = useState(0)
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [csvHeaders, setCsvHeaders] = useState([
    { label: 'Candidato', key: 'name' },
    { label: 'Documento', key: 'document' },
  ])
  const [csvData, setCsvData] = useState([])
  const [MDTData, setMDTData] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [disabledTo, setdisabledTo] = useState(true)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    testAccount: '',
    testAccountData: [],
    testName: '',
    testTime: '',
    testTotalScore: '',
    created: Date('now'),
    errorTestAccount: false,
    fromDate: null,
    toDate: null,
    yearFrom: null,
    yearTo: null,
  })

  const submitAdd = (e) => {
    e.preventDefault()

    if (values.testAccount === '') {
      setValues({ ...values, errorTestAccount: true })
      setMensaje('Seleccione un cargo.')
      setOpenAlert(true)
      setError('error')
      setoOpenBackdrop(false)
    } else if (values.yearFrom !== null && values.yearTo === null) {
      setValues({ ...values, errorYearTo: true })
      setMensaje('Seleccione una fecha de fin')
      setOpenAlert(true)
      setError('error')
      setoOpenBackdrop(false)
    } else {
      setoOpenBackdrop(true)

      const dataValue = {
        jobId: values.testAccount,
        fromDate: startDate,
        toDate: endDate,
      }

      axios
        .post(`${urlServices}reports/general/factors`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            // Unificar datos
            const unifiedData = response.data.jobUser.reduce((acc, curr) => {
              const applicantId = curr.applicantId

              // Inicializar el applicant en el acumulador si no existe
              if (!acc[applicantId]) {
                acc[applicantId] = {
                  applicant: curr.applicant,
                  applicantId: curr.applicantId,
                  testsResultsTpm: [],
                  // Mantener los datos de la última entrada para este applicant
                  createdAt: curr.createdAt,
                  endDate: curr.endDate,
                  id: curr.id,
                  jobTestId: curr.jobTestId,
                  jobtesttpm: curr.jobtesttpm,
                  startDate: curr.startDate,
                  status: curr.status,
                  timer: curr.timer,
                  updatedAt: curr.updatedAt,
                  userId: curr.userId,
                }
              }

              // Unificar testsResultsTpm
              curr.testsResultsTpm.forEach((result) => {
                const { testId, factorId } = result
                const existingResultIndex = acc[
                  applicantId
                ].testsResultsTpm.findIndex(
                  (r) => r.testId === testId && r.factorId === factorId
                )
                if (existingResultIndex > -1) {
                  // Si ya existe, actualizar el score si es diferente
                  if (
                    acc[applicantId].testsResultsTpm[existingResultIndex]
                      .score !== result.score
                  ) {
                    acc[applicantId].testsResultsTpm[
                      existingResultIndex
                    ].score = result.score
                  }
                } else {
                  // Si no existe, agregar nuevo resultado
                  acc[applicantId].testsResultsTpm.push(result)
                }
              })

              return acc
            }, {})

            setRowsJobUser(Object.values(unifiedData))
            setRows(response.data)
            setResultTotal(Object.values(unifiedData).length)
            setoOpenBackdrop(false)

            handleCSV(
              response.data.jobFactor,
              response.data.jobUser,
              response.data.jobTpm
            )

            handleMDT(
              response.data.jobFactor,
              response.data.jobUser,
              response.data.jobTpm
            )
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            console.log(e)
          }
        })
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const changeJobsAccount = (pro) => {
    setValues({
      ...values,
      testAccount: pro ? pro.id : '',
      testName: pro ? pro.name : '',
      testTime: pro ? pro.time : '',
      testTotalScore: pro ? pro.totalScore : '',
      testAccountData: pro,
      errorTestAccount: false,
    })
  }

  const handleCSV = (jobFactors, data, job) => {
    const newHeaders = jobFactors.map((jobFactor) => {
      return { label: jobFactor.name, key: jobFactor.factor.name }
    })
    setCsvHeaders([
      ...csvHeaders,
      ...newHeaders,
      { label: 'Total', key: 'total' },
      { label: 'Equivalencia', key: 'equivalence' },
    ])

    const newData = data.map((result) => {
      let newResult = {
        name: `${result.applicant.lastName} ${result.applicant.name}`,
        document: result.applicant.documentId,
      }

      let total = 0

      jobFactors.map((jf) => {
        const score = parseFloat(
          result.testsResultsTpm.find((e) => e.factorId == jf.factorId)
            ?.score || 0
        )
        total += parseFloat(score)

        newResult = {
          ...newResult,
          [jf.factor.name]: score.toFixed(2),
        }
      })

      return {
        ...newResult,
        total: total.toFixed(2),
        equivalence: `${total.toFixed(2)}/${job.totalScore}`,
      }
    })
    setCsvData(newData)
  }

  const handleMDT = (jobFactors, data, job) => {
    const newHeaders = jobFactors.map((jobFactor) => {
      return { label: jobFactor.name, key: jobFactor.factor.name }
    })
    setCsvHeaders([
      ...csvHeaders,
      ...newHeaders,
      { label: 'Total', key: 'total' },
      { label: 'Equivalencia', key: 'equivalence' },
    ])

    const newData = data.map((result) => {
      let newResult = {
        name: `${result.applicant.lastName} ${result.applicant.name}`,
        document: result.applicant.documentId,
      }

      let total = 0
      let total_factor = 0

      if (jobFactors.length > 0) {
        jobFactors.map((jf) => {
          const score = parseFloat(
            result.testsResultsTpm.find((e) => e.factorId == jf.factorId)
              ?.score || 0
          )
          total += parseFloat(score)

          if (jf.test.code === 'CEQ') {
            total_factor += parseFloat(score)
          } else {
            total_factor = score
          }

          newResult = {
            ...newResult,
            [jf.name.toLowerCase()]: score.toFixed(2),
            [jf.test.code]: total_factor.toFixed(2),
          }
        })
      }

      return {
        ...newResult,
        total: total.toFixed(2),
        equivalence: `${total.toFixed(2)}/${job.totalScore}`,
      }
    })
    setMDTData(newData)
  }

  const handleDateChangeFrom = (event) => {
    if (event !== null) {
      setValues({
        ...values,
        yearFrom: event,
        yearToMinDate: event,
        yearTo: null,
        errorYearFrom: false,
        errorYearTo: false,
      })

      setStartDate(Moment(event).format('YYYY-MM-DD'))
      setdisabledTo(false)
    } else {
      setValues({
        ...values,
        yearFrom: null,
        yearTo: null,
        errorYearFrom: false,
        errorYearTo: false,
      })
      setStartDate(null)
      setEndDate(null)
      setdisabledTo(true)
    }
  }

  const handleDateChangeTo = (event) => {
    if (event !== null) {
      setValues({
        ...values,
        yearTo: event,
        errorYearTo: false,
      })

      setEndDate(Moment(event).format('YYYY-MM-DD'))
    } else {
      setValues({
        ...values,
        yearTo: null,
        errorYearFrom: false,
      })
      setEndDate(null)
    }
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <div className={classes.cardTitleWhite}>
                <Assignment className={classes.iconWhite} /> Reporte general por
                cargo
              </div>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={10}>
                  <LoadJobsAccount
                    refresh={changeJobsAccount}
                    value={values.testAccountData}
                    error={values.errorTestAccount}
                    all={true}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <br></br>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <KeyboardDatePicker
                        id="date-picker-dialog"
                        label="Desde"
                        onChange={handleDateChangeFrom}
                        value={values.yearFrom}
                        format="yyyy-MM-dd"
                        // views={["year"]}
                        animateYearScrolling={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        variant="inline"
                        keyboardIcon={<Today className={classes.iconTheme} />}
                        autoComplete="off"
                        //InputProps={{ readOnly: true }}
                        autoOk
                        //minDate={new Date()}
                        minDateMessage={false}
                        maxDateMessage={false}
                        error={values.errorYearFrom}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} md={4}>
                  <br></br>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <KeyboardDatePicker
                        id="date-picker-dialog"
                        label="Hasta"
                        onChange={handleDateChangeTo}
                        value={values.yearTo}
                        format="yyyy-MM-dd"
                        // views={["year"]}
                        animateYearScrolling={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        variant="inline"
                        keyboardIcon={
                          <Today
                            className={
                              disabledTo ? classes.iconWhite : classes.iconTheme
                            }
                          />
                        }
                        minDate={values.yearToMinDate}
                        autoComplete="off"
                        //InputProps={{ readOnly: true }}
                        autoOk
                        minDateMessage={false}
                        maxDateMessage={false}
                        disabled={disabledTo}
                        error={values.errorYearTo}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} md={4}>
                  <br></br>
                  <Button
                    className={classes.buttonLeft}
                    onClick={submitAdd}
                    startIcon={<Search />}
                  >
                    Buscar
                  </Button>
                </GridItem>
              </GridContainer>

              {resultTotal > 0 ? (
                <GridContainer>
                  <GridItem xs={12} md={12}>
                    <br></br>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              fontWeight: 'bold',
                              textAlign: 'center',
                              backgroundColor: '#F2F2F2',
                            }}
                          >
                            Candidato{resultTotal > 1 ? 's' : ''}: {resultTotal}{' '}
                            registro
                            {resultTotal > 1 ? 's' : ''}
                            &nbsp;&nbsp;
                            <Button
                              type="submit"
                              className={classes.buttonSubmit1}
                              href={`/reportgeneraljobs?jobs=${
                                values.testAccount
                              }&startDate=${startDate || ''}&endDate=${
                                endDate || ''
                              }`}
                              startIcon={<GetApp style={{ fontSize: 14 }} />}
                              component="a"
                              target="_blank"
                            >
                              PDF
                            </Button>
                            &nbsp;&nbsp;
                            <CSVLink
                              data={csvData}
                              headers={csvHeaders}
                              filename={'reporte_general_por_cargo.csv'}
                            >
                              <Button
                                type="button"
                                className={classes.buttonSubmit1}
                                startIcon={<GetApp style={{ fontSize: 14 }} />}
                              >
                                CSV
                              </Button>
                            </CSVLink>
                            &nbsp;&nbsp;
                            <ExportReportGeneralJobsExcel
                              excelData={MDTData}
                              fileName={'Exportar excel para el MDT'}
                              className={classes.buttonSubmit1}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <br></br>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        <TableRow className={classes.titleFilterAction}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              fontWeight: 'bold',
                              fontSize: 10,
                              width: 250,
                              backgroundColor: '#F2F2F2',
                              textAlign: 'center',
                              textTransform: 'uppercase',
                            }}
                          >
                            Candidato
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              fontWeight: 'bold',
                              fontSize: 10,
                              backgroundColor: '#F2F2F2',
                              textTransform: 'uppercase',
                            }}
                          >
                            Documento
                          </TableCell>

                          {rows.jobFactor.map((rowFactor, jf) => {
                            return (
                              <TableCell
                                key={jf}
                                component="th"
                                scope="row"
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  fontSize: 10,
                                  backgroundColor: '#F2F2F2',
                                  textTransform: 'uppercase',
                                  width: 80,
                                }}
                              >
                                {rowFactor.name}
                              </TableCell>
                            )
                          })}
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              fontWeight: 'bold',
                              fontSize: 10,
                              backgroundColor: '#F2F2F2',
                              textAlign: 'center',
                              textTransform: 'uppercase',
                            }}
                          >
                            Total
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              fontWeight: 'bold',
                              fontSize: 10,
                              backgroundColor: '#F2F2F2',
                              textAlign: 'center',
                              textTransform: 'uppercase',
                              width: 100,
                            }}
                          >
                            Equivalencia
                          </TableCell>
                        </TableRow>
                        {rowsJobUser.map((row1, ju) => {
                          let scoretotal = 0
                          return (
                            <TableRow
                              key={ju}
                              className={classes.titleFilterAction}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                {row1.applicant.lastName +
                                  ' ' +
                                  row1.applicant.name}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                {row1.applicant.documentId}
                              </TableCell>
                              {row1.testsResultsTpm.length > 0 ? (
                                <Fragment>
                                  {rows.jobFactor.map((row2, jf) => {
                                    scoretotal += parseFloat(
                                      row1.testsResultsTpm.find(
                                        (e) => e.factorId == row2.factorId
                                      )?.score || 0
                                    )
                                    return (
                                      <TableCell
                                        key={jf}
                                        component="th"
                                        scope="row"
                                        style={{
                                          fontWeight: 'bold',
                                          textAlign: 'center',
                                          fontSize: 12,
                                        }}
                                      >
                                        {parseFloat(
                                          row1.testsResultsTpm.find(
                                            (e) => e.factorId == row2.factorId
                                          )?.score || 0
                                        ).toFixed(2)}
                                      </TableCell>
                                    )
                                  })}
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {rows.jobFactor.map((factor, jf) => {
                                    return (
                                      <TableCell
                                        key={jf}
                                        component="th"
                                        scope="row"
                                        style={{
                                          fontWeight: 'bold',
                                          textAlign: 'center',
                                          fontSize: 12,
                                        }}
                                      >
                                        {parseFloat(0).toFixed(2)}
                                      </TableCell>
                                    )
                                  })}
                                </Fragment>
                              )}
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  fontSize: 12,
                                }}
                              >
                                {parseFloat(scoretotal).toFixed(2)}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  fontSize: 12,
                                }}
                              >
                                {parseFloat(scoretotal).toFixed(2)} /{' '}
                                {rows.jobTpm.totalScore}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                </GridContainer>
              ) : (
                <div
                  style={{
                    color: '#C5C5C5',
                    padding: '60px 0',
                    fontWeight: 'bold',
                  }}
                >
                  <center> No hay resultados </center>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Backdrop
        style={{ zIndex: '9999', color: '#FFFFFF' }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
          <Alert
            onClose={handleClose}
            severity={error === 'error' ? 'error' : 'success'}
            elevation={6}
            variant="filled"
          >
            {mensaje}
          </Alert>
        </Slide>
      </Snackbar>
    </Fragment>
  )
}
