import React, { Fragment, useState, useEffect, forwardRef } from 'react'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Search, ArrowRightAlt, CheckBox, Cancel } from '@material-ui/icons'
import MaterialTable, { MTableBodyRow } from 'material-table'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const useStyles2 = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(232, 244, 253)!important' } },
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function EnableTestJobsPsychometrics(props) {
  const classes = useStyles()
  const classes2 = useStyles2()

  const [rows, setRows] = useState([])
  const [totalTest, settotalTest] = useState(0)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Prueba',
        field: 'test.name',
        render: (rowData) => {
          return <span>{rowData.test.name}</span>
        },
        width: '60%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
        },
      },
      {
        title: 'Nivel',
        field: 'testlevel.name',
        render: (rowData) => {
          return (
            <span>
              {rowData.testLevelId === null
                ? ' --- '
                : //: rowData.testlevel.name
                  'Asignado'}
            </span>
          )
        },
        width: '10%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Tiempo (Min)',
        field: 'time',
        width: '10%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
    ],
  })

  useEffect(() => {
    setRows(props.id.jobsteststpm)
    settotalTest(props.id.jobsteststpm.length)
  }, [props])

  const HandleSelect = async (data) => {
    if (data.length > 0) {
      props.tests(data)
      props.exit()
    }
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        style={{ zIndex: 100 }}
        //scroll="body"
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Grid container spacing={1} className={classes.containerProfile}>
            <Grid item xs={12} sm={12}>
              <Alert severity="info" icon={<CheckBox fontSize="inherit" />}>
                <b>
                  SELECCIONAR PRUEBA{totalTest > 1 ? 'S' : ''}
                  <span style={{ marginLeft: 5, fontSize: 12 }}>
                    (Total registro: {totalTest})
                  </span>
                </b>
              </Alert>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MaterialTable
                title=""
                columns={state.columns}
                style={{
                  boxShadow: 'unset',
                  border: '1px solid rgb(241, 241, 241)',
                }}
                data={rows}
                localization={{
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected',
                    searchTooltip: 'Filtrar',
                    searchPlaceholder: 'Buscar',
                  },
                  pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Previo',
                    nextTooltip: 'Siguiente',
                    lastTooltip: 'Ultima página',
                  },
                  header: {
                    actions: ' --- ',
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay datos.',
                    filterRow: {
                      filterTooltip: 'Filtro',
                    },
                  },
                }}
                actions={[
                  /* {
                              icon: "edit",
                              tooltip: "Editar",
                              onClick: (event, rowData) => {
                                setOpenEditQuestion(true);
                                setIdRow(rowData);
                              },
                            },*/
                  {
                    tooltip: 'Registrar selección',
                    icon: 'refresh',
                    onClick: (evt, data) => HandleSelect(data),
                  },
                ]}
                icons={tableIcons}
                options={{
                  showTextRowsSelected: false,
                  selection: true,
                  search: true,
                  padding: 1,
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    background: '#F2F2F2',
                    padding: '1%',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  },
                  cellStyle: { border: '1px solid #DDDDDD' },
                }}
                components={{
                  Action: (props) => {
                    if (props.action.icon === 'refresh') {
                      return (
                        <Button
                          onClick={(event) =>
                            props.action.onClick(event, props.data)
                          }
                          className={classes.buttonContactGreen}
                          endIcon={<ArrowRightAlt />}
                        >
                          Agregar
                        </Button>
                      )
                    }
                  },
                  Row: (props) => (
                    <MTableBodyRow className={classes2.tableRow} {...props} />
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
