import React, { useEffect, useContext, useState, Fragment } from 'react'
import { UrlServicesContext } from '../../components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Cancel, Print } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Highcharts from 'highcharts'
import Moment from 'moment'
import HighchartsMore from 'highcharts/highcharts-more'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

HighchartsMore(Highcharts)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const useStyles = makeStyles(cardBodyStyle)

export default function EnableResultsPsychometricsAdminColor(props) {
  const classes = useStyles()
  const [rows1, setRows1] = useState([])
  const [rows2, setRows2] = useState([])
  const [nameTest, setNameTest] = useState([])
  const [nameApplicant, setNameApplicant] = useState('')
  const [dateApplicant, setDateApplicant] = useState('')
  const [loader, setLoader] = useState(true)
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [movilidad, setMovilidad] = React.useState('')
  const [dataTable, setDataTable] = React.useState([])
  const [diagnosticos, setdiagnosticos] = React.useState('')

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    const dataValue = {
      testId: props.id.testId,
      jobId: props.id.jobId,
      userId: props.id.userId,
    }

    axios
      .post(`${urlServices}reports/individual/test-color`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        setNameTest(response.data.job.name)
        setRows1([
          response.data.resultsTpm.resPer1,
          response.data.resultsTpm.resPer2,
          response.data.resultsTpm.resPer3,
          response.data.resultsTpm.resPer4,
          response.data.resultsTpm.resPer5,
          response.data.resultsTpm.resPer6,
          response.data.resultsTpm.resPer7,
          response.data.resultsTpm.resPer8,
        ])

        setRows2([
          response.data.resultsTpm.resAd1,
          response.data.resultsTpm.resAd2,
          response.data.resultsTpm.resAd3,
          response.data.resultsTpm.resAd4,
          response.data.resultsTpm.resAd5,
          response.data.resultsTpm.resAd6,
          response.data.resultsTpm.resAd7,
          response.data.resultsTpm.resAd8,
        ])

        setNameApplicant(
          response.data.resultsTpm.user.name +
            ' ' +
            response.data.resultsTpm.user.lastName
        )
        setDateApplicant(
          Moment(response.data.resultsTpm.createdAt).format('DD-MM-YYYY')
        )

        setMovilidad(response.data.dataResults.movilidad)
        setDataTable(response.data.dataResults.dataTable)
        setdiagnosticos(response.data.dataResults.diagnosticos)
        //console.log(response.data)

        setLoader(false)
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setLoader(false)
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const handleCloseDialog = () => (event) => {
    props.exit()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Grid container spacing={1} className={classes.containerProfile}>
            <Grid item xs={6} sm={6}>
              <div className={classes.titleCardFilter}>{nameTest}</div>
              <div className={classes.lineBottom}></div>
              <br></br>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div style={{ float: 'right', marginTop: 5 }}>
                <Button
                  type="submit"
                  className={classes.buttonSubmit1}
                  href={
                    '/printresultstestcolor?test=' +
                    props.id.testId +
                    '&job=' +
                    props.id.jobId +
                    '&user=' +
                    props.id.userId
                  }
                  startIcon={<Print style={{ fontSize: 14 }} />}
                  component="a"
                  target="_blank"
                >
                  Imprimir
                </Button>
              </div>
            </Grid>
            <Fragment>
              {loader ? (
                <Grid item xs={12} sm={12}>
                  <Box sx={{ width: '100%' }}>
                    <Skeleton width="100%" height={400} />
                  </Box>
                </Grid>
              ) : (
                <Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      textAlign: 'center',
                      fontSize: 18,
                      color: 'blue',
                      marginBottom: 30,
                      letterSpacing: 10,
                    }}
                  >
                    <b>EL TEST DE COLORES </b>
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ fontSize: 14 }}>
                    <b>Nombre del candidato: </b>
                    {nameApplicant}
                    <br></br>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    style={{ fontSize: 14, textAlign: 'right' }}
                  >
                    <b>Fecha: </b>
                    {dateApplicant}
                    <br></br>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ fontSize: 14 }}>
                    <b>
                      Estos son los números asociados con las filas escogidas:
                    </b>
                    <br></br>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <table>
                      <tr style={{ fontSize: 14, fontWeight: '400' }}>
                        {rows1.map((number, index) => (
                          <td style={{ padding: '0 25px' }} key={index}>
                            {number}
                          </td>
                        ))}
                      </tr>
                    </table>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <table>
                      <tr style={{ fontSize: 14, fontWeight: '400' }}>
                        {rows2.map((number, index) => (
                          <td style={{ padding: '0 25px' }} key={index}>
                            {number}
                          </td>
                        ))}
                      </tr>
                    </table>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ fontSize: 14 }}>
                    <b>La movilidad de estos números es: {movilidad}</b>
                    <br></br>
                    <br></br>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      fontSize: 12,
                      textAlign: 'justify',
                      border: '1px solid red',
                      padding: 4,
                      lineHeight: 1.3,
                    }}
                  >
                    <b>
                      La movilidad es la característica que indica que tan terca
                      o voluble es una persona. Una movilidad de 0, denominada
                      rigidéz corresponde a una persona terca. Una movilidad
                      ideal y en menos grado una movilidad esperada, corresponde
                      a una persona que no es terca y está abierta a dialogar.
                      Las personas inestables son muy volubles.
                    </b>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      textAlign: 'center',
                      fontSize: 14,
                      color: 'blue',
                      marginTop: 30,
                      letterSpacing: 10,
                    }}
                  >
                    <b>FACTORES LABORALES DE LA PRUEBA DE COLORES</b>
                    <br></br>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <center>
                      <table
                        style={{
                          border: '1px solid #000',
                          borderCollapse: 'collapse',
                          width: 500,
                        }}
                      >
                        <tr>
                          <td></td>
                          <td
                            style={{
                              fontWeight: 'bold',
                              fontSize: 12,
                              color: 'gray',
                              textAlign: 'center',
                              border: '1px solid #000',
                            }}
                          >
                            PERSONALIDAD
                          </td>
                          <td
                            style={{
                              fontWeight: 'bold',
                              fontSize: 12,
                              color: 'gray',
                              textAlign: 'center',
                              border: '1px solid #000',
                            }}
                          >
                            ADAPTACION AL MEDIO
                          </td>
                        </tr>
                        <tr style={{ fontSize: 12, fontWeight: 'bold' }}>
                          <td
                            style={{
                              border: '1px solid #000',
                              padding: '0 5px',
                            }}
                          >
                            Productividad:
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[0].rP1 + ' %'}
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[0].rA1 + ' %'}
                          </td>
                        </tr>
                        <tr style={{ fontSize: 12, fontWeight: 'bold' }}>
                          <td
                            style={{
                              border: '1px solid #000',
                              padding: '0 5px',
                            }}
                          >
                            Empuje o Agresividad:
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[1].rP2 + ' %'}
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[1].rA2 + ' %'}
                          </td>
                        </tr>
                        <tr style={{ fontSize: 12, fontWeight: 'bold' }}>
                          <td
                            style={{
                              border: '1px solid #000',
                              padding: '0 5px',
                            }}
                          >
                            Sociabilidad:
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[2].rP3 + ' %'}
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[2].rA3 + ' %'}
                          </td>
                        </tr>
                        <tr style={{ fontSize: 12, fontWeight: 'bold' }}>
                          <td
                            style={{
                              border: '1px solid #000',
                              padding: '0 5px',
                            }}
                          >
                            Creatividad:
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[3].rP4 + ' %'}
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[3].rA4 + ' %'}
                          </td>
                        </tr>
                        <tr style={{ fontSize: 12, fontWeight: 'bold' }}>
                          <td
                            style={{
                              border: '1px solid #000',
                              padding: '0 5px',
                            }}
                          >
                            Paciencia:
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[4].rP5 + ' %'}
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[4].rA5 + ' %'}
                          </td>
                        </tr>
                        <tr style={{ fontSize: 12, fontWeight: 'bold' }}>
                          <td
                            style={{
                              border: '1px solid #000',
                              padding: '0 5px',
                            }}
                          >
                            Vigor:
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[5].rP6 + ' %'}
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[5].rA6 + ' %'}
                          </td>
                        </tr>
                        <tr style={{ fontSize: 12, fontWeight: 'bold' }}>
                          <td
                            style={{
                              border: '1px solid #000',
                              padding: '0 5px',
                            }}
                          >
                            Participación:
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[6].rP7 + ' %'}
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[6].rA7 + ' %'}
                          </td>
                        </tr>
                        <tr style={{ fontSize: 12, fontWeight: 'bold' }}>
                          <td
                            style={{
                              border: '1px solid #000',
                              padding: '0 5px',
                            }}
                          >
                            Satisfacción:
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[7].rP8 + ' %'}
                          </td>
                          <td
                            style={{
                              border: '1px solid #000',
                              textAlign: 'center',
                            }}
                          >
                            {dataTable[7].rA8 + ' %'}
                          </td>
                        </tr>
                      </table>
                    </center>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      textAlign: 'center',
                      fontSize: 14,
                      color: 'blue',
                      marginTop: 15,
                      letterSpacing: 10,
                    }}
                  >
                    <b>DIAGN&Oacute;STICO DE LA PRUEBA DE COLORES</b>
                    <br></br>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ fontSize: 14, textAlign: 'justify' }}
                  >
                    El reporte de la Prueba de Colores para{' '}
                    <b>{nameApplicant}</b>, es el siguiente:
                    <br></br>
                    {diagnosticos[0] !== '' ? (
                      <>
                        <br></br>
                        <b>OBJETIVOS DESEADOS: </b>
                        {diagnosticos[0]}
                      </>
                    ) : (
                      ''
                    )}
                    {diagnosticos[1] !== '' || diagnosticos[2] !== '' ? (
                      <>
                        <br></br>
                        <b>SITUACIÓN PRESENTE: </b>
                        {diagnosticos[1] + '' + diagnosticos[2]}
                      </>
                    ) : (
                      ''
                    )}
                    {diagnosticos[3] !== '' ? (
                      <>
                        <br></br>
                        <b>CONDUCTA INADECUADA ANTE LA SITUACIÓN PRESENTE: </b>
                        {diagnosticos[3]}
                      </>
                    ) : (
                      ''
                    )}
                    {diagnosticos[4] !== '' ? (
                      <>
                        <br></br>
                        <b>
                          CARACTERÍSTICAS QUE PRODUCEN STRESS EN SITUACIONES
                          FISIOLÓGICAS:{' '}
                        </b>
                        {diagnosticos[4]}
                      </>
                    ) : (
                      ''
                    )}
                    {diagnosticos[5] !== '' ? (
                      <>
                        <br></br>
                        <b>
                          CARACTERÍSTICAS QUE PRODUCEN STRESS EN SITUACIONES
                          FISIOLÓGICAS:{' '}
                        </b>
                        {diagnosticos[5]}
                      </>
                    ) : (
                      ''
                    )}
                    {diagnosticos[6] !== '' ? (
                      <>
                        <br></br>
                        <b>
                          CARACTERÍSTICAS QUE PRODUCEN STRESS EN SITUACIONES
                          FISIOLÓGICAS:{' '}
                        </b>
                        {diagnosticos[6]}
                      </>
                    ) : (
                      ''
                    )}
                    {diagnosticos[7] !== '' ? (
                      <>
                        <br></br>
                        <b>CONDUCTA QUE RESULTA DE LA TENSIÓN: </b>
                        {diagnosticos[7]}
                      </>
                    ) : (
                      ''
                    )}
                    {diagnosticos[8] !== '' ? (
                      <>
                        <br></br>
                        CONDUCTA QUE RESULTA DE LA TENSIÓN:&nbsp;
                        {diagnosticos[8]}
                      </>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Fragment>
              )}
            </Fragment>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
