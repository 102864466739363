import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Search, CheckBox, TrendingFlat } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import MaterialTable from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import ViewProfilePrivileges from "views/dialog/ViewProfilePrivileges";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function ProfilesListAdmin() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [idRow, setIdRow] = useState("");
  const [openBackdrop, setoOpenBackdrop] = useState(false);
  const [OpenEnable, setOpenEnable] = useState(false);
  const [returnLogin, setReturnLogin] = React.useState(false);

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  };

  const [state] = useState({
    columns: [
      {
        title: "ID",
        field: "id",
        width: "3%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
          textAlign: "CENTER",
        },
      },
      {
        title: "Nombre perfil",
        field: "name",
        width: "20%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
        },
      },
      {
        title: "Permisos",
        field: "status",
        render: (rowData) => {
          return (
            <Button
              className={classes.btnGreen}
              onClick={() => openEnable(rowData.id)}
              endIcon={
                <TrendingFlat
                  style={{ verticalAlign: "middle", fontSizes: 8 }}
                />
              }
            >
              Permisos
            </Button>
          );
        },
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
        sorting: false,
      },
    ],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    axios
      .get(`${urlServices}profiles`, {
        headers: {
          application: keyAplication,
        },
      })
      .then((response) => {
        let result = response.data.rows.filter(
          (filtered) => Number(filtered.id) !== 1
        );
        setRows(result);
        setLoader(false);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 200);
        } else {
          setRows([]);
          setError(true);
        }
      });
  }, [keyAplication, urlServices]);

  const RefreshTable = () => {
    setoOpenBackdrop(true);

    axios
      .get(`${urlServices}profiles`, {
        headers: {
          application: keyAplication,
        },
      })
      .then((response) => {
        let result = response.data.rows.filter(
          (filtered) => Number(filtered.id) !== 1
        );
        setRows(result);
        setoOpenBackdrop(false);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setoOpenBackdrop(false);
          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 200);
        } else {
          setoOpenBackdrop(false);
          setRows([]);
          setError(true);
        }
      });
  };

  const handleClose = () => {
    setOpenEnable(false);
  };

  const openEnable = (rows) => {
    setIdRow(rows);
    setOpenEnable(true);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <CheckBox className={classes.iconWhite} /> Listado de perfiles y
              permisos
            </div>
          </CardHeader>
          <CardBody>
            {error ? (
              <Alert severity="error">
                Error en la consulta con sel servidor.
              </Alert>
            ) : (
              ""
            )}
            {loader ? (
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ width: "100%" }}>
                    <Skeleton
                      width="40%"
                      height={40}
                      style={{ float: "right" }}
                    />
                    <Skeleton width="100%" height={300} />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <MaterialTable
                  title=""
                  columns={state.columns}
                  style={{
                    boxShadow: "unset",
                    border: "1px solid rgb(241, 241, 241)",
                  }}
                  data={rows}
                  localization={{
                    pagination: {
                      labelRowsSelect: "Filas",
                      labelDisplayedRows: " {from}-{to} de {count}",
                      firstTooltip: "Primera página",
                      previousTooltip: "Previo",
                      nextTooltip: "Siguiente",
                      lastTooltip: "Ultima página",
                    },
                    toolbar: {
                      // nRowsSelected: '{0} row(s) selected',
                      searchTooltip: "Filtrar",
                      searchPlaceholder: "Buscar",
                    },
                    header: {
                      actions: " --- ",
                    },
                    body: {
                      emptyDataSourceMessage: "No hay datos.",
                      filterRow: {
                        filterTooltip: "Filtro",
                      },
                    },
                  }}
                  icons={tableIcons}
                  options={{
                    search: true,
                    padding: "default",
                    filtering: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    headerStyle: {
                      background: "#F2F2F2",
                      padding: "1%",
                      fontWeight: "bold",
                      textAlign: "center",
                    },
                    cellStyle: { border: "1px solid #DDDDDD" },
                  }}
                />
              </Fragment>
            )}
          </CardBody>
        </Card>
      </GridItem>
      {OpenEnable ? (
        <ViewProfilePrivileges
          open={OpenEnable}
          exit={handleClose}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
      <Backdrop
        style={{ zIndex: "9999", color: "#FFFFFF" }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </GridContainer>
  );
}
