import React, { useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

// @material-ui/core components
import { makeStyles, withStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Cancel, AddCircle, Delete, Save } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import {
  Button,
  Grid,
  Dialog,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Snackbar,
  Slide,
} from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { green } from '@material-ui/core/colors'
import axios from 'axios'
import Alert from '@material-ui/lab/Alert'

import img from 'assets/img/loading.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FAFAFA',
    },
  },
}))(TableRow)

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

export default function NewAnswer(props) {
  const classes = useStyles()

  const [rowsAnswer, setRowsAnswer] = useState([])
  const [loader, setloader] = React.useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState(false)
  const [disabled, setDisabled] = useState(true)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    answers: '',
    answerName: '',
    errorAnswerName: false,
  })

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorAnswerName: false,
    })
    setOpenAlert(false)
  }

  const handleRadioChange = (event) => {
    setValues({
      ...values,
      answers: event.target.value,
    })
  }

  const handleAdd = () => {
    setError('')

    if (values.answerName === '') {
      setValues({ ...values, errorAnswerName: true })
      setMensaje('Agregue una respuesta.')
      setOpenAlert(true)
      setError('error')
    } else if (values.answers.length === 0) {
      setMensaje('Seleccion una opción de verdadero o falso.')
      setOpenAlert(true)
      setError('error')
    } else {
      setRowsAnswer([
        ...rowsAnswer,
        {
          statement: values.answerName,
          rightAnswer: values.answers,
          questionId: props.data.id,
        },
      ])

      setValues({
        ...values,
        answerName: '',
        answers: '',
      })

      setDisabled(false)
    }
  }

  const handleRemoveClick = (i) => {
    const list = [...rowsAnswer]
    list.splice(i, 1)
    setRowsAnswer(list)
  }

  const handleSaveAnswers = async () => {
    if (rowsAnswer.length > 0) {
      setloader(true)

      try {
        const insertPromises = rowsAnswer.map(async (row1) => {
          const dataAnswers = {
            statement: row1.statement,
            rightAnswer: row1.rightAnswer,
            questionId: row1.questionId,
          }

          const result = await insert(dataAnswers) // Insertar y obtener la respuesta
          return result.data // Devolver los datos insertados
        })

        // Esperar a que todas las promesas se resuelvan y obtener las respuestas insertadas
        const newAnswers = await Promise.all(insertPromises)

        // Actualizar el array props.data.answer con las nuevas respuestas
        props.data.answer = [...props.data.answer, ...newAnswers]

        setOpenAlert(true)
        setMensaje('Respuestas registradas con éxito.')
        setError('success')
        setRowsAnswer([])
        setDisabled(true)
        props.callBackRefresh(props.data.answer) // Enviar las respuestas actualizadas
        setloader(false)
      } catch (error) {
        setOpenAlert(true)
        setMensaje('Error al registrar respuestas.')
        setError('error')
        setloader(false)
        console.error('Error al actualizar respuestas:', error)
      }
    } else {
      setOpenAlert(true)
      setMensaje('Debe agregar alguna respuesta.')
      setError('error')
    }
  }

  const insert = async (dataAnswers) => {
    try {
      const response = await axios.post(`${urlServices}answers`, dataAnswers, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      return response // Retornar la respuesta completa
    } catch (e) {
      if (e.response.status === 401) {
      } else {
        console.log(e)
      }
    }
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      // onClose={handleCloseDialog(true)}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      keepMounted
      scroll="body"
      PaperProps={{
        classes: { root: classes.DialogStyle },
      }}
    >
      <DialogContent dividers className={classes.styleDialogContent}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <div className={classes.cardTitleWhite}>
                  <AddCircle className={classes.iconWhite} /> Agregar respuesta
                </div>
              </CardHeader>
              <CardBody>
                <Grid
                  container
                  spacing={1}
                  style={{
                    borderRadius: 5,
                    padding: 10,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ paddingTop: 8, fontSize: 16, fontWeight: 'bold' }}
                  >
                    Respuesta
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="answerName"
                      value={values.answerName}
                      error={values.errorAnswerName}
                      onChange={handleChange('answerName')}
                      fullWidth
                      required
                      label="Respuesta"
                      margin="dense"
                      autoComplete="off"
                      variant="outlined"
                      style={{ background: 'white' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      component="fieldset"
                      style={{ paddingTop: 8, paddingLeft: 25 }}
                    >
                      <RadioGroup
                        row
                        aria-label="position"
                        name="answers"
                        value={values.answers}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value="true"
                          control={<GreenRadio />}
                          label="Verdadero"
                          style={{ color: 'dimgray' }}
                        />
                        <FormControlLabel
                          value="false"
                          control={<GreenRadio />}
                          label="Falso"
                          style={{ color: 'dimgray' }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <center>
                      <Button
                        className={classes.buttonLeft}
                        onClick={handleAdd}
                        startIcon={<AddCircle />}
                      >
                        Agregar
                      </Button>
                    </center>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ paddingLeft: 70, paddingRight: 70 }}
                  >
                    {rowsAnswer.length > 0 ? (
                      <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableBody>
                          <TableCell component="th" scope="row">
                            <b>Respuesta</b>{' '}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <center>
                              <b>Opción</b>{' '}
                            </center>
                          </TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          {rowsAnswer.map((row1, i1) => {
                            return (
                              <StyledTableRow key={i1}>
                                <TableCell
                                  // align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {row1.statement}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {row1.rightAnswer === 'true'
                                    ? 'Verdadero'
                                    : 'Falso'}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  <Delete
                                    style={{
                                      cursor: 'pointer',
                                      float: 'right',
                                    }}
                                    onClick={() => handleRemoveClick(i1)}
                                  />
                                </TableCell>
                              </StyledTableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleClose}
        >
          <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
            <Alert
              onClose={handleClose}
              severity={error === 'error' ? 'error' : 'success'}
              elevation={6}
              variant="filled"
            >
              {mensaje}
            </Alert>
          </Slide>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        {loader ? (
          <center>
            <img
              src={img}
              alt="loader"
              style={{ width: 60, marginRight: 30 }}
            />
          </center>
        ) : (
          <>
            <Button
              type="submit"
              className={classes.buttonSubmit}
              onClick={handleSaveAnswers}
              disabled={disabled}
              startIcon={<Save />}
            >
              Guardar
            </Button>
            <Button onClick={handleCloseDialog(true)}>
              <Cancel />
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
