import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import { Instagram, Facebook } from "@material-ui/icons";
import Link from "@material-ui/core/Link";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function FooterAccount() {
  const classes = useStyles();
  return (
    <Hidden smDown>
      <div className={classes.footer2}>
        <p className={classes.right}>
          <span
            //onClick={props.handleDrawerToggle}
            style={{ verticalAlign: "middle", cursor: "pointer" }}
          >
            <Link
              href="#"
              target="_blank"
              color="inherit"
              className={classes.blockReds}
            >
              <Instagram />
            </Link>
          </span>
          <span
            //  onClick={props.handleDrawerToggle}
            style={{
              padding: "0 15px",
              verticalAlign: "middle",
              cursor: "pointer",
            }}
          >
            <Link
              href="#"
              target="_blank"
              color="inherit"
              className={classes.blockReds}
            >
              <Facebook />
            </Link>
          </span>

          <span>POLYGRAPH - {1900 + new Date().getYear()} COPYRIGHT&copy;</span>
        </p>
      </div>
    </Hidden>
  );
}
