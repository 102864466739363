/*eslint-disable*/
import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'

import { ArrowRight, ExpandMore, Remove, ExpandLess } from '@material-ui/icons'

// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.js'

import styles from 'assets/jss/material-dashboard-react/components/sidebarStyle.js'

const useStyles = makeStyles(styles)

export default function Sidebar(props) {
  const { logo, image, routes } = props

  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [menu, setMenu] = useState(0)

  const linksUrl = (url) => () => {
    if (url === '/dashboard') setOpen(false)
    props.url(url)
  }

  const handleOpen = (pro) => () => {
    let statusMenu = open
    if (menu != pro) {
      statusMenu = false
    }

    setOpen(!statusMenu)
    setMenu(pro)
  }

  var links = (
    <List className={classes.list}>
      <ListItem
        button
        onClick={linksUrl('/dashboard')}
        className={classes.itemLinkCollapse}
      >
        <Remove />
        <ListItemText
          primary="Inicio"
          className={classes.itemLinkCollapseName}
          disableTypography={true}
        />
      </ListItem>
      {routes.map((prop, key) => {
        return (
          <Fragment key={prop.id}>
            <ListItem
              key={prop.id}
              button
              onClick={handleOpen(prop.id)}
              className={classes.itemLinkCollapse}
            >
              <Remove />
              <ListItemText
                primary={prop.name}
                className={classes.itemLinkCollapseName}
                disableTypography={true}
              />
              {menu === prop.id && open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse
              in={menu === prop.id ? open : false}
              timeout="auto"
              unmountOnExit
              style={{ backgroundColor: 'rgb(247, 247, 247)' }}
            >
              {
                <List component="div" key={key}>
                  {prop.resource.map((prop2) => {
                    return (
                      <ListItem
                        key={prop2.id}
                        button
                        onClick={linksUrl(prop2.path)}
                        className={classes.itemLinkCollapseSubmenu}
                      >
                        <ArrowRight
                          style={{
                            minWidth: ' 0px !important',
                            color: '#000',
                          }}
                        />
                        <ListItemText
                          primary={prop2.name}
                          className={classes.itemLinkCollapseNameSub}
                          disableTypography={true}
                        />
                      </ListItem>
                    )
                  })}
                </List>
              }
            </Collapse>
          </Fragment>
        )
      })}
    </List>
  )
  var brand = (
    <div className={classes.logo}>
      <img src={logo} alt="logo" className={classes.img} />
    </div>
  )
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? 'left' : 'right'}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          onClose={props.handleDrawerToggle}
          anchor={props.rtlActive ? 'right' : 'left'}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : (
            <div className={classes.background} />
          )}
        </Drawer>
      </Hidden>
    </div>
  )
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
}
