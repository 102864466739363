import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'
import { Cancel } from '@material-ui/icons'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'
import { green } from '@material-ui/core/colors'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Moment from 'moment'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export default function NewJobPsychometrics(props) {
  const classes = useStyles()

  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [openAlertSuccess, setopenAlertSuccess] = useState(false)
  const [mensaje_success, setmensaje_success] = useState('')
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    name: '',
    equivalent: '',
    totalScore: '',
    managePasswords: false,
    dateSelectStart: '',
    dateSelectEnd: '',
    disabledEnd: true,
    dateStartEnd: false,
    takePhoto: false,
    showApplicantreport: false,
    errorName: false,
    errorTotalScore: false,
    errorEquivalent: false,
  })

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleChange = (prop) => (event) => {
    setopenAlertSuccess(false)

    setValues({
      ...values,
      [prop]: event.target.value,
      errorEquivalent: false,
      errorTotalScore: false,
      errorName: false,
    })
    setOpenAlert(false)
  }

  const submitForm = (e) => {
    e.preventDefault()
    setError('')
    setopenAlertSuccess(false)

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true })
      setMensaje('El campo "Nombre" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.totalScore.length === 0) {
      setValues({ ...values, errorTotalQuestions: true })
      setMensaje('El campo "Puntaje total" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else {
      const dataValue = {
        name: values.name,
        totalScore: values.totalScore,
        equivalent: values.equivalent,
        managePasswords:
          values.managePasswords === '' ? false : values.managePasswords,
        activationTime: values.dateStartEnd,
        takePhoto: values.takePhoto,
        showApplicantreport: values.showApplicantreport,
      }

      if (values.dateStartEnd) {
        dataValue.activationStart = Moment(values.dateSelectStart).format(
          'YYYY-MM-DD HH:mm:ss'
        )
        dataValue.activationEnd = Moment(values.dateSelectEnd).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      }

      axios
        .post(`${urlServices}jobs-tpm`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then((response) => {
          setopenAlertSuccess(true)
          setmensaje_success('Registro guardado con éxito.')
          props.callBackRefresh()

          setValues({
            name: '',
            equivalent: '',
            totalScore: '',
            managePasswords: false,
            dateSelectStart: '',
            dateSelectEnd: '',
            disabledEnd: true,
            dateStartEnd: false,
            takePhoto: false,
            showApplicantreport: false,
          })
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            setMensaje('Error de conexión, intente más tarde.')
            setOpenAlert(true)
            setError('error')
          }
        })
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  const handleCheckboxPassChange = (e) => {
    setValues({
      ...values,
      managePasswords: e.target.checked,
    })
  }

  const handleChangeDateStart = (e) => {
    setValues({
      ...values,
      dateSelectStart: e.target.value,
      disabledEnd: e.target.value !== '' ? false : true,
      dateSelectEnd: '',
    })
  }

  const handleChangeDateEnd = (e) => {
    setValues({
      ...values,
      dateSelectEnd: e.target.value,
    })
  }

  const handleCheckboxChange = (e) => {
    setValues({
      ...values,
      dateStartEnd: e.target.checked,
      dateSelectEnd: '',
      disabledEnd: true,
      dateSelectStart: '',
    })
  }

  const handleCheckboxTakePhoto = (e) => {
    setValues({
      ...values,
      takePhoto: e.target.checked,
    })
  }

  const handleShowApplicantreport = (e) => {
    setValues({
      ...values,
      showApplicantreport: e.target.checked,
    })
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        //onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={2} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>Nuevo cargo</div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    name="name"
                    value={values.name}
                    error={values.errorName}
                    onChange={handleChange('name')}
                    fullWidth
                    required
                    label="Nombre"
                    margin="dense"
                    autoComplete="off"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <NumberFormat
                  customInput={TextField}
                  name="totalScore"
                  value={values.totalScore}
                  error={values.errorTotalScore}
                  onChange={handleChange('totalScore')}
                  allowNegative={false}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: 16,
                    },
                  }}
                  label="Puntaje total"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  placeholder="1 - 100"
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <NumberFormat
                  customInput={TextField}
                  name="equivalent"
                  value={values.equivalent}
                  error={values.errorEquivalent}
                  onChange={handleChange('equivalent')}
                  allowNegative={false}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: 16,
                    },
                  }}
                  label="Equivalencia"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  placeholder="1% - 100%"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={values.dateStartEnd}
                      onClick={(event) => {
                        handleCheckboxChange(event)
                      }}
                      name="dateStartEnd"
                    />
                  }
                  label="Fecha de activación (Si se desea seleccionar una fecha y hora para aplicar la prueba)"
                  style={{ color: 'dimgray' }}
                />
              </Grid>

              {values.dateStartEnd ? (
                <Fragment>
                  <Grid item xs={6} sm={6}>
                    <center>
                      <TextField
                        name="dateSelectStart"
                        label="Desde"
                        type="datetime-local"
                        value={values.dateSelectStart}
                        onChange={(e) => handleChangeDateStart(e)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                      />
                    </center>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <center>
                      <TextField
                        name="dateSelectEnd"
                        label="Hasta"
                        type="datetime-local"
                        value={values.dateSelectEnd}
                        onChange={(e) => handleChangeDateEnd(e)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        mindate={values.dateSelectStart}
                        InputProps={{
                          inputProps: { min: values.dateSelectStart },
                        }}
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                        disabled={values.disabledEnd}
                      />
                    </center>
                  </Grid>
                </Fragment>
              ) : (
                ''
              )}
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={values.managePasswords}
                      onClick={(event) => {
                        handleCheckboxPassChange(event)
                      }}
                      name="managePasswords"
                    />
                  }
                  label="Gestionar contraseñas (Generar contraseñas aleatorias para cada candidato asignado a la prueba)"
                  style={{ color: 'dimgray' }}
                />
              </Grid>
              {props.activatePhoto ? (
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={values.takePhoto}
                        onClick={(event) => {
                          handleCheckboxTakePhoto(event)
                        }}
                        name="takePhoto"
                      />
                    }
                    label="Tomar foto al candidato (Se aplica a todas las pruebas agregadas al cargo)"
                    style={{ color: 'dimgray' }}
                  />
                </Grid>
              ) : (
                <></>
              )}
              {props.activateApplicantreport ? (
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={values.showApplicantreport}
                        onClick={(event) => {
                          handleShowApplicantreport(event)
                        }}
                        name="showApplicantreport"
                      />
                    }
                    label="Mostrar reporte de resultados al candidato"
                    style={{ color: 'dimgray' }}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} sm={12}>
                <br></br>
                <br></br>
                <center>
                  <Button
                    type="submit"
                    className={classes.buttonSubmit}
                    onClick={submitForm}
                  >
                    Guardar
                  </Button>
                </center>
              </Grid>
              <Grid item xs={12} sm={12}>
                <center>
                  {openAlertSuccess ? (
                    <Alert severity="success">{mensaje_success}</Alert>
                  ) : (
                    ''
                  )}
                </center>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === 'error' ? 'error' : 'success'}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
