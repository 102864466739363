import React, { Fragment, useState, useContext, forwardRef } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Search, ArrowRightAlt, CheckBox } from '@material-ui/icons'
import axios from 'axios'
import MaterialTable, { MTableBodyRow } from 'material-table'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const useStyles2 = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(232, 244, 253)!important' } },
})

export default function TestApplicantsSelect(props) {
  const classes = useStyles()
  const classes2 = useStyles2()

  const [errorServer, setErrorServer] = useState(false)
  const [error, setError] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Nombre',
        field: 'name',
        render: (rowData) => {
          return <span>{rowData.name + ' ' + rowData.lastName}</span>
        },
        width: '60%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
        },
      },
      {
        title: 'Identificación',
        field: 'documentId',
        width: '10%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Correo electrónico',
        field: 'email',
        render: (rowData) => {
          return (
            <Fragment>
              <span style={{ fontSize: 12 }}>
                {rowData.email !== '' ? rowData.email : 'SIN REGISTRAR'}
              </span>
            </Fragment>
          )
        },
        width: '30%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
    ],
  })

  const HandleSelect = async (data) => {
    let n = 0

    if (data.length > 0) {
      await data.forEach((items) => {
        props.id.jobsteststpm.forEach((items2) => {
          const dataValues = {
            applicantId: items.id,
            jobTestId: items2.id,
          }

          axios
            .post(`${urlServices}jobs-users-tpm`, dataValues, {
              headers: {
                application: keyAplication,
                Authorization: 'Bearer ' + keyAuthorization,
              },
            })
            .then((response) => {
              if (data.length === n) {
                //setRows([])
                props.callback()
                setMensaje('Candidato(s) asignado(s) con éxito.')
                setOpenAlert(true)
                setError('success')
              }
            })
            .catch((e) => {
              if (e.response.status === 401) {
                setTimeout(() => {
                  localStorage.clear()
                  setReturnLogin(true)
                }, 200)
              } else {
                setErrorServer(true)
                console.log(e)
              }
            })
        })
        n++
      })
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Grid container spacing={1} className={classes.containerProfile}>
        {errorServer ? (
          <Grid item xs={12} sm={12}>
            <Alert severity="error">
              Error en la consulta con sel servidor.
            </Alert>
          </Grid>
        ) : (
          ''
        )}
        {props.loader ? (
          <Grid item xs={12} sm={12}>
            <Box sx={{ width: '100%' }}>
              <Skeleton width="100%" height={300} />
            </Box>
          </Grid>
        ) : (
          <Fragment>
            <Grid item xs={12} sm={12}>
              <Alert severity="info" icon={<CheckBox fontSize="inherit" />}>
                <b>
                  CANDIDATOS DISPONIBLES
                  <span style={{ marginLeft: 5, fontSize: 12 }}>
                    (Total: {props.users.length})
                  </span>
                </b>
              </Alert>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MaterialTable
                title=""
                columns={state.columns}
                style={{
                  boxShadow: 'unset',
                  border: '1px solid rgb(241, 241, 241)',
                }}
                data={props.users}
                localization={{
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected',
                    searchTooltip: 'Filtrar',
                    searchPlaceholder: 'Buscar',
                  },
                  pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Previo',
                    nextTooltip: 'Siguiente',
                    lastTooltip: 'Ultima página',
                  },
                  header: {
                    actions: ' --- ',
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay datos.',
                    filterRow: {
                      filterTooltip: 'Filtro',
                    },
                  },
                }}
                actions={[
                  /* {
                            icon: "edit",
                            tooltip: "Editar",
                            onClick: (event, rowData) => {
                              setOpenEditQuestion(true);
                              setIdRow(rowData);
                            },
                          },*/
                  {
                    tooltip: 'Registrar selección',
                    icon: 'refresh',
                    onClick: (evt, data) => HandleSelect(data),
                  },
                ]}
                icons={tableIcons}
                options={{
                  showTextRowsSelected: false,
                  selection: true,
                  search: true,
                  padding: 1,
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    background: '#F2F2F2',
                    padding: '1%',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  },
                  cellStyle: { border: '1px solid #DDDDDD' },
                }}
                components={{
                  Action: (props) => {
                    if (props.action.icon === 'refresh') {
                      return (
                        <Button
                          onClick={(event) =>
                            props.action.onClick(event, props.data)
                          }
                          className={classes.buttonContactGreen}
                          endIcon={<ArrowRightAlt />}
                        >
                          Agregar
                        </Button>
                      )
                    }
                  },
                  Row: (props) => (
                    <MTableBodyRow className={classes2.tableRow} {...props} />
                  ),
                }}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
          <Alert
            onClose={handleClose}
            severity={error === 'error' ? 'error' : 'success'}
            elevation={6}
            variant="filled"
          >
            {mensaje}
          </Alert>
        </Slide>
      </Snackbar>
    </Fragment>
  )
}
