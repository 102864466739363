import React, { Fragment, useState, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'
import FormControl from '@material-ui/core/FormControl'
import { Cancel } from '@material-ui/icons'
import NumberFormat from 'react-number-format'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { green } from '@material-ui/core/colors'
import Checkbox from '@material-ui/core/Checkbox'
import Moment from 'moment'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export default function EditTests(props) {
  const classes = useStyles()

  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [openAlertSuccess, setopenAlertSuccess] = useState(false)
  const [mensaje_success, setmensaje_success] = useState('')
  const [disabled, setdisabled] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  let data = props.id

  const [values, setValues] = useState({
    id: '',
    name: '',
    time: '',
    totalScore: '',
    totalQuestions: '',
    random: false,
    dateSelectStart: '',
    dateSelectEnd: '',
    managePasswords: false,
    dateStartEnd: false,
    disabledEnd: true,
    takePhoto: false,
    showApplicantreport: false,
    errorName: false,
    errorTime: false,
    errorTotalScore: false,
    errorTotalQuestions: false,
  })

  useEffect(() => {
    axios
      .get(`${urlServices}tests/${data.id}`, {
        headers: {
          application: keyAplication,
        },
      })
      .then((response) => {
        setdisabled(response.data.status)

        setValues({
          ...values,
          id: response.data.id,
          name: response.data.name,
          time: response.data.time,
          jobname:
            response.data.testjob.length > 0
              ? response.data.testjob[0].name
              : '',
          totalScore: response.data.totalScore,
          totalQuestions: response.data.totalQuestions,
          random: response.data.isRandom,
          dateSelectStart:
            response.data.activationStart === null
              ? ''
              : Moment(response.data.activationStart).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
          dateSelectEnd:
            response.data.activationEnd === null
              ? ''
              : Moment(response.data.activationEnd).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
          managePasswords: response.data.managePasswords,
          dateStartEnd: response.data.activationTime,
          disabledEnd: response.data.activationEnd === null ? true : false,
          takePhoto: response.data.takePhoto,
          showApplicantreport: response.data.showApplicantreport,
        })
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setMensaje('Error de conexión, intente más tarde.')
          setOpenAlert(true)
          setError('error')
        }
      })
  }, [keyAplication, urlServices])

  const handleChange = (prop) => (event) => {
    setopenAlertSuccess(false)

    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorTime: false,
      errorTotalScore: false,
      errorTotalQuestions: false,
    })
    setOpenAlert(false)
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const submitForm = (e) => {
    e.preventDefault()
    setError('')
    setopenAlertSuccess(false)

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true })
      setMensaje('El campo "Nombre" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.totalQuestions.length === 0) {
      setValues({ ...values, errorTotalQuestions: true })
      setMensaje('El campo "Total de preguntas" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.time.length === 0) {
      setValues({ ...values, errorTime: true })
      setMensaje('El campo "Tiempo" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.totalScore.length === 0) {
      setValues({ ...values, errorTotalScore: true })
      setMensaje('El campo "Puntaje total" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else {
      const dataValue = {
        name: values.name,
        time: values.time,
        totalScore: values.totalScore,
        totalQuestions: values.totalQuestions,
        isRandom: values.random,
        activationTime: values.dateStartEnd,
        managePasswords: values.managePasswords,
        takePhoto: values.takePhoto,
        showApplicantreport: values.showApplicantreport,
      }

      if (values.dateStartEnd) {
        dataValue.activationStart = Moment(values.dateSelectStart).format(
          'YYYY-MM-DD HH:mm:ss'
        )
        dataValue.activationEnd = Moment(values.dateSelectEnd).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      }

      axios
        .put(`${urlServices}tests/${values.id}`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then((response) => {
          setopenAlertSuccess(true)
          setmensaje_success('Registro guardado con éxito.')
          props.callBackRefresh()
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            setMensaje('Error de conexión, intente más tarde.')
            setOpenAlert(true)
            setError('error')
          }
        })
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleRadioChange = (e) => {
    setValues({
      ...values,
      random: e.target.checked,
    })
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  const handleChangeDateStart = (e) => {
    setValues({
      ...values,
      dateSelectStart: e.target.value,
      disabledEnd: e.target.value !== '' ? false : true,
      dateSelectEnd: '',
    })
  }

  const handleChangeDateEnd = (e) => {
    setValues({
      ...values,
      dateSelectEnd: e.target.value,
    })
  }

  const handleCheckboxChange = (e) => {
    setValues({
      ...values,
      dateStartEnd: e.target.checked,
      dateSelectEnd: '',
      disabledEnd: true,
      dateSelectStart: '',
    })
  }

  const handleCheckboxPassChange = (e) => {
    setValues({
      ...values,
      managePasswords: e.target.checked,
    })
  }

  const handleCheckboxTakePhoto = (e) => {
    setValues({
      ...values,
      takePhoto: e.target.checked,
    })
  }

  const handleShowApplicantreport = (e) => {
    setValues({
      ...values,
      showApplicantreport: e.target.checked,
    })
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        // onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={2} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>Editar prueba</div>
                <div className={classes.lineBottom}></div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    name="name"
                    value={values.name}
                    error={values.errorName}
                    onChange={handleChange('name')}
                    fullWidth
                    required
                    label="Nombre de la prueba"
                    margin="dense"
                    autoComplete="off"
                    disabled={disabled !== 1 ? false : true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8} md={8}>
                <TextField
                  name="jobname"
                  value={values.jobname}
                  fullWidth
                  label=""
                  margin="dense"
                  disabled
                  helperText="Cargo"
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <NumberFormat
                  customInput={TextField}
                  name="totalQuestions"
                  value={values.totalQuestions}
                  error={values.errorTotalQuestions}
                  onChange={handleChange('totalQuestions')}
                  allowNegative={false}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: 16,
                    },
                  }}
                  label="Total de preguntas"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  placeholder="1"
                  disabled={disabled !== 1 ? false : true}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <NumberFormat
                  customInput={TextField}
                  name="time"
                  value={values.time}
                  error={values.errorTime}
                  onChange={handleChange('time')}
                  allowNegative={false}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: 16,
                    },
                  }}
                  label="Tiempo (minutos)"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  placeholder="1"
                  disabled={disabled !== 1 ? false : true}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <NumberFormat
                  customInput={TextField}
                  name="totalScore"
                  value={values.totalScore}
                  error={values.errorTotalScore}
                  onChange={handleChange('totalScore')}
                  allowNegative={false}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: 16,
                    },
                  }}
                  label="Puntaje total"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  placeholder="20"
                  disabled={disabled !== 1 ? false : true}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={values.dateStartEnd}
                      onClick={(event) => {
                        handleCheckboxChange(event)
                      }}
                      name="dateStartEnd"
                    />
                  }
                  label="Fecha de activación (Si se desea seleccionar una fecha y hora para aplicar la prueba)"
                  style={{ color: 'dimgray' }}
                />
              </Grid>
              {values.dateStartEnd ? (
                <Fragment>
                  <Grid item xs={6} sm={6}>
                    <center>
                      <TextField
                        name="dateSelectStart"
                        label="Desde"
                        type="datetime-local"
                        value={values.dateSelectStart}
                        onChange={(e) => handleChangeDateStart(e)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                      />
                    </center>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <center>
                      <TextField
                        name="dateSelectEnd"
                        label="Hasta"
                        type="datetime-local"
                        value={values.dateSelectEnd}
                        onChange={(e) => handleChangeDateEnd(e)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        mindate={values.dateSelectStart}
                        InputProps={{
                          inputProps: { min: values.dateSelectStart },
                        }}
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                        disabled={values.disabledEnd}
                      />
                    </center>
                  </Grid>
                </Fragment>
              ) : (
                ''
              )}
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={values.managePasswords}
                      onClick={(event) => {
                        handleCheckboxPassChange(event)
                      }}
                      name="managePasswords"
                    />
                  }
                  label="Gestionar contraseñas (Generar contraseñas aleatorias para cada candidato asignado a la prueba)"
                  style={{ color: 'dimgray' }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={values.random}
                      onClick={(event) => {
                        handleRadioChange(event)
                      }}
                      name="quiz"
                    />
                  }
                  label="Mostrar preguntas aleatorias"
                  style={{ color: 'dimgray' }}
                />
              </Grid>
              {props.activatePhoto ? (
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={values.takePhoto}
                        onClick={(event) => {
                          handleCheckboxTakePhoto(event)
                        }}
                        name="takePhoto"
                      />
                    }
                    label="Tomar foto al candidato"
                    style={{ color: 'dimgray' }}
                  />
                </Grid>
              ) : (
                <></>
              )}
              {props.activateApplicantreport ? (
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={values.showApplicantreport}
                        onClick={(event) => {
                          handleShowApplicantreport(event)
                        }}
                        name="showApplicantreport"
                      />
                    }
                    label="Mostrar reporte de resultados al candidato"
                    style={{ color: 'dimgray' }}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} sm={12}>
                <br></br>
                <center>
                  <Button
                    type="submit"
                    className={classes.buttonSubmit}
                    onClick={submitForm}
                    disabled={disabled !== 1 ? false : true}
                  >
                    Guardar
                  </Button>
                  <br></br>
                  <br></br>
                  {disabled !== 1 ? (
                    false
                  ) : (
                    <Alert severity="warning">
                      No se puede modificar nungún dato cuando la prueba está
                      publicada.
                    </Alert>
                  )}
                </center>
              </Grid>
              <Grid item xs={12} sm={12}>
                <center>
                  {openAlertSuccess ? (
                    <Alert severity="success">{mensaje_success}</Alert>
                  ) : (
                    ''
                  )}
                </center>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === 'error' ? 'error' : 'success'}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
