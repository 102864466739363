import React, { Fragment, useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { UrlServicesContext } from "../../components/UrlServicesContext";
import { Mail, Cancel } from "@material-ui/icons";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function EnableAcounts(props) {
  const classes = useStyles();

  const [error, setError] = useState(false);
  const [mensaje_error, setMensaje_error] = useState("");
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [mensaje_success, setMensaje_success] = useState("");
  const [disabled, setdisabled] = useState(false);

  let mail = props.id.email;

  console.log(mail)

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const handleSend = (event) => {
    const data2 = {
      email: mail.toLowerCase(),
    };

    axios
      .post(`${urlServices}auth/recovery`, data2, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response2) => {
          setdisabled(true);
          setOpenAlertSuccess(true);
          setMensaje_success("Correo electrónico enviado.");
        },
        (error) => {
          console.log(error);
          setError(true);
          setMensaje_error("Error al habilitar");
        }
      );
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Grid container spacing={1} className={classes.containerProfile}>
            <Grid item xs={12} md={12}>
              <center>
                <Mail className={classes.iconDelete} />
                <br></br>
                <div className={classes.titleCardFilter}>
                  ¿Desea enviar un correo de recuperación de contraseña?
                </div>
                <br></br>
                <Button
                  className={classes.buttonSubmit2}
                  onClick={handleSend}
                  disabled={disabled}
                >
                  Enviar
                </Button>
              </center>
            </Grid>
          </Grid>
          <br></br>
          <center>
            {error ? <Alert severity="error">{mensaje_error}</Alert> : ""}
            {openAlertSuccess ? (
              <Alert severity="success">{mensaje_success}</Alert>
            ) : (
              ""
            )}
          </center>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
