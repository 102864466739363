import React, { Fragment } from "react";

// core components
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fab from "@material-ui/core/Fab";
import { KeyboardArrowUp } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Zoom from "@material-ui/core/Zoom";

import logo from "assets/img/logomain.png";

import { headerStyle } from "assets/jss/material-dashboard-react/components/headerStyle.js";

const useStyles = makeStyles(headerStyle);

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.rootScrollTop}
      >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function HeaderHome(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <CssBaseline />
      <AppBar className={classes.appBarPage}>
        <Toolbar>
          <Grid container>
            <Grid item xs={4} md={4}>
              {/*<div className={classes.nameLogo}>AUTOCLICKEA</div>*/}
              <img
                src={logo}
                alt="validate"
                style={{
                  width: 120,
                  marginLeft: 30,
                  objectFit: "contain",
                }}
              />
            </Grid>
            <Grid item xs={8} md={8}>
              <ButtonGroup
                aria-label="outlined primary button group"
                className={classes.buttomGroup}
              >
                <Button
                  className={classes.buttonRight}
                  component="a"
                  href="/login"
                >
                  <div className={classes.titleButtom}>Ingresar</div>
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <ScrollTop {...props}>
        <Fab
          className={classes.scrollTop}
          size="small"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
    </Fragment>
  );
}
