import React, { Fragment, useState, useEffect, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Button, Dialog, Grid } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import {
  Cancel,
  CheckBoxOutlineBlank,
  CheckBox,
  Print,
} from '@material-ui/icons'
import axios from 'axios'
import img from 'assets/img/loading.gif'
import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function EnableSelectResultsTest(props) {
  const classes = useStyles()
  const [rows, setRows] = useState({ sections: [] })
  const [loader, setLoader] = useState(true)
  const [returnLogin, setReturnLogin] = useState(false)

  const keyAuthorization = localStorage.getItem('Session')
  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    console.log(props.id)
    axios
      .get(
        `${urlServices}jobs-tpm/${props.id.jobtesttpm.jobId}/test/${props.id.jobtesttpm.testId}/applicant/${props.id.applicantId}`,
        {
          headers: {
            application: keyAplication,
            Authorization: `Bearer ${keyAuthorization}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // Construye un mapa de respuestas seleccionadas
          const selectedAnswersMap = response.data.testResultTpm.reduce(
            (map, result) => {
              map[result.questionId] = map[result.questionId] || {}
              map[result.questionId][result.answerId] = result.selectedAnswer
              return map
            },
            {}
          )

          // Actualiza las respuestas con las seleccionadas
          const updatedSections =
            response.data.jobTpm.jobTestTpm.test.sections.map((section) => ({
              ...section,
              scales: section.scales.map((scale) => ({
                ...scale,
                questions: scale.questions.map((question) => ({
                  ...question,
                  answers: question.answers.map((answer) => ({
                    ...answer,
                    selectedAnswer:
                      selectedAnswersMap[question.id]?.[answer.id] || false,
                  })),
                })),
              })),
            }))

          setRows({
            ...response.data.jobTpm.jobTestTpm.test,
            sections: updatedSections,
          })
          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          // Manejo de error 404
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const handleCloseDialog = () => {
    props.exit()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container className={classes.containerProfile}>
              <Grid item xs={12} md={12}>
                <div className={classes.titleCardFilter}>
                  Prueba realizada por el candidato
                </div>
                <div className={classes.lineBottom}></div>
              </Grid>
              {loader ? (
                <Grid item xs={12} md={12}>
                  <center>
                    <img
                      src={img}
                      alt="loader"
                      style={{ width: 40, margin: '40px 0' }}
                    />
                  </center>
                </Grid>
              ) : (
                <Grid item xs={12} md={12} style={{ paddingTop: 25 }}>
                  {rows.sections && rows.sections.length > 0 ? (
                    rows.sections.map((section, index2) => (
                      <div key={index2}>
                        <b>{section.name}</b>
                        {section.scales &&
                          section.scales.map((scale, index3) => (
                            <div key={index3} style={{ marginTop: 15 }}>
                              <div
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 16,
                                  color: 'green',
                                }}
                              >
                                {scale.name}
                              </div>
                              {scale.questions &&
                                scale.questions.map((question, index4) => (
                                  <div
                                    key={index4}
                                    style={{
                                      background: 'aliceblue',
                                      padding: 5,
                                      borderRadius: 10,
                                      margin: '10px 0',
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: '500',
                                        fontSize: 14,
                                      }}
                                    >
                                      {index4 + 1 + '-. ' + question.statement}
                                    </div>
                                    {question.answers &&
                                      question.answers.map((answer, index5) => (
                                        <div
                                          key={index5}
                                          style={{ fontSize: 14 }}
                                        >
                                          {answer.selectedAnswer ? (
                                            <CheckBox
                                              style={{
                                                verticalAlign: 'middle',
                                                marginLeft: 5,
                                                marginRight: 10,
                                                color: 'green',
                                              }}
                                            />
                                          ) : (
                                            <CheckBoxOutlineBlank
                                              style={{
                                                verticalAlign: 'middle',
                                                marginLeft: 5,
                                                marginRight: 10,
                                              }}
                                            />
                                          )}
                                          {answer.statement}
                                          <br />
                                        </div>
                                      ))}
                                  </div>
                                ))}
                            </div>
                          ))}
                      </div>
                    ))
                  ) : (
                    <div>No hay datos disponibles.</div>
                  )}
                </Grid>
              )}
            </Grid>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className={classes.buttonSubmit1}
            href={
              '/printtest?jobtpm=' +
              props.id.jobtesttpm.jobId +
              '&testId=' +
              props.id.jobtesttpm.testId +
              '&userId=' +
              props.id.applicantId +
              '&userName=' +
              props.id.applicant.name +
              ' ' +
              props.id.applicant.lastName +
              '&userDocument=' +
              props.id.applicant.documentId +
              '&testName=' +
              props.id.jobtesttpm.test.name +
              '&jobName=' +
              props.id.jobtesttpm.job.name
            }
            startIcon={<Print style={{ fontSize: 14 }} />}
            component="a"
            target="_blank"
          >
            Imprimir
          </Button>
          <Button onClick={handleCloseDialog}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
