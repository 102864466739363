import React, { Fragment, useState, useEffect, forwardRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Search, Person } from '@material-ui/icons'
import MaterialTable, { MTableBodyRow } from 'material-table'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const useStyles2 = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(237, 247, 237)!important' } },
})

export default function TestApplicantsDone(props) {
  const classes = useStyles()
  const classes2 = useStyles2()

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Nombre',
        field: 'user.name',
        render: (rowData) => {
          return <span>{rowData.user.name + ' ' + rowData.user.lastName}</span>
        },
        width: '60%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
        },
      },
      {
        title: 'Identificación',
        field: 'user.documentId',
        width: '10%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Correo electrónico',
        field: 'user.email',
        render: (rowData) => {
          return (
            <Fragment>
              <span style={{ fontSize: 12 }}>
                {rowData.user.email !== ''
                  ? rowData.user.email
                  : 'SIN REGISTRAR'}
              </span>
            </Fragment>
          )
        },
        width: '30%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
    ],
  })

  useEffect(() => {
    if (props.refresh) {
      props.refreshReturn()
    }
  }, [])

  return (
    <Fragment>
      <Grid container spacing={1} className={classes.containerProfile}>
        {props.loader ? (
          <Grid item xs={12} sm={12}>
            <Box sx={{ width: '100%' }}>
              <Skeleton width="100%" height={300} />
            </Box>
          </Grid>
        ) : (
          <Fragment>
            <Grid item xs={12} sm={12}>
              <Alert severity="success" icon={<Person fontSize="inherit" />}>
                <b>
                  CANDIDATOS ASIGNADOS
                  <span style={{ marginLeft: 5, fontSize: 12 }}>
                    (Total: {props.users.length})
                  </span>
                </b>
              </Alert>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MaterialTable
                title=""
                columns={state.columns}
                style={{
                  boxShadow: 'unset',
                  border: '1px solid rgb(241, 241, 241)',
                }}
                data={props.users}
                localization={{
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected',
                    searchTooltip: 'Filtrar',
                    searchPlaceholder: 'Buscar',
                  },
                  pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Previo',
                    nextTooltip: 'Siguiente',
                    lastTooltip: 'Ultima página',
                  },
                  header: {
                    actions: ' --- ',
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay datos.',
                    filterRow: {
                      filterTooltip: 'Filtro',
                    },
                  },
                }}
                actions={
                  [
                    /* {
                            icon: "edit",
                            tooltip: "Editar",
                            onClick: (event, rowData) => {
                              setOpenEditQuestion(true);
                              setIdRow(rowData);
                            },
                          },*/
                  ]
                }
                icons={tableIcons}
                options={{
                  search: true,
                  padding: 1,
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    background: '#F2F2F2',
                    padding: '1%',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  },
                  cellStyle: { border: '1px solid #DDDDDD' },
                }}
                components={{
                  Row: (props) => (
                    <MTableBodyRow className={classes2.tableRow} {...props} />
                  ),
                }}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  )
}
