import React, { Fragment, useState, useEffect, useContext } from 'react'

import { Redirect } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Cancel } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import axios from 'axios'

import { UrlServicesContext } from 'components/UrlServicesContext'
import TestApplicantsDone from 'views/AdminInstitution/TestApplicantsDone'
import TestApplicantsSelect from 'views/AdminInstitution/TestApplicantsSelect'
import EnableAssignMasivamente from 'views/dialog/EnableAssignMasivamente'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function EnableApplicantTest(props) {
  const classes = useStyles()
  const [refreshListDone, setRefreshListDone] = useState(false)
  const [openAssign, setOpenAssign] = useState(false)
  const [pendingUsers, setPendingUsers] = useState([])
  const [doneUsers, setDoneUsers] = useState([])
  const [returnLogin, setReturnLogin] = useState(false)
  const [errorServer, setErrorServer] = useState(false)
  const [loader, setLoader] = useState(true)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const RefreshList = () => {
    DataList()
    setRefreshListDone(true)
  }

  const RefreshReturn = () => {
    setRefreshListDone(false)
  }

  const handleCloseAssign = () => {
    setOpenAssign(false)
  }

  const DataList = () => {
    axios
      .get(`${urlServices}tests-users/test/${props.id.id}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        setDoneUsers(response.data)
        DataListApplicant(response.data)
      })
      .catch((e) => {
        if (e.response.status === 404) {
          DataListApplicant()
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setPendingUsers([])
          setErrorServer(true)
          console.log(e)
        }
      })
  }

  const DataListApplicant = (data = null) => {
    axios
      .get(`${urlServices}accounts/${localStorage.getItem('account_id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const result = response.data.users.filter(
            (filtered) => Number(filtered.profileId) === 6
          )

          if (data !== null) {
            let outPending = []

            for (var i0 = 0; i0 < result.length; i0++) {
              var found0 = false

              for (var j0 = 0; j0 < data.length; j0++) {
                // j < is missed;
                if (result[i0].id === data[j0].userId) {
                  found0 = true
                  break
                }
              }
              if (found0 === false) {
                outPending.push(result[i0])
              }
            }

            setPendingUsers(outPending)
          } else {
            setPendingUsers(result)
          }
          setLoader(false)
        }
      })
      .catch((e) => {
        console.log(e)
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setErrorServer(true)
        }
      })
  }

  useEffect(() => {
    DataList()
  }, [])

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        //scroll="body"
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Grid container spacing={1} className={classes.containerProfile}>
            {errorServer ? (
              <Grid item xs={12} sm={12}>
                <Alert severity="error">
                  Error en la consulta con el servidor.
                </Alert>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={8}>
              <div className={classes.titleCardFilter}>
                {' '}
                Lista de candidatos
              </div>
              <div className={classes.lineBottom}></div>
              <div className={classes.titleCardFilter}>
                {' '}
                <small>
                  Agregar candidatos a la prueba <b>{props.id.name}</b>.
                </small>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <span style={{ float: 'right' }}>
                <Button
                  className={classes.buttonSubmit2}
                  onClick={() => setOpenAssign(true)}
                >
                  Asignar masivamente
                </Button>
              </span>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TestApplicantsSelect
                      id={props.id}
                      users={pendingUsers}
                      callback={RefreshList}
                      loader={loader}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TestApplicantsDone
                      id={props.id.id}
                      users={doneUsers}
                      refresh={refreshListDone}
                      refreshReturn={RefreshReturn}
                      loader={loader}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
      {openAssign ? (
        <EnableAssignMasivamente
          open={openAssign}
          exit={handleCloseAssign}
          callback={RefreshList}
          id={props.id}
          users={pendingUsers}
        />
      ) : (
        <></>
      )}
    </Fragment>
  )
}
