import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { Dialog, Button, Grid } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import {
  BorderColor,
  ArrowRightAlt,
  Save,
  WatchLater,
  Person,
} from '@material-ui/icons'
import { Animated } from 'react-animated-css'
import { Alert, AlertTitle } from '@material-ui/lab'

import successImg from 'assets/img/success.gif'
import test from 'assets/img/undraw_Speed_test_re_pe1f.png'
import exit from 'assets/img/undraw_towing_6yy4.png'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function StartTestColorPsychometrics(props) {
  const classes = useStyles()

  const [next, setNext] = useState(0)
  const [disabled1, setDisabled1] = useState(true)
  const [disabled2, setDisabled2] = useState(true)
  const [mensageExit, setMensageExit] = useState(false)
  const [returnLogin, setReturnLogin] = useState(false)
  const [selections1, setSelections1] = useState([])
  const [selections2, setSelections2] = useState([])
  const [mensageTestSTOP, setMensageTestSTOP] = useState(false)

  const totalPages = 2

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const handleNext = (pro) => async (event) => {
    setNext(next + 1)
    UpdateStatusTest(pro)
  }

  // Función para manejar el clic en cada celda de color
  const handleColorClick1 = (number) => {
    if (selections1.length < 8 && !selections1.includes(number)) {
      setSelections1([...selections1, number]) // Agregar el número seleccionado al array

      if (selections1.length === 7) {
        setDisabled1(false)
      }
    }
    ViewsStatusActual()
  }

  const handleColorClick2 = (number) => {
    if (selections2.length < 8 && !selections2.includes(number)) {
      setSelections2([...selections2, number]) // Agregar el número seleccionado al array

      if (selections2.length === 7) {
        setDisabled2(false)
      }
    }
    ViewsStatusActual()
  }

  const ViewsStatusActual = async () => {
    await axios
      .get(`${urlServices}jobs-users-tpm/${props.id.id}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response3) => {
          if (response3.data.status === 4) {
            setMensageTestSTOP(true)
            props.callBackRefreshPsychometrics()
          }
        },
        (error) => {}
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const UpdateStatusTest = async (n) => {
    const data = {
      status: n === 1 ? 2 : 3,
    }

    await axios
      .put(`${urlServices}jobs-users-tpm/${props.id.id}`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response3) => {
          //props.callBackRefreshPsychometrics()
        },
        (error) => {
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const insert = async (data) => {
    await axios
      .post(`${urlServices}tests-color-results-tpm`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response3) => {
          if (response3.status === 201) {
            UpdateStatusTest(2)
            setNext(next + 1)
          }
        },
        (error) => {
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const handleExit = (pro) => (event) => {
    setMensageExit(true)
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleContinue = (pro) => (event) => {
    setMensageExit(false)
  }

  const handleSave = () => (event) => {
    const dataValue = {
      jobId: props.id.jobId,
      testId: props.id.test.id,
      userId: props.id.user.id,
      jobTestId: props.id.jobTestId,
    }

    selections1.forEach((items, index) => {
      dataValue[`resPer${index + 1}`] = items
    })

    selections2.forEach((items, index) => {
      dataValue[`resAd${index + 1}`] = items
    })

    insert(dataValue)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      aria-labelledby="customized-dialog-title"
      open={props.open}
      // onClose={handleCloseDialog(true)}
      keepMounted
      scroll="body"
      style={{ zIndex: 100 }}
      PaperProps={{
        classes: { root: classes.DialogStyle },
      }}
    >
      <DialogContent dividers className={classes.styleDialogContent}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <BorderColor className={classes.iconWhite} />{' '}
              <span style={{ textTransform: 'uppercase' }}>
                {props.id.test.name}
              </span>
            </div>
          </CardHeader>
          <CardBody>
            <Grid container>
              {next === 0 ? (
                <Fragment>
                  <Grid item xs={12} sm={5}>
                    <center>
                      <img
                        src={test}
                        alt="test"
                        style={{ marginTop: 150, width: 300 }}
                      />
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Fragment>
                      <div className={classes.fontParagraph}>
                        <br></br>
                        <b>Señor aspirante</b>
                        <br></br>
                        <br></br>
                        Bienvenido/a al Test de los Colores, una herramienta
                        psicométrica diseñada para explorar aspectos emocionales
                        y psicológicos de tu personalidad. Este test te invita a
                        reflexionar sobre tus preferencias cromáticas y cómo
                        éstas pueden revelar aspectos importantes de tu mundo
                        interior.
                        <br></br>
                        Durante esta evaluación, se te presentarán ocho colores
                        diferentes. Te pedimos que los ordenes según tu
                        preferencia personal, sin pensar demasiado en ello. No
                        hay respuestas correctas o incorrectas, simplemente
                        elige los colores que te llamen más la atención en el
                        momento.
                        <br></br>
                        Es importante que te sientas cómodo/a y relajado/a
                        mientras realizas este test. No hay prisa, tómate tu
                        tiempo para considerar cada opción antes de tomar una
                        decisión. Recuerda que esta prueba es una oportunidad
                        para conocerte mejor a ti mismo/a.
                      </div>
                    </Fragment>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonLeftSteps2}
                        onClick={handleCloseDialog(true)}
                      >
                        Salir
                      </Button>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={handleNext(1)}
                        endIcon={
                          props.id.status === 2 ? (
                            <ArrowRightAlt />
                          ) : (
                            <WatchLater />
                          )
                        }
                      >
                        {props.id.status === 2 ? 'Continuar' : 'Comenzar'}
                      </Button>
                    </center>
                  </Grid>
                </Fragment>
              ) : next <= totalPages ? (
                <Fragment>
                  {mensageExit ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{ padding: '0 40px 20px 40px' }}
                    >
                      <Animated
                        animationIn="bounceIn"
                        animationInDuration={300}
                        isVisible={true}
                      >
                        <center>
                          <img
                            src={exit}
                            alt="exit"
                            style={{
                              width: 170,
                            }}
                          />
                        </center>
                        <br></br>
                        <Alert severity="warning">
                          <AlertTitle>Confirmación</AlertTitle>
                          Puede salir de la prueba sin ningún problema, pero
                          recuerda que estará pendiente por finalizarla.
                        </Alert>

                        <br></br>
                        <center>
                          <Button
                            type="submit"
                            className={classes.buttonLeftSteps2}
                            onClick={handleContinue(true)}
                          >
                            Continuar la prueba
                          </Button>
                          <Button
                            type="submit"
                            className={classes.buttonSubmit}
                            onClick={handleCloseDialog(true)}
                          >
                            Salir
                          </Button>
                        </center>
                      </Animated>
                    </Grid>
                  ) : mensageTestSTOP ? (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ padding: '0 40px 20px 40px' }}
                      >
                        <Animated
                          animationIn="bounceIn"
                          animationInDuration={300}
                          isVisible={true}
                        >
                          <center>
                            <img
                              src={exit}
                              alt="exit"
                              style={{
                                width: 170,
                              }}
                            />
                          </center>
                          <br></br>
                          <Alert severity="warning">
                            <AlertTitle>Información</AlertTitle>
                            El administrador detuvo tu prueba por alguna razón,
                            por favor comunicarte con la persona encargada.
                          </Alert>

                          <br></br>
                          <center>
                            <Button
                              type="submit"
                              className={classes.buttonSubmit}
                              onClick={handleCloseDialog(true)}
                            >
                              Salir
                            </Button>
                          </center>
                        </Animated>
                      </Grid>
                    </Grid>
                  ) : next === 1 ? (
                    <Fragment>
                      <Grid
                        item
                        xs={8}
                        sm={8}
                        style={{
                          padding: '1px 3px',
                          color: '#333',
                          fontWeight: 'bold',
                          fontSize: 13,
                        }}
                      >
                        <Person
                          style={{ color: '#333', verticalAlign: 'middle' }}
                        />{' '}
                        {props.id.user.name + ' ' + props.id.user.lastName}
                        &nbsp;({props.id.user.documentId})
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                          color: '#000',
                          fontSize: 16,
                          fontWeight: 300,
                        }}
                      >
                        <p>
                          Observe bien estos ocho colores, seleccione (con el
                          botón izquierdo del mouse) el color que más llame su
                          atención. No escoja su color favorito, y trate de no
                          asociar los colores con otras cosas, como vestidos,
                          automóviles, etc.
                        </p>

                        <p>
                          Concéntrese en elegir el color que más le atraiga
                          visualmente. Una vez que haya elegido, repita la misma
                          operación con los colores restantes, de tal manera que
                          el último que escoja sea el que menos llame su
                          atención.
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12} sm={12}>
                            <center>
                              <Grid container style={{ fontWeight: 'bold' }}>
                                <Grid item xs={3} sm={3}></Grid>
                                <Grid item xs={3} sm={3}>
                                  <table>
                                    <tr>
                                      <td style={{ padding: 5 }}>3</td>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections1.includes(3)
                                            ? ''
                                            : '#FF0000'
                                        }
                                        onClick={() => handleColorClick1(3)}
                                      >
                                        &nbsp;
                                      </td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                  <table>
                                    <tr>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections1.includes(4)
                                            ? ''
                                            : '#FF8000'
                                        }
                                        onClick={() => handleColorClick1(4)}
                                      >
                                        &nbsp;
                                      </td>
                                      <td style={{ padding: 5 }}>4</td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={3} sm={3}></Grid>
                              </Grid>
                            </center>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <center>
                              <Grid container style={{ fontWeight: 'bold' }}>
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  style={{ marginLeft: 70 }}
                                >
                                  <table>
                                    <tr>
                                      <td style={{ padding: 5 }}>6</td>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections1.includes(6)
                                            ? ''
                                            : '#804000'
                                        }
                                        onClick={() => handleColorClick1(6)}
                                      >
                                        &nbsp;
                                      </td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={2} sm={2}></Grid>
                                <Grid item xs={2} sm={2}></Grid>
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  style={{ marginRight: 60 }}
                                >
                                  <table>
                                    <tr>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections1.includes(2)
                                            ? ''
                                            : '#008080'
                                        }
                                        onClick={() => handleColorClick1(2)}
                                      >
                                        &nbsp;
                                      </td>
                                      <td style={{ padding: 5 }}>2</td>
                                    </tr>
                                  </table>
                                </Grid>
                              </Grid>
                            </center>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <center>
                              <table>
                                <tr
                                  style={{ fontSize: 12, fontWeight: 'bold' }}
                                >
                                  {selections1.map((number, index) => (
                                    <td
                                      style={{ padding: '5px 15px' }}
                                      key={index}
                                    >
                                      {number}
                                    </td>
                                  ))}
                                </tr>
                              </table>
                            </center>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <center>
                              <Grid container style={{ fontWeight: 'bold' }}>
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  style={{ marginLeft: 70 }}
                                >
                                  <table>
                                    <tr>
                                      <td style={{ padding: 5 }}>0</td>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections1.includes(0)
                                            ? ''
                                            : '#CBB3A1'
                                        }
                                        onClick={() => handleColorClick1(0)}
                                      >
                                        &nbsp;
                                      </td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={2} sm={2}></Grid>
                                <Grid item xs={2} sm={2}></Grid>
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  style={{ marginRight: 60 }}
                                >
                                  <table>
                                    <tr>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections1.includes(5)
                                            ? ''
                                            : '#FF004D'
                                        }
                                        onClick={() => handleColorClick1(5)}
                                      >
                                        &nbsp;
                                      </td>

                                      <td style={{ padding: 5 }}>5</td>
                                    </tr>
                                  </table>
                                </Grid>
                              </Grid>
                            </center>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <center>
                              <Grid container style={{ fontWeight: 'bold' }}>
                                <Grid item xs={3} sm={3}></Grid>
                                <Grid item xs={3} sm={3}>
                                  <table>
                                    <tr>
                                      <td style={{ padding: 5 }}>1</td>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections1.includes(1)
                                            ? ''
                                            : '#410761'
                                        }
                                        onClick={() => handleColorClick1(1)}
                                      >
                                        &nbsp;
                                      </td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                  <table>
                                    <tr>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections1.includes(7)
                                            ? ''
                                            : '#000000'
                                        }
                                        onClick={() => handleColorClick1(7)}
                                      >
                                        &nbsp;
                                      </td>
                                      <td style={{ padding: 5 }}>7</td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={3} sm={3}></Grid>
                              </Grid>
                            </center>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div style={{ float: 'right', marginTop: 8 }}>
                          <Button
                            type="submit"
                            className={classes.buttonSubmit}
                            onClick={handleNext(1)}
                            endIcon={<ArrowRightAlt />}
                            disabled={disabled1}
                          >
                            Continuar
                          </Button>
                        </div>
                      </Grid>
                    </Fragment>
                  ) : next === 2 ? (
                    <Fragment>
                      <Grid
                        item
                        xs={8}
                        sm={8}
                        style={{
                          padding: '1px 3px',
                          color: '#333',
                          fontWeight: 'bold',
                          fontSize: 13,
                        }}
                      >
                        <Person
                          style={{ color: '#333', verticalAlign: 'middle' }}
                        />{' '}
                        {props.id.user.name + ' ' + props.id.user.lastName}
                        &nbsp;({props.id.user.documentId})
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                          color: '#000',
                          fontSize: 16,
                          fontWeight: 300,
                        }}
                      >
                        <p>
                          Ahora le voy a pedir que procure olvidar la
                          experiencia que acaba de vivir y que observe estos
                          colores como si nunca los hubiera visto antes, no
                          trate de recordar o repetir la selección de colores
                          que hizo antes, ni tampoco haga esfuerzos conscientes
                          para no repetirla.
                        </p>
                        <p>
                          Piense que es la primera vez que ve estos colores y
                          seleccione el color que más llame su atención. No
                          escoja su color favorito, y trate de no asociar los
                          colores con otras cosas, como vestidos, automóviles,
                          etc.
                        </p>
                        <p>
                          Concéntrese en elegir el color que más le atraiga
                          visualmente. Una vez que haya elegido, repita la misma
                          operación con los colores restantes, de tal manera que
                          el último que escoja sea el que menos llame su
                          atención.
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12} sm={12}>
                            <center>
                              <Grid container style={{ fontWeight: 'bold' }}>
                                <Grid item xs={3} sm={3}></Grid>
                                <Grid item xs={3} sm={3}>
                                  <table>
                                    <tr>
                                      <td style={{ padding: 5 }}>4</td>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections2.includes(4)
                                            ? ''
                                            : '#FF8000'
                                        }
                                        onClick={() => handleColorClick2(4)}
                                      >
                                        &nbsp;
                                      </td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                  <table>
                                    <tr>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections2.includes(2)
                                            ? ''
                                            : '#008080'
                                        }
                                        onClick={() => handleColorClick2(2)}
                                      >
                                        &nbsp;
                                      </td>
                                      <td style={{ padding: 5 }}>2</td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={3} sm={3}></Grid>
                              </Grid>
                            </center>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <center>
                              <Grid container style={{ fontWeight: 'bold' }}>
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  style={{ marginLeft: 70 }}
                                >
                                  <table>
                                    <tr>
                                      <td style={{ padding: 5 }}>5</td>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections2.includes(5)
                                            ? ''
                                            : '#FF004D'
                                        }
                                        onClick={() => handleColorClick2(5)}
                                      >
                                        &nbsp;
                                      </td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={2} sm={2}></Grid>
                                <Grid item xs={2} sm={2}></Grid>
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  style={{ marginRight: 60 }}
                                >
                                  <table>
                                    <tr>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections2.includes(0)
                                            ? ''
                                            : '#CBB3A1'
                                        }
                                        onClick={() => handleColorClick2(0)}
                                      >
                                        &nbsp;
                                      </td>
                                      <td style={{ padding: 5 }}>0</td>
                                    </tr>
                                  </table>
                                </Grid>
                              </Grid>
                            </center>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <center>
                              <table>
                                <tr
                                  style={{ fontSize: 12, fontWeight: 'bold' }}
                                >
                                  {selections2.map((number, index) => (
                                    <td
                                      style={{ padding: '5px 15px' }}
                                      key={index}
                                    >
                                      {number}
                                    </td>
                                  ))}
                                </tr>
                              </table>
                            </center>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <center>
                              <Grid container style={{ fontWeight: 'bold' }}>
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  style={{ marginLeft: 70 }}
                                >
                                  <table>
                                    <tr>
                                      <td style={{ padding: 5 }}>6</td>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections2.includes(6)
                                            ? ''
                                            : '#804000'
                                        }
                                        onClick={() => handleColorClick2(6)}
                                      >
                                        &nbsp;
                                      </td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={2} sm={2}></Grid>
                                <Grid item xs={2} sm={2}></Grid>
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  style={{ marginRight: 60 }}
                                >
                                  <table>
                                    <tr>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections2.includes(7)
                                            ? ''
                                            : '#000000'
                                        }
                                        onClick={() => handleColorClick2(7)}
                                      >
                                        &nbsp;
                                      </td>

                                      <td style={{ padding: 5 }}>7</td>
                                    </tr>
                                  </table>
                                </Grid>
                              </Grid>
                            </center>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <center>
                              <Grid container style={{ fontWeight: 'bold' }}>
                                <Grid item xs={3} sm={3}></Grid>
                                <Grid item xs={3} sm={3}>
                                  <table>
                                    <tr>
                                      <td style={{ padding: 5 }}>1</td>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections2.includes(1)
                                            ? ''
                                            : '#410761'
                                        }
                                        onClick={() => handleColorClick2(1)}
                                      >
                                        &nbsp;
                                      </td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                  <table>
                                    <tr>
                                      <td
                                        style={{
                                          cursor: 'pointer',
                                          width: 60,
                                          height: 60,
                                        }}
                                        bgcolor={
                                          selections2.includes(3)
                                            ? ''
                                            : '#FF0000'
                                        }
                                        onClick={() => handleColorClick2(3)}
                                      >
                                        &nbsp;
                                      </td>
                                      <td style={{ padding: 5 }}>3</td>
                                    </tr>
                                  </table>
                                </Grid>
                                <Grid item xs={3} sm={3}></Grid>
                              </Grid>
                            </center>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div style={{ float: 'right', marginTop: 8 }}>
                          <Button
                            type="submit"
                            className={classes.buttonSubmit}
                            onClick={handleSave()}
                            endIcon={<Save />}
                            disabled={disabled2}
                          >
                            Finalizar
                          </Button>
                        </div>
                      </Grid>
                    </Fragment>
                  ) : (
                    ''
                  )}
                </Fragment>
              ) : (
                <Grid item xs={12} sm={12} style={{ padding: 20 }}>
                  <center>
                    <img
                      src={successImg}
                      alt="successPassword"
                      style={{
                        width: '150px',
                      }}
                    />
                    <br></br>
                    <Animated
                      animationIn="bounceIn"
                      animationInDuration={1000}
                      isVisible={true}
                    >
                      <div className={classes.titleCard}>
                        Usted ha finalizado el test de forma exitosa.
                      </div>
                    </Animated>
                  </center>

                  <br></br>
                  <center>
                    <Button
                      type="submit"
                      className={classes.buttonSubmit}
                      onClick={() => {
                        props.callBackRefreshPsychometrics()
                        props.exit()
                      }}
                    >
                      Aceptar
                    </Button>
                  </center>
                </Grid>
              )}
            </Grid>
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ paddingRight: 35, marginLeft: 15 }}
          >
            {next <= totalPages && next > 0 ? (
              <Fragment>
                {!mensageExit ? (
                  <div style={{ float: 'left' }}>
                    <Button
                      type="submit"
                      className={classes.buttonLeftSteps2}
                      onClick={handleExit(true)}
                    >
                      Salir
                    </Button>
                  </div>
                ) : (
                  ''
                )}
              </Fragment>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
