import { backdrop } from 'assets/jss/material-dashboard-react.js'

const backdropStyle = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
})

export { backdropStyle }
