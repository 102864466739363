import React, { Fragment, useState, useEffect, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import { Cancel, Person, Print } from '@material-ui/icons'
import axios from 'axios'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function EnableResultsJobsIndividual(props) {
  const classes = useStyles()

  const [errorServer, setErrorServer] = useState(false)
  const [loader, setLoader] = useState(true)
  const [dataValueJobs, setDataValueJob] = useState([])
  const [rows, setRows] = useState([])
  const [returnLogin, setReturnLogin] = React.useState(false)

  let dataTest = props.id
  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    const dataValue = {
      jobId: dataTest.jobId,
      userId: dataTest.userId,
    }

    axios
      .post(`${urlServices}reports/individual/factors`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setDataValueJob(response.data)
          setRows(response.data.jobFactor)
          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
          setErrorServer(true)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  let totalScore = 0

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>
                  Reporte de prueba psicométrica
                </div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              {errorServer ? (
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">
                    Error en la consulta con sel servidor.
                  </Alert>
                </Grid>
              ) : (
                ''
              )}
              {loader ? (
                <Grid item xs={12} sm={12}>
                  <Box sx={{ width: '100%' }}>
                    <Skeleton width="100%" height={200} />
                  </Box>
                </Grid>
              ) : (
                <Fragment>
                  <Grid item xs={12} sm={8}>
                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                      <Person className={classes.iconTheme} />{' '}
                      {dataValueJobs.user.name +
                        ' ' +
                        dataValueJobs.user.lastName}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.titleFilterAction}>
                      Identificación: &nbsp;&nbsp;
                      {dataValueJobs.user.documentId}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleFilterAction}>
                      Cargo: &nbsp;&nbsp;{dataValueJobs.jobTpm.name}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {rows.map((row) => {
                      totalScore +=
                        row.testsResultsTpm === null
                          ? parseFloat(0)
                          : parseFloat(row.testsResultsTpm.score)
                      return <></>
                    })}
                    <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          size={'small'}
                          aria-label="enhanced table"
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell
                                align="right"
                                style={{
                                  background: 'cornflowerblue',
                                  color: 'black',
                                  fontWeight: 'bold',
                                }}
                              >
                                Calificación obtenida
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  background: 'black',
                                  color: 'white',
                                  fontWeight: 'bold',
                                }}
                              >
                                {parseFloat(totalScore).toFixed(2)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  background: 'cornflowerblue',
                                  color: 'black',
                                  fontWeight: 'bold',
                                }}
                              >
                                Sobre{' '}
                                {parseFloat(
                                  dataValueJobs.jobTpm.totalScore
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
