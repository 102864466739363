import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import {
  makeStyles,
  ThemeProvider,
  createTheme,
  withStyles,
} from '@material-ui/core/styles'
import {
  Dialog,
  Backdrop,
  CircularProgress,
  Grid,
  Button,
  TextField,
  Snackbar,
  Slide,
  FormControl,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import axios from 'axios'
import Alert from '@material-ui/lab/Alert'
import NumberFormat from 'react-number-format'
import { green } from '@material-ui/core/colors'
import { Cancel, Today, Apps, Save } from '@material-ui/icons'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Moment from 'moment'

import LoadProvince from 'components/services/LoadProvince'
import LoadCities from 'components/services/LoadCities'
import ViewProfilePrivileges from 'views/dialog/ViewProfilePrivilegesUser'

import {
  cardBodyStyle,
  themeCss,
} from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const defaultMaterialTheme = createTheme(themeCss)

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export default function NewAcounts(props) {
  const classes = useStyles()

  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [errorServer, setErrorServer] = useState(false)
  const [start_date, setstart_date] = useState(
    Moment().format('YYYY-MM-DD hh:mm:ss')
  )
  const [end_date, setend_date] = useState(
    Moment().format('YYYY-MM-DD hh:mm:ss')
  )
  const [disabledTo, setdisabledTo] = useState(true)
  const [OpenEnable, setOpenEnable] = useState(false)
  const [ProfileID, setProfileID] = useState('')
  const [privilegesResources, setPrivilegesResources] = useState([])
  const [disabled, setDisabled] = useState(true)
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')
  let keyprofile_id = localStorage.getItem('profile_id')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    name: '',
    lastName: '',
    address: '',
    document_id: '',
    companyName: '',
    province: '',
    provinceName: '',
    provinceValue: '',
    ciudad: '',
    ciudadName: '',
    cityValue: '',
    disabledCity: true,
    phone: '',
    email: '',
    managers: '',
    agents: '',
    yearFrom: null,
    yearTo: null,
    yearToMinDate: new Date(),
    fixer: '',
    activatePhoto: false,
    activateApplicantreport: false,
    errorName: false,
    errorAddress: false,
    errorPhone: false,
    errorDocument_id: false,
    errorEmail: false,
    errorManagers: false,
    errorAgents: false,
    errorYearFrom: false,
    errorYearTo: false,
    errorLastName: false,
    errorProvince: false,
    errorCiudad: false,
    errorCompanyName: false,
    errorFixer: false,
  })

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorAddress: false,
      errorPhone: false,
      errorDocument_id: false,
      errorEmail: false,
      errorManagers: false,
      errorAgents: false,
      errorLastName: false,
      errorCompanyName: false,
      errorFixer: false,
    })
    setOpenAlert(false)
  }

  const handleDateChangeFrom = (event) => {
    if (event !== null) {
      setValues({
        ...values,
        yearFrom: event,
        yearToMinDate: event,
        yearTo: null,
        errorYearFrom: false,
      })

      setstart_date(Moment(event).format('YYYY-MM-DD hh:mm:ss'))
      setdisabledTo(false)
    }
  }

  const handleDateChangeTo = (event) => {
    if (event !== null) {
      setValues({
        ...values,
        yearTo: event,
        errorYearTo: false,
      })

      setend_date(Moment(event).format('YYYY-MM-DD hh:mm:ss'))
    }
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const submitForm = (e) => {
    e.preventDefault()
    setOpenAlert(false)
    setErrorServer(false)

    if (values.document_id.length === 0) {
      setValues({ ...values, errorDocument_id: true })
      setMensaje('El campo "Documento" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.name.length === 0) {
      setValues({ ...values, errorName: true })
      setMensaje('El campo "Nombres" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.lastName.length === 0) {
      setValues({ ...values, errorLastName: true })
      setMensaje('El campo "Apellidos" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.companyName.length === 0) {
      setValues({ ...values, errorCompanyName: true })
      setMensaje('El campo "Nombre de empresa" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.email.length === 0) {
      setValues({ ...values, errorEmail: true })
      setMensaje('El campo "E-mail" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.phone === '') {
      setValues({ ...values, errorPhone: true })
      setMensaje('El campo "Teléfono" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.province === '') {
      setValues({ ...values, errorProvince: true })
      setMensaje('Seleccione una PROVINCIA')
      setOpenAlert(true)
      setError('error')
    } else if (values.ciudad === '') {
      setValues({ ...values, errorCiudad: true })
      setMensaje('Seleccione una CIUDAD')
      setOpenAlert(true)
      setError('error')
    } else if (values.address === '') {
      setValues({ ...values, errorAddress: true })
      setMensaje('El campo "Dirección" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.managers === '') {
      setValues({ ...values, errorManagers: true })
      setMensaje('El campo "Nº administradores" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.agents === '') {
      setValues({ ...values, errorAgents: true })
      setMensaje('El campo "Nº gestores" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.fixer === '') {
      setValues({ ...values, errorFixer: true })
      setMensaje('El campo "Ajuste" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.yearFrom === null) {
      setValues({ ...values, errorYearFrom: true })
      setMensaje('Seleccione una fecha de inicio')
      setOpenAlert(true)
      setError('error')
    } else if (values.yearTo === null) {
      setValues({ ...values, errorYearTo: true })
      setMensaje('Seleccione una fecha de fin')
      setOpenAlert(true)
      setError('error')
    } else {
      setoOpenBackdrop(true)

      const dataValue = {
        email: values.email.toLowerCase().trim(),
        documentId: values.document_id,
        name: values.name,
        lastName: values.lastName,
        address: values.address,
        phoneNumber: values.phone,
        companyName: values.companyName,
        managers: Number(values.managers),
        agents: Number(values.agents),
        startDate: start_date,
        endDate: end_date,
        cityId: Number(values.ciudad),
        fixer: Number(values.fixer),
        activatePhoto: values.activatePhoto,
        activateApplicantreport: values.activateApplicantreport,
      }

      axios
        .post(`${urlServices}accounts`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const dataValue2 = {
              email: values.email.toLowerCase().trim(),
              profileId: keyprofile_id === '1' ? '2' : '3',
              admin: '0',
              accountId: response.data.id,
              documentId: values.document_id,
              name: values.name,
              lastName: values.lastName,
            }

            axios
              .post(`${urlServices}users`, dataValue2, {
                headers: {
                  application: keyAplication,
                  Authorization: 'Bearer ' + keyAuthorization,
                },
              })
              .then((response2) => {
                SaveProfileResources(response2.data.id)
                setoOpenBackdrop(false)

                setOpenAlert(true)
                setMensaje('Registro guardado con éxito.')
                setError('success')
                props.callBackRefresh()

                setValues({
                  ...values,
                  name: '',
                  lastName: '',
                  address: '',
                  document_id: '',
                  companyName: '',
                  province: '',
                  provinceName: '',
                  provinceValue: '',
                  ciudad: '',
                  ciudadName: '',
                  cityValue: '',
                  disabledCity: true,
                  phone: '',
                  email: '',
                  managers: '',
                  agents: '',
                  yearFrom: null,
                  yearTo: null,
                  yearToMinDate: new Date(),
                  fixer: '',
                  activatePhoto: false,
                  activateApplicantreport: false,
                })
              })
              .catch((e) => {
                if (e.response.status === 401) {
                  setTimeout(() => {
                    localStorage.clear()
                    setReturnLogin(true)
                  }, 200)
                } else {
                  setErrorServer(true)
                  setoOpenBackdrop(false)
                }
              })
          }
        })
        .catch((e) => {
          if (e.response.status === 409) {
            setMensaje('El correo electrónico está registrado.')
            setOpenAlert(true)
            setError('error')
            setErrorServer(true)
            setoOpenBackdrop(false)
          } else if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            setMensaje('Error de conexión, intente más tarde.')
            setOpenAlert(true)
            setError('error')
            setErrorServer(true)
            setoOpenBackdrop(false)
          }
        })
    }
  }

  const SaveProfileResources = (id) => {
    if (privilegesResources.length > 0) {
      privilegesResources.forEach((row) => {
        const dataValue3 = {
          userId: id,
          resourceId: row,
        }

        axios
          .post(`${urlServices}users-resources`, dataValue3, {
            headers: {
              application: keyAplication,
              Authorization: 'Bearer ' + keyAuthorization,
            },
          })
          .then((response) => {})
          .catch((e) => {
            if (e.response.status === 401) {
              setTimeout(() => {
                localStorage.clear()
                setReturnLogin(true)
              }, 200)
            } else {
              console.log(e)
            }
          })
      })
    }
  }

  const callBackPrivileges = (data) => {
    setPrivilegesResources(data)
    setDisabled(false)
    setOpenEnable(false)
  }

  const changeProvince = (data) => {
    let response, disabled, detailName
    if (data !== null) {
      response = data.id
      detailName = data.name
      disabled = false
    } else {
      response = ''
      detailName = ''
      disabled = true
    }
    setValues({
      ...values,
      province: response,
      provinceName: detailName,
      disabledCity: disabled,
      ciudad: '',
      cityValue: null,
      provinceValue: data,
      errorProvince: false,
    })
  }

  const changeCities = (data) => {
    let response, detailName

    if (data !== null) {
      response = data.id
      detailName = data.name
    } else {
      response = ''
      detailName = ''
    }

    setValues({
      ...values,
      ciudad: response,
      ciudadName: detailName,
      cityValue: data,
      errorCiudad: false,
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const openEnable = () => {
    let idprofile = keyprofile_id === '1' ? '2' : '3'

    setProfileID(idprofile)
    setOpenEnable(true)
  }

  const handleCheckboxActivatePhoto = (e) => {
    setValues({
      ...values,
      activatePhoto: e.target.checked,
    })
  }

  const handleClosePrivileges = () => {
    setOpenEnable(false)
  }

  const handleActivateApplicantreport = (e) => {
    setValues({
      ...values,
      activateApplicantreport: e.target.checked,
    })
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        //onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleCardFilter}>Nueva cuenta</div>
                    <div className={classes.lineBottom}></div>
                    <Alert severity="info" style={{ fontSize: 12 }}>
                      Al registrar esta cuenta, se le enviará al correo una
                      clave temporal para el inicio de sesión. (Revisar tanto la
                      bandeja de entrada como la del spam).
                    </Alert>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <NumberFormat
                      customInput={TextField}
                      name="document_id"
                      value={values.document_id}
                      onChange={handleChange('document_id')}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          // fontSize: 16,
                          // marginTop: 25,
                        },
                      }}
                      label="Documento"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      required
                      variant="outlined"
                      helperText="Cédula, RUC o pasaporte."
                      error={values.errorDocument_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      autoComplete="off"
                      name="email"
                      value={values.email}
                      onChange={handleChange('email')}
                      label="E-mail"
                      error={values.errorEmail}
                      //className={classes.textField2}
                      fullWidth
                      margin="dense"
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl style={{ width: '100%' }}>
                      <TextField
                        name="name"
                        value={values.name}
                        error={values.errorName}
                        onChange={handleChange('name')}
                        fullWidth
                        required
                        label="Nombres"
                        margin="dense"
                        autoComplete="off"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl style={{ width: '100%' }}>
                      <TextField
                        name="lastName"
                        value={values.lastName}
                        error={values.errorLastName}
                        onChange={handleChange('lastName')}
                        fullWidth
                        required
                        label="Apellidos"
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl style={{ width: '100%' }}>
                      <TextField
                        name="companyName"
                        value={values.companyName}
                        error={values.errorCompanyName}
                        onChange={handleChange('companyName')}
                        fullWidth
                        label="Nombre de empresa"
                        margin="dense"
                        autoComplete="off"
                        required
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <NumberFormat
                      customInput={TextField}
                      name="phone"
                      value={values.phone}
                      onChange={handleChange('phone')}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          fontSize: 16,
                        },
                      }}
                      label="Teléfono"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      required
                      variant="outlined"
                      error={values.errorPhone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl style={{ width: '100%' }}>
                      <TextField
                        name="address"
                        value={values.address}
                        error={values.errorAddress}
                        onChange={handleChange('address')}
                        fullWidth
                        required
                        label="Dirección"
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <NumberFormat
                      customInput={TextField}
                      name="managers"
                      value={values.managers}
                      onChange={handleChange('managers')}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          fontSize: 16,
                        },
                      }}
                      label="Nº administradores"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      required
                      variant="outlined"
                      error={values.errorManagers}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <NumberFormat
                      customInput={TextField}
                      name="agents"
                      value={values.agents}
                      onChange={handleChange('agents')}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          fontSize: 16,
                        },
                      }}
                      label="Nº gestores"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      required
                      variant="outlined"
                      error={values.errorAgents}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <NumberFormat
                      customInput={TextField}
                      name="fixer"
                      value={values.fixer}
                      onChange={handleChange('fixer')}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          //fontSize: 16,
                        },
                      }}
                      label="Ajuste"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      required
                      helperText="Ej: 1.20"
                      variant="outlined"
                      error={values.errorFixer}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid item xs={12} md={6}>
                    <LoadProvince
                      refresh={changeProvince}
                      value={values.provinceValue}
                      error={values.errorProvince}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LoadCities
                      disabled={values.disabledCity}
                      idProvince={values.province}
                      refresh={changeCities}
                      value={values.cityValue}
                      error={values.errorCiudad}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <br></br>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={defaultMaterialTheme}>
                        <KeyboardDatePicker
                          id="date-picker-dialog"
                          label="Inicio de cuenta"
                          onChange={handleDateChangeFrom}
                          value={values.yearFrom}
                          format="yyyy-MM-dd"
                          // views={["year"]}
                          animateYearScrolling={true}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          inputVariant="outlined"
                          keyboardIcon={<Today className={classes.iconTheme} />}
                          autoComplete="off"
                          InputProps={{ readOnly: true }}
                          autoOk
                          minDate={new Date()}
                          minDateMessage={false}
                          maxDateMessage={false}
                          error={values.errorYearFrom}
                          required
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <br></br>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={defaultMaterialTheme}>
                        <KeyboardDatePicker
                          id="date-picker-dialog"
                          label="Fin de cuenta"
                          onChange={handleDateChangeTo}
                          value={values.yearTo}
                          format="yyyy-MM-dd"
                          // views={["year"]}
                          animateYearScrolling={true}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          inputVariant="outlined"
                          keyboardIcon={
                            <Today
                              className={
                                disabledTo
                                  ? classes.iconWhite
                                  : classes.iconTheme
                              }
                            />
                          }
                          minDate={values.yearToMinDate}
                          autoComplete="off"
                          InputProps={{ readOnly: true }}
                          autoOk
                          minDateMessage={false}
                          maxDateMessage={false}
                          disabled={disabledTo}
                          error={values.errorYearTo}
                          required
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ alignItems: 'center', display: 'inline-flex' }}
                  >
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={values.activatePhoto}
                          onClick={(event) => {
                            handleCheckboxActivatePhoto(event)
                          }}
                          name="activatePhoto"
                        />
                      }
                      label={
                        <span style={{ fontSize: 14 }}>
                          Habilitar fotos en pruebas
                        </span>
                      }
                      style={{ color: 'dimgray' }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ alignItems: 'center', display: 'inline-flex' }}
                  >
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={values.activateApplicantreport}
                          onClick={(event) => {
                            handleActivateApplicantreport(event)
                          }}
                          name="activateApplicantreport"
                        />
                      }
                      label={
                        <span style={{ fontSize: 14 }}>
                          Habilitar reporte para candidato
                        </span>
                      }
                      style={{ color: 'dimgray' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <br></br>
                    <center>
                      <div className={classes.cardTitle}>
                        {' '}
                        <Apps className={classes.iconFilter} /> Privilegios que
                        tiene el perfil &nbsp;&nbsp;
                        <Button
                          className={classes.btnGreen}
                          onClick={() => openEnable()}
                        >
                          Privilegios
                        </Button>
                      </div>
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {errorServer ? (
                      <Alert severity="error">
                        Error en la consulta al servidor
                      </Alert>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {OpenEnable ? (
              <ViewProfilePrivileges
                open={OpenEnable}
                callBack={callBackPrivileges}
                idprofile={ProfileID}
                exit={handleClosePrivileges}
              />
            ) : (
              ''
            )}
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === 'error' ? 'error' : 'success'}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
            <Backdrop
              style={{ zIndex: '9999', color: '#FFFFFF' }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className={classes.buttonSubmit}
            onClick={submitForm}
            disabled={disabled}
            startIcon={<Save />}
          >
            Guardar
          </Button>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
