import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, Cancel, Edit, Info, Check } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import axios from 'axios'
import MaterialTable, { MTableBodyRow } from 'material-table'
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import format from 'date-fns/format'

import EditAnswers from 'views/dialog/EditAnswers'
import EditQuestion from 'views/dialog/EditQuestion'
import CheckAnswers from 'views/dialog/CheckAnswers'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

import img from 'assets/img/loading.gif'

const useStyles = makeStyles(cardBodyStyle)

const useStyles2 = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(237, 247, 237)!important' } },
})

const MuiDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent)

const MuiDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions)

export default function TestsDone(props) {
  const classes = useStyles()
  const classes2 = useStyles2()

  const [rows, setRows] = useState([])
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(false)
  const [idRow, setIdRow] = useState('')
  const [openEnableAnswer, setOpenEnableAnswer] = useState(false)
  const [openEditQuestion, setOpenEditQuestion] = useState(false)
  const [statusTest, setStatusTest] = useState(0)
  const [returnLogin, setReturnLogin] = useState(false)
  const [openCheckAnswers, setOpenCheckAnswers] = useState(false)

  const { urlServices, keyAplication } = useContext(UrlServicesContext)
  const keyAuthorization = localStorage.getItem('Session')

  useEffect(() => {
    if (props.refresh) {
      TableData()
      props.callbackRefresh()
    } else {
      TableData()
    }
  }, [props.refresh])

  const TableData = () => {
    setError(false)
    setLoader(true)

    axios
      .get(`${urlServices}tests/${props.id.id}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        setRows(response.data.question)
        setStatusTest(response.data.status)
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRows([])
        } else if (e.response.status === 401) {
          localStorage.clear()
          setReturnLogin(true)
        } else {
          setError(true)
          console.log(e)
        }
      })
      .finally(() => {
        setLoader(false)
      })
  }

  const columns = useMemo(
    () => [
      {
        title: 'Preguntas',
        field: 'statement',
        width: '65%',
        render: (rowData) => <Fragment>{rowData.statement}</Fragment>,
        cellStyle: {
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
      {
        title: 'Tipo Pregunta',
        field: 'questiontype.name',
        width: '10%',
        cellStyle: {
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Registro',
        render: (rowData) => format(new Date(rowData.createdAt), 'dd-MM-yyyy'),
        width: '25%',
        cellStyle: {
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
        },
      },
      {
        title: '---',
        field: 'id',
        render: (rowData) => (
          <Button
            className={classes.buttonSubmit4}
            startIcon={<Search />}
            onClick={() => OpenAnswer(rowData)}
          >
            Respuesta
          </Button>
        ),
        width: '5%',
        cellStyle: {
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
        sorting: false,
      },
    ],
    [rows, classes.buttonSubmit4]
  )

  const OpenAnswer = (data) => {
    setIdRow(data)
    setOpenEnableAnswer(true)
  }

  const handleCloseEnableAnswer = () => {
    setOpenEnableAnswer(false)
  }

  const handleCloseEditQuestion = () => {
    setOpenEditQuestion(false)
  }

  const handleCloseDialog = () => {
    props.exit()
  }

  const handleCloseDialogCheckAnswers = () => {
    setOpenCheckAnswers(false)
  }

  const RefreshAnswers = () => {
    TableData()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <MuiDialogContent dividers className={classes.styleDialogContent}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  <div className={classes.cardTitleWhite}>
                    Lista de preguntas registradas
                  </div>
                </CardHeader>
                <CardBody>
                  {statusTest === 1 && (
                    <Alert severity="warning">
                      Prueba ya publicada, no se puede modificar las preguntas
                      ni respuestas.
                    </Alert>
                  )}
                  {error && (
                    <Alert severity="error">
                      Error en la consulta con el servidor.
                    </Alert>
                  )}
                  {loader ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} style={{ padding: '180px 0' }}>
                        <center>
                          <img src={img} alt="loader" style={{ width: 60 }} />
                          <br></br>
                          <b style={{ fontSize: 10 }}>Cargando preguntas...</b>
                        </center>
                      </Grid>
                    </Grid>
                  ) : (
                    <Fragment>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <Button
                            className={classes.buttonRightWarning}
                            onClick={() => setOpenCheckAnswers(true)}
                            startIcon={<Info style={{ color: 'white' }} />}
                          >
                            Puedes verificar las preguntas
                          </Button>
                        </Grid>
                      </Grid>
                      <MaterialTable
                        title=""
                        columns={columns}
                        style={{
                          boxShadow: 'unset',
                          border: '1px solid rgb(241, 241, 241)',
                        }}
                        data={rows}
                        localization={{
                          toolbar: {
                            searchTooltip: 'Filtrar',
                            searchPlaceholder: 'Buscar',
                          },
                          pagination: {
                            labelRowsSelect: 'Filas',
                            labelDisplayedRows: ' {from}-{to} de {count}',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Previo',
                            nextTooltip: 'Siguiente',
                            lastTooltip: 'Ultima página',
                          },
                          header: {
                            actions: '',
                          },
                          body: {
                            emptyDataSourceMessage: 'No hay preguntas.',
                            filterRow: {
                              filterTooltip: 'Filtro',
                            },
                          },
                        }}
                        actions={[
                          (rowData) => ({
                            icon: () => <Edit />,
                            tooltip: 'Editar',
                            onClick: (event, rowData) => {
                              setOpenEditQuestion(true)
                              setIdRow(rowData)
                            },
                            hidden: statusTest === 1 ? true : false,
                          }),
                          (rowData) => ({
                            icon: () => <Check style={{ color: 'green' }} />,
                            tooltip: 'Publicado',
                            hidden: statusTest === 1 ? false : true,
                          }),
                        ]}
                        options={{
                          search: true,
                          padding: 1,
                          filtering: false,
                          actionsColumnIndex: -1,
                          pageSize: 10,
                          headerStyle: {
                            background: '#F2F2F2',
                            padding: '1%',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          },
                          cellStyle: { border: '1px solid #DDDDDD' },
                        }}
                        components={{
                          Row: (props) => (
                            <MTableBodyRow
                              className={classes2.tableRow}
                              {...props}
                            />
                          ),
                        }}
                      />
                    </Fragment>
                  )}
                </CardBody>
              </Card>
            </GridItem>
            {openEnableAnswer && (
              <EditAnswers
                open={openEnableAnswer}
                exit={handleCloseEnableAnswer}
                id={idRow}
                statusTest={statusTest}
                callBackRefresh={RefreshAnswers}
              />
            )}
            {openEditQuestion && (
              <EditQuestion
                open={openEditQuestion}
                exit={handleCloseEditQuestion}
                id={idRow}
                callBackRefresh={TableData}
              />
            )}
            {openCheckAnswers && (
              <CheckAnswers
                open={openCheckAnswers}
                id={props.id.id}
                exit={handleCloseDialogCheckAnswers}
                callBackRefresh={TableData}
              />
            )}
          </GridContainer>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseDialog}>
            <Cancel />
          </Button>
        </MuiDialogActions>
      </Dialog>
    </Fragment>
  )
}
