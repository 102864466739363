import React, { Component, Fragment } from "react";
// import {
//   ExcelExport,
//   ExcelExportColumn /*ExcelExportColumnGroup*/,
// } from "@progress/kendo-react-excel-export";
import Button from "@material-ui/core/Button";
import { Print } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";

export default class ExportApplicantPassTestExcel extends Component {
  constructor(props) {
    super(props);
  }
  _export;
  export = () => {
    this._export.save();
  };

  render() {
    return (
      <Fragment>
        {/* <ExcelExport
          data={this.props.data}
          ref={(exporter) => (this._export = exporter)}
        >
          <ExcelExportColumn
            field="user.name"
            title="Nombres"
            width={150}
          />
          <ExcelExportColumn
            field="user.lastName"
            title="Apellidos"
            width={150}
          />
          <ExcelExportColumn
            field="user.documentId"
            title="Documento"
            width={80}
          />
          <ExcelExportColumn
            field="user.userspasswords[0].password"
            title="Clave"
            width={80}
            headerCellOptions={{
              // background: "#7fff0d",
              textAlign: "center",
              // color: "#000000",
            }}
            cellOptions={{
              // background: "#7fff0d",
              textAlign: "center",
              fontSize: 14,
              bold: true,
              // color: "#000000",
            }}
          />
        </ExcelExport>

        <Tooltip title="Exportar a Excel" aria-label="Exportar a Excel">
          <Button
            onClick={this.export}
            type="submit"
            className={this.props.style}
            startIcon={<Print style={{ fontSize: 14 }} />}
          >
            Excel
          </Button>
          </Tooltip>*/}
      </Fragment>
    );
  }
}
