import React, { Fragment, useState, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

// @material-ui/core components
import { makeStyles, withStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { AddCircle, Cancel, Edit } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import axios from 'axios'
import {
  Button,
  Grid,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Alert from '@material-ui/lab/Alert'

import EditAnswers from 'views/dialog/EditAnswers'

import img from 'assets/img/loading.gif'
import imgSuccess from 'assets/img/success.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function CheckAnswers(props) {
  const classes = useStyles()

  const [openEditCheckAnswers, setOpenEditCheckAnswers] = useState(false)
  const [loader, setLoader] = React.useState(true)
  const [rows, setRows] = React.useState([])
  const [idRow, setIdRow] = React.useState([])
  const [mensajeAlert, setMensajeAlert] = React.useState('todo está correcto.')

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    TableData()
  }, [])

  const TableData = async () => {
    try {
      const response = await axios.get(`${urlServices}tests/${props.id}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })

      const questions = response.data.question

      if (questions.length > 0) {
        const out2 = questions.flatMap((row) => {
          if (row.answer.length === 0) {
            // Caso donde no hay respuestas registradas
            return [
              {
                ...row,
                warningID: 0,
                warning: 'NO TIENE RESPUESTAS REGISTRADAS',
              },
            ]
          } else {
            // Verificación de si al menos una respuesta es verdadera y está activa
            const hasTrueAnswer = row.answer.some(
              (row1) => row1.rightAnswer === true && row1.status === 1
            )

            if (!hasTrueAnswer) {
              return [
                {
                  ...row,
                  warningID: 1,
                  warning: 'NO TIENE RESPUESTAS VERDADERAS',
                },
              ]
            }
          }
          return []
        })

        setRows(out2)
      } else {
        setMensajeAlert('no hay pregunta.')
      }
    } catch (e) {
      if (e.response?.status === 404) {
        setRows([])
      } else if (e.response?.status === 401) {
        // Manejar el error 401 si es necesario
      } else {
        console.error(e)
      }
    } finally {
      setLoader(false)
    }
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleCloseEnableAnswer = () => {
    setOpenEditCheckAnswers(false)
  }

  const OpenEdit = (data) => () => {
    setIdRow(data)
    setOpenEditCheckAnswers(true)
  }

  const RefreshAnswers = () => {
    TableData()
    props.callBackRefresh()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleCloseDialog(true)}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      keepMounted
      scroll="paper"
      PaperProps={{
        classes: { root: classes.DialogStyle },
      }}
    >
      <DialogContent dividers className={classes.styleDialogContent}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <div className={classes.cardTitleWhite}>
                  <AddCircle className={classes.iconWhite} /> Validar preguntas
                </div>
              </CardHeader>
              <CardBody>
                <Fragment>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Alert severity="info">
                        Validar que las preguntas tengan respuestas registradas
                        y que cada pregunta tenga al menos una opción verdadera.
                      </Alert>
                    </Grid>
                  </Grid>
                  {loader ? (
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ fontSize: 12, fontWeight: 'bold' }}
                      >
                        <center>
                          <img
                            alt="loader"
                            src={img}
                            style={{ width: 50, marginTop: 50 }}
                          />
                          <br></br>
                          Revisando todas las preguntas...
                        </center>
                      </Grid>
                    </Grid>
                  ) : rows.length === 0 ? (
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ fontSize: 14, fontWeight: 'bold' }}
                      >
                        <center>
                          <img
                            alt="loader"
                            src={imgSuccess}
                            style={{ width: 100, marginTop: 50 }}
                          />
                          <br />
                          Sin ningún resultado, {mensajeAlert}
                        </center>
                      </Grid>
                    </Grid>
                  ) : (
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: '70%' }}>
                              Preguntas
                            </TableCell>
                            <TableCell style={{ width: '25%' }}>
                              Advertencia
                            </TableCell>
                            <TableCell style={{ fontSize: 12, width: '5%' }}>
                              ---
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                {row.statement}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  fontSize: 9,
                                  fontWeight: 'bold',
                                  color: row.warningID === 0 ? 'red' : 'orange',
                                }}
                              >
                                {row.warning}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <center>
                                  <Edit
                                    style={{
                                      fontSize: 22,
                                      cursor: 'pointer',
                                    }}
                                    onClick={OpenEdit(row)}
                                  />
                                </center>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Fragment>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {openEditCheckAnswers && (
          <EditAnswers
            open={openEditCheckAnswers}
            exit={handleCloseEnableAnswer}
            id={idRow}
            callBackRefresh={RefreshAnswers}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog(true)}>
          <Cancel />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
