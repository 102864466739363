import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import axios from "axios";
import { Animated } from "react-animated-css";
import Link from "@material-ui/core/Link";
import queryString from "query-string";

import Header from "../components/Navbars/HeaderLogin";
import Footer from "../components/Footer/FooterAccount";

import pass from "assets/img/passlogin.png";
import successImg from "assets/img/success.gif";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function RecoveryPassword(props) {
  const classes = useStyles();

  let get = queryString.parse(props.location.search);

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [values, setValues] = useState({
    newPassword1: "",
    newPassword2: "",
    showPassword1: false,
    showPassword2: false,
    errorPassword1: false,
    errorPassword2: false,
    success: false,
  });

  const [alert, setAlert] = useState({
    openAlert: false,
    mensaje_error: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorPassword1: false,
      errorPassword2: false,
    });
  };

  const handleClickShowPassword1 = () => {
    setValues({
      ...values,
      showPassword1: !values.showPassword1,
    });
  };

  const handleClickShowPassword2 = () => {
    setValues({
      ...values,
      showPassword2: !values.showPassword2,
    });
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const submitForm = () => {
    setValues({
      ...values,
      errorPassword1: false,
      errorPassword2: false,
    });

    if (values.newPassword1.length === 0) {
      setValues({
        ...values,
        errorPassword1: true,
      });
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "El campo nueva contraseña es obligatorio!",
      });
    } else if (values.newPassword2.length === 0) {
      setValues({
        ...values,
        errorPassword2: true,
      });
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "El campo repetir contraseña es obligatorio!",
      });
    } else {
      if (values.newPassword1 === values.newPassword2) {
        const data = {
          newPassword: values.newPassword2,
          token: get.token,
        };

        axios
          .put(`${urlServices}auth/change-password`, data, {
            headers: {
              application: keyAplication,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setValues({
                ...values,
                success: true,
              });
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              setValues({ ...values, newPassword1: "", newPassword2: "" });
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error:
                  "Link caducado, vuelva a recuperar su contraseña",
              });
            } else {
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: "Error de conexión, intente más tarde.",
              });
              console.log(e);
            }
          });
      } else {
        setAlert({
          ...alert,
          openAlert: true,
          mensaje_error: "Las contraseñas no coinciden.",
        });
      }
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ openAlert: false });
  };

  return (
    <Fragment>
      <Header />
      <center>
        <Grid container className={classes.carContacts}>
          {values.success ? (
            <Grid item xs={12} sm={12}>
              <Animated
                animationIn="bounceIn"
                animationInDuration={1500}
                isVisible={true}
              >
                <center>
                  <img
                    src={successImg}
                    alt="successPassword"
                    style={{
                      width: "150px",
                    }}
                  />
                  <br></br>
                  <div className={classes.titleCard}>
                    Contraseña registrada con éxito.
                  </div>
                  <br></br>
                  <Link
                    href="/login"
                    color="inherit"
                    className={classes.blockReds}
                  >
                    <Button className={classes.buttonSubmit}>
                      Iniciar sesión
                    </Button>
                  </Link>
                </center>
              </Animated>
            </Grid>
          ) : (
            <Fragment>
              <Grid item xs={12} md={6}>
                <Hidden smDown implementation="css">
                  <center>
                    <img
                      src={pass}
                      alt="recovery"
                      style={{
                        width: "80%",
                        marginTop: 30,
                      }}
                    />
                  </center>
                </Hidden>
                <Hidden mdUp implementation="css">
                  <center>
                    <img
                      src={pass}
                      alt="recovery"
                      style={{
                        width: "80%",
                      }}
                    />
                  </center>
                </Hidden>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.titleCardFilter}>Contraseña</div>
                <div className={classes.lineBottom}></div>
                <FormControl className={classes.textField}>
                  <InputLabel htmlFor="standard-adornment-password">
                    Nueva contraseña
                  </InputLabel>
                  <Input
                    name="newPassword1"
                    type={values.showPassword1 ? "text" : "password"}
                    value={values.newPassword1}
                    onChange={handleChange("newPassword1")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword1}
                        >
                          {values.showPassword1 ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={values.errorPassword1}
                  />
                </FormControl>
                <br></br>
                <FormControl className={classes.textField}>
                  <InputLabel htmlFor="standard-adornment-password">
                    Repetir contraseña
                  </InputLabel>
                  <Input
                    name="newPassword2"
                    type={values.showPassword2 ? "text" : "password"}
                    value={values.newPassword2}
                    onChange={handleChange("newPassword2")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                        >
                          {values.showPassword2 ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={values.errorPassword2}
                  />
                </FormControl>

                <br></br>
                <br></br>
                <center>
                  <Button
                    className={classes.buttonSubmit}
                    onClick={submitForm}
                    size="large"
                  >
                    Guardar
                  </Button>
                </center>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </center>

      <Grid container>
        <Footer />
      </Grid>
      {alert.openAlert ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseAlert}
        >
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              elevation={6}
              variant="filled"
            >
              {alert.mensaje_error}
            </Alert>
          </Slide>
        </Snackbar>
      ) : (
        ""
      )}
    </Fragment>
  );
}
