import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, Layers } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import axios from 'axios'
import MaterialTable from 'material-table'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import format from 'date-fns/format'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'

import EnableResultsJobsIndividual from 'views/dialog/EnableResultsJobsIndividual'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

export default function ReportindIvidualFactors() {
  const classes = useStyles()

  const [rows, setRows] = useState([])
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(false)
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [openResults, setOpenResults] = useState(false)
  const [idRow, setIdRow] = useState('')
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Candidato',
        field: 'applicant.name',
        render: (rowData) => {
          return (
            <b>{rowData.applicant.name + ' ' + rowData.applicant.lastName}</b>
          )
        },
        width: '30%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
      {
        title: 'Cargo',
        field: 'jobtesttpm.job.name',
        width: '35%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
      /*{
        title: "Prueba",
        field: "jobtesttpm.test.name",
        width: "35%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },*/
      {
        title: 'Estado',
        render: (rowData) => {
          const statusMap = {
            1: { text: 'POR COMENZAR', color: 'grey' },
            2: { text: 'INICIADA', color: 'blue' },
            3: { text: 'FINALIZADA', color: 'green' },
            4: { text: 'PAUSADA', color: 'orange' },
            5: { text: 'INACTIVA', color: 'red' },
          }
          const { text, color } = statusMap[rowData.status]
          return (
            <Fragment>
              <b style={{ color }}>{text}</b>{' '}
              {rowData.status === 2 && <LinearProgress />}
            </Fragment>
          )
        },
        width: '5%',
        cellStyle: {
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
      },
      {
        title: 'Iniciada',
        render: (rowData) =>
          rowData.startDate
            ? format(new Date(rowData.startDate), 'dd-MM-yyyy kk:mm:ss')
            : '',
        width: '8%',
        cellStyle: {
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
          color: 'green',
          fontWeight: 'bold',
        },
      },
      {
        title: 'Finalizada',
        render: (rowData) =>
          rowData.endDate
            ? format(new Date(rowData.endDate), 'dd-MM-yyyy kk:mm:ss')
            : '',
        width: '8%',
        cellStyle: {
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
          color: 'green',
          fontWeight: 'bold',
        },
      },
      {
        title: '---',
        render: (rowData) =>
          rowData.status === 3 ? (
            <Button
              size="small"
              style={{ width: 80, fontSize: 10 }}
              className={classes.buttonSubmit4}
              onClick={() => OpenResult(rowData)}
            >
              Resultados
            </Button>
          ) : (
            <Button
              size="small"
              style={{ width: 80, fontSize: 10 }}
              className={classes.buttonSubmit4}
              disabled
            >
              Resultados
            </Button>
          ),
        width: '5%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
        sorting: false,
      },
    ],
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    axios
      .get(
        `${urlServices}jobs-users-tpm/account/${localStorage.getItem(
          'account_id'
        )}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            const sortedData = response.data.sort(
              (a, b) => new Date(b.startDate) - new Date(a.startDate)
            )

            let result = sortedData.filter(
              (filtered) => filtered.jobtesttpm.testId !== 12
            )

            setRows(result)
          }

          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRows([])
          setLoader(false)
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setRows([])
          setError(true)
          console.log(e)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const RefreshTable = () => {
    setoOpenBackdrop(true)

    axios
      .get(
        `${urlServices}jobs-users-tpm/account/${localStorage.getItem(
          'account_id'
        )}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setoOpenBackdrop(false)

          if (response.data.length > 0) {
            const sortedData = response.data.sort(
              (a, b) => new Date(b.startDate) - new Date(a.startDate)
            )

            let result = sortedData.filter(
              (filtered) => filtered.jobtesttpm.testId !== 12
            )

            setRows(result)
          }

          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRows([])
          setLoader(false)
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setRows([])
          setError(true)
          console.log(e)
        }
      })
  }

  const handleCloseOpenResults = () => {
    setOpenResults(false)
  }

  const OpenResult = (data) => {
    let out = {
      jobId: data.jobtesttpm.jobId,
      userId: data.applicantId,
    }

    setIdRow(out)
    setOpenResults(true)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Layers className={classes.iconWhite} /> Reporte individual por
              factores
            </div>
          </CardHeader>
          <CardBody>
            {error ? (
              <Alert severity="error">
                Error en la consulta con sel servidor.
              </Alert>
            ) : (
              ''
            )}
            {loader ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ width: '100%' }}>
                    <Skeleton
                      width="40%"
                      height={40}
                      style={{ float: 'right' }}
                    />
                    <Skeleton width="100%" height={300} />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <MaterialTable
                  title=""
                  columns={state.columns}
                  style={{
                    boxShadow: 'unset',
                    border: '1px solid rgb(241, 241, 241)',
                  }}
                  data={rows}
                  localization={{
                    pagination: {
                      labelRowsSelect: 'Filas',
                      labelDisplayedRows: ' {from}-{to} de {count}',
                      firstTooltip: 'Primera página',
                      previousTooltip: 'Previo',
                      nextTooltip: 'Siguiente',
                      lastTooltip: 'Ultima página',
                    },
                    toolbar: {
                      // nRowsSelected: '{0} row(s) selected',
                      searchTooltip: 'Filtrar',
                      searchPlaceholder: 'Buscar',
                    },
                    header: {
                      actions: ' --- ',
                    },
                    body: {
                      emptyDataSourceMessage: 'No hay datos.',
                      filterRow: {
                        filterTooltip: 'Filtro',
                      },
                    },
                  }}
                  actions={[
                    /* {
                              icon: "search",
                              tooltip: "Ver",
                              onClick: (event, rowData) => {
                                setopenViewUser(true);
                                setIdRow(rowData);
                              },
                            },*/
                    /* {
                            icon: "edit",
                            tooltip: "Editar",
                            onClick: (event, rowData) => {
                              setOpenEdit(true);
                              setIdRow(rowData);
                            },
                          },
                          {
                                icon: "delete",
                                tooltip: "Eliminar",
                                onClick: (event, rowData) => {
                                  setOpenDelete(true);
                                  setIdRow(rowData);
                                },
                              },*/
                    /* (rowData1) => ({
                            icon: () => <Cancel />,
                            tooltip: "Deshabilitar",
                            /* onClick: (event, rowData) => {
                              setOpenDelete(true);
                              setIdRow(rowData);
                            },
                            hidden: rowData1.status === 1 ? false : true,
                          }),*/
                    {
                      icon: 'refresh',
                      tooltip: 'Refresh Data',
                      isFreeAction: true,
                      onClick: () => RefreshTable(),
                    },
                  ]}
                  icons={tableIcons}
                  options={{
                    search: true,
                    padding: 'default',
                    filtering: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    headerStyle: {
                      background: '#F2F2F2',
                      padding: '1%',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    },
                    cellStyle: { border: '1px solid #DDDDDD' },
                  }}
                />
              </Fragment>
            )}
          </CardBody>
        </Card>
      </GridItem>

      {openResults ? (
        <EnableResultsJobsIndividual
          open={openResults}
          exit={handleCloseOpenResults}
          id={idRow}
        />
      ) : (
        ''
      )}

      <Backdrop
        style={{ zIndex: '9999', color: '#FFFFFF' }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </GridContainer>
  )
}
