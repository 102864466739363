import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'
import { UrlServicesProvider } from 'components/UrlServicesContext'

// Core components
import SuperAdmin from 'layouts/SuperAdmin'
import Admin from 'layouts/Admin'
import Login from 'views/Login'
import PrintPDFResultsApplicantsTest from 'views/Print/PrintPDFResultsApplicantsTest'
import PrintPDFPassApplicantsTest from 'views/Print/PrintPDFPassApplicantsTest'
import PrintPDFResultsApplicantsTestColor from 'views/Print/PrintPDFResultsApplicantsTestColor'
import PrintPDFReportApplicantsTest from 'views/Print/PrintPDFReportApplicantsTest'
import PrintPDFPassApplicantsJobs from 'views/Print/PrintPDFPassApplicantsJobs'
import PrintPDFReportGeneralJobs from 'views/Print/PrintPDFReportGeneralJobs'
import PrintPDFResultsIndividualJobs from 'views/Print/PrintPDFResultsIndividualJobs'
import PrintPDFApplicantsTestPsychometrics from 'views/Print/PrintPDFApplicantsTestPsychometrics'
import NotFoundPage from 'views/404'
import RecoveryPassword from 'views/RecoveryPassword'

import 'assets/css/material-dashboard-react.css?v=1.8.0'

const history = createBrowserHistory()

const App = () => (
  <UrlServicesProvider>
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/superadmin" component={SuperAdmin} />
        <Route path="/admin" component={Admin} />
        <Route path="/login" exact component={Login} />
        <Route path="/recovery" exact component={RecoveryPassword} />
        <Route path="/printresults" exact component={PrintPDFResultsApplicantsTest} />
        <Route path="/printresultstestcolor" exact component={PrintPDFResultsApplicantsTestColor} />
        <Route path="/passapplicants" exact component={PrintPDFPassApplicantsTest} />
        <Route path="/reportapplicants" exact component={PrintPDFReportApplicantsTest} />
        <Route path="/passapplicantsjobs" exact component={PrintPDFPassApplicantsJobs} />
        <Route path="/reportgeneraljobs" exact component={PrintPDFReportGeneralJobs} />
        <Route path="/printresultsindividualjobs" exact component={PrintPDFResultsIndividualJobs} />
        <Route path="/printtest" exact component={PrintPDFApplicantsTestPsychometrics} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  </UrlServicesProvider>
)

ReactDOM.render(<App />, document.getElementById('root'))