import React, {
  Fragment,
  useState,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, Layers, Print } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import axios from 'axios'
import MaterialTable from 'material-table'
import { Grid, Button, LinearProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import format from 'date-fns/format'

import EnableResults from 'views/dialog/EnableResults'
import EnableResultsReport from 'views/dialog/EnableResultsReport'
import EnableInformationTestUser from 'views/dialog/EnableInformationTestUser'
import EnableRestartTestUserStop from 'views/dialog/EnableRestartTestUserStop'
import img from 'assets/img/loading.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const ApplicantsTest = () => {
  const classes = useStyles()

  const [rows, setRows] = useState([])
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(false)
  const [openResults, setOpenResults] = useState(false)
  const [openInformation, setOpenInformation] = useState(false)
  const [idRow, setIdRow] = useState('')
  const [returnLogin, setReturnLogin] = useState(false)
  const [OpenReport, setOpenReport] = useState(false)
  const [openRestart, setOpenRestart] = useState(false)

  const keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const state = {
    columns: [
      {
        title: 'Candidato',
        field: 'user.name',
        render: (rowData) => (
          <b>{rowData.user.name + ' ' + rowData.user.lastName}</b>
        ),
        width: '30%',
        cellStyle: {
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 11,
        },
      },
      {
        title: 'Prueba',
        field: 'test.name',
        width: '35%',
        cellStyle: {
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
      {
        title: 'Estado',
        render: (rowData) => {
          const text =
            rowData.status === 1
              ? 'POR COMENZAR'
              : rowData.status === 2
              ? 'INICIADA'
              : rowData.status === 3
              ? 'FINALIZADA'
              : rowData.status === 4
              ? 'PAUSADA'
              : 'INACTIVA'
          const color =
            rowData.status === 1
              ? 'grey'
              : rowData.status === 2
              ? 'blue'
              : rowData.status === 3
              ? 'green'
              : rowData.status === 4
              ? 'orange'
              : 'red'
          return (
            <Fragment>
              <b style={{ color: color }}>{text}</b>{' '}
              {rowData.status === 2 ? <LinearProgress /> : ''}{' '}
            </Fragment>
          )
        },
        width: '5%',
        cellStyle: {
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
      },
      {
        title: 'Iniciada',
        render: (rowData) =>
          rowData.startDate !== null
            ? format(new Date(rowData.startDate), 'dd-MM-yyyy kk:mm:ss')
            : '',
        width: '8%',
        cellStyle: {
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
          color: 'green',
          fontWeight: 'bold',
        },
      },
      {
        title: 'Finalizada',
        render: (rowData) =>
          rowData.endDate !== null
            ? format(new Date(rowData.endDate), 'dd-MM-yyyy kk:mm:ss')
            : '',
        width: '8%',
        cellStyle: {
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
          color: 'green',
          fontWeight: 'bold',
        },
      },
      {
        title: '---',
        render: (rowData) =>
          rowData.status === 3 ? (
            <Button
              size="small"
              style={{ width: 80, fontSize: 10 }}
              className={classes.buttonSubmit4}
              onClick={() => OpenResult(rowData)}
            >
              Resultados
            </Button>
          ) : rowData.status === 2 ? (
            <Button
              size="small"
              style={{ width: 80, fontSize: 10 }}
              className={classes.buttonSubmit5}
              onClick={() => OpenInformation(rowData)}
            >
              Información
            </Button>
          ) : rowData.status === 4 ? (
            <Button
              size="small"
              style={{ width: 80, fontSize: 10 }}
              className={classes.buttonSubmit5}
              onClick={() => OpenRestart(rowData)}
            >
              Reanudar
            </Button>
          ) : (
            <Button
              size="small"
              style={{ width: 80, fontSize: 10 }}
              className={classes.buttonSubmit4}
              disabled
            >
              Resultados
            </Button>
          ),
        width: '3%',
        cellStyle: {
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
        },
        sorting: false,
      },
    ],
  }

  const fetchData = useCallback(async () => {
    try {
      setLoader(true)
      const response = await axios.get(
        `${urlServices}tests-users/account/${localStorage.getItem(
          'account_id'
        )}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )

      if (response.status === 200) {
        if (response.data.length > 0) {
          const sortedData = response.data.sort(
            (a, b) => new Date(b.startDate) - new Date(a.startDate)
          )
          setRows(sortedData)
        }
      }
    } catch (e) {
      if (e.response.status === 404) {
        setRows([])
      } else if (e.response.status === 401) {
        localStorage.clear()
        setReturnLogin(true)
      } else {
        setError(true)
        console.log(e)
      }
    } finally {
      setLoader(false)
    }
  }, [urlServices, keyAplication, keyAuthorization])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const RefreshTable = useCallback(() => {
    fetchData()
  }, [fetchData])

  const handleCloseOpenInformation = () => {
    setOpenInformation(false)
  }

  const handleCloseOpenResults = () => {
    setOpenResults(false)
  }

  const handleCloseReport = () => {
    setOpenReport(false)
  }

  const handleCloseOpenRestart = () => {
    setOpenRestart(false)
  }

  const OpenResult = (data) => {
    let out = {
      testId: data.test.id,
      testName: data.test.name,
      testTime: data.test.time,
      testScore: data.test.totalScore,
      userId: data.user.id,
      userNames: data.user.name + ' ' + data.user.lastName,
      userEmail: data.user.email,
      userDocument: data.user.documentId,
    }

    setIdRow(out)
    setOpenResults(true)
  }

  const OpenInformation = (data) => {
    let out = {
      id: data.id,
      testId: data.test.id,
      testName: data.test.name,
      testTime: data.test.time,
      testScore: data.test.totalScore,
      userId: data.user.id,
      userNames: data.user.name + ' ' + data.user.lastName,
      userEmail: data.user.email,
      userDocument: data.user.documentId,
    }

    setIdRow(out)
    setOpenInformation(true)
  }

  const OpenRestart = (data) => {
    setIdRow(data)
    setOpenRestart(true)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Layers className={classes.iconWhite} /> Estado de pruebas
              técnicas
            </div>
          </CardHeader>
          <CardBody>
            {error && (
              <Alert severity="error">
                Error en la consulta con el servidor.
              </Alert>
            )}
            {loader ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <center>
                    <img
                      src={img}
                      alt="loader"
                      style={{
                        width: 60,
                        margin: '100px 0',
                      }}
                    />
                  </center>
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <div>
                  Reporte general de candidatos: &nbsp;&nbsp;
                  <Button
                    className={classes.buttonLeft}
                    startIcon={<Print style={{ fontSize: 14 }} />}
                    onClick={() => setOpenReport(true)}
                  >
                    Reporte
                  </Button>
                </div>
                <MaterialTable
                  title=""
                  columns={state.columns}
                  data={rows}
                  localization={{
                    pagination: {
                      labelRowsSelect: 'Filas',
                      labelDisplayedRows: '{from}-{to} de {count}',
                      firstTooltip: 'Primera página',
                      previousTooltip: 'Previo',
                      nextTooltip: 'Siguiente',
                      lastTooltip: 'Ultima página',
                    },
                    toolbar: {
                      searchTooltip: 'Filtrar',
                      searchPlaceholder: 'Buscar',
                    },
                    header: {
                      actions: ' --- ',
                    },
                    body: {
                      emptyDataSourceMessage: 'No hay datos.',
                      filterRow: {
                        filterTooltip: 'Filtro',
                      },
                    },
                  }}
                  actions={[
                    {
                      icon: 'refresh',
                      tooltip: 'Refresh Data',
                      isFreeAction: true,
                      onClick: RefreshTable,
                    },
                  ]}
                  icons={tableIcons}
                  options={{
                    search: true,
                    paging: true,
                    filtering: false,
                    sorting: true,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    headerStyle: {
                      background: '#F2F2F2',
                      padding: '1%',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    },
                    cellStyle: { border: '1px solid #DDDDDD' },
                  }}
                />
              </Fragment>
            )}
          </CardBody>
        </Card>
      </GridItem>

      {openResults && (
        <EnableResults
          open={openResults}
          exit={handleCloseOpenResults}
          id={idRow}
        />
      )}
      {openInformation && (
        <EnableInformationTestUser
          open={openInformation}
          exit={handleCloseOpenInformation}
          id={idRow}
          callBackRefresh={RefreshTable}
          path="tests-users"
        />
      )}
      {openRestart && (
        <EnableRestartTestUserStop
          open={openRestart}
          exit={handleCloseOpenRestart}
          id={idRow}
          callBackRefresh={RefreshTable}
          path="tests-users"
        />
      )}

      {OpenReport && (
        <EnableResultsReport open={OpenReport} exit={handleCloseReport} />
      )}
    </GridContainer>
  )
}

export default ApplicantsTest
