import React, { Fragment, useEffect, useState, useContext } from "react";
import { Redirect } from "react-router-dom";

import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import { UrlServicesContext } from "../UrlServicesContext";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function LoadCountries(props) {
  const [results, setResults] = useState([]);
  const [error, setError] = useState(false);
  const [isLoadedd, setIsLoadedd] = useState(false);
  const [returnLogin, setReturnLogin] = React.useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  useEffect(() => {
    const consultar = async () => {
      await axios
        .get(`${urlServices}countries`, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          setResults(response.data.data);
          setIsLoadedd(true);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 200);
          } else {
            setResults(null);
            setError(true);
            setIsLoadedd(false);
          }
        });
    };
    consultar();
  }, [urlServices, keyAplication]);

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      {error ? (
        <Alert severity="error">
          Error en la consulta de <b>PAISES</b>
        </Alert>
      ) : (
        ""
      )}
      {!isLoadedd ? (
        <Fragment>
          <Grid item xs={12} sm={12}>
            <center>
              <Box sx={{ width: "100%" }}>
                <Skeleton width="100%" height={35} />
              </Box>
            </center>
          </Grid>
        </Fragment>
      ) : (
        <Autocomplete
          id="combo-box-demo"
          options={results}
          value={props.value}
          onChange={(event, newValue) => {
            props.refresh(newValue);
          }}
          getOptionLabel={(option) => option.name || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="País"
              variant="standard"
              error={props.error}
              required
              inputProps={{
                ...params.inputProps,
                autoComplete: "off", // disable autocomplete and autofill
              }}
            />
          )}
          classes={{
            clearIndicatorDirty: "none",
            popupIndicator: "none",
          }}
        />
      )}
    </Fragment>
  );
}
