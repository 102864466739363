import React, { Fragment, useState, useContext } from 'react'

import { Redirect } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Markunread, Cancel } from '@material-ui/icons'
import axios from 'axios'
import { UrlServicesContext } from '../../components/UrlServicesContext'
import { Animated } from 'react-animated-css'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'

import successImg from 'assets/img/success.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function PasswordRecovery(props) {
  const classes = useStyles()
  const [mail, setMail] = useState('')
  const [sendMail, setSendMail] = useState('')
  const { urlServices, keyAplication } = useContext(UrlServicesContext)
  const [mensaje_error, setMensaje_error] = useState('')
  const [errorFieldMail, setErrorFieldMail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorServer, setErrorServer] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleChange = () => (event) => {
    setMensaje_error('')
    setErrorFieldMail(false)
    setMail(event.target.value)
  }

  const emailForm = (e) => {
    e.preventDefault()

    setErrorFieldMail(false)
    setMensaje_error('')
    setErrorServer(false)

    if (mail.length === 0) {
      setMensaje_error('El campo es obligatorio!')
      setErrorFieldMail(true)
    } else {
      const data = {
        email: mail.toLowerCase(),
      }

      setLoading(true)

      axios
        .post(`${urlServices}auth/email`, data, {
          headers: {
            application: keyAplication,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const data2 = {
              email: mail.toLowerCase(),
            }

            axios
              .post(`${urlServices}auth/recovery`, data2, {
                headers: {
                  application: keyAplication,
                },
              })
              .then(
                (response2) => {
                  setSendMail(true)
                  setLoading(false)
                },
                (error) => {
                  console.log(error)
                  setErrorServer(true)
                  setLoading(false)
                }
              )
            setLoading(false)
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            setMensaje_error('Correo electrónico no registrado.')
            setErrorFieldMail(true)
            setLoading(false)
          } else if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            setErrorServer(true)
            setLoading(false)
          }
        })
    }
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Grid container spacing={1} className={classes.containerProfile}>
            <Grid item xs={12} sm={12}>
              {errorServer ? (
                <Alert severity="error">Error en la consulta al servidor</Alert>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className={classes.titleCardFilter}>
                Recuperación de contraseña
              </div>
              <div className={classes.lineBottom}></div>
              <br></br>
            </Grid>
            {sendMail ? (
              <Grid item xs={12} sm={12}>
                <Animated
                  animationIn="bounceIn"
                  animationInDuration={1500}
                  isVisible={true}
                >
                  <center>
                    <img
                      src={successImg}
                      alt="successPassword"
                      style={{
                        width: '150px',
                      }}
                    />
                    <br></br>
                    <div className={classes.titleCard}>
                      Enviamos un enlace de recuperación al correo <b>{mail}</b>
                    </div>
                  </center>
                </Animated>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12}>
                <center>
                  <Animated
                    animationIn="fadeIn"
                    animationInDuration={1000}
                    isVisible={true}
                  >
                    <Markunread style={{ fontSize: '42px' }} />
                    <Box width={300}>
                      <TextField
                        name="mail"
                        value={mail}
                        error={errorFieldMail}
                        onChange={handleChange('mail')}
                        fullWidth
                        required
                        label="Correo electrónico"
                        helperText={mensaje_error}
                        margin="normal"
                      />
                    </Box>
                    <br></br>
                    <div className={classes.wrapperButtonProgress}>
                      <Button
                        disabled={loading}
                        onClick={emailForm}
                        className={classes.buttonSubmit}
                      >
                        Enviar
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </Animated>
                </center>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
