import React, { useEffect, useContext, useState, Fragment } from 'react'
import { UrlServicesContext } from '../../components/UrlServicesContext'
import { Redirect } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Cancel } from '@material-ui/icons'
import { Button, Dialog, Grid } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import axios from 'axios'
import Highcharts from 'highcharts'
import HighchartsCode from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import Alert from '@material-ui/lab/Alert'

import img from 'assets/img/loading.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'
import { zIndex } from 'material-ui/styles'
import { de } from 'date-fns/locale'

HighchartsMore(Highcharts)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const useStyles = makeStyles(cardBodyStyle)

export default function EnableResultsPsychometricsAdmin(props) {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [rowsFactors, setRowsFactors] = useState([])
  const [rowsTextTitleCL1, setRowsTextTitleCL1] = useState([])
  const [rowsTextTitleCL2, setRowsTextTitleCL2] = useState([])
  const [loader, setLoader] = useState(true)
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [alertNotification, setAlertNotification] = React.useState(false)
  const [namePrueba, setNamePrueba] = React.useState('')
  const [ts, setTs] = React.useState('')

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    const dataValue = {
      testId: props.id.testId,
      jobId: props.id.jobId,
      userId: props.id.userId,
    }

    axios
      .post(`${urlServices}reports/individual/test`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        let outFactor = [],
          textTitle = [],
          textTitleP2 = []
        setRowsFactors(response.data.testFactor)

        if (props.id.testId === 1) {
          //let title, text
          setNamePrueba('Prueba de Personalidad Laboral (FP)')

          const testFactor = response.data.testFactor
          let $t = ''

          //const $D = $rs_resultados['res_D'];
          const $AA = testFactor.find((f) => f.factorId === 170).testsResultsTpm
            .scoreBase
          const $B = testFactor.find((f) => f.factorId === 124).testsResultsTpm
            .scoreBase
          const $c = testFactor.find((f) => f.factorId === 99).testsResultsTpm
            .scoreBase
          const $E = testFactor.find((f) => f.factorId === 186).testsResultsTpm
            .scoreBase
          const $F = testFactor.find((f) => f.factorId === 182).testsResultsTpm
            .scoreBase
          const $GG = testFactor.find((f) => f.factorId === 4).testsResultsTpm
            .scoreBase
          const $H = testFactor.find((f) => f.factorId === 165).testsResultsTpm
            .scoreBase
          const $I = testFactor.find((f) => f.factorId === 19).testsResultsTpm
            .scoreBase
          const $L = testFactor.find((f) => f.factorId === 40).testsResultsTpm
            .scoreBase
          const $M = testFactor.find((f) => f.factorId === 152).testsResultsTpm
            .scoreBase
          const $N = testFactor.find((f) => f.factorId === 102).testsResultsTpm
            .scoreBase
          const $O = testFactor.find((f) => f.factorId === 176).testsResultsTpm
            .scoreBase
          const $Q1 = testFactor.find((f) => f.factorId === 82).testsResultsTpm
            .scoreBase
          const $Q2 = testFactor.find((f) => f.factorId === 53).testsResultsTpm
            .scoreBase
          const $Q3 = testFactor.find((f) => f.factorId === 96).testsResultsTpm
            .scoreBase
          const $Q4 = testFactor.find((f) => f.factorId === 168).testsResultsTpm
            .scoreBase
          const $QS1 = testFactor.find((f) => f.factorId === 86).testsResultsTpm
            .scoreBase
          const $QS2 = testFactor.find((f) => f.factorId === 125)
            .testsResultsTpm.scoreBase
          const $QS3 = testFactor.find((f) => f.factorId === 189)
            .testsResultsTpm.scoreBase
          const $QS4 = testFactor.find((f) => f.factorId === 97).testsResultsTpm
            .scoreBase

          switch ($AA) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>RESERVADO/SOLILOQIO </strong> - Gusta de cosas o palabras (como por ejemplo: lógica, maquinaria) y de trabajar solo, tiene un enfoque intelectual obstina y rehusa transigir. Es discreto, desprendido, crítico, alejado, inflexible; crítico, mantiene sus propias ideas, alejado e indiferente, desconfiado; escéptico, rígido, frío y propenso al mal humor.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>EXPRESIVO/SOCIABILIDAD </strong> - Son por naturaleza "asociativos" dispuestos a formar grupos activos, son más generosos en sus relaciones personales, menos temerosos a la crítica, más aptos para recordar nombres de personas, pero posiblemente menos confiables en trabajos de precisión y son más despreocupados para cumplir sus obligaciones. Es cariñoso, le gusta salir, complaciente, participante, de buen carácter; complaciente, dispuesto a cooperar, atento con la gente; sentimental, inesperado, fortuito, confiado; adaptable, descuidado, "sigue la corriente", afectuoso, ríe con facilidad.</p>'
              break
            default:
              break
          }

          switch ($B) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>INTELIGENCIA BAJA </strong> - Es de baja capacidad mental, incapaz de resolver problemas abstractos, poco organizado; juicio pobre (débil), de moral baja, desertor.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>INTELIGENCIA ALTA </strong> - Es brillante, alta capacidad mental general, perspicaz, aprende rápido, intelectualmente adaptable; inclinación a tener más intereses de orden intelectual, muestra buen juicio, de moral alta, perseverante.</p>'
              break
            default:
              break
          }

          switch ($c) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>EMOCIONALMENTE INESTABLE/INESTABILIDAD EMOCIONAL O DEBILIDAD DEL YO </strong> - Se molesta fácilmente por cosas y personas. Se encuentra inconforme con la situación mundial, con su familia, con las limitaciones de la vida, con su propia salud y siente que no puede enfrentarse a la vida; muestra respuestas neuróticas generalizadas que se traducen en fobias, perturbaciones psicosomáticas, sueño perturbado y una conducta histérica y obsesiva. Inconsistente en actitudes e intereses, tendencia a desistir. Busca pleitos y situaciones problemáticas.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>EMOCIONALMENTE ESTABLE/FUERZA SUPERIOR DEL YO </strong> - Maduro, se enfrenta a la realidad, calmado; constante en sus intereses, no permite que sus necesidades emocionales oscurezcan las realidades de cierta situación; se ajusta a los hechos, sereno, se reprime para evitar dificultades.</p>'
              break
            default:
              break
          }

          switch ($E) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>SUMISIÓN </strong> - Obediente, indulgente, moldeable, dócil, servicial, dependiente; considerado, diplomático, sencillo, convencional, ajustado, fácilmente perturbado por la autoridad, humilde.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>AFIRMATIVO/ASCENDENCIA </strong> - Dominancia. Es afirmativo, agresivo, competitivo, terco, afirmativo, mente independiente; austero, severo, solemne, poco convencional, rebelde, testarudo, exige admiración.</p>'
              break
            default:
              break
          }

          switch ($F) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>SOBRIO/RETRAIMIENTO </strong> -  Sobrio, taciturno, serio, silencioso, introspectivo, lleno de preocupaciones; reflexivo, incomunicativo, apegado a valores internos, lento, cauto.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>DESPREOCUPADO/IMPETUOSIDAD </strong> - Entusiasta, precipitado, despreocupado, conversador, alegre; franco, expresivo, es reflejo del grupo, rápido y alerta.</p>'
              break
            default:
              break
          }

          switch ($GG) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>ACTIVO/SUPEREGO DÉBIL </strong> - Falta de aceptación de las normas morales del grupo, desacata las reglas, activo, desobligado, inconstante; frívolo, autoindulgente, perezoso, desidioso, no confiable, omite sus obligaciones sociales.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>ESCRUPULOSO/SUPEREGO FUERTE </strong> - Carácter, escrupuloso, perseverante, moralista, juicioso, determinado, responsable; emocionalmente disciplinado, consistentemente ordenado, consciente, dominado por el sentido de la obligación, preocupado por estándares morales y reglas.</p>'
              break
            default:
              break
          }

          switch ($H) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>RECATADO/TIMIDEZ </strong> - Recatado, tímido, reprimido, susceptible a las amenazas; vergonzoso, retraído, se retrae en presencia del sexo opuesto.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>AVENTURADO/AUDACIA </strong> - Aventurado, insensible a la amenaza, vigoroso socialmente; gusta de conocer gente, activo, interesado en el sexo opuesto.</p>'
              break
            default:
              break
          }

          switch ($I) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>CALCULADOR/SEVERIDAD </strong> - Rechaza las ilusiones. No sentimental, espera poco. Confiado en sí mismo, toma responsabilidad; severo (al punto del cinismo), pocas respuestas artísticas, pero no falto de gusto. Inafectable por fantasías; actúa por evidencia lógica y práctica, centrado en la realidad, no piensa en discapacidades físicas.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>AFECTUOSO/SENSIBILIDAD EMOCIONAL </strong> - Afectuoso, sensitivo, dependiente, sobreprotegido, inquieto, espera atenciones y afecto. Dependiente, inseguro, busca ayuda y simpatía, amable, gentil, indulgente consigo mismo y hacia otros. Artísticamente exigente, exagerado, amanerado. Imaginativo en su vida interior y en su conversación, actúa por intuición sensitiva. Descuidado, ¨anda en las nubes¨, hipocondríaco, ansioso acerca de sí mismo.</p>'
              break
            default:
              break
          }

          switch ($L) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>CONFIANZA </strong> - Es tolerante, relajado, amigable, y quizá carente de ambición y de empeño. Confiado, acepta condiciones, admite poca importancia personal, es flexible a cambios, sin sospecha de hostilidad, dispuesto a olvidar dificultades, comprensivo y permisivo, tolerante. Elástico para corregir a la gente, conciliador.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>DESCONFIANZA </strong> - Proviene de un hogar paternal que admiró y en el que tenían intereses intelectuales vivos; desprecia la mediocridad, es escrupulosamente correcto en su conducta; le molesta la gente que se da aires de superioridad y se muestra escéptico de supuestos motivos idealistas en otros. Es suspicaz, celoso, dogmático, hace hincapié en sus frustraciones, tiránico, exige que la gente acepte las responsabilidades de sus errores, irritable.</p>'
              break
            default:
              break
          }

          switch ($M) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>PRÁCTICO/OBJETIVIDAD </strong> -  Práctico, tiene preocupaciones reales. Convencional, alerta a las necesidades prácticas. Preocupado por intereses y asuntos inmediatos; prosaico, evita todo lo muy fantástico. Guiado por realidades objetivas. Confiable en su buen juicio. Honrado, preocupado pero constante.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>IMAGINATIVO/SUBJETIVIDAD </strong> - Es imaginativo, bohemio, distraído. No convencional, absorto en ideas. Interesado en arte, teoría y creencias básicas. Cautivado imaginativamente por creaciones internas, caprichoso y fácilmente alejable del buen juicio. Generalmente entusiasta, pero con ocasionales rachas de histéricas de ¨darse por vencido¨. En un grupo tienden a sentirse inaceptados, pero despreocupados. Proponen y hacen sugerencias directivas originales, que no pasan desapercibidas, aun cuando a la larga sus (parcialmente imprácticas) sugerencias resultan rechazadas.</p>'
              break
            default:
              break
          }

          switch ($N) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>INGENUIDAD </strong> - Ingenuo, modesto. Sincero, pero socialmente torpe. Tiene mentalidad vaga e imprudente. Muy sociable, se involucra afectuosa y emocionalmente. Espontáneo y natural, tiene gustos simples; carece de autocomprensión, no hábil en análisis de motivos. Se contenta con su suerte. Tiene confianza ciega en la naturaleza humana.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>ASTUCIA </strong> - Astuto, mundano, inteligente; cultivado, socialmente consciente, tienen mentalidad calculadora y precisa. Emocionalmente despegado (no se involucra emocionalmente) y disciplinado, estéticamente delicado, comprensión respecto de sí mismo. Perspicaz respecto de otros; ambicioso, quizá inseguro. Observador de modales, de obligaciones sociales, inclinado a estudiar los ¨distintos puntos de vista¨.</p>'
              break
            default:
              break
          }

          switch ($O) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>SEGURO DE SÍ/ADECUACIÓN SERENA </strong> - Seguro de sí mismo, apacible, complaciente. Jovial, persistente; oportuno, insensible a la aprobación o desaprobación de la gente. No es cuidadoso. Vigorosamente brusco. Sin temores, dado a las acciones simples.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>APRENSIVO/PROPENSIÓN A LA CULPABILIDAD </strong> - Aprehensivo, autorecriminante. Inseguro, preocupado, atormentado. Ansioso, deprimido, llora fácilmente. Se conmueve fácilmente, vencido por caprichos. Fuerte sentido de la obligación; sensible a la aprobación o desaprobación de la gente. Escrupuloso, delicado. Hipocondríaco e inadecuado; síntomas de fobias. Solitario, pensador.</p>'
              break
            default:
              break
          }

          switch ($Q1) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>CONSERVADURISMO </strong> - Conservador, respeta ideas establecidas. caselerante ante las dificultades tradicionales.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>EXPERIMENTADOR/RADICALISMO </strong> - Le gusta experimentar, liberal, analítico, pensamiento libre.</p>'
              break
            default:
              break
          }

          switch ($Q2) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>DEPENDENCIA GRUPAL </strong> - Socialmente dependiente del grupo. Se ¨adhiere¨ y es un seguidor ejemplar.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>AUTOSUFICIENCIA </strong> - Autosuficiente, rico en recursos. Prefiere sus propias decisiones.</p>'
              break
            default:
              break
          }

          switch ($Q3) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>INCONTROLADO/INDIFERENCIA </strong> - Flojo, sigue sus propios impulsos. Indiferente a las reglas sociales.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>CONTROL </strong> - Controlado, firme fuerza de voluntad. Escrupuloso socialmente. Estricto en su proceder. Se guía por su propia imagen.</p>'
              break
            default:
              break
          }

          switch ($Q4) {
            case 1:
            case 2:
            case 3:
            case 4:
              $t +=
                '<p align="justify"><strong>RELAJADO/TRANQUILIDAD </strong> - tranquilo, aletargado, no frustrado, sereno.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>TENSIÓN </strong> - Tenso, frustrado, impulsivo, sobreexcitado, mal humorado.</p>'
              break
            default:
              break
          }

          $t += '<p align="justify"><strong>FACTORES SECUNDARIOS:</strong></p>'

          if ($QS1 < 5.5) {
            $t +=
              '<p align="justify"><strong>INTROVERSIÓN</strong> - Persona que tiende a ser tímida, autosuficiente, e inhibida en los contactos interpersonales. Este puede ser un rasgo favorable o desfavorable dependiendo de la situación particular en la cual tenga que desenvolverse la persona; por ejemplo, la introversión es un atributo favorable de un trabajador de precisión.</p>'
          }

          if ($QS1 > 5.5) {
            $t +=
              '<p align="justify"><strong>EXTRAVERSIÓN</strong> - Es una persona que sobresale socialmente, desinhibida, buena para establecer y mantener contactos interpersonales. Esto puede resultar muy favorable en situaciones que requieren de este tipo de temperamento, por ejemplo, vendedor, pero no debe considerarse necesariamente como una predicción general favorable, por ejemplo, en logros escolares.</p>'
          }

          if ($QS2 < 5.5) {
            $t +=
              '<p align="justify"><strong>POCA ANSIEDAD</strong> - Persona cuya vida es generalmente satisfactoria, y alguien capaz de lograr aquellas cosas que le parecen importantes. Sin embargo, una puntuación excesivamente baja puede significar falta de motivación para las tareas difíciles.</p>'
          }

          if ($QS2 > 5.5) {
            $t +=
              '<p align="justify"><strong>MUCHA ANSIEDAD</strong> - Persona que tiende a tener un alto grado de ansiedad como se entiende comúnmente. No es necesariamente neurótico, ya que la ansiedad puede ser situacional, pero es probable que tenga algunas inadaptaciones, por ejemplo, está insatisfecho con la forma en que enfrenta las demandas de la vida y de lograr lo que él quiere. La ansiedad muy alta generalmente distrae la ejecución y produce perturbaciones físicas.</p>'
          }

          if ($QS3 < 5.5) {
            $t +=
              '<p align="justify"><strong>SUSCEPTIBILIDAD</strong> - Es una persona que probablemente se vea preocupado por una penetrante emotividad, y puede ser del tipo frustrado y desanimado. Sin embargo él es sensible a las sutilezas de la vida, es probable que sea artístico y gentil. Si tiene problemas se involucra en muchas reflexiones y consideraciones antes de emprender la acción.</p>'
          }

          if ($QS3 > 5.5) {
            $t +=
              '<p align="justify"><strong>TENACIDAD</strong> - Persona probablemente emprendedora, decisiva, una personalidad animada. Sin embargo, es probable que ignore las sutiles relaciones de la vida, y que oriente su conducta hacia lo obvio. Si tiene dificultades, es probable que emprenda rápidas acciones sin suficiente consideración y reflexión.</p>'
          }

          if ($QS4 < 5.5) {
            $t +=
              '<p align="justify"><strong>DEPENDENCIA</strong> - Persona que tiende a ser dependiente del grupo y de personalidad pasiva. Es probable que desee y necesite apoyo de otras personas, y que oriente su conducta hacia las personas que le brindan mucho apoyo.</p>'
          }

          if ($QS4 > 5.5) {
            $t +=
              '<p align="justify"><strong>INDEPENDENCIA</strong> - Persona que tiende a ser agresiva, independiente, emprendedora, incisiva. Buscará aquellas situaciones donde tal conducta sea al menos tolerada y posiblemente recompensada, es probable que exhiba una considerable iniciativa.</p>'
          }

          setTs($t)
        } else if (props.id.testId === 3) {
          setNamePrueba('Test EQ')
          const testFactor = response.data.testFactor

          const factors = [
            testFactor.find((f) => f.factorId === 185),
            testFactor.find((f) => f.factorId === 158),
            testFactor.find((f) => f.factorId === 159),
            testFactor.find((f) => f.factorId === 45),
            testFactor.find((f) => f.factorId === 83),
            testFactor.find((f) => f.factorId === 44),
            testFactor.find((f) => f.factorId === 107),
            testFactor.find((f) => f.factorId === 51),
            testFactor.find((f) => f.factorId === 61),
            testFactor.find((f) => f.factorId === 39),
            testFactor.find((f) => f.factorId === 55),
            testFactor.find((f) => f.factorId === 63),
            testFactor.find((f) => f.factorId === 148),
            testFactor.find((f) => f.factorId === 118),
            testFactor.find((f) => f.factorId === 162),
            testFactor.find((f) => f.factorId === 150),
            testFactor.find((f) => f.factorId === 106),
            testFactor.find((f) => f.factorId === 172),
            testFactor.find((f) => f.factorId === 20),
            testFactor.find((f) => f.factorId === 30),
            testFactor.find((f) => f.factorId === 139),
          ]

          factors.forEach((items) => {
            if (items.testsResultsTpm.score <= 25) {
              outFactor.push({
                id: items.testsResultsTpm.factorId,
                score: items.testsResultsTpm.score,
                name: items.factor.name.toUpperCase(),
                scale: 'RIESGO',
              })
            } else if (
              items.testsResultsTpm.score >= 26 &&
              items.testsResultsTpm.score <= 50
            ) {
              outFactor.push({
                id: items.testsResultsTpm.factorId,
                score: items.testsResultsTpm.score,
                name: items.factor.name.toUpperCase(),
                scale: 'VULNERABLE',
              })
            } else if (
              items.testsResultsTpm.score >= 51 &&
              items.testsResultsTpm.score <= 75
            ) {
              outFactor.push({
                id: items.testsResultsTpm.factorId,
                score: items.testsResultsTpm.score,
                name: items.factor.name.toUpperCase(),
                scale: 'HÁBIL',
              })
            } else if (items.testsResultsTpm.score >= 76) {
              outFactor.push({
                id: items.testsResultsTpm.factorId,
                score: items.testsResultsTpm.score,
                name: items.factor.name.toUpperCase(),
                scale: 'ÓPTIMO',
              })
            }
          })
        } else if (props.id.testId === 6) {
          setNamePrueba('Prueba MMPI (M)')
          const testFactor = response.data.testFactor
          const $sexo = props.id.userGender

          const $L = testFactor.find((f) => f.factorId === 77).testsResultsTpm
            .scoreBase
          const $F = testFactor.find((f) => f.factorId === 75).testsResultsTpm
            .scoreBase
          const $K = testFactor.find((f) => f.factorId === 76).testsResultsTpm
            .scoreBase
          const $Dp = testFactor.find((f) => f.factorId === 69).testsResultsTpm
            .scoreBase
          const $Pa = testFactor.find((f) => f.factorId === 71).testsResultsTpm
            .scoreBase
          const $Ma = testFactor.find((f) => f.factorId === 74).testsResultsTpm
            .scoreBase
          const $Si = testFactor.find((f) => f.factorId === 66).testsResultsTpm
            .scoreBase
          const $Hs = testFactor.find((f) => f.factorId === 34).testsResultsTpm
            .scoreBase
          const $D = testFactor.find((f) => f.factorId === 67).testsResultsTpm
            .scoreBase
          const $Hi = testFactor.find((f) => f.factorId === 68).testsResultsTpm
            .scoreBase
          const $Mf = testFactor.find((f) => f.factorId === 70).testsResultsTpm
            .scoreBase
          const $Pt = testFactor.find((f) => f.factorId === 72).testsResultsTpm
            .scoreBase
          const $Es = testFactor.find((f) => f.factorId === 73).testsResultsTpm
            .scoreBase

          const $L_T = testFactor.find((f) => f.factorId === 77).testsResultsTpm
            .score
          const $F_T = testFactor.find((f) => f.factorId === 75).testsResultsTpm
            .score
          const $K_T = testFactor.find((f) => f.factorId === 76).testsResultsTpm
            .score
          const $Dp_T = testFactor.find((f) => f.factorId === 69)
            .testsResultsTpm.score
          const $Pa_T = testFactor.find((f) => f.factorId === 71)
            .testsResultsTpm.score
          const $Ma_T = testFactor.find((f) => f.factorId === 74)
            .testsResultsTpm.score
          const $Si_T = testFactor.find((f) => f.factorId === 66)
            .testsResultsTpm.score
          const $Hs_T = testFactor.find((f) => f.factorId === 34)
            .testsResultsTpm.score
          const $D_T = testFactor.find((f) => f.factorId === 67).testsResultsTpm
            .score
          const $Hi_T = testFactor.find((f) => f.factorId === 68)
            .testsResultsTpm.score
          const $Mf_T = testFactor.find((f) => f.factorId === 70)
            .testsResultsTpm.score
          const $Pt_T = testFactor.find((f) => f.factorId === 72)
            .testsResultsTpm.score
          const $Es_T = testFactor.find((f) => f.factorId === 73)
            .testsResultsTpm.score

          let $tcom1 = ''
          let $tcom2 = ''

          if ($L_T > 67 && $F_T > 67 && $K_T < 50) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> Al contestar el inventario, la persona trató de mostrarse espontánea para comunicar sus problemas, los cuales vive con mucha intensidad y fuerte tensión interna (+F). Intenta ofrecer una imagen favorable de sí mismo, describiéndose como alguien adaptado a los valores convencionales, quizá en un intento de buscar ayuda para solucionar sus conflictos (+L), a los que se siente poco capaz de enfrentar solo (-K). También es posible que la persona no utilice en forma adecuada su capacidad intelectual al tratar de encontrar soluciones a su problemática.</p>'
          }

          if ($L_T < 50 && $F_T > 67 && $K_T < 50) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> La persona se mostró espontánea al responder el inventario, con posibles tendencias a exagerar su problemática y a vivirla con una importante tensión interna (+F) ante la sensación de estar incapacitado para resolverla por sí mismo (-K), tendiendo a aparecer muy sincero en su autocrítica (-L). Es posible que, en forma exagerada, demande ayuda para enfrentar sus conflictos.</p>'
          }

          if ($L_T > 67 && $F_T > 49 && $F_T < 67 && $K_T < 50) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> Al responder a la prueba el sujeto trató de ofrecer una imagen muy favorable de sí mismo, mostrándose convencional y bien adaptado a los valores sociales (+L). Aunque siente tener problemas, no consigue expresarlos en forma abierta ya que esto distorsionaría su imagen y reduciría la consecuente aceptación social que necesita (+-F). Por otra parte, tampoco se siente capaz de enfrentar sus conflictos por sí mismo y pretende minimizarlos, aparentemente, a través de su búsqueda de ajuste social (-K).</p>'
          }

          if ($L_T < 50 && $F_T > 67 && $K_T > 67) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> Durante la prueba, el sujeto, sintiéndose tenso (+F), trató de mostrase espontáneo al expresar sus problemas y reconocer sus fallas, y a la vez capacitado para enfrentarlos y resolverlos sin necesidad de ayuda externa (K+). En este sentido, su actitud al responder al inventario ha tratado de ser espontánea y autocrítica (L-) y, al mismo tiempo, de cierta reserva y fortaleza, que muy posiblemente darán lugar a la intelectualización de sus problemas.</p>'
          }

          if ($L_T < 50 && $F_T > 49 && $F_T < 67 && $K > 67) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> El sujeto se mostró bastante reservado al contestar el inventario y trató de minimizar sus problemas, ya que se siente bastante capaz de resolverlos por sí mismo (K+).</p>'
            $tcom2 =
              '<p align="justify">Aunque trató de ser autocrítico (F+- y  L-), es posible que su capacidad de intuir sea algo reducida, lo que le impide reconocer con mayor claridad la relación causa-efecto de sus condiciones conflictivas.</p>'
          }

          if ($L_T > 67 && $F_T < 50 && $K_T > 67) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> El sujeto se mostró sumamente reservado al responder el inventario y trató de ofrecer una imagen favorable de sí mismo, intentando aparecer libre de situaciones conflictivas, a las que tiende minimizar. Pretende demostrar que es capaz de enfrentar y resolver sus conflictos, en caso de que los reconozca, sin necesidad de ayuda.</p>'
            $tcom2 =
              '<p align="justify">También es importante señalar que existe la fuerte tendencia a manifestar una adecuada conducta de ajuste social, de tipo convencional, a través de la cual la persona se siente más segura al pensar que tiene la aceptación de su grupo cuando, en apariencia, enfrenta sus problemas.</p>'
          }

          if ($L_T > 69 && $F_T > 69 && $K_T > 69) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> Durante la prueba, el sujeto trató de comunicar sus problemas, al parecer, porque experimenta una fuete tensión al enfrentarlos (F+); sin embargo, se mostró reservado para reconocerlos, quizá por la angustia que esto ocasionaría (K+). Es posible que al mismo tiempo busque la aceptación social, manifestando sentirse adecuado al respecto, para lo que se esfuerza en ofrecer una imagen favorable de sí mismo (L+).</p>'
          }

          if (
            $L_T > 50 &&
            $L_T < 69 &&
            $F_T > 50 &&
            $F_T < 69 &&
            $K_T > 50 &&
            $K_T < 69
          ) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> Durante la prueba, el sujeto trató de ofrecer una imagen favorable de sí mismo (+- L), con una reducida capacidad de autocrítica que le impide un adecuado reconocimiento de sus problemas, distorsionándolos (+- F) y sintiendo que posee buena capacidad para enfrentarlos sin necesidad de ayuda (+- K).</p>'
          }

          if ($L_T < 50 && $F_T < 50 && $K_T < 50) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> El sujeto mostró una actitud de indiferencia y falta de colaboración al responder al inventario. Al parecer, no reconoce tener problemas de importancia (-F), aunque trató de ser algo crítico consigo mismo (-L). Por esta razón, es posible que no sienta la necesidad de mejorar su capacidad para enfrentar las situaciones conflictivas (-K).</p>'
          }

          if ($L_T > 50 && $L_T < 69 && $F_T > 70 && $K_T > 50 && $K_T < 69) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> En esta combinación, los elementos principales de la actitud del sujeto ante el inventario son:</strong> la vivencia exagerada de sus problemas, a los que experimenta con fuerte tensión interna (+F):</strong> a la vez, la sensación de poder resolver la mayoría de ellos (+-K); la tendencia a ser bastante autocrítico, y el intento de ajustarse a lo que socialmente se espera de él (+-L).</p>'
          }

          if ($L_T > 50 && $L_T < 69 && $F_T < 50 && $K_T > 50 && $K_T < 69) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> Dentro de las características que contiene esta combinación sobresalen principalmente:</strong> la falta de reconocimiento de la propia problemática (-F), cierta tendencia a mostrar una imagen favorable de sí mismo (+-L) y cierta capacidad para enfrentar los conflictos en caso de llegar a tenerlos (+-K).</p>'
          }

          if ($L_T > 50 && $L_T < 69 && $F_T > 70 && $K_T < 50) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> En esta combinación los elementos más importantes de la actitud de la persona ante su autodescripción son:</strong> la tendencia a experimentar fuerte tensión interna ante un gran número de conflictos (+F); el enfrentamiento a éstos con sensación de impotencia por la incapacidad para encontrarles soluciones válidas y eficaces (-K), y por último, cierta reducción de la capacidad de autocrítica del sujeto, lo que ocasiona que en su autodescripción se exageran las situaciones conflictivas que enfrenta (+- L + F).</p>'
          }

          if ($L_T < 50 && $F_T > 70 && $K_T > 50 && $K_T < 69) {
            $tcom1 =
              '<p align="justify"><strong>COMBINACION L-F-K:</strong> En relación con esta combinación, se puede decir que el sujeto que la produjo asumió una actitud de espontaneidad al responder al inventario y comunicar sus problemas, viviéndolos con fuerte tensión interna (+F) y tratando de manifestar una severa autocrítica (-L). No obstante, se siente capaz de enfrentar y resolver por sí mismo sus conflictos (+K).</p>'
          }

          const $tcom = $tcom1 + $tcom2
          let $es_l = '<p align="justify"><strong>'

          if ($L < 4) {
            $es_l =
              '<p align="justify"><strong>ESCALA L:</strong> Sujetos perceptivos, socialmente adecuados, confiados en sí mismos, independientes, ocasionalmente sarcásticos y cínicos, comunicativos, con gran fluidez verbal, espontáneos, de ideas ingeniosas y con capacidad de líderes efectivos.</p>'
          }

          if ($L > 3 && $L < 6) {
            $es_l =
              '<p align="justify"><strong>ESCALA L:</strong> (sin comentarios)'
          }

          if ($L > 5 && $L < 9) {
            $es_l =
              '<p align="justify"><strong>ESCALA L:</strong> Tiende a dar respuestas socialmente aceptables en relación al control y valores morales. Es inteligente, limitado, pasivo, inseguro y rígido.</p>'
          }

          if ($L > 8 && $L < 12) {
            $es_l =
              '<p align="justify"><strong>ESCALA L:</strong> Conformista, trata de presentar un cuadro favorable en cuanto a conformidad, control y valores morales.</p>'
          }

          if ($L > 11) {
            $es_l =
              '<p align="justify"><strong>ESCALA L:</strong> Tendencia a cubrir sus faltas personales. Es muy escrupuloso o muy falso en su descripción, de alto estándar moral, de control y de conformidad social.</p>'
          }

          let $es_f = '<p align="justify"><strong>'

          if ($F < 4) {
            $es_f =
              '<p align="justify"><strong>ESCALA F:</strong> Personas normales, tienen una adecuada liberación de las situaciones frustrantes; pero todo puede tratarse de personas que se esfuerzan por cubrir cualquier tipo de perturbación emocional que en ese momento experimente.</p>'
          }

          if ($F > 3 && $F < 11) {
            $es_f =
              '<p align="justify"><strong>ESCALA F:</strong> Persona con adecuada adaptación psicológica aun cuando puede tener algunas reacciones emotivas.</p>'
          }

          if ($F > 10 && $F < 13) {
            $es_f =
              '<p align="justify"><strong>ESCALA F:</strong> (sin comentarios)'
          }

          if ($F > 12 && $F < 22) {
            $es_f =
              '<p align="justify"><strong>ESCALA F:</strong> Psicosis, interpretar este perfil con cautela, admite gran número de experiencias, sentimientos o síntomas inusuales, puede tener problemas de tipo psiquiátrico.</p>'
          }

          if ($F > 21) {
            $es_f =
              '<p align="justify"><strong>ESCALA F:</strong> Distorsionado, la validez del perfil es muy dudosa, puede haber dificultad en la comprensión o disturbio psiquiátrico grave. Arrogante, presumidas, oportunistas, inquietas, rebeldes e inconformes.</p>'
          }

          let $es_k = '<p align="justify"><strong>'

          if ($K < 10) {
            $es_k =
              '<p align="justify"><strong>ESCALA K:</strong> Autoconcepto pobre, posiblemente le gustaría discutir sus problemas. Indefensos, desconfiados, inhibidos, superficiales, sombríos.</p>'
          }

          if ($K > 9 && $K < 17) {
            $es_k =
              '<p align="justify"><strong>ESCALA K:</strong> (sin comentarios)'
          }

          if ($K > 16 && $K < 22) {
            $es_k =
              '<p align="justify"><strong>ESCALA K:</strong> Personas de yo fuerte, emprendedoras, ingeniosas, dinámicas, enérgicas, racionales con mucha iniciativa en sus relaciones sociales, son individuos reservados adecuados y tranquilos.</p>'
          }

          if ($K > 21 && $K < 26) {
            $es_k =
              '<p align="justify"><strong>ESCALA K:</strong> Tiende a suavizar faltas sobre sí mismo, la familia, o las circunstancias. Minimizan sus problemas emocionales.</p>'
          }

          if ($K > 25) {
            $es_k =
              '<p align="justify"><strong>ESCALA K:</strong> Muy defensivo, da poca importancia a problemas en su ajuste social y emocional.</p>'
          }

          let $es_Hs = '<p align="justify"><strong>'
          let $tem = '<p align="justify"><strong>'

          if (($Hs < 12 && $sexo == 1) || ($Hs < 13 && $sexo == 2)) {
            $es_Hs =
              '<p align="justify"><strong>ESCALA 1 (Hs):</strong> Pocas quejas físicas, no tienen preocupaciones por su cuerpo.</p>'
          }

          if (
            ($Hs > 11 && $Hs < 15 && $sexo == 1) ||
            ($Hs > 12 && $Hs < 18 && $sexo == 2)
          ) {
            $es_Hs =
              '<p align="justify"><strong>ESCALA 1 (Hs):</strong> Preocupación hipocondríaca de tipo clínico, no psiquiátrico.</p>'
          }

          if (
            ($Hs > 14 && $Hs < 20 && $sexo == 1) ||
            ($Hs > 17 && $Hs < 24 && $sexo == 2)
          ) {
            $es_Hs =
              '<p align="justify"><strong>ESCALA 1 (Hs):</strong> Más alto que el promedio en su preocupación hipocondríaca. A medida que se eleva, aumenta la posibilidad de que el individuo utilice molestias físicas (cuyo origen, o el de la tendencia a magnificarlos, es psicológico) como mecanismo para evitar el enfrentamiento a obstáculos y problemas dentro del trabajo, así como a experiencias de mucha presión. Es de esperarse que las personas que elevan sus respuestas en esta escala, no se presenten a trabajar cuando aparece cualquier dificultad o presión en el trabajo o se declare físicamente incapacitadas (por molestias leves) buscando que se les disculpe por no participar ante el problema, o por hacerlo con menor responsabilidad. Este tipo de mecanismos releva a la persona de sentimientos de culpa y hace que se sientan suficien$temente justificados ante su falta de capacidad para resolver el problema en el trabajo.</p>'
          }

          if (
            ($Hs > 19 && $Hs < 24 && $sexo == 1) ||
            ($Hs > 23 && $Hs < 29 && $sexo == 2)
          ) {
            $es_Hs =
              '<p align="justify"><strong>ESCALA 1 (Hs):</strong> Nivel considerable de preocupación hipocondríaca. Mas o menos inmaduro y con poca responsabilidad, interfiere con su rendimiento. Carácter agrio, apáticas, falta para dirigir sus impulsos, con facilidad de irritar a los demás, persona agresiva, insatisfecha con pocos intereses y pocos logros. A medida que se eleva, aumenta la posibilidad de que el individuo utilice molestias físicas (cuyo origen, o el de la tendencia a magnificarlos, es psicológico) como mecanismo para evitar el enfrentamiento a obstáculos y problemas dentro del trabajo, así como a experiencias de mucha presión.</p>'
            $tem =
              '<p align="justify"><strong>Es de esperarse que las personas que elevan sus respuestas en esta escala, no se presenten a trabajar cuando aparece cualquier dificultad o presión en el trabajo o se declare físicamente incapacitadas (por molestias leves) buscando que se les disculpe por no participar ante el problema, o por hacerlo con menor responsabilidad. Este tipo de mecanismos releva a la persona de sentimientos de culpa y hace que se sientan suficien$temente justificados ante su falta de capacidad para resolver el problema en el trabajo.</p>'
            $es_Hs = $es_Hs + $tem
          }

          if (($Hs > 23 && $sexo == 1) || ($Hs > 28 && $sexo == 2)) {
            $es_Hs =
              '<p align="justify"><strong>ESCALA 1 (Hs):</strong> Gran número de quejas físicas crónicas, muchos dolores físicos de tipo funcional, fatiga y debilidad. Carácter agrio, apáticas, falta para dirigir sus impulsos, con facilidad de irritar a los demás, persona agresiva, insatisfecha con pocos intereses y pocos logros. A medida que se eleva, aumenta la posibilidad de que el individuo utilice molestias físicas (cuyo origen, o el de la tendencia a magnificarlos, es psicológico) como mecanismo para evitar el enfrentamiento a obstáculos y problemas dentro del trabajo, así como a experiencias de mucha presión.</p>'
            $tem =
              '<p align="justify"><strong>Es de esperarse que las personas que elevan sus respuestas en esta escala, no se presenten a trabajar cuando aparece cualquier dificultad o presión en el trabajo o se declare físicamente incapacitadas (por molestias leves) buscando que se les disculpe por no participar ante el problema, o por hacerlo con menor responsabilidad. Este tipo de mecanismos releva a la persona de sentimientos de culpa y hace que se sientan suficien$temente justificados ante su falta de capacidad para resolver el problema en el trabajo.</p>'
            $es_Hs = $es_Hs + $tem
          }

          let $es_D = '<p align="justify"><strong>'

          if (($D < 16 && $sexo == 1) || ($D < 18 && $sexo == 2)) {
            $es_D =
              '<p align="justify"><strong>ESCALA 2 (D):</strong> Simpático y optimista. Por otra parte, el puntaje muy bajo en esta escala puede indicar menos capacidad de autocrítica y mayor posibilidad de actuar en forma temeraria, sin reflexionar adecuadamente acerca de las soluciones que se ofrecen ante los problemas, por lo que es difícil que se adviertan los errores para intentar corregirlos de manera oportuna. En estos casos, la persona puede trabajar en condiciones de mucha presión, pero posiblemente disminuya la calidad de su trabajo y de sus decisiones, dando la impresión de una gran eficiencia más por la cantidad que por la calidad de los que hace.</p>'
          }

          if (
            ($D > 15 && $D < 21 && $sexo == 1) ||
            ($D > 17 && $D < 29 && $sexo == 2)
          ) {
            $es_D =
              '<p align="justify"><strong>ESCALA 2 (D):</strong> Mezcla de optimismo y pesimismo. Por otra parte, un puntaje bajo en esta escala puede indicar menos capacidad de autocrítica y mayor posibilidad de actuar en forma temeraria, sin reflexionar adecuadamente acerca de las soluciones que se ofrecen ante los problemas, por lo que es difícil que se adviertan los errores para intentar corregirlos de manera oportuna.</p>'
          }

          if (
            ($D > 20 && $D < 25 && $sexo == 1) ||
            ($D > 23 && $D < 30 && $sexo == 2)
          ) {
            $es_D =
              '<p align="justify"><strong>ESCALA 2 (D):</strong> Medianamente deprimido o pesimista. Esta persona tiene una adecuada capacidad de autocrítica. Es posible que la persona sea capaz de revisar y valorar por sí misma sus experiencias, rectificando oportunamente sus errores y beneficiándose del aprendizaje de éstas.</p>'
          }

          if (
            ($D > 24 && $D < 32 && $sexo == 1) ||
            ($D > 29 && $D < 38 && $sexo == 2)
          ) {
            $es_D =
              '<p align="justify"><strong>ESCALA 2 (D):</strong> Moderado; deprimido, pesimista, preocupado, sin confianza en sí mismo. Persona tímida, inhibida, sumisa, persona oscura que no se da a conocer. Conforme se incrementa el puntaje de esta escala, la presencia de ciertas características, como el pesimismo, convierten la autocrítica en autocensura y limitan entonces la posibilidad de actuar por el temor a un fracaso, lo que hará más intensos los sentimientos de desvalorización de sí mismo.</p>'
          }

          if (($D > 31 && $sexo == 1) || ($D > 37 && $sexo == 2)) {
            $es_D =
              '<p align="justify"><strong>ESCALA 2 (D):</strong> Seriamente deprimido, indeciso o pesimista. Esta escala indica el riesgo de que el individuo presente deficiencias en el trabajo. Al revisar las características de esta escala, es conveniente observar los puntajes de las demás escalas clínicas, ya que es frecuente que la condición de desempleo se relacione con el fracaso personal, produciendo una forma de depresión reactiva que pasará cuando el sujeto nuevamente se sienta productivo y se incorpore al trabajo.</p>'
          }

          let $es_Hi = '<p align="justify"><strong>'

          if (($Hi < 18 && $sexo == 1) || ($Hi < 22 && $sexo == 2)) {
            $es_Hi =
              '<p align="justify"><strong>ESCALA 3 (Hi):</strong> Pensamiento rígido. Un puntaje muy bajo es signo de la indiferencia del sujeto ante la aprobación y el afecto de otros.</p>'
          }

          if (
            ($Hi > 17 && $Hi < 27 && $sexo == 1) ||
            ($Hi > 21 && $Hi < 29 && $sexo == 2)
          ) {
            $es_Hi =
              '<p align="justify"><strong>ESCALA 3 (Hi):</strong> Este puntaje sugieren la búsqueda de aceptación social a través de actitudes de amabilidad, colaboración e interés en los demás. En este nivel, la necesidad de aceptación , que produce la elevación en los puntajes de esta escala, puede ser una forma de integrarse al trabajo de otros, colaborando con ellos o tratando de manera amable a las personas que requieren de los servicios del candidato a ocupar el puesto.</p>'
          }

          if (
            ($Hi > 26 && $Hi < 32 && $sexo == 1) ||
            ($Hi > 28 && $Hi < 34 && $sexo == 2)
          ) {
            $es_Hi =
              '<p align="justify"><strong>ESCALA 3 ($Hi):</strong> Probablemente algo inmaduro, egocéntrico y exigente.</p>'
          }

          if (
            ($Hi > 31 && $Hi < 37 && $sexo == 1) ||
            ($Hi > 33 && $Hi < 40 && $sexo == 2)
          ) {
            $es_Hi =
              '<p align="justify"><strong>ESCALA 3 (Hi):</strong> Inmaduro, egocéntrico, sugestionable, con tendencia a presentar dolencias de tipo funcional. La elevación por arriba de T70 hará pensar en una mayor necesidad de reconocimiento, aprobación y aceptación, para lo que se tenderá a manifestar comportamientos pueriles de desvalidamiento o de exigencia. En estos casos, el hecho de que el sujeto sienta que no se le reconoce lo suficiente en el trabajo, es decir, que faltan el aprecio y la aprobación sobre todo de las figuras que representan autoridad, plantea la posibilidad de que se reduzca su eficiencia.</p>'
          }

          if (($Hi > 36 && $sexo == 1) || ($Hi > 39 && $sexo == 2)) {
            $es_Hi =
              '<p align="justify"><strong>ESCALA 3 (Hi):</strong> Muy inmaduro, exigente, egocéntrico; nociones fijas respecto a las bases orgánicas, altamente inestable y neurótico, causará dificultades. Tiende a los accidentes. La elevación por arriba de T70 hará pensar en una mayor necesidad de reconocimiento, aprobación y aceptación, para lo que se tenderá a manifestar comportamientos pueriles de desvalidamiento o de exigencia. En estos casos, el hecho de que el sujeto sienta que no se le reconoce lo suficiente en el trabajo, es decir, que faltan el aprecio y la aprobación sobre todo de las figuras que representan autoridad, plantea la posibilidad de que se reduzca su eficiencia.</p>'
          }

          let $es_Dp = '<p align="justify"><strong>'

          if ($Dp < 18) {
            $es_Dp =
              '<p align="justify"><strong>ESCALA 4 (Dp):</strong> Moral, convencional y conformista. Persona sumisa, complaciente, acepta la autoridad, y en este sentido, hacen todas las cosas que se les prescriben, el sujeto aparentemente acierta con cierta pasividad, las órdenes que se le dan, aunque con algo de indiferencia para apegarse a ellas. Persona de buen temperamento, persistente, con metas adecuadas, agradables, tímidas, sinceras y confiables. La reducción en el puntaje de esta escala (por debajo de T45) puede indicar menor capacidad del individuo para alcanzar algo, además de una actitud poco comprometida (como cuando el puntaje es muy elevado) con las consecuentes deficiencias en el trabajo.</p>'
          }

          if ($Dp > 17 && $Dp < 23) {
            $es_Dp =
              '<p align="justify"><strong>ESCALA 4 (Dp):</strong> Se adapta razonablemente a normas y hábitos sociales. Cuando esta escala es la más elevada del perfil, con un puntaje alrededor de T65, quizá indique una forma de conseguir las metas, con una adecuada organización dentro de la realidad, apareciendo la persona dispuesta a respetar las necesidades y metas de los demás.</p>'
          }

          if ($Dp > 22 && $Dp < 27) {
            $es_Dp =
              '<p align="justify"><strong>ESCALA 4 (Dp):</strong> Independiente o ligeramente anticonformista. Esto indica el máximo permitido para garantizar estabilidad laboral. La elevación en el puntaje de la escala 4 indica buena capacidad para trabajar bajo presión, ya que, en estos casos, la presión afecta poco y el sujeto soporta bien las frustraciones sin sentirse culpable o muy angustiado, salvo cuando el fracaso compromete alguna meta personal y, en ocasiones, muy egocéntricamente fijada, apareciendo entonces la tendencia a abandonar los esfuerzos por alcanzar dicha meta y a establecer otra en la que no se adviertan obstáculos. Este tipo de personas se muestran muy eficientes en el trabajo, pero organizadas más por sus propias metas, que por las del grupo o las de la empresa.</p>'
            $tem =
              '<p align="justify">El compromiso con determinada tarea se establece mientras coincida con metas muy personales. En lo que respecta a las relaciones de trabajo, a medida que se eleva el puntaje de esta escala, se esperan algunas dificultades para que la persona acepte órdenes y sugerencias, con la tendencia a imponer las propias ideas y al trabajo individualista, negándose a trabajar en equipo.</p>'
            $es_Dp = $es_Dp + $tem
          }

          if ($Dp > 26 && $Dp < 32) {
            $es_Dp =
              '<p align="justify"><strong>ESCALA 4 (Dp):</strong> Algo rebelde o anticonformista. Evita ligar emociones estrechas. Insatisfecho con la familia o sociedad. Ligeramente inestable y presentando algunas veces la tendencia a ansiedad irracional. Poco confiable en cuanto a su lealtad y honradez. Los puntajes exagerados reflejan el escaso control de la persona al encontrar obstáculos que impiden el alcance de lo que se pretende, llegando a actuar en forma impulsiva o sin suficiente apego a las normas y reglamentos sociales. Es por esto que cuando se observa un puntaje superior a T70, acompañado por la elevación de la escala 8 o de la escala 6, es posible que la permanencia en estos puestos sea breve. En lo que respecta a las relaciones de trabajo, a medida que se eleva el puntaje de esta escala, se esperan algunas dificultades para que la persona acepte órdenes y sugerencias, con la tendencia a imponer las propias ideas y al trabajo individualista, negándose a trabajar en equipo.</p>'
          }

          if ($Dp > 31) {
            $es_Dp =
              '<p align="justify"><strong>ESCALA 4 (Dp):</strong> Muy anticonformista y rebelde, poco confiable en trabajos. Escasos sentimientos de lealtad, pobreza en la integración familiar y/o social.  Puede ser manipulador y simpático y complaciente en la primera impresión. Los puntajes exagerados reflejan el escaso control de la persona al encontrar obstáculos que impiden el alcance de lo que se pretende, llegando a actuar en forma impulsiva o sin suficiente apego a las normas y reglamentos sociales. Es por esto que cuando se observa un puntaje superior a T70, acompañado por la elevación de la escala 8 o de la escala 6, es posible que la permanencia en estos puestos sea breve. En lo que respecta a las relaciones de trabajo, a medida que se eleva el puntaje de esta escala, se esperan algunas dificultades para que la persona acepte órdenes y sugerencias, con la tendencia a imponer las propias ideas y al trabajo individualista, negándose a trabajar en equipo.</p>'
          }

          let $es_Mf = ''

          if ($sexo == 1) {
            $es_Mf = '<p align="justify"><strong>'

            if ($Mf < 17) {
              $es_Mf =
                '<p align="justify"><strong>ESCALA 5 (Mf-Hombre):</strong> Gran énfasis en intereses masculinos. Ej:</strong> mecánica, deporte. Cuando el puntaje T de la escala 5 es muy reducido, refleja la necesidad de aparecer firme y decidido, con una gran fuerza para enfrentar los problemas, aunque a veces, la forma que se elija para hacerlo, no sea la más convencional. En ocasiones, estas personas dan la apariencia de ser rudos, y utilizan más su fuerza física que otros recursos en la resolución de los problemas.</p>'
            }

            if ($Mf > 16 && $Mf < 24) {
              $es_Mf =
                '<p align="justify"><strong>ESCALA 5 (Mf-Hombre):</strong> (sin comentarios)'
            }

            if ($Mf > 23 && $Mf < 29) {
              $es_Mf =
                '<p align="justify"><strong>ESCALA 5 (Mf-Hombre):</strong> Tendencia hacia intereses estéticos. En la población masculina, cuando la escala se observa elevada, el examinado tiende a mostrarse más tolerante y dispuesto a ceder, evitando conflictos con otros y tratando de resolver los problemas en forma armoniosa, a través de la caballerosidad y el buen trato a otros. Con frecuencia en algunos contextos laborales, ésta se considera una actitud pusilánime y de poca energía para solucionar problemas. Es posible que los varones de la escala 5 elevada, valoren mucho, además de su vida laboral y su participación en ella, su vida familiar y cultural.</p>'
            }

            if ($Mf > 28 && $Mf < 36) {
              $es_Mf =
                '<p align="justify"><strong>ESCALA 5 (Mf-Hombre):</strong> Sensible e idealista, con muchos intereses de tipo estético cultural o artístico. En la población masculina, cuando la escala se observa elevada , el examinado tiende a mostrarse más tolerante y dispuesto a ceder, evitando conflictos con otros y tratando de resolver los problemas en forma armoniosa, a través de la caballerosidad y el buen trato a otros. Con frecuencia en algunos contextos laborales, ésta se considera una actitud pusilánime y de poca energía para solucionar problemas. Es posible que los varones de la escala 5 elevada, valoren mucho, además de su vida laboral y su participación en ella, su vida familiar y cultural.</p>'
            }

            if ($Mf > 35) {
              $es_Mf =
                '<p align="justify"><strong>ESCALA 5 (Mf-Hombre):</strong> Inquieto o afeminado. Probables intereses artísticos. En la población masculina, cuando la escala se observa elevada , el examinado tiende a mostrarse más tolerante y dispuesto a ceder, evitando conflictos con otros y tratando de resolver los problemas en forma armoniosa, a través de la caballerosidad y el buen trato a otros. Con frecuencia en algunos contextos laborales, ésta se considera una actitud pusilánime y de poca energía para solucionar problemas. Es posible que los varones de la escala 5 elevada, valoren mucho, además de su vida laboral y su participación en ella, su vida familiar y cultural.</p>'
            }
          }

          if ($sexo == 2) {
            $es_Mf = '<p align="justify"><strong>'

            if ($Mf < 23) {
              $es_Mf =
                '<p align="justify"><strong>ESCALA 5 (Mf -Mujer):</strong> Intereses marcadamente masculinos en el trabajo o diversiones. La elevación de la escala 5 indica la sensación de ser decidida, firme y activa en el trabajo (se eleva esta escala por la percepción de romper el patrón social de lo femenino). En general, las mujeres que obtienen puntajes elevados afirman que su trabajo es muy importante y se esfuerzan por cumplir con todas las tareas que se les asignan, jerarquizando su trabajo o su profesión en un lugar más elevado que el desempeño de otras actividades o experiencias de la vida.</p>'
            }

            if ($Mf > 22 && $Mf < 29) {
              $es_Mf =
                '<p align="justify"><strong>ESCALA 5 (Mf -Mujer):</strong> Tendencia a patrones de tipo masculino en trabajo o deportes. La elevación de la escala 5 indica la sensación de ser decidida, firme y activa en el trabajo (se eleva esta escala por la percepción de romper el patrón social de lo femenino). En general, las mujeres que obtienen puntajes elevados afirman que su trabajo es muy importante y se esfuerzan por cumplir con todas las tareas que se les asignan, jerarquizando su trabajo o su profesión en un lugar más elevado que el desempeño de otras actividades o experiencias de la vida.</p>'
            }

            if ($Mf > 28 && $Mf < 34) {
              $es_Mf =
                '<p align="justify"><strong>ESCALA 5 (Mf -Mujer):</strong> Probablemente se interese en actividades fuera del hogar, ej:</strong> trabajo o deportes. La elevación de la escala 5 indica la sensación de ser decidida, firme y activa en el trabajo (se eleva esta escala por la percepción de romper el patrón social de lo femenino). En general, las mujeres que obtienen puntajes elevados afirman que su trabajo es muy importante y se esfuerzan por cumplir con todas las tareas que se les asignan, jerarquizando su trabajo o su profesión en un lugar más elevado que el desempeño de otras actividades o experiencias de la vida.</p>'
            }

            if ($Mf > 33 && $Mf < 41) {
              $es_Mf =
                '<p align="justify"><strong>ESCALA 5 (Mf -Mujer):</strong> (sin comentarios)'
            }

            if ($Mf > 40) {
              $es_Mf =
                '<p align="justify"><strong>ESCALA 5 (Mf -Mujer):</strong> Marcados intereses femeninos. Cuando los puntajes son reducidos, la persona se considera frágil y dispuesta a seguir las indicaciones de otros, como una forma de sentirse protegida y menos forzada a aceptar muchas responsabilidades. Posiblemente se valoren más las actividades y funciones de la vida distintas al trabajo, que el trabajo mismo.</p>'
            }
          }

          let $es_Pa = '<p align="justify"><strong>'

          if ($Pa < 7) {
            $es_Pa =
              '<p align="justify"><strong>ESCALA 6 (Pa):</strong> Persona equilibrada, madura en su manera de enfrentar la vida, razonables, confiables e íntegras. La reducción en el puntaje de la escala 6, indica que el sujeto mantiene un fuerte control sobre el enojo y la agresividad, intentando ofrecer la apariencia de no molestarse por nada, aun frente a situaciones verdaderamente irritantes. En estos casos hay reacciones de ira ante estímulos poco importantes, en las que parece descargarse toda la energía destructiva acumulada.</p>'
          }

          if ($Pa > 6 && $Pa < 11) {
            $es_Pa =
              '<p align="justify"><strong>ESCALA 6 (Pa):</strong> Respeta las opiniones de los demás sin una sensibilidad marcada. Su sensibilidad no es indebida. Cuando el puntaje está arriba de T65, ya tiende a interpretarse que la persona tiende a ser cautelosa y suspicaz, especialmente al establecer nuevos contactos interpersonales, donde se muestra algo hostil y poco espontánea.</p>'
          }

          if ($Pa > 10 && $Pa < 15) {
            $es_Pa =
              '<p align="justify"><strong>ESCALA 6 (Pa):</strong> Sensible entusiasmadamente hacia las opiniones de los demás. Tendencia hacia la animosidad. Probablemente participante. Para el desempeño de actividades en que se requiera de un estado de alerta y de observación constantes, con muy buena anticipación, quizá resulte conveniente que la escala 6 se encuentre algo elevada, aunque sin rebasar T70.</p>'
          }

          if ($Pa > 14 && $Pa < 20) {
            $es_Pa =
              '<p align="justify"><strong>ESCALA 6 (Pa):</strong> Quisquilloso, suspicaz, hipersensible. Con tendencia a culpar a otros de sus dificultades. Probablemente algo agresivo. A medida que estos puntajes se elevan, es posible que surjan dificultades para las relaciones interpersonales, basadas en interpretaciones adecuadas, lo que puede provocar tensiones dentro del grupo laboral y reducción de la eficiencia.</p>'
          }

          if ($Pa > 19) {
            $es_Pa =
              '<p align="justify"><strong>ESCALA 6 (Pa):</strong> Resentido, receloso con los demás. Tenso, ansioso y prejuicioso, hipersensible, suspicaz, que tiende a cuestionar todos los motivos psicológicos, personas que usan mucho la racionalización. A medida que estos puntajes se elevan, es posible que surjan dificultades para las relaciones interpersonales, basadas en interpretaciones adecuadas, lo que puede provocar tensiones dentro del grupo laboral y reducción de la eficiencia.</p>'
          }

          let $es_Pt = '<p align="justify"><strong>'

          if (($Pt < 21 && $sexo == 1) || ($Pt < 23 && $sexo == 2)) {
            $es_Pt =
              '<p align="justify"><strong>ESCALA 7 (Pt):</strong> Relajamiento en cuanto a las posibilidades. No tiende a preocuparse. Los puntajes reducidos en esta escala reflejan la tendencia del sujeto a no sentirse angustiado al enfrentarse a problemas en el trabajo, y a mostrar espontaneidad y capacidad para tomar decisiones en situaciones de presión.</p>'
          }

          if (
            ($Pt > 20 && $Pt < 29 && $sexo == 1) ||
            ($Pt > 22 && $Pt < 35 && $sexo == 2)
          ) {
            $es_Pt =
              '<p align="justify"><strong>ESCALA 7 (Pt):</strong> Tiene suficiente capacidad para la organización de su trabajo y de su vida personal. Probablemente laborioso. Para el desempeño de actividades que requieren de un trabajo cuidadoso y de una actitud disciplinada y estrictamente apegada al deber, lo más conveniente es que el puntaje en esta escala se encuentre entre T55 y T60.</p>'
          }

          if (
            ($Pt > 28 && $Pt < 36 && $sexo == 1) ||
            ($Pt > 34 && $Pt < 41 && $sexo == 2)
          ) {
            $es_Pt =
              '<p align="justify"><strong>ESCALA 7 (Pt):</strong> Consciente, ordenado, autocrítica probablemente detallista, minucioso, perfeccionista. Tiende a ser indeciso. Las personas que obtienen un puntaje elevado en esta escala, pero no mayor de T70, y que ocasionalmente se someten a un exceso de tensión, pueden padecer trastornos físicos relacionados con la expresión somática de la angustia. Estas personas se desempeñan mejor en actividades que requieran de meticulosidad, cuidado y perfeccionismo, por lo que buscan disponer de tiempo y de condiciones suficientes para no sentirse muy presionadas. En general, son ordenadas y se apegan a rutinas estables de trabajo que difícilmente aceptan cambiar.</p>'
          }

          if (
            ($Pt > 35 && $Pt < 42 && $sexo == 1) ||
            ($Pt > 40 && $Pt < 48 && $sexo == 2)
          ) {
            $es_Pt =
              '<p align="justify"><strong>ESCALA 7 (Pt):</strong> Rígido, meticuloso, inquieto y aprensivo. Insatisfecho con sus relaciones sociales. Probablemente muy religioso o moralista. La elevación moderada en la escala 7 indica características relacionadas con una actitud de excesivo deber, responsabilidad y cumplimiento con las tareas que se emprenden que, aun cuando sean pocas, se llevan a acabo cuidadosamente.</p>'
          }

          if (($Pt > 41 && $sexo == 1) || ($Pt > 47 && $sexo == 2)) {
            $es_Pt =
              '<p align="justify"><strong>ESCALA 7 (Pt):</strong> Agitado, medroso. Probablemente obsesivo, compulsivo y con fobias. Sentimientos de culpas y preocupación religiosa. Persona insegura, con dificultad de decisión. Desde el punto de vista clínico, la elevación de los puntajes en esta escala puede entenderse como un incremento de la angustia. Desde el enfoque de la selección de personal, los puntajes por arriba de T70, también indican un exceso de angustia, el cual en especial, reduce la posibilidad de tomar decisiones rápidas en situaciones de mucha presión.</p>'
          }

          let $es_Es = '<p align="justify"><strong>'

          if (($Es < 19 && $sexo == 1) || ($Es < 20 && $sexo == 2)) {
            $es_Es =
              '<p align="justify"><strong>ESCALA 8 (Es):</strong> Intereses marcados por las personas y cosas prácticas. Probablemente realista. Personas que buscan poder y reconocimiento por medio de la productividad, sin embargo, tienen la tendencia a la sumisión y al reconocimiento casi incondicional de la figura autoritaria, convencional, responsable, conservadora, amistosa y honesta. Cuando los puntajes son cercanos a T50, o menores, indican que la persona posee un notable sentido de la realidad, que le permite manejar datos y problemas de trabajo en forma clara y precisa. Este tipo de personas se describen capaces de relacionarse bien con los demás, sin permitir que sus problemas emocionales interfieran en sus decisiones y eficiencia en el trabajo.</p>'
          }

          if (
            ($Es > 18 && $Es < 27 && $sexo == 1) ||
            ($Es > 19 && $Es < 29 && $sexo == 2)
          ) {
            $es_Es =
              '<p align="justify"><strong>ESCALA 8 (Es):</strong> Combinación de intereses práctico - teóricos. Cuando los puntajes son cercanos a T50, o menores, indican que la persona posee un notable sentido de la realidad, que le permite manejar datos y problemas de trabajo en forma clara y precisa. Este tipo de personas se describen capaces de relacionarse bien con los demás, sin permitir que sus problemas emocionales interfieran en sus decisiones y eficiencia en el trabajo.</p>'
          }

          if (
            ($Es > 26 && $Es < 34 && $sexo == 1) ||
            ($Es > 27 && $Es < 40 && $sexo == 2)
          ) {
            $es_Es =
              '<p align="justify"><strong>ESCALA 8 (Es):</strong> Viendo a intereses abstractos tales como la ciencia, filosofía y religión. La elevación de los puntajes se puede interpretar como la utilización de elementos fantaseados en el manejo de situaciones laborales. Esta característica sería conveniente para el desempeño de actividades relacionadas con la creatividad, en donde la fantasía es importante para la productividad del sujeto. Aunada a esta característica, es necesario recordar la tendencia al trabajo aislado.</p>'
          }

          if (
            ($Es > 33 && $Es < 41 && $sexo == 1) ||
            ($Es > 36 && $Es < 45 && $sexo == 2)
          ) {
            $es_Es =
              '<p align="justify"><strong>ESCALA 8 (Es):</strong> Algo excéntrico, retraído, con muchos conflictos interiores. Personas solitarias y aisladas. La elevación de estos puntajes se puede interpretar como la utilización de elementos fantaseados en el manejo de situaciones laborales. Esta característica sería conveniente para el desempeño de actividades relacionadas con la creatividad, en donde la fantasía es importante para la productividad del sujeto. Aunada a esta característica, es necesario recordar la tendencia al trabajo aislado.</p>'
          }

          if (($Es > 40 && $sexo == 1) || ($Es > 44 && $sexo == 2)) {
            $es_Es =
              '<p align="justify"><strong>ESCALA 8 (Es):</strong> Ideas irracionales, pensamiento estricto. Presenta inconformidad con todo lo establecido por la sociedad. Pensamientos confusos. Pudiera tener actitudes extrañas y falsas creencias. Considerar evaluación psiquiátrica. La exageración de los puntajes es signo de algún trastorno importante en la personalidad, que puede interferir la eficiencia en el trabajo, con la posibilidad de que la tendencia sea tan fuerte que dificulte la integración del sujeto a cualquier grupo de trabajo.</p>'
          }

          let $es_Ma = '<p align="justify"><strong>'

          if ($Ma < 16) {
            $es_Ma =
              '<p align="justify"><strong>ESCALA 9 (Ma):</strong> Bajo nivel de energía y actividad. Difícil de motivar, apático. Personas modestas, convencionales, aisladas y humildes. Un puntaje bajo en la escala 9 quizá refleje la disminución en el interés para fijarse metas, acompañada de una reducción de la energía.</p>'
          }

          if ($Ma > 15 && $Ma < 20) {
            $es_Ma =
              '<p align="justify"><strong>ESCALA 9 (Ma):</strong> Personas prácticas, confiables, adaptables y ordenadas, índice para predecir conducta adecuada.</p>'
          }

          if ($Ma > 19 && $Ma < 26) {
            $es_Ma =
              '<p align="justify"><strong>ESCALA 9 (Ma):</strong> Probablemente enérgico y entusiasta. Variedad de intereses, sociable, emprendedor dinámico, ambicioso. Conforme se eleva, tales metas pueden ser más ambiciosas, y posiblemente están alejadas de las habilidades reales del sujeto, quien tiene muchas aspiraciones y habla con entusiasmo de sus metas, aunque sólo logre unas cuantas.</p>'
          }

          if ($Ma > 25 && $Ma < 31) {
            $es_Ma =
              '<p align="justify"><strong>ESCALA 9 (Ma):</strong> Impaciente e impulsivo, disperso de su energía y sus intereses. Probablemente su energía y su gregorismo son superficiales. Conforme se eleva, tales metas pueden ser más ambiciosas, y posiblemente están alejadas de las habilidades reales del sujeto, quien tiene muchas aspiraciones y habla con entusiasmo de sus metas, aunque sólo logre unas cuantas.</p>'
          }

          if ($Ma > 30) {
            $es_Ma =
              '<p align="justify"><strong>ESCALA 9 (Ma):</strong> Explosivo, impulsivo, alocado, improductivo. Probablemente fuga de ideas o hiperactividad. Considerar evaluación psiquiátrica, disperso. Con dificultad para concentrarse. Conforme se eleva, tales metas pueden ser más ambiciosas, y posiblemente están alejadas de las habilidades reales del sujeto, quien tiene muchas aspiraciones y habla con entusiasmo de sus metas, aunque sólo logre unas cuantas.</p>'
          }

          let $es_Si = '<p align="justify"><strong>'

          if ($Si < 21) {
            $es_Si =
              '<p align="justify"><strong>ESCALA 0 (Si):</strong> Manipulador social, gregario, extrovertido, superficial, necesita un trabajo que tenga un 85% de contacto abierto, sociable, dominante, convencedor. La reducción de los puntajes en esta escala, indica la tendencia a buscar una explicación a los problemas y a las experiencias de la vida en los hechos del exterior, por lo que, difícilmente, la persona es consciente de su participación y responsabilidad, tanto en los logros como en los fracasos. Sus explicaciones se basan en lo que otras personas hacen o en las circunstancias.</p>'
          }

          if ($Si > 20 && $Si < 32) {
            $es_Si =
              '<p align="justify"><strong>ESCALA 0 (Si):</strong> Capacidad para mantener adecuadas relaciones sociales. Necesita bastante contacto en su trabajo. La reducción de los puntajes en esta escala, indica la tendencia a buscar una explicación a los problemas y a las experiencias de la vida en los hechos del exterior, por lo que, difícilmente, la persona es consciente de su participación y responsabilidad, tanto en los logros como en los fracasos. Sus explicaciones se basan en lo que otras personas hacen o en las circunstancias.</p>'
          }

          if ($Si > 31 && $Si < 40) {
            $es_Si =
              '<p align="justify"><strong>ESCALA 0 (Si):</strong> Reservado en situaciones sociales. Fuera del área familiar, necesita un 70% de contacto abierto con la gente.</p>'
          }

          if ($Si > 39 && $Si < 49) {
            $es_Si =
              '<p align="justify"><strong>ESCALA 0 (Si):</strong> Retraído y tímido en situaciones sociales, prefiere estar solo que en contacto con la gente. Los puntajes elevados pueden expresar la búsqueda de explicaciones a través de la reflexión sobre sí mismo, reconociendo los éxitos y los fracasos, así como la responsabilidad y participación que se ha tenido en cada una de las experiencias. Es posible que estas personas sean algo reservadas para hablar de sí mismas, o para magnificar la importancia de sus logros o el pesimismo frente al fracaso, mostrándose mesuradas ante los problemas de trabajo y de la vida en general.</p>'
          }

          if ($Si > 48) {
            $es_Si =
              '<p align="justify"><strong>ESCALA 0 (Si):</strong> Tímido, socialmente inepto. Se concentrará mejor en un trabajo estrictamente interior siendo eficiente y pudiendo trabajar de una manera ininterrumpida. Deseable en científicos, contadores y obreros semicalificados. Autosuficiente, individualista. Los puntajes elevados pueden expresar la búsqueda de explicaciones a través de la reflexión sobre sí mismo, reconociendo los éxitos y los fracasos, así como la responsabilidad y participación que se ha tenido en cada una de las experiencias. Es posible que estas personas sean algo reservadas para hablar de sí mismas, o para magnificar la importancia de sus logros o el pesimismo frente al fracaso, mostrándose mesuradas ante los problemas de trabajo y de la vida en general.</p>'
          }

          let $uno = ''
          if ($D_T > 54 && $D_T < 66) {
            if ($K_T > $L_T && $K_T > $F_T && $K_T > $Si_T) {
              $uno =
                '<p align="justify"><strong> CAPACIDAD DE AUTOCRITICA:</strong> Como se ha señalado, esta característica se relaciona con la capacidad para revisar la propia experiencia, sin restar importancia a los logros o exagerar el pesimismo ante el fracaso. Este rubro indica la aptitud de auto-observación para identificar los errores o aciertos que se cometen al tomar decisiones, así como para reconocer la propia participación y responsabilidad dentro de estas situaciones. En consecuencia, para hablar de una adecuada capacidad de autocrítica, es necesario que el puntaje de la escala 2 se localice entre T55 y T65, y que K esté sobre las escalas L,F y 0.</p>'
            }
          }

          let $dos = ''
          if (
            $Dp_T > 62 &&
            $Dp_T < 71 &&
            $Ma_T > 62 &&
            $Ma_T < 71 &&
            $Dp_T > $Ma_T
          ) {
            $dos =
              '<p align="justify"><strong> CAPACIDAD DE LIDERAZGO:</strong> El liderazgo es la capacidad de decidir por otros y conducirlos para que actúen de acuerdo con las propuestas que se les plantean. Es recomendable observar los puntajes de las escalas 4 y 9 y la relación que guardan con la 6 y la 8. El ejercicio del liderazgo con metas más definidas realistas, y basado en las características y los recursos personales de los subordinados, se puede inferir cuando las escalas 4 y 9 se encuentran elevadas, alrededor de T65, e incluso, cercanas a T70.</p>'
          }

          if ($Dp_T > 70 && $Ma_T > 70 && $Dp_T > $Ma_T) {
            $dos =
              '<p align="justify"><strong> CAPACIDAD DE LIDERAZGO:</strong> El liderazgo es la capacidad de decidir por otros y conducirlos para que actúen de acuerdo con las propuestas que se les plantean. Puntajes más altos harían pensar en un menor control y en la exaltación de la energía.</p>'
          }

          if (
            $Dp_T < $Pa_T ||
            $Dp_T < $Es_T ||
            $Ma_T < $Pa_T ||
            ($Ma_T < $Es_T && $Pa_T > 70 && $Es_T > 70)
          ) {
            $dos =
              '<p align="justify"><strong> CAPACIDAD DE LIDERAZGO:</strong> El liderazgo es la capacidad de decidir por otros y conducirlos para que actúen de acuerdo con las propuestas que se les plantean. La relación de alguna de estas escalas (4 ó 9) con puntajes elevados para las escalas 6 u 8, sugieren que las metas se planteen en una forma más egocéntrica y menos realista, en ocasiones buscando compensar alguna carencia personal y con cierto sentido revanchista.</p>'
          }

          let $tres = ''
          if ($Hi_T > 54 && $Hi_T < 61) {
            $tres =
              '<p align="justify"><strong> ACTITUD DE SERVICIO:</strong> Para el desempeño de algunas actividades es importante mostrar amabilidad y apego a las conductas convencionales. En estos casos, es importante tener presente el nivel de la escala 3. Los puntajes entre T55 y T60 indican que la persona trata a los demás en forma amable y considerada, sin manifestaciones exageradas de la necesidad de aceptación social.</p>'
          }

          if ($Hi_T > 60 && $D_T > 60 && $Hi_T > $D_T) {
            $tres =
              '<p align="justify"><strong> ACTITUD DE SERVICIO:</strong> Para el desempeño de algunas actividades es importante mostrar amabilidad y apego a las conductas convencionales. En estos casos, es importante tener presente el nivel de la escala 3. Los puntajes entre T55 y T60 indican que la persona trata a los demás en forma amable y considerada, sin manifestaciones exageradas de la necesidad de aceptación social.</p>'
          }

          if ($Hi_T > 60 && $Dp_T > 60 && $Hi_T > $Dp_T) {
            $tres =
              '<p align="justify"><strong> ACTITUD DE SERVICIO:</strong> Para el desempeño de algunas actividades es importante mostrar amabilidad y apego a las conductas convencionales. La relación de la escala 3 con la escala 4, cuando sus puntajes son altos, se refiere a la tendencia del sujeto a ser amable, pero sin comprometerse con los demás.</p>'
          }

          if ($Pa_T > 60 || $Es_T > 60) {
            $tres =
              '<p align="justify"><strong> ACTITUD DE SERVICIO:</strong> Para el desempeño de algunas actividades es importante mostrar amabilidad y apego a las conductas convencionales. La elevación de los puntajes en las escalas 6 u 8 llevaría a inferir una reducción de esta capacidad.</p>'
          }

          let $cuatro = ''
          if ($Dp_T > 60 && $Ma_T > 60 && $Dp_T > $Ma_T) {
            if ($D_T < 60 && $Pt_T < 60 && $Es_T < 60) {
              $cuatro =
                '<p align="justify"><strong> CAPACIDAD PARA TRABAJAR BAJO PRESION:</strong> Con frecuencia, en las empresas la toma de decisiones se debe efectuar en condiciones poco favorables, con tiempos reducidos y ante la exigencia de un buen nivel de eficiencia. Trabajar bajo presión es, en ocasiones, un estilo personal de desempeñarse, incluso en las actividades cotidianas y rutinarias. Hay personas que se organizan para disponer de tiempo y condiciones suficientes para llevar a acabo sus actividades, en cambio, hay otras que dejan para el último momento el desarrollo de sus tareas, sin que se reduzca, en ninguno de estos casos, la calidad y la eficiencia en el trabajo. Respecto a lo anterior, el MMPI puede ofrecer algunos datos a través del nivel de la escala 4 y su relacion con la escala 9. La capacidad para soportar las presiones, y hasta la frustraciones, quizá sea mayor a medida que se elevan los puntajes de estas escalas y se reducen los de las escalas 2, 7 y 8.</p>'
            }
          }

          if ($D_T > 60 && $Pt_T > 60 && $Es_T > 60) {
            $cuatro =
              '<p align="justify"><strong> CAPACIDAD PARA TRABAJAR BAJO PRESION:</strong> Cuando predomina la elevación en los puntajes de las escalas 2, 7 y 8, la persona prefiere disponer de tiempo y condiciones suficientes para no sentirse presionada, y su eficiencia disminuye cuando aumentan las presiones en el trabajo.</p>'
          }

          let $cinco = ''
          if ($Hi_T > 60 && $Dp_T > 60 && $Dp_T > $Hi_T) {
            $cinco =
              '<p align="justify"><strong> CAPACIDAD PARA TRABAJAR EN EQUIPO:</strong> Trabajar en equipo es, con frecuencia, una característica que se trata de explorar y que se dificulta en algunas personas. En muchas ocasiones, el éxito de una actividad depende de la integración de un equipo de trabajo. Escala 4 3:</strong> La persona es capaz de externar sus puntos de vista y sus ideas, sin imponerlas abiertamente a los otros.</p>'
          }

          if ($Hi_T > 60 && $D_T > 60 && $Hi_T > $D_T) {
            $cinco =
              '<p align="justify"><strong> CAPACIDAD PARA TRABAJAR EN EQUIPO:</strong> Escala 3 2:</strong> El sujeto se incluye dentro del equipo de trabajo para seguir los planteamientos de otros, sin manifestar sus propias ideas y aportaciones, pero mostrando compromiso e interés en lo que otros proponen.</p>'
          }

          let $seis = ''
          if ($Es_T < 60) {
            $seis =
              '<p align="justify"><strong> SENTIDO DE LA REALIDAD:</strong> Esta característica es importante cuando se tienen que manejar elementos en donde el cálculo y el apego a los hechos son muy necesarios. Esto no quiere decir que la persona que tiene buen sentido de la realidad sea poco creativa. Su creatividad y productividad tienen fundamento en la organización que dan a los datos de la realidad. Por eso, una reducción en el puntaje de la escala 8 tal vez sea bastante conveniente, ya que indica que los datos de la realidad son captados tal como se presentan y que sobre esta base se elaboran propuestas para su manejo.</p>'
          }

          let $siete = ''
          if ($Dp_T > 63 && $Ma_T > 63 && $Dp_T > $Ma_T) {
            $siete =
              '<p align="justify"><strong> METAS Y LOGROS:</strong> También dentro de las características a explorar en la selección de personal se encuentra la relación entre las metas y los logros de una persona. Como se dijo, es conveniente en este punto tener en cuenta la relación entre las escalas 4 y 9. Cuando el nivel de ambas está alrededor de T65, expresa una adecuada relación entre las metas y los logros, ya que aquéllas se determinan en forma realista y de acuerdo con la capacidad del sujeto, quien reconoce tener una importante energía para alcanzarlas.</p>'
          }

          if ($Dp_T > 63 && $Ma_T < 60) {
            $siete =
              '<p align="justify"><strong> METAS Y LOGROS:</strong> También dentro de las características a explorar en la selección de personal se encuentra la relación entre las metas y los logros de una persona. Un puntaje bajo en la escala 9 quizá refleje la disminución en el interés para fijarse metas, acompañada de una reducción de la energía.</p>'
          }

          if ($Dp_T < 63 && $Ma_T < 63 && $Ma_T > $Dp_T) {
            $siete =
              '<p align="justify"><strong> METAS Y LOGROS:</strong> También dentro de las características a explorar en la selección de personal se encuentra la relación entre las metas y los logros de una persona. En ocasiones, el puntaje de la escala 9 es bajo y el de la escala 4 más elevado. En el contexto laboral, esta discrepancia se explica como la necesidad de actuar sin una meta bien establecida, alcanzando objetivos un tanto al azar y aprovechando las oportunidades que se presentan.</p>'
          }

          let $ocho = ''
          if (
            $Pt_T > 64 &&
            $Pt_T < 71 &&
            $Es_T > 64 &&
            $Es_T < 71 &&
            $Pt_T > $Es_T
          ) {
            $ocho =
              '<p align="justify"><strong> METICULOSIDAD Y DETALLISMO:</strong> Para el desempeño de algunas actividades se requiere de una actitud muy cuidadosa, perfeccionista y ordenada, fundamentada en un gran sentido de la responsabilidad y el deber. Estas características se relacionan con los puntajes algo elevados (T65 a T70) de la combinación 7 8. Si se recuerda, desde un enfoque clínico, esta combinación se interpreta como la presencia de características obsesivas, en donde la persona como una forma para reducir la angustia, busca actuar en forma cuidadosa y con apego al deber, para sentirse menos expuesta a la posibilidad de fracasar.</p>'
          }

          if ($Pt_T > 70 && $Es_T > 70 && $Pt_T > $Es_T) {
            $ocho =
              '<p align="justify"><strong> METICULOSIDAD Y DETALLISMO:</strong> Para el desempeño de algunas actividades se requiere de una actitud muy cuidadosa, perfeccionista y ordenada, fundamentada en un gran sentido de la responsabilidad y el deber. Una elevación mayor en las escalas 7 8, sugiere un nivel mayor de angustia, que requerirá de controles más rígidos, y quizá interfiera la toma de decisiones y la eficiencia en el trabajo.</p>'
          }

          const $val1 =
            '<p align="justify"><strong>Los valores de cada escala son los siguientes:</strong> L: ' +
            $L +
            ' F: ' +
            $F +
            ' K: ' +
            $K +
            ' Hs: ' +
            $Hs +
            ' D: ' +
            $D +
            ' Hi: ' +
            $Hi +
            ' Dp: ' +
            $Dp +
            ' Mf: ' +
            $Mf +
            ' Pa: ' +
            $Pa +
            ' Pt: ' +
            $Pt +
            ' Es: ' +
            $Es +
            ' Ma: ' +
            $Ma +
            ' Si: ' +
            $Si

          const $val2 =
            '<p align="justify"><strong>Los puntajes en bruto (T) de cada escala son los siguientes:</strong> L: ' +
            $L_T +
            ' F: ' +
            $F_T +
            ' K: ' +
            $K_T +
            ' Hs: ' +
            $Hs_T +
            ' D: ' +
            $D_T +
            ' Hi: ' +
            $Hi_T +
            ' Dp: ' +
            $Dp_T +
            ' Mf: ' +
            $Mf_T +
            ' Pa: ' +
            $Pa_T +
            ' Pt: ' +
            $Pt_T +
            ' Es: ' +
            $Es_T +
            ' Ma: ' +
            $Ma_T +
            ' Si: ' +
            $Si_T

          let $es_tot =
            $tcom +
            $es_l +
            $es_f +
            $es_k +
            $es_Hs +
            $es_D +
            $es_Hi +
            $es_Dp +
            $es_Mf +
            $es_Pa +
            $es_Pt +
            $es_Es +
            $es_Ma +
            $es_Si

          $es_tot =
            $es_tot +
            $uno +
            $dos +
            $tres +
            $cuatro +
            $cinco +
            $seis +
            $siete +
            $ocho

          const $t = $es_tot + $val1 + $val2

          setTs($t)
        } else if (props.id.testId === 7) {
          setNamePrueba('Test de Clima Laboral (MS) Individual')
          const testFactor = response.data.testFactor

          const $im = testFactor.find((f) => f.factorId === 93).testsResultsTpm
            .score
          const $co = testFactor.find((f) => f.factorId === 32).testsResultsTpm
            .score
          const $ap = testFactor.find((f) => f.factorId === 11).testsResultsTpm
            .score
          const $au = testFactor.find((f) => f.factorId === 15).testsResultsTpm
            .score
          const $orr = testFactor.find((f) => f.factorId === 141)
            .testsResultsTpm.score
          const $PR = testFactor.find((f) => f.factorId === 157).testsResultsTpm
            .score
          const $cl = testFactor.find((f) => f.factorId === 79).testsResultsTpm
            .score
          const $cn = testFactor.find((f) => f.factorId === 48).testsResultsTpm
            .score
          const $inn = testFactor.find((f) => f.factorId === 105)
            .testsResultsTpm.score
          const $cf = testFactor.find((f) => f.factorId === 35).testsResultsTpm
            .score

          const $t = `<p align="justify">En la dimensión <strong>RELACIONES</strong> se evalúa el grado en que los empleados están interesados y comprometidos en su trabajo y el grado en que la dirección apoya a los empleados y les anima a apoyarse unos a otros, se han calificado a las tres subescalas de esta manera:
          <br><br> <strong>(IM) IMPLICACIÓN</strong> - Grado en que los empleados se preocupan por su actividad y se entregan a ella - obtuvo una puntuación de: <font color="blue">${$im}</font>.
          <br><br> <strong>(CO) COHESIÓN</strong> - Grado en que los empleados se ayudan entre sí y se muestran amables con los compañeros - obtuvo un puntaje de: <font color="blue">${$co}</font>.
          <br><br> <strong>(AP) APOYO</strong> - Grado en que los jefes ayudan y animan al personal para crear un buen clima social - obtuvo un total de: <font color="blue">${$ap}</font> puntos</p>
          <p align="justify">En la dimensión <strong>AUTO-REALIZACIÓN</strong> u orientación hacia unos objetivos, se evalúa el grado en que se estimula a los empleados a ser autosuficientes y a tomar sus propias decisiones; la importancia que se da a la buena planificación, eficiencia y terminación de las tareas y el grado en que la presión en el trabajo o la urgencia dominan el ambiente laboral, se han calificado a las tres subescalas de la siguiente manera: 
          <br><br> <strong>(AU) AUTONOMÍA</strong> - Grado en que se anima a los empleados a ser autosuficientes y a tomar iniciativas propias - obtuvo una puntuación de: <font color="blue">${$au}</font>
          <br><br> <strong>(OR) ORGANIZACIÓN</strong> - Grado en que se subraya una buena planificación, eficiencia y terminación de la tarea - obtuvo un puntaje de: <font color="blue">${$orr}</font>.
          <br><br> <strong>(PR) PRESIÓN</strong> - Grado en que la urgencia o la presión en el trabajo domina el ambiente laboral - obtuvo un total de: <Font color="blue">${$PR}</font> puntos.    </p>
          <p align="justify">En la dimensión <strong>ESTABILIDAD / CAMBIO</strong> se evalúa el grado en que los empleados conocen lo que se espera de su tarea diaria y cómo se les explican las normas y planes de trabajo; el grado en que la dirección utiliza las normas y la presión para controlar a los empleados; la importancia que se da a la variedad, al cambio y a las nuevas propuestas y, por último, el grado en que el entorno físico contribuye a crear un ambiente de trabajo agradable, se han calificado a las cuatro subescalas de la manera siguiente: 
          <br><br> <strong>(CL) CLARIDAD</strong> - Grado en que se conocen las expectativas de las tareas diarias y se explican las reglas y planes para el trabajo - obtuvo una puntuación de: <font color="blue">${$cl}</font>.
          <br><br> <strong>(CN) CONTROL</strong> - Grado en que los jefes utilizan las reglas y las presiones para tener controlados a los empleados - obtuvo un puntaje de: <font color="blue">${$cn}</font>.
          <br><br> <strong>(IN) INNOVACIÓN</strong> - Grado en que se subraya la variedad, el cambio y los nuevos enfoques - obtuvo un total de: <font color="blue">${$inn}</font> puntos.
          <br><br> <strong>(CF) COMODIDAD</strong> - Grado en que el ambiente físico contribuye a crear un ambiente laboral agradable - obtuvo una puntuación de: <font color="blue">${$cf}</font></p>`

          setTs($t)
        } else if (props.id.testId === 8) {
          let value, title, text, factor
          setNamePrueba('Competencias Interpersonales (GD)')

          response.data.testFactor.forEach((items) => {
            value = ''
            title = ''
            text = ''
            factor = ''

            if (items.factorId === 18) {
              //  Benevolencia
              switch (items.testsResultsTpm.scoreBase) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                  value = 1
                  break
                case 9:
                  value = 2
                  break
                case 10:
                  value = 3
                  break
                case 11:
                  value = 4
                  break
                case 12:
                case 13:
                  value = 5
                  break
                case 14:
                case 15:
                  value = 10
                  break
                case 16:
                case 17:
                  value = 15
                  break
                case 18:
                  value = 20
                  break
                case 19:
                  value = 25
                  break
                case 20:
                  value = 30
                  break
                case 21:
                  value = 35
                  break
                case 22:
                  value = 40
                  break
                case 23:
                  value = 50
                  break
                case 24:
                  value = 60
                  break
                case 25:
                  value = 70
                  break
                case 26:
                  value = 75
                  break
                case 27:
                  value = 80
                  break
                case 28:
                  value = 90
                  break
                case 29:
                  value = 95
                  break
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                case 45:
                case 46:
                case 47:
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                case 58:
                case 59:
                case 60:
                case 61:
                case 62:
                case 63:
                case 64:
                case 65:
                case 66:
                case 67:
                case 68:
                case 69:
                case 70:
                case 71:
                case 72:
                case 73:
                case 74:
                case 75:
                case 76:
                case 77:
                case 78:
                case 79:
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 88:
                case 89:
                case 90:
                case 91:
                case 92:
                case 93:
                case 94:
                case 95:
                case 96:
                case 97:
                case 98:
                case 99:
                case 100:
                  value = 99
                  break
                default:
                  break
              }

              if (value > 75) {
                title = 'ALTO'
                text =
                  'En este caso se valora la generosidad y el poder compartir lo bueno con los demás. '
              } else {
                if (value < 26) {
                  title = 'BAJO'
                  text =
                    'En este caso no se encuentre este valor, no es importante ser generoso con la demás gente. '
                } else {
                  title = 'NORMAL'
                  text =
                    'En este caso se valora la generosidad y el poder compartir lo bueno con los demás. '
                }
              }

              factor = 'be'
            } else if (items.factorId === 41) {
              //  Conformidad
              switch (items.testsResultsTpm.scoreBase) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                  value = 1
                  break
                case 7:
                  value = 2
                  break
                case 8:
                  value = 3
                  break
                case 9:
                  value = 4
                  break
                case 10:
                  value = 5
                  break
                case 11:
                case 12:
                  value = 10
                  break
                case 13:
                  value = 15
                  break
                case 14:
                  value = 20
                  break
                case 15:
                case 16:
                  value = 25
                  break
                case 17:
                  value = 30
                  break
                case 18:
                  value = 35
                  break
                case 19:
                  value = 40
                  break
                case 20:
                  value = 50
                  break
                case 21:
                  value = 60
                  break
                case 22:
                  value = 70
                  break
                case 23:
                  value = 80
                  break
                case 24:
                  value = 85
                  break
                case 25:
                  value = 90
                  break
                case 26:
                  value = 96
                  break
                case 27:
                  value = 98
                  break
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                case 45:
                case 46:
                case 47:
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                case 58:
                case 59:
                case 60:
                case 61:
                case 62:
                case 63:
                case 64:
                case 65:
                case 66:
                case 67:
                case 68:
                case 69:
                case 70:
                case 71:
                case 72:
                case 73:
                case 74:
                case 75:
                case 76:
                case 77:
                case 78:
                case 79:
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 88:
                case 89:
                case 90:
                case 91:
                case 92:
                case 93:
                case 94:
                case 95:
                case 96:
                case 97:
                case 98:
                case 99:
                case 100:
                  value = 99
                  break
                default:
                  break
              }

              if (value > 75) {
                title = 'ALTO'
                text =
                  'Encontramos presente la aceptación social en este caso. '
              } else {
                if (value < 26) {
                  title = 'BAJO'
                  text =
                    'Aquí no se aprecia este valor, por lo que se rechazan las normas sociales. '
                } else {
                  title = 'NORMAL'
                  text =
                    'Encontramos presente la aceptación social en este caso. '
                }
              }

              factor = 'co'
            } else if (items.factorId === 80) {
              //  Estímulo
              switch (items.testsResultsTpm.scoreBase) {
                case 0:
                case 1:
                case 2:
                case 3:
                  value = 1
                  break
                case 4:
                  value = 2
                  break
                case 5:
                  value = 3
                  break
                case 6:
                  value = 4
                  break
                case 7:
                  value = 5
                  break
                case 8:
                case 9:
                  value = 10
                  break
                case 10:
                  value = 15
                  break
                case 11:
                  value = 25
                  break
                case 12:
                  value = 30
                  break
                case 13:
                  value = 40
                  break
                case 14:
                  value = 50
                  break
                case 15:
                  value = 60
                  break
                case 16:
                  value = 65
                  break
                case 17:
                  value = 70
                  break
                case 18:
                  value = 75
                  break
                case 19:
                  value = 80
                  break
                case 20:
                  value = 85
                  break
                case 21:
                  value = 90
                  break
                case 23:
                  value = 95
                  break
                case 24:
                  value = 96
                  break
                case 25:
                  value = 97
                  break
                case 26:
                  value = 98
                  break
                case 27:
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                  value = 99
                  break
                default:
                  break
              }

              if (value > 75) {
                title = 'ALTO'
                text =
                  'Gracias a que valora todo ello, a su vez proporciona a los demás apoyo y comprensión. '
              } else {
                if (value < 26) {
                  title = 'BAJO'
                  text =
                    'Como aquí no se valora este aspecto no proporciona estímulos a los demás. '
                } else {
                  title = 'NORMAL'
                  text =
                    'Gracias a que valora todo ello, a su vez proporciona a los demás apoyo y comprensión. '
                }
              }

              factor = 'es'
            } else if (items.factorId === 97) {
              //  Independencia
              switch (items.testsResultsTpm.scoreBase) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                  value = 1
                  break
                case 5:
                  value = 2
                  break
                case 6:
                  value = 3
                  break
                case 7:
                  value = 4
                  break
                case 8:
                  value = 5
                  break
                case 9:
                case 10:
                  value = 10
                  break
                case 11:
                case 12:
                  value = 15
                  break
                case 13:
                  value = 20
                  break
                case 14:
                  value = 25
                  break
                case 15:
                  value = 30
                  break
                case 16:
                  value = 40
                  break
                case 17:
                  value = 50
                  break
                case 18:
                  value = 60
                  break
                case 19:
                  value = 65
                  break
                case 20:
                  value = 70
                  break
                case 22:
                  value = 80
                  break
                case 23:
                  value = 85
                  break
                case 24:
                  value = 90
                  break
                case 25:
                  value = 95
                  break
                case 26:
                  value = 96
                  break
                case 27:
                  value = 97
                  break
                case 28:
                  value = 98
                  break
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                case 45:
                case 46:
                case 47:
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                case 58:
                case 59:
                case 60:
                case 61:
                case 62:
                case 63:
                case 64:
                case 65:
                case 66:
                case 67:
                case 68:
                case 69:
                case 70:
                case 71:
                case 72:
                case 73:
                case 74:
                case 75:
                case 76:
                case 77:
                case 78:
                case 79:
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 88:
                case 89:
                case 90:
                case 91:
                case 92:
                case 93:
                case 94:
                case 95:
                case 96:
                case 97:
                case 98:
                case 99:
                case 100:
                  value = 99
                  break
                default:
                  break
              }

              if (value > 75) {
                title = 'ALTO'
                text =
                  'Aquí encontramos este valor por lo que es muy apreciada la independencia y libertad. '
              } else {
                if (value < 26) {
                  title = 'BAJO'
                  text =
                    'Este valor no se encuentra por lo que no es importante la independencia y el libre albedrío. '
                } else {
                  title = 'NORMAL'
                  text =
                    'Aquí encontramos este valor por lo que es muy apreciada la independencia y libertad. '
                }
              }

              factor = 'in'
            } else if (items.factorId === 121) {
              //  Liderazgo
              switch (items.testsResultsTpm.scoreBase) {
                case 0:
                  value = 1
                  break
                case 1:
                  value = 2
                  break
                case 2:
                  value = 4
                  break
                case 3:
                  value = 5
                  break
                case 4:
                case 5:
                  value = 10
                  break
                case 6:
                  value = 15
                  break
                case 7:
                  value = 20
                  break
                case 8:
                  value = 35
                  break
                case 9:
                  value = 45
                  break
                case 10:
                  value = 50
                  break
                case 11:
                  value = 55
                  break
                case 12:
                  value = 65
                  break
                case 13:
                  value = 70
                  break
                case 14:
                case 15:
                  value = 75
                  break
                case 16:
                  value = 80
                  break
                case 17:
                  value = 85
                  break
                case 18:
                case 19:
                  value = 90
                  break
                case 20:
                case 21:
                  value = 95
                  break
                case 22:
                  value = 96
                  break
                case 23:
                  value = 97
                  break
                case 24:
                  value = 98
                  break
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                case 45:
                case 46:
                case 47:
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                case 58:
                case 59:
                case 60:
                case 61:
                case 62:
                case 63:
                case 64:
                case 65:
                case 66:
                case 67:
                case 68:
                case 69:
                case 70:
                case 71:
                case 72:
                case 73:
                case 74:
                case 75:
                case 76:
                case 77:
                case 78:
                case 79:
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 88:
                case 89:
                case 90:
                case 91:
                case 92:
                case 93:
                case 94:
                case 95:
                case 96:
                case 97:
                case 98:
                case 99:
                case 100:
                  value = 99
                  break
                default:
                  break
              }

              if (value > 75) {
                title = 'ALTO'
                text =
                  'Este valor se encuentra presente, es importante tener autoridad. '
              } else {
                if (value < 26) {
                  title = 'BAJO'
                  text =
                    'Este valor no se encuentra presente, el liderazgo no es importante. '
                } else {
                  title = 'NORMAL'
                  text =
                    'Este valor se encuentra presente, es importante tener autoridad. '
                }
              }

              factor = 'li'
            } else if (items.factorId === 167) {
              //  Reconocimiento
              switch (items.testsResultsTpm.scoreBase) {
                case 0:
                  value = 1
                  break
                case 1:
                  value = 3
                  break
                case 2:
                  value = 5
                  break
                case 3:
                  value = 15
                  break
                case 4:
                  value = 25
                  break
                case 5:
                  value = 40
                  break
                case 6:
                  value = 50
                  break
                case 7:
                  value = 60
                  break
                case 8:
                  value = 65
                  break
                case 9:
                  value = 75
                  break
                case 10:
                  value = 80
                  break
                case 11:
                  value = 85
                  break
                case 12:
                  value = 90
                  break
                case 13:
                  value = 95
                  break
                case 14:
                  value = 96
                  break
                case 15:
                  value = 97
                  break
                case 16:
                  value = 98
                  break
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                case 45:
                case 46:
                case 47:
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                case 58:
                case 59:
                case 60:
                case 61:
                case 62:
                case 63:
                case 64:
                case 65:
                case 66:
                case 67:
                case 68:
                case 69:
                case 70:
                case 71:
                case 72:
                case 73:
                case 74:
                case 75:
                case 76:
                case 77:
                case 78:
                case 79:
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 88:
                case 89:
                case 90:
                case 91:
                case 92:
                case 93:
                case 94:
                case 95:
                case 96:
                case 97:
                case 98:
                case 99:
                case 100:
                  value = 99
                  break
                default:
                  break
              }

              if (value > 75) {
                title = 'ALTO'
                text =
                  'En este caso, se encuentra presente el reconocimiento, por lo que darlo y recibirlo es muy importante. '
              } else {
                if (value < 26) {
                  title = 'BAJO'
                  text =
                    'Este valor no se encuentra en este caso, por lo que el reconocimiento de los demás no es trascendente. '
                } else {
                  title = 'NORMAL'
                  text =
                    'En este caso, se encuentra presente el reconocimiento, por lo que darlo y recibirlo es muy importante. '
                }
              }

              factor = 're'
            }

            if (factor && value && title && text)
              outFactor.push({
                factor,
                value,
                title,
                text,
              })
          })
        } else if (props.id.testId === 13) {
          setNamePrueba('Test de Compatibilidad Laboral (CL)')

          response.data.testFactor.forEach((items) => {
            if (items.factorId === 64) {
              let val_verde_m = '',
                val_verde_l = '',
                val_menosML = ''

              switch (items.testsResultsTpm.scoreBase.verde_m) {
                case 0:
                  val_verde_m = 2
                  break
                case 1:
                  val_verde_m = 6
                  break
                case 2:
                  val_verde_m = 12
                  break
                case 3:
                  val_verde_m = 24
                  break
                case 4:
                  val_verde_m = 31
                  break
                case 5:
                  val_verde_m = 41
                  break
                case 6:
                  val_verde_m = 48
                  break
                case 7:
                  val_verde_m = 58
                  break
                case 8:
                  val_verde_m = 68
                  break
                case 9:
                  val_verde_m = 75
                  break
                case 10:
                  val_verde_m = 87
                  break
                case 11:
                  val_verde_m = 89
                  break
                case 12:
                  val_verde_m = 90
                  break
                case 13:
                  val_verde_m = 93
                  break
                case 14:
                  val_verde_m = 95
                  break
                case 15:
                  val_verde_m = 97
                  break
                case 16:
                case 17:
                  val_verde_m = 98
                  break
                default:
                  val_verde_m = 99
              }

              switch (items.testsResultsTpm.scoreBase.verde_l) {
                case 0:
                  val_verde_l = 99
                  break
                case 1:
                  val_verde_l = 95
                  break
                case 2:
                  val_verde_l = 89
                  break
                case 3:
                  val_verde_l = 81
                  break
                case 4:
                  val_verde_l = 68
                  break
                case 5:
                  val_verde_l = 61
                  break
                case 6:
                  val_verde_l = 48
                  break
                case 7:
                  val_verde_l = 41
                  break
                case 8:
                  val_verde_l = 34
                  break
                case 9:
                  val_verde_l = 24
                  break
                case 10:
                  val_verde_l = 19
                  break
                case 11:
                  val_verde_l = 14
                  break
                case 12:
                  val_verde_l = 9
                  break
                case 13:
                  val_verde_l = 6
                  break
                case 14:
                  val_verde_l = 5
                  break
                case 15:
                  val_verde_l = 4
                  break
                case 16:
                case 17:
                  val_verde_l = 2
                  break
                default:
                  val_verde_l = 1
              }

              switch (
                items.testsResultsTpm.scoreBase.verde_m -
                items.testsResultsTpm.scoreBase.verde_l
              ) {
                case 0:
                  val_menosML = 48
                  break
                case 1:
                  val_menosML = 54
                  break
                case 2:
                  val_menosML = 61
                  break
                case 3:
                  val_menosML = 65
                  break
                case 4:
                  val_menosML = 68
                  break
                case 5:
                  val_menosML = 71
                  break
                case 6:
                  val_menosML = 78
                  break
                case 7:
                  val_menosML = 81
                  break
                case 8:
                  val_menosML = 85
                  break
                case 9:
                  val_menosML = 87
                  break
                case 10:
                  val_menosML = 90
                  break
                case 11:
                  val_menosML = 91
                  break
                case 12:
                  val_menosML = 93
                  break
                case 13:
                  val_menosML = 95
                  break
                case 14:
                  val_menosML = 97
                  break
                case 15:
                case 16:
                  val_menosML = 98
                  break
                case 17:
                case 18:
                case 19:
                case 20:
                  val_menosML = 99
                  break
                case -1:
                  val_menosML = 45
                  break
                case -2:
                  val_menosML = 41
                  break
                case -3:
                  val_menosML = 34
                  break
                case -4:
                  val_menosML = 28
                  break
                case -5:
                  val_menosML = 24
                  break
                case -6:
                  val_menosML = 21
                  break
                case -7:
                  val_menosML = 17
                  break
                case -8:
                  val_menosML = 14
                  break
                case -9:
                  val_menosML = 12
                  break
                case -10:
                  val_menosML = 9
                  break
                case -11:
                  val_menosML = 6
                  break
                case -12:
                  val_menosML = 5
                  break
                case -13:
                  val_menosML = 4
                  break
                case -15:
                case -14:
                  val_menosML = 2
                  break
                case -21:
                case -20:
                case -19:
                case -18:
                case -17:
                case -16:
                  val_menosML = 1
                  break
                default:
                  break
              }

              outFactor.push({
                factorName: items.factor.name,
                valueVerde_m: val_verde_m,
                m: val_verde_m,
                valueVerde_l: val_verde_l,
                l: val_verde_l,
                valueRestaML: val_menosML,
              })
            } else if (items.factorId === 100) {
              let val_amar_m = '',
                val_amar_l = '',
                val_menosML = ''

              switch (items.testsResultsTpm.scoreBase.amar_m) {
                case 0:
                  val_amar_m = 4
                  break
                case 1:
                  val_amar_m = 10
                  break
                case 2:
                  val_amar_m = 28
                  break
                case 3:
                  val_amar_m = 41
                  break
                case 4:
                  val_amar_m = 58
                  break
                case 5:
                  val_amar_m = 75
                  break
                case 6:
                  val_amar_m = 83
                  break
                case 7:
                  val_amar_m = 90
                  break
                case 8:
                  val_amar_m = 95
                  break
                case 9:
                  val_amar_m = 97
                  break
                case 10:
                case 11:
                  val_amar_m = 98
                  break
                default:
                  val_amar_m = 99
              }

              switch (items.testsResultsTpm.scoreBase.amar_l) {
                case 0:
                  val_amar_l = 99
                  break
                case 1:
                  val_amar_l = 95
                  break
                case 2:
                  val_amar_l = 87
                  break
                case 3:
                  val_amar_l = 78
                  break
                case 4:
                  val_amar_l = 61
                  break
                case 5:
                  val_amar_l = 45
                  break
                case 6:
                  val_amar_l = 31
                  break
                case 7:
                  val_amar_l = 19
                  break
                case 8:
                  val_amar_l = 10
                  break
                case 9:
                  val_amar_l = 6
                  break
                case 10:
                  val_amar_l = 4
                  break
                case 11:
                case 12:
                  val_amar_l = 2
                  break
                default:
                  val_amar_l = 1
              }

              switch (
                items.testsResultsTpm.scoreBase.amar_m -
                items.testsResultsTpm.scoreBase.amar_l
              ) {
                case 0:
                  val_menosML = 54
                  break
                case 1:
                  val_menosML = 65
                  break
                case 2:
                  val_menosML = 71
                  break
                case 3:
                  val_menosML = 78
                  break
                case 4:
                  val_menosML = 87
                  break
                case 5:
                  val_menosML = 90
                  break
                case 6:
                  val_menosML = 95
                  break
                case 7:
                  val_menosML = 97
                  break
                case 8:
                case 9:
                  val_menosML = 98
                  break
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                  val_menosML = 99
                  break
                case -1:
                  val_menosML = 45
                  break
                case -2:
                  val_menosML = 34
                  break
                case -3:
                  val_menosML = 28
                  break
                case -4:
                  val_menosML = 21
                  break
                case -5:
                  val_menosML = 14
                  break
                case -6:
                  val_menosML = 10
                  break
                case -7:
                  val_menosML = 6
                  break
                case -8:
                  val_menosML = 5
                  break
                case -9:
                  val_menosML = 4
                  break
                case -11:
                case -10:
                  val_menosML = 2
                  break
                case -19:
                case -18:
                case -17:
                case -16:
                case -15:
                case -14:
                case -13:
                case -12:
                  val_menosML = 1
                  break
                default:
                  break
              }

              outFactor.push({
                factorName: items.factor.name,
                valueAmar_m: val_amar_m,
                m: val_amar_m,
                valueAmar_l: val_amar_l,
                l: val_amar_l,
                valueRestaML: val_menosML,
              })
            } else if (items.factorId === 47) {
              let val_rojo_m = '',
                val_rojo_l = '',
                val_menosML = ''

              switch (items.testsResultsTpm.scoreBase.rojo_m) {
                case 0:
                  val_rojo_m = 5
                  break
                case 1:
                  val_rojo_m = 12
                  break
                case 2:
                  val_rojo_m = 19
                  break
                case 3:
                  val_rojo_m = 31
                  break
                case 4:
                  val_rojo_m = 41
                  break
                case 5:
                  val_rojo_m = 58
                  break
                case 6:
                  val_rojo_m = 68
                  break
                case 7:
                  val_rojo_m = 78
                  break
                case 8:
                  val_rojo_m = 85
                  break
                case 9:
                  val_rojo_m = 89
                  break
                case 10:
                  val_rojo_m = 94
                  break
                case 11:
                  val_rojo_m = 95
                  break
                case 12:
                case 13:
                  val_rojo_m = 98

                  break
                default:
                  val_rojo_m = 99
              }

              switch (items.testsResultsTpm.scoreBase.rojo_l) {
                case 0:
                  val_rojo_l = 99
                  break
                case 1:
                  val_rojo_l = 98
                  break
                case 2:
                  val_rojo_l = 94
                  break
                case 3:
                  val_rojo_l = 89
                  break
                case 4:
                  val_rojo_l = 78
                  break
                case 5:
                  val_rojo_l = 68
                  break
                case 6:
                  val_rojo_l = 61
                  break
                case 7:
                  val_rojo_l = 41
                  break
                case 8:
                  val_rojo_l = 31
                  break
                case 9:
                  val_rojo_l = 19
                  break
                case 10:
                  val_rojo_l = 12
                  break
                case 11:
                  val_rojo_l = 6
                  break
                case 12:
                  val_rojo_l = 4
                  break
                case 13:
                case 14:
                  val_rojo_l = 2
                  break
                default:
                  val_rojo_l = 1
              }

              switch (
                items.testsResultsTpm.scoreBase.rojo_m -
                items.testsResultsTpm.scoreBase.rojo_l
              ) {
                case 0:
                  val_menosML = 58
                  break
                case 1:
                  val_menosML = 65
                  break
                case 2:
                  val_menosML = 71
                  break
                case 3:
                  val_menosML = 78
                  break
                case 4:
                  val_menosML = 83
                  break
                case 5:
                  val_menosML = 85
                  break
                case 6:
                  val_menosML = 89
                  break
                case 7:
                  val_menosML = 91
                  break
                case 8:
                  val_menosML = 94
                  break
                case 9:
                  val_menosML = 95
                  break
                case 10:
                  val_menosML = 97
                  break
                case 11:
                case 12:
                  val_menosML = 98
                  break
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                  val_menosML = 99
                  break
                case -1:
                  val_menosML = 48
                  break
                case -2:
                  val_menosML = 41
                  break
                case -3:
                  val_menosML = 34
                  break
                case -4:
                  val_menosML = 31
                  break
                case -5:
                  val_menosML = 24
                  break
                case -6:
                  val_menosML = 21
                  break
                case -7:
                  val_menosML = 14
                  break
                case -8:
                  val_menosML = 10
                  break
                case -9:
                  val_menosML = 8
                  break
                case -10:
                  val_menosML = 5
                  break
                case -11:
                  val_menosML = 4
                  break
                case -13:
                case -12:
                  val_menosML = 2
                  break
                case -19:
                case -18:
                case -16:
                case -15:
                case -14:
                  val_menosML = 1
                  break
                default:
                  break
              }

              outFactor.push({
                factorName: items.factor.name,
                valueRojo_m: val_rojo_m,
                m: val_rojo_m,
                valueRojo_l: val_rojo_l,
                l: val_rojo_l,
                valueRestaML: val_menosML,
              })
            } else if (items.factorId === 10) {
              let val_azul_m = '',
                val_azul_l = '',
                val_menosML = ''

              switch (items.testsResultsTpm.scoreBase.azul_m) {
                case 0:
                  val_azul_m = 1
                  break
                case 1:
                  val_azul_m = 8
                  break
                case 2:
                  val_azul_m = 19
                  break
                case 3:
                  val_azul_m = 34
                  break
                case 4:
                  val_azul_m = 54
                  break
                case 5:
                  val_azul_m = 75
                  break
                case 6:
                  val_azul_m = 85
                  break
                case 7:
                  val_azul_m = 93
                  break
                case 8:
                  val_azul_m = 95
                  break
                case 9:
                case 10:
                  val_azul_m = 98
                  break
                default:
                  val_azul_m = 99
              }

              switch (items.testsResultsTpm.scoreBase.azul_l) {
                case 0:
                  val_azul_l = 99
                  break
                case 1:
                  val_azul_l = 98
                  break
                case 2:
                  val_azul_l = 97
                  break
                case 3:
                  val_azul_l = 91
                  break
                case 4:
                  val_azul_l = 85
                  break
                case 5:
                  val_azul_l = 75
                  break
                case 6:
                  val_azul_l = 61
                  break
                case 7:
                  val_azul_l = 48
                  break
                case 8:
                  val_azul_l = 34
                  break
                case 9:
                  val_azul_l = 24
                  break
                case 10:
                  val_azul_l = 14
                  break
                case 11:
                  val_azul_l = 6
                  break
                case 12:
                  val_azul_l = 4
                  break
                case 13:
                  val_azul_l = 2
                  break
                default:
                  val_azul_l = 1
              }

              switch (
                items.testsResultsTpm.scoreBase.azul_m -
                items.testsResultsTpm.scoreBase.azul_l
              ) {
                case 0:
                  val_menosML = 71
                  break
                case 1:
                  val_menosML = 78
                  break
                case 2:
                  val_menosML = 85
                  break
                case 3:
                  val_menosML = 90
                  break
                case 4:
                  val_menosML = 93
                  break
                case 5:
                  val_menosML = 97
                  break
                case 6:
                case 7:
                  val_menosML = 98
                  break
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                  val_menosML = 99
                  break
                case -1:
                  val_menosML = 61
                  break
                case -2:
                  val_menosML = 54
                  break
                case -3:
                  val_menosML = 45
                  break
                case -4:
                  val_menosML = 38
                  break
                case -5:
                  val_menosML = 24
                  break
                case -6:
                  val_menosML = 19
                  break
                case -7:
                  val_menosML = 12
                  break
                case -8:
                  val_menosML = 9
                  break
                case -9:
                  val_menosML = 6
                  break
                case -10:
                  val_menosML = 5
                  break
                case -11:
                  val_menosML = 4
                  break
                case -13:
                case -12:
                  val_menosML = 2
                  break
                case -16:
                case -15:
                case -14:
                  val_menosML = 1
                  break
                default:
                  break
              }

              outFactor.push({
                factorName: items.factor.name,
                valueAzul_m: val_azul_m,
                m: val_azul_m,
                valueAzul_l: val_azul_l,
                l: val_azul_l,
                valueRestaML: val_menosML,
              })
            }
          })

          let R = [0, 1, 2, 3, 4],
            c = [0, 1, 2, 3, 4],
            t = [0, 1, 2, 3, 4],
            m = [0, 1, 2, 3, 4],
            q = [0, 1, 2, 3, 4, 5, 6, 7, 8],
            P = '',
            tem = '',
            tem2 = ''

          R[0] = ''

          c[0] = ''
          c[1] = outFactor[2].valueRestaML
          c[2] = outFactor[3].valueRestaML
          c[3] = outFactor[1].valueRestaML
          c[4] = outFactor[0].valueRestaML

          t[0] = ''
          t[1] = 'd'
          t[2] = 'i'
          t[3] = 's'
          t[4] = 'c'

          q[0] = ''

          for (let I = 1; I <= 4; I++) {
            for (let j = I; j <= 3; j++) {
              if (c[I] < c[j + 1]) {
                tem = c[I]
                tem2 = t[I]
                c[I] = c[j + 1]
                t[I] = t[j + 1]
                c[j + 1] = tem
                t[j + 1] = tem2
              }
            }
          }

          if (c[1] === c[4]) {
            q[1] = 'd=c'
          } else {
            q[1] = t[1] + '/' + t[4]
          }

          q[2] = t[1] + '/' + t[3]
          q[3] = t[1] + '/' + t[2]
          R[1] = t[1]
          R[2] = t[4]

          m[1] = outFactor[2].m
          m[2] = outFactor[3].m
          m[3] = outFactor[1].m
          m[4] = outFactor[0].m
          t[1] = 'd'
          t[2] = 'i'
          t[3] = 's'
          t[4] = 'c'

          for (let I = 1; I <= 4; I++) {
            for (let j = I; j <= 3; j++) {
              if (m[I] < m[j + 1]) {
                tem = m[I]
                tem2 = t[I]
                m[I] = m[j + 1]
                t[I] = t[j + 1]
                m[j + 1] = tem
                t[j + 1] = tem2
              }
            }
          }

          if (m[1] === m[4]) {
            if (q[1] !== 'd=c') {
              q[4] = 'd=c'
            }
          } else {
            q[4] = t[1] + '/' + t[4]
            if (q[1] === q[4] || q[2] === q[4] || q[3] === q[4]) {
              q[4] = ''
            }
          }

          t[1] = 'd'
          t[2] = 'i'
          t[3] = 's'
          t[4] = 'c'

          for (let I = 1; I <= 4; I++) {
            for (let j = I; j <= 3; j++) {
              if (P[I] < P[j + 1]) {
                tem = P[I]
                tem2 = t[I]
                P[I] = P[j + 1]
                t[I] = t[j + 1]
                P[j + 1] = tem
                t[j + 1] = tem2
              }
            }
          }

          if (P[1] === P[4]) {
            if (q[1] !== 'd=c' && q[4] !== 'd=c') {
              q[5] = 'd=c'
            }
          } else {
            q[5] = t[1] + '/' + t[4]
            if (
              q[1] === q[5] ||
              q[2] === q[5] ||
              q[3] === q[5] ||
              q[4] === q[5]
            ) {
              q[5] = ''
            }
          }

          R[3] = t[1]
          R[4] = t[4]

          if (c[1] > 89 && c[4] > 89) {
            q[6] = 'd alta y c alta'
          }

          if (c[1] < 11 && c[4] < 11) {
            q[6] = 'd baja y c baja'
          }

          if (m[1] > 89 && m[4] > 89) {
            q[7] = 'd alta y c alta'

            if (q[6] === q[7]) {
              q[7] = ''
            }
          }

          if (m[1] < 11 && m[4] < 11) {
            q[7] = 'd baja y c baja'
            if (q[6] === q[7]) {
              q[7] = ''
            }
          }

          if (P[1] > 89 && P[4] > 89) {
            q[8] = 'd alta y c alta'
            if (q[6] === q[8] || q[7] === q[8]) {
              q[8] = ''
            }
          }

          if (P[1] < 11 && P[4] < 11) {
            q[8] = 'd baja y c baja'
            if (q[6] === q[8] || q[7] === q[8]) {
              q[8] = ''
            }
          }

          let outTitle = '',
            outText = ''

          for (let I = 1; I <= 8; I++) {
            switch (q[I]) {
              case 'd/i':
                outTitle = 'CREATIVIDAD: '
                outText =
                  'Tiende a ser lógico, critico e incisivo en sus enfoques hacia la obtención de metas. Se sentirá retado por problemas que requieren esfuerzos de análisis y originalidad. Será llano y critico con la gente.'
                break
              case 'd/s':
                outTitle = 'EMPUJE: '
                outText =
                  'Responde rápidamente a los retos, demuestra movilidad y flexibilidad en sus enfoques, tiende a ser iniciador versátil, respondiendo rápidamente a la competencia.'
                break
              case 'd/c':
                outTitle = 'INDIVIDUALIDAD: '
                outText =
                  ' Actúa de manera directa y positiva ante la oposición. Es una persona fuerte que toma posición y lucha por mantenerla. Esta dispuesto a tomar riesgos y puede aun ignorar niveles jerárquicos.'
                break
              case 'i/d':
                outTitle = 'BUENA VOLUNTAD: '
                outText =
                  'Tiende a comportarse en una forma equilibrada y cordial, desplegando "agresividad social" en situaciones que percibe como favorables y sin amenazas. Tiende a mostrarse simpático y lucha por establecer relaciones armoniosas con la gente desde el primer contacto.'
                break
              case 'i/s':
                outTitle = 'HABILIDAD DE CONTACTO: '
                outText =
                  'Tiende a buscar a la gente con entusiasmo y chispa, es una persona abierta que despliega un optimismo contagioso y trata de ganarse a la gente a través de la persuasión de un acercamiento emotivo.'
                break
              case 'i/c':
                outTitle = 'CONFIANZA EN SÍ MISMO: '
                outText =
                  'Despliega confianza en sí  mismo en la mayoría de sus tratos con otras personas. Aunque siempre lucha por ganarse a la gente, se muestra reacio a ceder su punto de vista. Esta persona siente que no importa la situación presente, el será capaz de actuar de forma éxitosa.'
                break
              case 's/d':
                outTitle = 'PACIENCIA: '
                outText =
                  'Tiende a ser constante y consistente, prefiriendo tratar un proyecto o tarea a la vez. En general, esta persona dirigirá sus habilidades y experiencias, hacia áreas que requieren profundización y especialización. Ecuánime bajo presión, busca estabilizar su ambiente y reacciona negativamente a los cambios en el mismo.'
                break
              case 's/i':
                outTitle = 'CONCENTRACION: '
                outText =
                  'Tiende a ser un individuo controlado y paciente. Se mueve con moderación y premeditación en la mayoría de las situaciones, con cuidado y concentración.'
                break
              case 's/c':
                outTitle = 'PERSISTENCIA: '
                outText =
                  'Tiende a ser un individuo persistente y perseverante, que una vez que decide algo, no fácilmente se desvía de su objetivo. Tenderá a tomar un ritmo de trabajo y a apegarse a él. Puede ser rígido e independiente cuando se aplica la fuerza para hacerle cambiar; exasperando a otros que requieran de su adaptación.'
                break
              case 'c/d':
                outTitle = 'ADAPTABILIDAD: '
                outText =
                  'Tiende a actuar de una forma cuidadosa y conservadora en general. Está dispuesto a modificar o transigir en su posición con el objeto de lograr sus objetivos. Siendo un estricto observador de las políticas, puede aparecer arbitrario y poco flexible al seguir una regla o fórmula establecida. Prefiere una atmósfera libre de antagonismos y desea la armonía.'
                break
              case 'c/i':
                outTitle = 'PERFECCIONISMO: '
                outText =
                  'Esta persona tiende a ser un seguidor apegado del orden y los sistemas. Toma decisiones basadas en hechos conocidos o procedimientos establecidos. En todas sus actividades, trata meticulosamente de apegarse a los estándares establecidos, ya sea por sí mismo o por los demás.'
                break
              case 'c/s':
                outTitle = 'SENSIBILIDAD: '
                outText =
                  'Esta persona estará muy consciente en evitar riesgos o problemas. Tiende a buscar significados ocultos. La tensión puede ser evidente particularmente si esta bajo presión por obtener resultados. En general se sentirá intranquilo mientras no tenga una confirmación absoluta, de que su decisión ha sido la correcta.'
                break
              case 'd=c':
                outTitle = 'AMBIVALENCIA: '
                outText =
                  'Cuando un empuje activo y un apego a regla igualmente activo desencadenados por un mismo estímulo, aparecen al mismo tiempo, tienden a cancelarse entre sí, o al menos a modificarse a sí mismos.'
                break
              case 'd alta y c alta':
                outTitle = ''
                outText =
                  'Ya que este individuo busca una igualdad entre la fuerza para obtener resultados y la calidad de los mismos, es visto con frecuencia como un perfeccionista. No busca "una respuesta" a los problemas, sino que desea alcanzar "la mejor respuesta". Podría sin embargo indicar, que el individuo tiene dificultad en tomar la mayoría de sus decisiones. Los signos externos de esta situación son tensión y vacilación.'
                break
              case 'd baja y c baja':
                outTitle = ''
                outText =
                  'Esta persona tiende a resistirse a las peticiones de adaptación. Prefiere operar solo, pero no luchara por su independencia se mostrará generalmente reacio a seguir sugerencias de otros, aunque pueda no tener ninguna otra idea que proponer.'
                break
              default:
                outTitle = ''
                outText = ''
            }

            textTitle.push({ title: outTitle, text: outText })
          }

          let textTitle2_1 = '',
            textTitle2_2 = '',
            textTitle2_3 = '',
            textTitle2_4 = ''

          switch (R[1]) {
            case 'd':
              textTitle2_1 =
                'La persona con ALTO GRADO DE EMPUJE DESEA: poder, autoridad; posición y prestigio; dinero y cosas materiales; retos; oportunidad de avance; logros, resultados; el saber "por qué"; amplio margen para operar; respuestas directas; libertad de controles, supervisión y detalle; eficiencia en la operación; actividades nuevas y variadas. La persona con alto grado de empuje NECESITA: compromisos negociados de igual a igual; identificación con la compañía; desarrollar valores intrínsecos; aprender a tomar su paso y relajarse; tareas difíciles; saber los resultados esperados; entender a las personas, enfoque lógico; empatía; técnicas basadas en experiencias prácticas; conciencia de que las sanciones existen; "sacudidas ocasionales"'
              break
            case 'i':
              textTitle2_1 =
                'La persona con ALTO GRADO DE INFLUENCIA DESEA: popularidad, reconocimiento social; recompensas monetarias para mantener su ritmo de vida; reconocimiento público que indique su habilidad; libertad de palabra, personas con quienes hablar; condiciones favorables de trabajo; actividades con gente fuera del trabajo; relaciones democráticas; libertad de control y detalles; ingreso psicológico; identificación con la compañía. La persona con alto grado de influencia NECESITA: control de su tiempo; objetividad; énfasis en la utilidad de su empresa; ser menos idealista; un supervisor democrático con quien pueda asociarse; presentarlo como gente influyente; control emocional; sentido de urgencia; control de su desempeño por proyectos; confianza en el producto; datos analizados; administración financiera personal; supervisión más estricta; presentación precisa.'
              break
            case 's':
              textTitle2_1 =
                'La persona con ALTO GRADO DE CONSTANCIA DESEA: estatus; situación segura; referencias; vida hogareña; procedimientos usuales; sinceridad; territorio limitado; largo tiempo para ajustarse; apreciación constante; identificación constante; reconocimiento por servicios continuos; productos especiales. La persona con alto grado de constancia NECESITA: condicionamiento anterior al cambio; recompensas en términos de cosas; beneficios adicionales; presentación a nuevos grupos; esposa(o) que los respalde; métodos que ahorren trabajo; enfoques profundos; presentaciones comprimidas; reafirmación; sentimiento de importancia; productos de calidad que lo satisfagan; mercancias especiales; asociados capaces.'
              break
            case 'c':
              textTitle2_1 =
                'La persona con un ALTO GRADO DE CUMPLIMIENTO DESEA: procedimientos estandarizados de operación; límite en el grado de exposición a otros ambientes; seguridad (protección), ambiente protegido; referencias; reafirmación; cambios poco rápidos o abruptos; ser parte de un grupo; atención personal; poca responsabilidad; personas a su servicio. La persona con un alto grado de cumplimiento NECESITA: trabajo de precisión; planeación; más confianza; más ángulos y mayor perspectiva en sus enfoques; argumentos que refuten; soporte en las situaciones difíciles; explicaciones y más explicaciones; participación de equipo; recompensas en términos de cosas finas; descripción exacta del trabajo; presentación de personas; ayuda para ser más independiente; menos atención a detalles; respeto a sí mismo.'
              break
            default:
              break
          }

          switch (R[2]) {
            case 'd':
              textTitle2_2 =
                'La persona con BAJO GRADO DE EMPUJE DESEA: paz; protección; dirección; ambiente predecible; un líder a quien seguir; un plan que comprenda; métodos; verse libre de conflictos; tiempo para pensar; un futuro seguro. La persona con bajo grado de empuje NECESITA: tareas claras; sanciones por parte de su jefe o del manual; ayuda en tareas nuevas o difíciles; una forma de decir que "no"; métodos alternativos; apoyo en situaciones difíciles; técnicas y herramientas para manejar conflictos (armas); un clima participativo (grupos o comités); reconocimiento por el precio pagado por "desempeñarse"; métodos para traducir ideas en acciones; "sacudidas ocasionales"'
              break
            case 'i':
              textTitle2_2 =
                'La persona con BAJO GRADO DE INFLUENCIA DESEA: que se le deje solo; un formato lógico; hechos; actividades sociales limitadas; respeto; conversación directa; enigmas que resolver; equipo para operar; experiencias emotivas limitadas; objetividad. La persona con bajo grado de influencia NECESITA: habilidades sociales; contactos con la gente; reconocimiento de los sentimientos de los demás; un jefe objetivo; respuestas lógicas; la oportunidad para hacer preguntas; sinceridad, ninguna sofisticación; suavizar las asperezas; tiempo para pensar; retroalimentación (feedback) de los demás.'
              break
            case 's':
              textTitle2_2 =
                'La persona con un BAJO GRADO DE CONSTANCIA DESEA: variedad; viajes; trabajo de generalista; nuevos entornos en los cuales trabajar/jugar; una amplia gama; libertad de rutina; enfoques de gran imagen; tropas de apoyo que terminen la labor; más tiempo en el día; actividades extracurriculares. La persona con un bajo grado de constancia NECESITA: aprender a establecer un paso razonable; vacaciones; exámenes médicos anuales; apreciación de la gente que se mueve más lentamente que ellos; respeto a las prerrogativas y propiedades personales; fechas definidas para terminación; presupuestos; consistencia; reanudar lo andado, recapitular; sistemas.'
              break
            case 'c':
              textTitle2_2 =
                'La persona con un BAJO GRADO DE CUMPLIMIENTO DESEA: libertad; tareas excepcionales; independencia; cero restricciones; ser evaluado por resultados; cero supervision; la oportunidad para divertirse; experiencias; ventilación; emociones fuertes. La persona con un bajo grado de cumplimiento NECESITA: un jefe tolerante; pólizas de seguro de vida, de enfermedades y de accidentes; reconocer que existen límites (y por qué); ser evaluado por resultados; oportunidad para probar lo nunca antes intentado; ayuda con los detalles; documentaciones; proyectos independientes; autoridad; restricciones.'
              break
            default:
              break
          }

          switch (R[3]) {
            case 'd':
              textTitle2_3 =
                'BAJO PRESIÓN una persona con un ALTO GRADO DE EMPUJE tiende a: excederse en sus prerrogativas; actuar intrépidamente; inspirar temor en los demás; imponerse a la gente; ser cortante y sarcástico con los demás; malhumorarse cuando no tiene el primer lugar; ser crítico y buscar errores; descuidar los "detalles"; mostrarse impaciente y descontento con el trabajo de rutina; resistirse a participar como parte de un grupo.'
              break
            case 'i':
              textTitle2_3 =
                'BAJO PRESIÓN una persona con un ALTO GRADO DE INFLUENCIA tiende a: preocuparse más de su popularidad que de los resultados tangibles; ser exageradamente persuasivo; actuar impulsivamente, siguiendo su corazón en lugar de su inteligencia; ser inconsistente en sus conclusiones; tomar decisiones basado en análisis superficiales; ser poco realista al evaluar a las personas; ser descuidado con los detalles; confiar en las personas indiscriminadamente; tener dificultad para planear y controlar su tiempo; ser superficial.'
              break
            case 's':
              textTitle2_3 =
                'BAJO PRESIÓN una persona con un ALTO GRADO DE CONSTANCIA tiende a: hacer un esfuerzo para mantener el "statuo quo"; requerir mucho tiempo para ajustarse al cambio; tener dificultades para cumplir con compromisos; necesitar ayuda para cumplir con compromisos; carecer de imaginación; sentirse contento y cómodo con las cosas tal y como son; continuar haciendo las cosas en la forma en que se han hecho; conservar resentimientos; esperar órdenes antes de actuar; tener dificultad para establecer prioridades.'
              break
            case 'c':
              textTitle2_3 =
                'BAJO PRESIÓN una persona con un ALTO GRADO DE CUMPLIMIENTO tiende a: depender de la supervisión; dudar antes de actuar sin precedente; estar atado a procedimientos y métodos; dejarse abrumar por los detalles; resistirse a aceptar responsabilidad plena; desear explicaciones completas antes de hacer un cambio; pasar la responsabilidad a otra persona; renunciar a su posición para evitar controversias; ponerse a la defensiva al verse amenazado; ser sugestionable y fácilmente dirigido.'
              break
            default:
              break
          }

          switch (R[4]) {
            case 'd':
              textTitle2_4 =
                'BAJO PRESIÓN una persona con un BAJO GRADO DE EMPUJE tiende a: renunciar a su posición para evitar controversias; dudar como actuar en problemas; autodespreciarse; evitar responsabilidad; retraerse cuando se le confronta; ser defensivo; permite que se aprovechen de él indebidamente; ser dependiente; ser demasiado conservador; ser evasivo.'
              break
            case 'i':
              textTitle2_4 =
                'BAJO PRESIÓN una persona con un BAJO GRADO DE INFLUENCIA tiende a: ser distante; ser cortante; ser crítico; ser suspicaz; carecer de empatía; lastimar los sentimientos de los demás; ser retraído; ser ecuánime, frío; preferir los objetos a la gente; carecer de confianza social.'
              break
            case 's':
              textTitle2_4 =
                'BAJO PRESIÓN una persona con un BAJO GRADO DE CONSTANCIA tiende a: ser inconsistente; dejar inconcluso lo que empieza; dedicarse a demasiadas actividades al mismo tiempo; tratar de abarcar demasiado; hacer cambios drásticos frecuentemente, especialmente al inicio de su carrera; ser perturbador; difícil de localizar; tener problemas de familia y/o salud; viajar extensa y costosamente; faltar al respeto de la propiedad o territorio de los demás.'
              break
            case 'c':
              textTitle2_4 =
                'BAJO PRESIÓN una persona con un BAJO GRADO DE CUMPLIMIENTO tiende a: ser poco convencional; ignorar las instrucciones y direcciones; ser descuidado con los detalles; desafiar al peligro; no documentarse; ser propenso a los accidentes; ignorar la política; provocar úlceras (en los demás); ser temerario; ser distraído.'
              break
            default:
              break
          }

          textTitleP2.push(
            { text: textTitle2_1 },
            { text: textTitle2_2 },
            { text: textTitle2_3 },
            { text: textTitle2_4 }
          )

          setRowsTextTitleCL1(textTitle)
          setRowsTextTitleCL2(textTitleP2)
        } else if (props.id.testId === 14) {
          setNamePrueba('Test de Inteligencia Nivel Medio (IQ)')
          const testFactor = response.data.testFactor

          let $l4, $LL4
          let $p1 = [
            testFactor.find((f) => f.factorId === 101).testsResultsTpm.score,
            testFactor.find((f) => f.factorId === 36).testsResultsTpm.score,
            testFactor.find((f) => f.factorId === 179).testsResultsTpm.score,
            testFactor.find((f) => f.factorId === 177).testsResultsTpm.score,
            testFactor.find((f) => f.factorId === 13).testsResultsTpm.score,
            testFactor.find((f) => f.factorId === 119).testsResultsTpm.score,
            testFactor.find((f) => f.factorId === 9).testsResultsTpm.score,
            testFactor.find((f) => f.factorId === 140).testsResultsTpm.score,
            testFactor.find((f) => f.factorId === 29).testsResultsTpm.score,
            testFactor.find((f) => f.factorId === 178).testsResultsTpm.score,
            testFactor.find((f) => f.factorId === 31).testsResultsTpm.scoreTC,
            testFactor.find((f) => f.factorId === 31).testsResultsTpm.scoreBase,
          ]

          /*if ($p1[3]<0){
          $p1[3]=number_format(0,2);
          }

          if ($p1[6]<0){
          $p1[6]=number_format(0,2);
          }*/

          const $eq1 = (($p1[10] * 10) / 130).toFixed(2)
          const $eq2 = (($p1[10] * 20) / 130).toFixed(2)
          const $eq3 = (($p1[10] * 30) / 130).toFixed(2)

          switch (true) {
            case $p1[10] >= 0 && $p1[10] <= 70:
              $l4 = 'MUY BAJO'
              break
            case $p1[10] >= 71 && $p1[10] <= 79:
              $l4 = 'BAJO'
              break
            case $p1[10] >= 80 && $p1[10] <= 89:
              $l4 = 'PROMEDIO BAJO'
              break
            case $p1[10] >= 90 && $p1[10] <= 100:
              $l4 = 'PROMEDIO'
              break
            case $p1[10] >= 101 && $p1[10] <= 110:
              $l4 = 'PROMEDIO ALTO'
              break
            case $p1[10] >= 111 && $p1[10] <= 120:
              $l4 = 'ALTO'
              break
            case $p1[10] >= 121 && $p1[10] <= 140:
              $l4 = 'MUY ALTO'
              break
            default:
              break
          }

          switch (true) {
            case $p1[11] >= 0 && $p1[11] <= 94:
              $LL4 = 'EXCESIVAMENTE DEFICIENTE'
              break
            case $p1[11] >= 95 && $p1[11] <= 101:
              $LL4 = 'DEFICIENTE'
              break
            case $p1[11] >= 102 && $p1[11] <= 122:
              $LL4 = 'MEDIA INFERIOR'
              break
            case $p1[11] >= 123 && $p1[11] <= 136:
              $LL4 = 'MEDIA'
              break
            case $p1[11] >= 137 && $p1[11] <= 150:
              $LL4 = 'MEDIA SUPERIOR'
              break
            case $p1[11] >= 151 && $p1[11] <= 171:
              $LL4 = 'SUPERIOR'
              break
            case $p1[11] >= 172 && $p1[11] <= 186:
              $LL4 = 'EXCEPCIONAL'
              break
            case $p1[11] > 186:
              $LL4 = 'EXCEPCIONAL +'
              break
            default:
              break
          }

          const $t = `<p align="justify"><strong> (1).- INFORMACIÓN Y CONOCIMIENTOS:</strong>  En Cultura y conocimientos generales, refleja la idea y experiencia que ha aprendido y organizado. Memoria mediata (largo plazo). Tiene un <font color="blue">${
            $p1[0]
          }%</font></p>
          <p align= "justify"><strong> (2).- COMPRENSIÓN:</strong>  En juicio y sentido común, manejo de la realidad. Tiene un <font color="blue">${
            $p1[1]
          }%</font></p>
          <p align="justify"><strong> (3).- SIGNIFICADO DE PALABRAS:</strong>  En capacidad de análisis y síntesis en la formación de conceptos verbales. Cultura general. Tiene un <font color="blue">${
            $p1[2]
          }%</font></p>
          <p align="justify"><strong> (4).- SELECCCIÓN LÓGICA:</strong>  En capacidad para razonar y deducir lógicamente conceptos verbales de acuerdo a la experiencia. Capacidad de abstracción. Tiene un <font color="blue">${
            $p1[3]
          }%</font></p>
          <p align="justify"><strong> (5).- ARITMETICA:</strong>  En razonamiento y manejo de aspectos cuantitativos y conocimientos adquiridos. Tiene un <font color="blue">${
            $p1[4]
          }%</font></p>
          <p align="justify"><strong> (6).- JUICIO PRÁCTICO:</strong>  En capacidad para captar situaciones de la vida diaria con sentido común. Tiene un <font color="blue">${
            $p1[5]
          }%</font></p>
          <p align="justify"><strong> (7).- ANALOGÍAS:</strong>  En la habilidad para comprender conceptos expresados en palabras. Habilidad para razonar, abstraer, generalizar y pensar en forma organizada. Tiene un <font color="blue">${
            $p1[6]
          }%</font></p>
          <p align="justify"><strong> (8).- ORDENAMIENTO DE FRASES:</strong>  En planeación, comprensión y organización de conceptos verbales y atención a pequeños detalles. Tiene un <font color="blue">${
            $p1[7]
          }%</font></p>
          <p align="justify"><strong> (9).- CLASIFICACIÓN:</strong>  En discriminación lógica de conceptos verbales. Tiene un <font color="blue">${
            $p1[8]
          }%</font></p>
          <p align="justify"><strong> (10).- SERIACIÓN:</strong>  En análisis y síntesis en el manejo de aspectos cuantitativos. Capacidad de deducción. Tiene un <font color="blue">${
            $p1[9]
          }%</font></p>
          <p align="justify"> Su Coeficiente Intelectual Terman es <strong><font color="blue">${
            $p1[10]
          }</font></strong>, lo cual indica que es <strong><font color="blue">${$l4}</font></strong> y su CAPACIDAD DE APRENDIZAJE es <strong><font color="blue">${$LL4}</font></strong>
          </p>
          <strong>
            <p>EQUIVALENCIAS</p>
            <font color='blue'>
              <p>${($eq1 * 100) / 100}/10 </p>     
              <p>${($eq2 * 100) / 100}/20 </p>      
              <p>${($eq3 * 100) / 100}/30 </p>
            </font>
          </strong>`

          setTs($t)
        } else if (props.id.testId === 16) {
          setNamePrueba('Test de Ambiente laboral - Nivel Gerencial (MS-G)')
          const testFactor = response.data.testFactor

          const $r1 = parseInt(
            testFactor.find((f) => f.factorId === 89).testsResultsTpm.score
          )
          const $r2 = parseInt(
            testFactor.find((f) => f.factorId === 25).testsResultsTpm.score
          )
          const $r3 = parseInt(
            testFactor.find((f) => f.factorId === 26).testsResultsTpm.score
          )
          const $r4 = parseInt(
            testFactor.find((f) => f.factorId === 91).testsResultsTpm.score
          )
          const $r5 = parseInt(
            testFactor.find((f) => f.factorId === 188).testsResultsTpm.score
          )
          const $r6 = parseInt(
            testFactor.find((f) => f.factorId === 22).testsResultsTpm.score
          )

          const $hab = `<p align="justify">1.- El manejo y control de grupos de <b>${props.id.userNames}</b>`
          const $es = ' es '
          const $efic =
            ' y la eficacia con que propicia que el personal a su cargo cumpla con las actividades encomendadas está al '
          const $porc = '<font color="blue">%. <br> </font>'
          let $sp = ''

          switch (true) {
            case $r1 >= 0 && $r1 <= 21:
              $sp = '<font color="blue">deficiente</font>'
              break

            case $r1 >= 22 && $r1 <= 49:
              $sp = '<font color="blue">inferior al promedio</font>'
              break

            case $r1 >= 50 && $r1 <= 79:
              $sp = '<font color="blue">promedio</font>'
              break

            case $r1 >= 80 && $r1 <= 97:
              $sp = '<font color="blue">superior al promedio</font>'
              break

            case $r1 >= 98 && $r1 <= 100:
              $sp = '<font color="blue">muy superior al promedio</font>'
              break
            default:
              break
          }

          const $caj1 =
            $es + $sp + $efic + '<font color="blue">' + $r1 + '</font>' + $porc

          const $cap =
            '<br>2.- Su capacidad de decisión en las relaciones humanas'
          const $crit =
            ' y su criterio y toma de decisiones con respecto a la forma de interactuar con los demás está al '

          switch (true) {
            case $r2 >= 0 && $r2 <= 21:
              $sp = '<font color="blue">deficiente</font>'
              break

            case $r2 >= 22 && $r2 <= 49:
              $sp = '<font color="blue">inferior al promedio</font>'
              break

            case $r2 >= 50 && $r2 <= 79:
              $sp = '<font color="blue">promedio</font>'
              break

            case $r2 >= 80 && $r2 <= 97:
              $sp = '<font color="blue">superior al promedio</font>'
              break

            case $r2 >= 98 && $r2 <= 100:
              $sp = '<font color="blue">muy superior al promedio</font>'
              break
            default:
              break
          }

          const $caj2 =
            $cap +
            $es +
            $sp +
            $crit +
            '<font color="blue">' +
            $r2 +
            '</font>' +
            $porc
          const $evalu =
            '<br>3.- Su capacidad de evaluación de problemas interpersonales'
          const $cnflc =
            ' y su criterio y juicio con respecto a situaciones sociales que presentan conflicto o cierta problemática está al '

          switch (true) {
            case $r3 >= 0 && $r3 <= 21:
              $sp = '<font color="blue">deficiente</font>'
              break

            case $r3 >= 22 && $r3 <= 49:
              $sp = '<font color="blue">inferior al promedio</font>'
              break

            case $r3 >= 50 && $r3 <= 79:
              $sp = '<font color="blue">promedio</font>'
              break

            case $r3 >= 80 && $r3 <= 97:
              $sp = '<font color="blue">superior al promedio</font>'
              break

            case $r3 >= 98 && $r3 <= 100:
              $sp = '<font color="blue">muy superior al promedio</font>'
              break
            default:
              break
          }

          const $caj3 =
            $evalu +
            $es +
            $sp +
            $cnflc +
            '<font color="blue">' +
            $r3 +
            '</font>' +
            $porc

          const $intprsnl =
            '<br>4.- Su habilidad para establecer relaciones interpersonales'
          const $fcltds =
            ' y las facultades con que cuenta y que le permiten establecer contactos con los demás de una manera adaptativa y eficiente se encuentran al '

          switch (true) {
            case $r5 >= 0 && $r5 <= 21:
              $sp = '<font color="blue">deficiente</font>'
              break

            case $r5 >= 22 && $r5 <= 49:
              $sp = '<font color="blue">inferior al promedio</font>'
              break

            case $r5 >= 50 && $r5 <= 79:
              $sp = '<font color="blue">promedio</font>'
              break

            case $r5 >= 80 && $r5 <= 97:
              $sp = '<font color="blue">superior al promedio</font>'
              break

            case $r5 >= 98 && $r5 <= 100:
              $sp = '<font color="blue">muy superior al promedio</font>'
              break
            default:
              break
          }

          const $caj4 =
            $intprsnl +
            $es +
            $sp +
            $fcltds +
            '<font color="blue">' +
            $r5 +
            '</font>' +
            $porc

          switch (true) {
            case $r4 >= 0 && $r4 <= 21:
              $sp = '<font color="blue">deficiente</font>'
              break

            case $r4 >= 22 && $r4 <= 49:
              $sp = '<font color="blue">inferior al promedio</font>'
              break

            case $r4 >= 50 && $r4 <= 79:
              $sp = '<font color="blue">promedio</font>'
              break

            case $r4 >= 80 && $r4 <= 97:
              $sp = '<font color="blue">superior al promedio</font>'
              break

            case $r4 >= 98 && $r4 <= 100:
              $sp = '<font color="blue">muy superior al promedio</font>'
              break
            default:
              break
          }

          const $scomun =
            '<br>5.- Su sentido común y tacto en las relaciones interpersonales'
          const $llevar =
            ' y su capacidad de llevarse bien con los demás, manteniendo una conducta basada en el buen juicio y la lógica, aún ante dificultades o conflictos de las personas con quienes se relaciona la persona están al '

          const $caj5 =
            $scomun +
            $es +
            $sp +
            $llevar +
            '<font color="blue">' +
            $r4 +
            '</font>' +
            $porc

          switch (true) {
            case $r6 >= 0 && $r6 <= 19:
              $sp = '<font color="blue">DEFICIENTE</font>'
              break

            case $r6 >= 20 && $r6 <= 44:
              $sp = '<font color="blue">INFERIOR AL PROMEDIO</font>'
              break

            case $r6 >= 45 && $r6 <= 55:
              $sp = '<font color="blue">PROMEDIO BAJO</font>'
              break

            case $r6 >= 56 && $r6 <= 69:
              $sp = '<font color="blue">PROMEDIO</font>'
              break

            case $r6 >= 70 && $r6 <= 78:
              $sp = '<font color="blue">PROMEDIO ALTO</font>'
              break

            case $r6 >= 79 && $r6 <= 97:
              $sp = '<font color="blue">SUPERIOR AL PROMEDIO</font>'
              break

            case $r6 >= 98 && $r6 <= 100:
              $sp = '<font color="blue">MUY SUPERIOR</font>'
              break
            default:
              break
          }

          const $adaptcn = '<br>6.- Su capacidad de adaptación'
          //const $hll = ' y se halla al '
          const $caj6 = $adaptcn + $es + $sp

          //caja2.Text = " COMENTARIOS: "

          const $t =
            $hab + $caj1 + $caj2 + $caj3 + $caj4 + $caj5 + $caj6 + '</p>'

          setTs($t)
        } else if (props.id.testId === 17) {
          setNamePrueba('Inventario Profesional de Ventas (IPV)')
          const testFactor = response.data.testFactor

          const $DGV = parseInt(
            testFactor.find((f) => f.factorId === 58).testsResultsTpm.scoreBase
          )
          const $R = parseInt(
            testFactor.find((f) => f.factorId === 166).testsResultsTpm.scoreBase
          )
          const $a = parseInt(
            testFactor.find((f) => f.factorId === 8).testsResultsTpm.scoreBase
          )
          const $I = parseInt(
            testFactor.find((f) => f.factorId === 36).testsResultsTpm.scoreBase
          )
          const $II = parseInt(
            testFactor.find((f) => f.factorId === 5).testsResultsTpm.scoreBase
          )
          const $III = parseInt(
            testFactor.find((f) => f.factorId === 49).testsResultsTpm.scoreBase
          )
          const $IV = parseInt(
            testFactor.find((f) => f.factorId === 194).testsResultsTpm.scoreBase
          )
          const $V = parseInt(
            testFactor.find((f) => f.factorId === 33).testsResultsTpm.scoreBase
          )
          const $VI = parseInt(
            testFactor.find((f) => f.factorId === 59).testsResultsTpm.scoreBase
          )
          const $VII = parseInt(
            testFactor.find((f) => f.factorId === 175).testsResultsTpm.scoreBase
          )
          const $VIII = parseInt(
            testFactor.find((f) => f.factorId === 2).testsResultsTpm.scoreBase
          )
          const $IX = parseInt(
            testFactor.find((f) => f.factorId === 183).testsResultsTpm.scoreBase
          )

          let $ta = '',
            $tb = '',
            $tc = '',
            $te = '',
            $tf = '',
            $tg = '',
            $th = '',
            $ti = '',
            $tl = '',
            $tm = '',
            $tn = '',
            $too = ''

          switch ($DGV) {
            case 1:
            case 2:
            case 3:
              $ta = `<p align="justify"><font color="blue"><strong>DGV:</strong> Muestra pocos rasgos de personalidad acordes con actividades comerciales.  Su facilidad para establecer en la venta relaciones con los demás, está por debajo del promedio.</font></p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $ta = `<p align="justify"><font color="blue"><strong>DGV:</strong> Posee facilidad para establecer relaciones con los demás, muestra un matiz de combatividad para persuadir al cliente, pero al mismo tiempo control de sí mismo. Cuenta con rasgos de personalidad acordes con actividades comerciales.</font></p>`
              break
            case 8:
            case 9:
            case 10:
              $ta = `<p align="justify"><font color="blue"><strong>DGV:</strong> El índice de disposición general para la venta es mayor al promedio. Tiene gran facilidad para establecer en la venta relaciones con los demás, muestra combatividad para elevar las ventas o persuadir al cliente, pero al mismo tiempo control suficiente de sí mismo. Cuenta con rasgos de personalidad acordes con actividades comerciales.</font></p>`
              break
            default:
              break
          }

          switch ($R) {
            case 1:
            case 2:
            case 3:
              $tb = `<p align="justify"><strong>R. RECEPTIVIDAD:</strong> En cuanto cualidades empáticas está por debajo del promedio y su capacidad de adaptación a situaciones y personas diferentes, es baja, quizá sea poco tolerable a la frustración.</p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $tb = `<p align="justify"><strong>R. RECEPTIVIDAD:</strong> Sabe ponerse en el lugar de los demás, escuchar y comprender; cuenta con capacidad de  adaptación a personas y circunstancias; posee autocontrol y resistencia a la frustración.</p>`
              break
            case 8:
            case 9:
            case 10:
              $tb = `<p align="justify"><strong>R. RECEPTIVIDAD:</strong> Cuenta excelentes cualidades empáticas, mayor al promedio, sabe ponerse en el lugar de los demás, escucha y comprende. Cuenta con capacidad de  adaptación rápida y fácil a situaciones y personas diferentes, lo cual implica capacidad de control de sí y resistencia a la frustración.</p>`
              break
            default:
              break
          }

          switch ($a) {
            case 1:
            case 2:
            case 3:
              $tc = `<p align="justify"><strong>A. AGRESIVIDAD:</strong> Muestra un índice de agresividad comercial menor que el promedio. Es un tanto activo y dinámico. Cuenta con cierta capacidad para soportar situaciones conflictivas. Tiene una actitud de poder o ascendencia suficiente para dominar; puede ser capaz de enfrentar riesgos en caso necesario.</p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $tc = `<p align="justify"><strong>A. AGRESIVIDAD:</strong> Es capaz de soportar situaciones conflictivas o para provocarlas con el deseo de ganar; implica también actitud dominante, por poder o por ascendencia, es seguro de sí, no rechazan los riesgos.</p>`
              break
            case 8:
            case 9:
            case 10:
              $tc = `<p align="justify"><strong>A. AGRESIVIDAD:</strong> Cuenta con gran capacidad para soportar situaciones conflictivas o para provocarlas con el deseo de ganar; así también, una actitud dominante, por poder o por ascendencia, es seguro de sí, no rechaza los riesgos, es activo y dinámico. Posee muy buena capacidad para  apertura de mercados, acción competitiva ante otros clientes o productos.</p>`
              break
            default:
              break
          }

          switch ($I) {
            case 1:
            case 2:
            case 3:
              $te = `<p align="justify"><strong>I. COMPRENSIÓN:</strong> Posee empatía y objetividad en sus relaciones humanas por debajo del promedio, es intuitivo e integrador.</p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $te = `<p align="justify"><strong>I. COMPRENSIÓN:</strong> Es empático y objetivo en sus relaciones humanas, intuitivo y capaz de integrar en su contexto un suceso cualquiera.</p>`
              break
            case 8:
            case 9:
            case 10:
              $te = `<p align="justify"><strong>I. COMPRENSIÓN:</strong> Tiene gran empatía y objetividad en sus relaciones humanas, por arriba del promedio, muy intuitivo y capaz de integrar en su contexto un suceso cualquiera.</p>`
              break
            default:
              break
          }

          switch ($II) {
            case 1:
            case 2:
            case 3:
              $tf = `<p align="justify"><strong>II. ADAPTABILIDAD:</strong> Su habilidad para adaptarse a situaciones y personas está por debajo del promedio.</p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $tf = `<p align="justify"><strong>II. ADAPTABILIDAD:</strong> Cuenta habilidad para adaptarse fácil y rápidamente a situaciones y personas diferentes, flexible en sus actividades (intelectuales o de relación) y capaz de desempeñar su papel de cierto mimetismo.</p>`
              break
            case 8:
            case 9:
            case 10:
              $tf = `<p align="justify"><strong>II. ADAPTABILIDAD:</strong> Cuenta con excelente habilidad para adaptarse fácil y rápidamente a situaciones y personas diferentes, flexible en sus actividades (intelectuales o de relación) y capaz de desempeñar su papel de cierto mimetismo.</p>`
              break
            default:
              break
          }

          switch ($III) {
            case 1:
            case 2:
            case 3:
              $tg = `<p align="justify"><strong>III. CONTROL DE SI MISMO:</strong> Poco control de sí mismo, por debajo del promedio.</p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $tg = `<p align="justify"><strong>III. CONTROL DE SI MISMO:</strong> Es controlado, dueño de sí mismo, capaz de una buena administración de su potencial psicológico o físico; es organizado y perseverante; hábil para ocultar sentimientos.</p>`
              break
            case 8:
            case 9:
            case 10:
              $tg = `<p align="justify"><strong>III. CONTROL DE SI MISMO:</strong> Es controlado, por arriba del promedio, dueño de sí mismo y capaz de una buena administración de su potencial intelectual, psicológico o físico; es una persona organizada, perseverante y hábil para ocultar sentimientos.</p>`
              break
            default:
              break
          }

          switch ($IV) {
            case 1:
            case 2:
            case 3:
              $th = `<p align="justify"><strong>IV. TOLERANCIA A LA FRUSTRACIÓN:</strong> No soporta adecuadamente las acciones frustrantes, está por debajo del promedio.</p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $th = `<p align="justify"><strong>IV. TOLERANCIA A LA FRUSTRACIÓN:</strong> Soporta adecuadamente las acciones frustrantes, capaz de comprender los fracasos y no personaliza demasiado las situaciones en que se ve implicado.</p>`
              break
            case 8:
            case 9:
            case 10:
              $th = `<p align="justify"><strong>IV. TOLERANCIA A LA FRUSTRACIÓN:</strong> Soporta adecuadamente las acciones frustrantes, por arriba del promedio, capaz de comprender los fracasos y no personaliza las situaciones en que se ve implicado.</p>`
              break
            default:
              break
          }

          switch ($V) {
            case 1:
            case 2:
            case 3:
              $ti = `<p align="justify"><strong>V. COMBATIVIDAD:</strong> Le cuesta mucho trabajo soportar desacuerdos y no cuenta con suficiente agresividad comercial.</p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $ti = `<p align="justify"><strong>V. COMBATIVIDAD:</strong> Es capaz de entrar en conflicto y soportar los desacuerdos; se refiere a esa persona porfiada y polémica que ejemplifica lo que se llama agresividad comercial.</p>`
              break
            case 8:
            case 9:
            case 10:
              $ti = `<p align="justify"><strong>V. COMBATIVIDAD:</strong> Tiene gran capacidad de entrar en conflicto y soportar los desacuerdos (por arriba del promedio); se refiere a esa persona porfiada y polémica que ejemplifica lo que se llama agresividad comercial.</p>`
              break
            default:
              break
          }

          switch ($VI) {
            case 1:
            case 2:
            case 3:
              $tl = `<p align="justify"><strong>VI. DOMINANCIA:</strong> Tiene poca voluntad de dominio o de ganar, poco persuasivo.</p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $tl = `<p align="justify"><strong>VI. DOMINANCIA:</strong> Cuenta con voluntad de dominio, de ganar o manipular a los demás, persuasivo o cautivador en su entorno, dominante o con ascendencia, esa cualidad propia de personas de elevada jerarquía.</p>`
              break
            case 8:
            case 9:
            case 10:
              $tl = `<p align="justify"><strong>VI. DOMINANCIA:</strong> Posee una excelente voluntad de dominio, de ganar o manipular a los demás, muy persuasivo o cautivador en su entorno, dominante o con ascendencia, esa cualidad propia de personas de elevada jerarquía.</p>`
              break
            default:
              break
          }

          switch ($VII) {
            case 1:
            case 2:
            case 3:
              $tm = `<p align="justify"><strong>VII. SEGURIDAD:</strong> Inseguro, no goza con las situaciones novedosas e inesperadas; difícilmente se enfrentaría a riesgos.</p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $tm = `<p align="justify"><strong>VII. SEGURIDAD:</strong> Es seguro de sí mismo, le gustan las situaciones nuevas o inesperadas y es capaz de enfrentarse, si es necesario, a los riesgos.</p>`
              break
            case 8:
            case 9:
            case 10:
              $tm = `<p align="justify"><strong>VII. SEGURIDAD:</strong> Muy seguro de sí mismo, le gustan las situaciones nuevas o inesperadas y tiene gran capacidad de enfrentarse a los riesgos.</p>`
              break
            default:
              break
          }

          switch ($VIII) {
            case 1:
            case 2:
            case 3:
              $tn = `<p align="justify"><strong>VIII. ACTIVIDAD:</strong> Es una persona que no gusta de actividades deportivas; suele ser pasiva.</p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $tn = `<p align="justify"><strong>VIII. ACTIVIDAD:</strong> Es activo y dinámico en el sentido físico, soporta mal la pasividad e inactividad.</p>`
              break
            case 8:
            case 9:
            case 10:
              $tn = `<p align="justify"><strong>VIII. ACTIVIDAD:</strong> Es muy activo y dinámico en el sentido físico, que soporta mal la pasividad e inactividad.</p>`
              break
            default:
              break
          }

          switch ($IX) {
            case 1:
            case 2:
            case 3:
              $too = `<p align="justify"><strong>IX. SOCIABILIDAD:</strong> Es introvertido, poco capaz de crear nuevos contactos y convivir con los demás, gusta en ocasiones de la soledad.</p>`
              break
            case 4:
            case 5:
            case 6:
            case 7:
              $too = `<p align="justify"><strong>IX. SOCIABILIDAD:</strong> Es extravertido, capaz de crear nuevos contactos y convivir con los demás, que prefiere compañía a la soledad, y sensible a la importancia de la relaciones humanas.</p>`
              break
            case 8:
            case 9:
            case 10:
              $too = `<p align="justify"><strong>IX. SOCIABILIDAD:</strong> Este índice mayor que el promedio muestra que es extravertido, capaz de crear nuevos contactos y convivir con los demás, sensible a las relaciones humanas y quien prefiere compañía a la soledad.</p>`
              break
            default:
              break
          }

          const $t =
            $ta +
            $tb +
            $tc +
            $te +
            $tf +
            $tg +
            $th +
            $ti +
            $tl +
            $tm +
            $tn +
            $too

          setTs($t)
        } else if (props.id.testId === 18) {
          setNamePrueba('Prueba KOSTICK (KO)')

          const testFactor = response.data.testFactor
          let $t = ''

          const $N = testFactor.find((f) => f.factorId === 137).testsResultsTpm
            .scoreBase
          const $a = testFactor.find((f) => f.factorId === 42).testsResultsTpm
            .scoreBase
          const $P = testFactor.find((f) => f.factorId === 180).testsResultsTpm
            .scoreBase
          const $XX = testFactor.find((f) => f.factorId === 170).testsResultsTpm
            .scoreBase
          const $B = testFactor.find((f) => f.factorId === 98).testsResultsTpm
            .scoreBase
          const $O = testFactor.find((f) => f.factorId === 136).testsResultsTpm
            .scoreBase
          const $Z = testFactor.find((f) => f.factorId === 129).testsResultsTpm
            .scoreBase
          const $K = testFactor.find((f) => f.factorId === 146).testsResultsTpm
            .scoreBase
          const $F = testFactor.find((f) => f.factorId === 164).testsResultsTpm
            .scoreBase
          const $W = testFactor.find((f) => f.factorId === 174).testsResultsTpm
            .scoreBase
          const $G = testFactor.find((f) => f.factorId === 138).testsResultsTpm
            .scoreBase
          const $L = testFactor.find((f) => f.factorId === 181).testsResultsTpm
            .scoreBase
          const $I = testFactor.find((f) => f.factorId === 120).testsResultsTpm
            .scoreBase
          const $T = testFactor.find((f) => f.factorId === 134).testsResultsTpm
            .scoreBase
          const $V = testFactor.find((f) => f.factorId === 196).testsResultsTpm
            .scoreBase
          const $S = testFactor.find((f) => f.factorId === 171).testsResultsTpm
            .scoreBase
          const $R = testFactor.find((f) => f.factorId === 152).testsResultsTpm
            .scoreBase
          const $D = testFactor.find((f) => f.factorId === 135).testsResultsTpm
            .scoreBase
          const $c = testFactor.find((f) => f.factorId === 56).testsResultsTpm
            .scoreBase
          const $E = testFactor.find((f) => f.factorId === 84).testsResultsTpm
            .scoreBase

          $t += '<p align="justify"><strong>NECESIDADES...</strong></p>'

          switch ($N) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>SIN NECESIDAD DE TERMINAR LA TAREA:</strong> Tiene poca necesidad para personalmente perseverar en tareas, es capaz de manejar varias tareas a la vez.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>NECESIDAD DE TERMINAR LA TAREA:</strong> Puede haber tenido dificultad en manejar varios trabajos simultáneamente, y en delegar. Tiene tendencia a ser obstinado. Se siente comprometido a terminar su tarea y piensa que todos los demás deben sentir de la misma manera.</p>'
              break
            default:
              break
          }

          switch ($a) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>SIN NECESIDAD DE LOGRO:</strong> Por alguna razón sus enfoques no son lo suficientemente altos. Ya sea que no desea ser sobresalir, o que está contento con el nivel en que está. Siente que la satisfacción y el no preocuparse son más importantes que la competencia.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>NECESIDAD DE LOGRO:</strong> Tiende a ser un autoiniciador, competitivo, conseguidor de altos estándares para sí mismo y para otros.</p>'
              break
            default:
              break
          }

          switch ($P) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>SIN NECESIDAD DE CONTROLAR A OTROS:</strong> El no obtiene suficiente recompensa interna en sus esfuerzos de liderazgo. No necesita controlar a otros.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>NECESIDAD DE CONTROLAR A OTROS:</strong> Tiene necesidad de controlar a otros.</p>'
              break
            default:
              break
          }

          switch ($XX) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>SIN NECESIDAD DE SER TOMADO EN CUENTA:</strong> Reservado y tímido. El no se mueve con facilidad entre los grupos.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>NECESIDAD DE SER TOMADO EN CUENTA:</strong> El tiende a dar mucha atención a estilos, efectos e impresionar más que dar pleno esfuerzo en ser efectivo. Gusta de hacer cosas para exhibirse, se interesa por el estilo, entusiasmo y respuesta del grupo. Es extrovertido en sus expresiones de sentimientos.</p>'
              break
            default:
              break
          }

          switch ($B) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>SIN NECESIDAD DE PERTENECER A GRUPOS:</strong> Puede no ser lo suficientemente sensible a las necesidades y actitudes del grupo. Se ve a sí mismo como una persona individualista con una mentalidad propia.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>NECESIDAD DE PERTENECER A GRUPOS:</strong> Es altamente sensible al grupo con posiblemente mucha dependencia, conformidad y lealtad a esto. Fuertemente influenciado por las opiniones y actitudes del grupo.</p>'
              break
            default:
              break
          }

          switch ($O) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>SIN NECESIDAD DE AFINIDAD Y AFECTO:</strong> Se siente más a gusto cuando no hay gente a su alrededor y mantiene relaciones frías y formales.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>NECESIDAD DE AFINIDAD Y AFECTO:</strong> Su alta necesidad de interacción e investidura emocional puede alejarlo del camino de un ejecutivo eficaz. Siente una gran necesidad de establecer una relación íntima y personal con otros y se impacienta si no es correspondido.</p>'
              break
            default:
              break
          }

          switch ($Z) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>SIN NECESIDAD DE CAMBIO:</strong> Está muy satisfecho con las doctrinas establecidas (especialmente con un bajo A). Puede tener dificultad en aceptar nuevas ideas. Se siente amenazado por cambios y confía en lo ya establecido.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>NECESIDAD DE CAMBIO:</strong> Es inquieto, busca nuevas amistades, nuevas cosas y cuando pertenece a un grupo trata de cambiar al grupo.</p>'
              break
            default:
              break
          }

          switch ($K) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>SIN NECESIDAD DE SER AGRESIVO A LA DEFENSIVA:</strong> Carece de suficiente sentido emocional hacia los sentimientos hacia otras personas. Tiende a evitar conflictos en lugar de tratar de resolverlos. Permanece apartado e ignora las fuerzas útiles del conflicto entre personas.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>NECESIDAD DE SER AGRESIVO A LA DEFENSIVA:</strong> Puede ser muy defensivo acerca de sí mismo, muy quisquilloso y muy punitivo hacia otros. Posee un sentido de culpabilidad y necesita proyectar esta culpa hacia otros.</p>'
              break
            default:
              break
          }

          switch ($F) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>SIN NECESIDAD DE PROVEER AUTORIDAD:</strong> Es muy obstinado para escuchar a los demás. Listo para pelear por la autoridad y con cualquier imagen de autoridad. Tiene poca sensibilidad a los sentimientos de otros. Es esencialmente rebelde y busca maneras de provocar o criticar al jefe.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>NECESIDAD DE PROVEER AUTORIDAD:</strong> Es muy sumiso a la autoridad. Quiere satisfacer al jefe y trata de comportarse en una forma que no causará disgustos.</p>'
              break
            default:
              break
          }

          switch ($W) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>SIN NECESIDAD DE REGLAS Y SUPERVISION:</strong> Sabe revisar reglas y ajustarlas en los cambios de una situación.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>NECESIDAD DE REGLAS Y SUPERVISION:</strong> Sigue perfectamente las instrucciones y se apega a reglas o políticas.</p>'
              break
            default:
              break
          }

          $t += '<p align="justify"><strong>ROLES...</strong></p>'

          switch ($G) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>NO ES TRABAJADOR TENAZ:</strong> Se detiene ante la intensidad de trabajo. El no gusta de un esfuerzo arduo. Puede tener desdén al trabajo duro; puede ser manipulador; o puede sentir realmente que es menos eficiente considerar trabajar arduo.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>TRABAJADOR TENAZ E INTENSO:</strong> El siente mucho orgullo y estima en trabajo arduo. Su fin u objetivo es trabajar duro. Siente que la única manera de sentirse benemérito es trabajar a lo máximo de su capacidad.</p>'
              break
            default:
              break
          }

          switch ($L) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>NO ES LIDER:</strong> No tiene suficiente confianza en sí mismo como líder. Puede tender a  pasar la pelota en problemas de liderazgo.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>LIDER:</strong> Tiene confianza en sí mismo como líder.</p>'
              break
            default:
              break
          }

          switch ($I) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>NO ES IMPULSIVO:</strong> El puede tener dificultad en tomar decisiones. Se siente inadecuado cuando se trata de competir con personas que respondan más rápido que él.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>IMPULSIVO:</strong> Es rápido para responder y tomar decisiones.</p>'
              break
            default:
              break
          }

          switch ($T) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>NO ES ACTIVO:</strong> El no respeta suficientemente el tiempo. El deberá ponerse a sí mismo y a sus subordinados un horario de tiempo estricto.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>ACTIVO:</strong> Se caracteriza por moverse, hablar y comer rápidamente.</p>'
              break
            default:
              break
          }

          switch ($V) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>NO ES VIGOROSO:</strong> Gran paciencia para estar en un escritorio o tras una máquina todo el día.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>VIGOROSO:</strong> Se desempeña mejor en puestos que exijan trabajos físicos.</p>'
              break
            default:
              break
          }

          switch ($S) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>NO ES SOCIAL:</strong> Puede ser un ejecutivo que presiona mucho a expensas de buenas relaciones interpersonales. El puede tender a perder la información que viene de rumores por su falta de expresión social. Retraído, poco abierto.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>SOCIAL:</strong> Cordial, sociable, saludador, etc.</p>'
              break
            default:
              break
          }

          switch ($R) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>NO ES TEÓRICO:</strong> Tiende a minimizar aspectos teóricos de trabajo, es un débil planeador a largo plazo. No piensa mucho, lento para tomar decisiones, tiende a ser práctico.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>TEÓRICO:</strong> Es altamente académico en su desempeño. El puede tender a teorizar más allá del punto de acción eficiente. Puede perderse en pensamientos y palabras.</p>'
              break
            default:
              break
          }

          switch ($c) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>NO ES ORGANIZADO:</strong> Desordenado, descuidado.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>ORGANIZADO:</strong> Puede haber algunos problemas en su desempeño debido a su compulsión por el orden y la rigidez. Tiene poco espacio en su mente para ajustes de flexibilidad. Para él todo debe estar su lugar.</p>'
              break
            default:
              break
          }

          switch ($E) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>NO ES EMOCIONALMENTE REFRENADO:</strong> Puede ser muy revelador en sus sentimientos. Falta de control emocional.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>EMOCIONALMENTE REFRENADO:</strong> Puede ser emocionalmente muy restringido para ser altamente dinámico, inspirador o de convencimiento. Pasivo, inhibido y desinteresado.</p>'
              break
            default:
              break
          }

          switch ($D) {
            case 0:
            case 1:
            case 2:
              $t +=
                '<p align="justify"><strong>NO TRABAJA CON DETALLES:</strong> Delega responsabilidades al estar desinteresado a trabajar con detalles.</p>'
              break
            case 7:
            case 8:
            case 9:
            case 10:
              $t +=
                '<p align="justify"><strong>DETALLISTA:</strong> Está muy interesado en personalmente trabajar en detalles. Puede afectar su habilidad para delegar y distinguir detalles importantes de los no importantes.</p>'
              break
            default:
              break
          }

          setTs($t)
        } else if (props.id.testId === 20) {
          setNamePrueba('Prueba de Kuder')

          const testFactor = response.data.testFactor
          const $sexo = props.id.userGender
          let $t = ''

          const $rv = testFactor.find((f) => f.factorId === 3).testsResultsTpm
            .testId
          const $r0 = testFactor.find((f) => f.factorId === 3).testsResultsTpm
            .scoreBase
          const $r1 = testFactor.find((f) => f.factorId === 112).testsResultsTpm
            .scoreBase
          const $r2 = testFactor.find((f) => f.factorId === 108).testsResultsTpm
            .scoreBase
          const $r3 = testFactor.find((f) => f.factorId === 110).testsResultsTpm
            .scoreBase
          const $r4 = testFactor.find((f) => f.factorId === 114).testsResultsTpm
            .scoreBase
          const $r5 = testFactor.find((f) => f.factorId === 109).testsResultsTpm
            .scoreBase
          const $r6 = testFactor.find((f) => f.factorId === 111).testsResultsTpm
            .scoreBase
          const $r7 = testFactor.find((f) => f.factorId === 113).testsResultsTpm
            .scoreBase
          const $r8 = testFactor.find((f) => f.factorId === 115).testsResultsTpm
            .scoreBase
          const $r9 = testFactor.find((f) => f.factorId === 116).testsResultsTpm
            .scoreBase

          const $g = [$r0, $r1, $r2, $r3, $r4, $r5, $r6, $r7, $r8, $r9]

          let $qu = '',
            $qv = '',
            $qs = '',
            $qt = '',
            $qtt = '',
            $qx = '',
            $qw = '',
            $may = '',
            $may2 = '',
            $may3 = '',
            $mayper = 0,
            $mayper2 = 0

          if ($sexo == 2) {
            if ($g[0] > 39) {
              $may = '0'
            }

            if ($g[1] > 28) {
              if ($may == '') {
                $may = '1'
              } else {
                $may2 = '1'
              }
            }

            if ($g[2] > 26) {
              if ($may == '') {
                $may = '2'
              } else {
                if ($may2 == '') {
                  $may2 = '2'
                } else {
                  $may3 = '2'
                }
              }
            }

            if ($g[3] > 37) {
              if ($may == '') {
                $may = '3'
              } else {
                if ($may2 == '') {
                  $may2 = '3'
                } else {
                  $may3 = '3'
                }
              }
            }

            if ($g[4] > 45) {
              if ($may == '') {
                $may = '4'
              } else {
                if ($may2 == '') {
                  $may2 = '4'
                } else {
                  $may3 = '4'
                }
              }
            }

            if ($g[5] > 35) {
              if ($may == '') {
                $may = '5'
              } else {
                if ($may2 == '') {
                  $may2 = '5'
                } else {
                  $may3 = '5'
                }
              }
            }

            if ($g[6] > 24) {
              if ($may == '') {
                $may = '6'
              } else {
                if ($may2 == '') {
                  $may2 = '6'
                } else {
                  $may3 = '6'
                }
              }
            }

            if ($g[7] > 23) {
              if ($may == '') {
                $may = '7'
              } else {
                if ($may2 == '') {
                  $may2 = '7'
                } else {
                  $may3 = '7'
                }
              }
            }

            if ($g[8] > 56) {
              if ($may == '') {
                $may = '8'
              } else {
                if ($may2 == '') {
                  $may2 = '8'
                } else {
                  $may3 = '8'
                }
              }
            }

            if ($g[9] > 68) {
              if ($may == '') {
                $may = '9'
              } else {
                if ($may2 == '') {
                  $may2 = '9'
                } else {
                  $may3 = '9'
                }
              }
            }

            if ($g[10] > 45) {
              $mayper = 10
            }

            if ($g[11] > 41) {
              if ($mayper == 0) {
                $mayper = 11
              } else {
                $mayper2 = 11
              }
            }

            if ($g[12] > 37) {
              if ($mayper == 0) {
                $mayper = 12
              } else {
                $mayper2 = 12
              }
            }

            if ($g[13] > 38) {
              if ($mayper == 0) {
                $mayper = 13
              } else {
                $mayper2 = 13
              }
            }

            if ($g[14] > 34) {
              if ($mayper == 0) {
                $mayper = 14
              } else {
                $mayper2 = 14
              }
            }
          } else {
            if ($g[0] > 58) {
              $may = '0'
            }

            if ($g[1] > 53) {
              if ($may == '') {
                $may = '1'
              } else {
                $may2 = '1'
              }
            }

            if ($g[2] > 29) {
              if ($may == '') {
                $may = '2'
              } else {
                if ($may2 == '') {
                  $may2 = '2'
                } else {
                  $may3 = '2'
                }
              }
            }

            if ($g[3] > 49) {
              if ($may == '') {
                $may = '3'
              } else {
                if ($may2 == '') {
                  $may2 = '3'
                } else {
                  $may3 = '3'
                }
              }
            }

            if ($g[4] > 46) {
              if ($may == '') {
                $may = '4'
              } else {
                if ($may2 == '') {
                  $may2 = '4'
                } else {
                  $may3 = '4'
                }
              }
            }

            if ($g[5] > 31) {
              if ($may == '') {
                $may = '5'
              } else {
                if ($may2 == '') {
                  $may2 = '5'
                } else {
                  $may3 = '5'
                }
              }
            }

            if ($g[6] > 22) {
              if ($may == '') {
                $may = '6'
              } else {
                if ($may2 == '') {
                  $may2 = '6'
                } else {
                  $may3 = '6'
                }
              }
            }

            if ($g[7] > 18) {
              if ($may == '') {
                $may = '7'
              } else {
                if ($may2 == '') {
                  $may2 = '7'
                } else {
                  $may3 = '7'
                }
              }
            }

            if ($g[8] > 43) {
              if ($may == '') {
                $may = '8'
              } else {
                if ($may2 == '') {
                  $may2 = '8'
                } else {
                  $may3 = '8'
                }
              }
            }

            if ($g[9] > 52) {
              if ($may == '') {
                $may = '9'
              } else {
                if ($may2 == '') {
                  $may2 = '9'
                } else {
                  $may3 = '9'
                }
              }
            }

            if ($g[10] > 41) {
              $mayper = 10
            }

            if ($g[11] > 45) {
              if ($mayper == 0) {
                $mayper = 11
              } else {
                $mayper2 = 11
              }
            }

            if ($g[12] > 31) {
              if ($mayper == 0) {
                $mayper = 12
              } else {
                $mayper2 = 12
              }
            }

            if ($g[13] > 41) {
              if ($mayper == 0) {
                $mayper = 13
              } else {
                $mayper2 = 13
              }
            }

            if ($g[14] > 44) {
              if ($mayper == 0) {
                $mayper = 14
              } else {
                $mayper2 = 14
              }
            }
          }

          $t = `<p><b>${$sexo == 1 ? 'HOMBRES' : 'MUJERES'} </b></p>
                <p><b>Puntaje de validación vocacional: ${$rv}</b></p>
                <p><b>Informe Cualitativo – Test Kuder Preferencias Vocacionales:</b></p>
                <p>El diagnóstico detallado a continuación contiene los 2 primeros aspectos destacados de las preferencias vocacionales del candidato y el tercero se lo obtiene de 2 maneras:</p>
                <p>
                  <ol>
                    <li><p align="justify">El tercero más destacado que supere la puntuación base.</p>
                    <li><p align="justify">Si hay más de 3 destacados se realiza una valoración del que está más acorde con los 2 primeros y sobre este se emite el diagnóstico, indistintamente que pueda tener mayor o menor puntaje que otro destacado.</p>
                  </ol>
                </p>`

          switch ($may) {
            case '0':
              $qs =
                '<p align="justify">0) Actividad al aire libre.- Altos puntajes obtenidos en esta área, significan que al examinando le gusta pasar la mayor parte del tiempo en el campo, en los bosques o en el mar. Le agrada cultivar plantas o cuidar a los animales, etc. En cambio, no se sentiría muy a gusto en una fábrica, en un laboratorio o en una oficina. Los ingenieros agrónomos, los ingenieros forestales, los ingenieros de minas, los militares, los avi-cultores, los prácticos agrícolas, los jardineros profesionales, los pescadores profesionales, etc., tienen generalmente, un alto pontaje en esta área.</p>'
              break
            case '1':
              $qs =
                '<p align="justify">1) Interés mecánico.- Un alto puntaje en la respectiva columna, indica interés para trabajar con máquinas y herramientas, construir o arreglar objetos mecánicos, artefactos eléctricos, muebles, etc. Logran altos puntajes en esta área, los ingenieros de distintas especializaciones: civiles, electricistas, industriales, mecánicos; los aviadores, los especialistas en radio y televisión y otras actividades afines, como también los carpinteros, maquinistas, torneros, soldadores, etc.</p>'
              break
            case '2':
              $qs =
                '<p align="justify">2) Interés para el cálculo.- Lo poseen aquellas personas a quienes les gusta trabajar con números. Un alto puntaje obtenido en esta área indica la posibilidad de que el examinado podría encontrar satisfacción en el ejercicio de las profesiones tales como: contador, estadígrafo, profesor de matemáticas, economista, actuario, especialista en elaboración de presupuestos, etc. Muchos ingenieros revelan también un marcado interés por las actividades relacionadas con el cálculo.</p>'
              break
            case '3':
              $qs =
                '<p align="justify">3) Interés científico.- Manifiestan este interés las personas que encuentran placer en investigar la razón de los hechos o de las cosas, en descubrir sus causas y en resolver problemas de distinta índole, por mera curiosidad científica y sin pensar en los beneficios económicos que puedan resultar de sus descubrimientos. El interés científico es de gran importancia en el ejercicio de muchas carreras profesionales, aún aquellas donde el móvil de actividad puede ser de índole distinta al progreso de la ciencia. Los médicos, los farmaceutas, los químicos, los odontólogos, los veterinarios, los técnicos de laboratorio, los psicólogos profesionales, etc., demuestran, por lo general, un alto interés científico.</p>'
              break
            case '4':
              $qs =
                '<p align="justify">4) Interés persuasivo.- Lo poseen aquellas personas a quienes les gusta el trato con la gente, a quienes agrada imponer sus puntos de vista, convencer a los demás respecto a algún proyecto, venderles algún artículo, etc. Abogados, periodistas, actores, comerciantes, agentes de compañías de seguro, diplomáticos de carrera, etc., logran tener altos puntajes en este campo de intereses.</p>'
              break
            case '5':
              $qs =
                '<p align="justify">5) Interés artístico-plástico.- Las personas con un interés artístico-plástico predominante, son aquellas a quienes gusta hacer trabajos de creación de tipo manual, usando combinaciones de colores, materiales, formas y diseños. Los pintores, escultores, arquitectos, decoradores, diseñadores, fotógrafos profesionales, modistos, etc. están entre aquellos cuyos intereses se sitúan dentro de esta área.</p>'
              break
            case '6':
              $qs =
                '<p align="justify">6) Interés literario.- Es propio de todos aquellos a quienes gusta la lectura o quienes encuentran placer en expresar sus ideas en forma oral o escrita. Los autores, editores, periodistas, abogados, profesores, bibliotecarios, etc., obtienen de ordinario, altos puntajes en esta área de intereses.</p>'
              break
            case '7':
              $qs =
                '<p align="justify">7) Interés musical.- En el campo del interés musical se sitúan las personas que denotar un marcado gusto para tocar instrumentos musicales, cantar, bailar, leer sobre música, estudiar la vida de destacados compositores, asistir a conciertos, etc. Los músicos profesionales, los compositores, los profesores de música, los artistas de ballet, son los que obtienen los más altos puntajes en esta área.</p>'
              break
            case '8':
              $qs =
                '<p align="justify">8) Interés por el servicio social.- Un alto puntaje en el área del servicio social indica un alto grado de interés para servir a los demás, y en primer lugar, a los necesitados, a los enfermos, a los niños y a los ancianos. Sacerdotes, misioneros, asistentes sociales, enfermeras, educadores, etc., muestran una marcada preferencia por el trabajo en el campo social.</p>'
              break
            case '9':
              $qs =
                '<p align="justify">9) Interés en el trabajo de oficina.- Es propio de las personas a quienes gusta un tipo de trabajo de escritorio que requiere exactitud y precisión. Los contadores, tenedores de libros, archivistas, secretarios, mecanógrafos, etc., marcan un alto puntaje en esta zona de intereses.</p>'
              break
            default:
              break
          }

          switch ($may2) {
            case '0':
              $qt =
                '<p align="justify"> 0) Actividad al aire libre.- Altos puntajes obtenidos en esta área, significan que al examinando le gusta pasar la mayor parte del tiempo en el campo, en los bosques o en el mar. Le agrada cultivar plantas o cuidar a los animales, etc. En cambio, no se sentiría muy a gusto en una fábrica, en un laboratorio o en una oficina. Los ingenieros agrónomos, los ingenieros forestales, los ingenieros de minas, los militares, los avi-cultores, los prácticos agrícolas, los jardineros profesionales, los pescadores profesionales, etc., tienen gene-ralmente, un alto pontaje en esta área. </p>'
              break
            case '1':
              $qt =
                '<p align="justify"> 1) Interés mecánico.- Un alto puntaje en la respectiva columna, indica interés para trabajar con máquinas y herramientas, construir o arreglar objetos mecánicos, artefactos eléctricos, muebles, etc. Logran altos puntajes en esta área, los ingenieros de distintas especializaciones: civiles, electricistas, industriales, mecánicos; los aviadores, los especialistas en radio y televisión y otras actividades afines, como también los carpinteros, maquinistas, torneros, soldadores, etc.</p>'
              break
            case '2':
              $qt =
                '<p align="justify"> 2) Interés para el cálculo.- Lo poseen aquellas personas a quienes les gusta trabajar con números. Un alto puntaje obtenido en esta área indica la posibilidad de que el examinado podría encontrar satisfacción en el ejercicio de las profesiones tales como: contador, estadígrafo, profesor de matemáticas, economista, actuario, especialista en elaboración de presupuestos, etc. Muchos ingenieros revelan también un marcado interés por las actividades relacionadas con el cálculo.</p>'
              break
            case '3':
              $qt =
                '<p align="justify"> 3) Interés científico.- Manifiestan este interés las personas que encuentran placer en investigar la razón de los hechos o de las cosas, en descubrir sus causas y en resolver problemas de distinta índole, por mera curiosidad científica y sin pensar en los beneficios económicos que puedan resultar de sus descubrimientos. El interés científico es de gran importancia en el ejercicio de muchas carreras profesionales, aún aquellas donde el móvil de actividad puede ser de índole distinta al progreso de la ciencia. Los médicos, los farmaceutas, los químicos, los odontólogos, los veterinarios, los técnicos de laboratorio, los psicólogos profesionales, etc., demuestran, por lo general, un alto interés científico.</p>'
              break
            case '4':
              $qt =
                '<p align="justify"> 4) Interés persuasivo.- Lo poseen aquellas personas a quienes les gusta el trato con la gente, a quienes agrada imponer sus puntos de vista, convencer a los demás respecto a algún proyecto, venderles algún artículo, etc. Abogados, periodistas, actores, comerciantes, agentes de compañías de seguro, diplomáticos de carrera, etc., logran tener altos puntajes en este campo de intereses.</p>'
              break
            case '5':
              $qt =
                '<p align="justify"> 5) Interés artístico-plástico.- Las personas con un interés artístico-plástico predominante, son aquellas a quienes gusta hacer trabajos de creación de tipo manual, usando combinaciones de colores, materiales, formas y diseños. Los pintores, escultores, arquitectos, decoradores, diseñadores, fotógrafos profesionales, modistos, etc. están entre aquellos cuyos intereses se sitúan dentro de esta área.</p>'
              break
            case '6':
              $qt =
                '<p align="justify"> 6) Interés literario.- Es propio de todos aquellos a quienes gusta la lectura o quienes encuentran placer en expresar sus ideas en forma oral o escrita. Los autores, editores, periodistas, abogados, profesores, bibliotecarios, etc., obtienen de ordinario, altos puntajes en esta área de intereses.</p>'
              break
            case '7':
              $qt =
                '<p align="justify"> 7) Interés musical.- En el campo del interés musical se sitúan las personas que denotar un marcado gusto para tocar instrumentos musicales, cantar, bailar, leer sobre música, estudiar la vida de destacados compositores, asistir a conciertos, etc. Los músicos profesionales, los compositores, los profesores de música, los artistas de ballet, son los que obtienen los más altos puntajes en esta área.</p>'
              break
            case '8':
              $qt =
                '<p align="justify"> 8) Interés por el servicio social.- Un alto puntaje en el área del servicio social indica un alto grado de interés para servir a los demás, y en primer lugar, a los necesitados, a los enfermos, a los niños y a los ancianos. Sacerdotes, misioneros, asistentes sociales, enfermeras, educadores, etc., muestran una marcada preferencia por el trabajo en el campo social.</p>'
              break
            case '9':
              $qt =
                '<p align="justify"> 9) Interés en el trabajo de oficina.- Es propio de las personas a quienes gusta un tipo de trabajo de escritorio que requiere exactitud y precisión. Los contadores, tenedores de libros, archivistas, secretarios, mecanógrafos, etc., marcan un alto puntaje en esta zona de intereses.</p>'
              break
            default:
              break
          }

          switch ($may3) {
            case '0':
              $qtt =
                '<p align="justify"> 0) Actividad al aire libre.- Altos puntajes obtenidos en esta área, significan que al examinando le gusta pasar la mayor parte del tiempo en el campo, en los bosques o en el mar. Le agrada cultivar plantas o cuidar a los animales, etc. En cambio, no se sentiría muy a gusto en una fábrica, en un laboratorio o en una oficina. Los ingenieros agrónomos, los ingenieros forestales, los ingenieros de minas, los militares, los avi-cultores, los prácticos agrícolas, los jardineros profesionales, los pescadores profesionales, etc., tienen gene-ralmente, un alto pontaje en esta área.</p>'
              break
            case '1':
              $qtt =
                '<p align="justify"> 1) Interés mecánico.- Un alto puntaje en la respectiva columna, indica interés para trabajar con máquinas y herramientas, construir o arreglar objetos mecánicos, artefactos eléctricos, muebles, etc. Logran altos puntajes en esta área, los ingenieros de distintas especializaciones: civiles, electricistas, industriales, mecánicos; los aviadores, los especialistas en radio y televisión y otras actividades afines, como también los carpinteros, maquinistas, torneros, soldadores, etc.</p>'
              break
            case '2':
              $qtt =
                '<p align="justify"> 2) Interés para el cálculo.- Lo poseen aquellas personas a quienes les gusta trabajar con números. Un alto puntaje obtenido en esta área indica la posibilidad de que el examinado podría encontrar satisfacción en el ejercicio de las profesiones tales como: contador, estadígrafo, profesor de matemáticas, economista, actuario, especialista en elaboración de presupuestos, etc. Muchos ingenieros revelan también un marcado interés por las actividades relacionadas con el cálculo.</p>'
              break
            case '3':
              $qtt =
                '<p align="justify"> 3) Interés científico.- Manifiestan este interés las personas que encuentran placer en investigar la razón de los hechos o de las cosas, en descubrir sus causas y en resolver problemas de distinta índole, por mera curiosidad científica y sin pensar en los beneficios económicos que puedan resultar de sus descubrimientos. El interés científico es de gran importancia en el ejercicio de muchas carreras profesionales, aún aquellas donde el móvil de actividad puede ser de índole distinta al progreso de la ciencia. Los médicos, los farmaceutas, los químicos, los odontólogos, los veterinarios, los técnicos de laboratorio, los psicólogos profesionales, etc., demuestran, por lo general, un alto interés científico.</p>'
              break
            case '4':
              $qtt =
                '<p align="justify"> 4) Interés persuasivo.- Lo poseen aquellas personas a quienes les gusta el trato con la gente, a quienes agrada imponer sus puntos de vista, convencer a los demás respecto a algún proyecto, venderles algún artículo, etc. Abogados, periodistas, actores, comerciantes, agentes de compañías de seguro, diplomáticos de carrera, etc., logran tener altos puntajes en este campo de intereses.</p>'
              break
            case '5':
              $qtt =
                '<p align="justify"> 5) Interés artístico-plástico.- Las personas con un interés artístico-plástico predominante, son aquellas a quienes gusta hacer trabajos de creación de tipo manual, usando combinaciones de colores, materiales, formas y diseños. Los pintores, escultores, arquitectos, decoradores, diseñadores, fotógrafos profesionales, modistos, etc. están entre aquellos cuyos intereses se sitúan dentro de esta área.</p>'
              break
            case '6':
              $qtt =
                '<p align="justify"> 6) Interés literario.- Es propio de todos aquellos a quienes gusta la lectura o quienes encuentran placer en expresar sus ideas en forma oral o escrita. Los autores, editores, periodistas, abogados, profesores, bibliotecarios, etc., obtienen de ordinario, altos puntajes en esta área de intereses.</p>'
              break
            case '7':
              $qtt =
                '<p align="justify"> 7) Interés musical.- En el campo del interés musical se sitúan las personas que denotar un marcado gusto para tocar instrumentos musicales, cantar, bailar, leer sobre música, estudiar la vida de destacados compositores, asistir a conciertos, etc. Los músicos profesionales, los compositores, los profesores de música, los artistas de ballet, son los que obtienen los más altos puntajes en esta área.</p>'
              break
            case '8':
              $qtt =
                '<p align="justify"> 8) Interés por el servicio social.- Un alto puntaje en el área del servicio social indica un alto grado de interés para servir a los demás, y en primer lugar, a los necesitados, a los enfermos, a los niños y a los ancianos. Sacerdotes, misioneros, asistentes sociales, enfermeras, educadores, etc., muestran una marcada preferencia por el trabajo en el campo social.</p>'
              break
            case '9':
              $qtt =
                '<p align="justify"> 9) Interés en el trabajo de oficina.- Es propio de las personas a quienes gusta un tipo de trabajo de escritorio que requiere exactitud y precisión. Los contadores, tenedores de libros, archivistas, secretarios, mecanógrafos, etc., marcan un alto puntaje en esta zona de intereses.</p>'
              break
            default:
              break
          }

          switch ($mayper) {
            case 10:
              $qu =
                '<p align="justify">A) Preferencia por trabajos en grupo: Los individuos que obtienen un alto puntaje en esta área demuestran el interés para trabajar en contacto con otras personas. Les agrada desempeñar papeles directivos, o constituir el centro de atención, lo cual no significa necesariamente que les guste dominar a los demás o ejercer control sobre ellos. Los ingenieros industriales, los actores y las actrices, los profesores, los directores de empresas, los jefes de ventas, los jefes de personal, son los que a menudo se distinguen por altos puntajes en esta área. En cambio, los médicos, los ingenieros agrónomos y los agricultores en general, tienden hacia los puntajes bajos.</p>'
              break
            case 11:
              $qu =
                '<p align="justify">B) Preferencia por las situaciones estables y familiares: La persona que obtiene un alto puntaje en esta área, prefiere que su actividad se desarrolle dentro de un cauce de situaciones familiares y estables, antes que aventurarse en el campo de nuevas experiencias. No le gusta correr riesgos ni exponerse a situaciones de consecuencias imprevisibles. Entre los profesionales que obtienen altos puntajes en esta área, figuran los agricultores, los ebanistas, los maestros de escuela y los profesores de enseñanza media y superior, los empleados de oficina y los médicos. Los puntajes bajos son propios de los autores, periodistas, abogados y jueces, gerentes de empresas, comerciales e industriales, arquitectos y constructores.</p>'
              break
            case 12:
              $qu =
                '<p align="justify">C) Preferencia al trabajo de orden intelectual o teórico:Un puntaje alto en esta área de preferencias, indica que la persona se inclina más hacia la meditación y el intercambio de ideas con otras personas, que al manejo de las cosas materiales. Le agradan las situaciones que permiten analizar a fondo los problemas y le gusta saber lo que piensan los demás y conocer los motivos que determinan su conducta. Muchos profesionales obtienen alto puntaje en esta área entre ellos, los autores y editores, los abogados y jueces, los profesores y los médicos, los jefes de empresas y los científicos en general. Los mecánicos y los agricultores, en cambio, muestran escaso interés por este tipo de actividad.</p>'
              break
            case 13:
              $qu =
                '<p align="justify">D) Preferencia por situaciones no conflictivas: El individuo que obtiene un alto puntaje en esta área, muestra su preferencia para actuar dentro de un ambiente tranquilo y agradable y evitar situaciones conflictivas. No le gusta ser agresivo o imponer sus puntos de vista, ni defender sus ideas con tal de lograr que sus relaciones con los demás sean amistosas y apacibles. Se distinguen por sus altos puntajes en esta área, los profesores universitarios y de enseñanza secundaria, los médicos, los ingenieros, los contadores y muchos empleados de oficina. Un puntaje bajo es propio del personal ocupado en el servicio de protección como la milicia, la policía, etcétera, y también de algunos ejecutivos como vicepresidentes, sugerentes y otros similares.</p>'
              break
            case 14:
              $qu =
                '<p align="justify">E) Inclinación a dirigir o dominar a los demás:Los altos puntajes en esta área indican la preferencia de la persona para dirigir y dominar a los demás. Prefiere situaciones que implican el ejercicio de la autoridad y del poder. Son numerosos los grupos profesionales que se distinguen por un alto puntaje en esta área. Entre otros: abogados y jueces, gerentes y jefes de empresas, personal del servicio de protección, personal de ventas. Los artesanos, en general, obtienen un puntaje bajo, igual que el personal de oficinas, especialmente el femenino.</p>'
              break
            default:
              break
          }

          switch ($mayper2) {
            case 10:
              $qv =
                '<p align="justify"> A) Preferencia por trabajos en grupo: Los individuos que obtienen un alto puntaje en esta área demuestran el interés para trabajar en contacto con otras personas. Les agrada desempeñar papeles directivos, o constituir el centro de atención, lo cual no significa necesariamente que les guste dominar a los demás o ejercer control sobre ellos. Los ingenieros industriales, los actores y las actrices, los profesores, los directores de empresas, los jefes de ventas, los jefes de personal, son los que a menudo se distinguen por altos puntajes en esta área. En cambio, los médicos, los ingenieros agrónomos y los agricultores en general, tienden hacia los puntajes bajos.</p>'
              break
            case 11:
              $qv =
                '<p align="justify"> B) Preferencia por las situaciones estables y familiares: La persona que obtiene un alto puntaje en esta área, prefiere que su actividad se desarrolle dentro de un cauce de situaciones familiares y estables, antes que aventurarse en el campo de nuevas experiencias. No le gusta correr riesgos ni exponerse a situaciones de consecuencias imprevisibles. Entre los profesionales que obtienen altos puntajes en esta área, figuran los agricultores, los ebanistas, los maestros de escuela y los profesores de enseñanza media y superior, los empleados de oficina y los médicos. Los puntajes bajos son propios de los autores, periodistas, abogados y jueces, gerentes de empresas, comerciales e industriales, arquitectos y constructores.</p>'
              break
            case 12:
              $qv =
                '<p align="justify"> C) Preferencia al trabajo de orden intelectual o teórico:Un puntaje alto en esta área de preferencias, indica que la persona se inclina más hacia la meditación y el intercambio de ideas con otras personas, que al manejo de las cosas materiales. Le agradan las situaciones que permiten analizar a fondo los problemas y le gusta saber lo que piensan los demás y conocer los motivos que determinan su conducta. Muchos profesionales obtienen alto puntaje en esta área entre ellos, los autores y editores, los abogados y jueces, los profesores y los médicos, los jefes de empresas y los científicos en general. Los mecánicos y los agricultores, en cambio, muestran escaso interés por este tipo de actividad.</p>'
              break
            case 13:
              $qv =
                '<p align="justify"> D) Preferencia por situaciones no conflictivas: El individuo que obtiene un alto puntaje en esta área, muestra su preferencia para actuar dentro de un ambiente tranquilo y agradable y evitar situaciones conflictivas. No le gusta ser agresivo o imponer sus puntos de vista, ni defender sus ideas con tal de lograr que sus relaciones con los demás sean amistosas y apacibles. Se distinguen por sus altos puntajes en esta área, los profesores universitarios y de enseñanza secundaria, los médicos, los ingenieros, los contadores y muchos empleados de oficina. Un puntaje bajo es propio del personal ocupado en el servicio de protección como la milicia, la policía, etcétera, y también de algunos ejecutivos como vicepresidentes, sugerentes y otros similares.</p>'
              break
            case 14:
              $qv =
                '<p align="justify"> E) Inclinación a dirigir o dominar a los demás:Los altos puntajes en esta área indican la preferencia de la persona para dirigir y dominar a los demás. Prefiere situaciones que implican el ejercicio de la autoridad y del poder. Son numerosos los grupos profesionales que se distinguen por un alto puntaje en esta área. Entre otros: abogados y jueces, gerentes y jefes de empresas, personal del servicio de protección, personal de ventas. Los artesanos, en general, obtienen un puntaje bajo, igual que el personal de oficinas, especialmente el femenino.</p>'
              break
            default:
              break
          }

          if ($may2 == '' && $may3 == '') {
            if ($may != '') {
              switch ($may) {
                case '0':
                  $qx =
                    '<p align="justify">0) Interés para el trabajo al aire libre: Ciencias del mar, Ecólogos, Geólogos, Ingenieros agrónomos, Ingenieros forestales, Ingenieros de minas, Ingenieros pesqueros, Oficiales de ejército, marina, aviación, policía, Profesores de educación física, Topógrafos.</p>'
                  break
                case '1':
                  $qx =
                    '<p align="justify">1) Interés mecánico: Aviadores, Electromecánicos, Físicos, Ingenieros civiles, Ingenieros electricistas, Ingenieros industriales, Ingenieros mecánicos, Ingenieros metalúrgicos, Ingenieros químicos, Ingenieros sanitarios, Tecnológicos mecánicos.</p>'
                  break
                case '2':
                  $qx =
                    '<p align="justify">2) Interés para el cálculo: Actuarios, Contadores públicos y auditores, Economistas, Estadísticos, Ingenieros de sistemas, Profesores de matemáticas, Programadores de computadoras, Tecnológos en mercadeo.</p>'
                  break
                case '3':
                  $qx =
                    '<p align="justify">3) Interés científico: Antropólogos, Arqueólogos, Astrónomos, Bacteriólogos, Biólogos, Ingenieros electricistas, Ingenieros electrónicos, Ingenieros de alimentos, Médicos y Cirujanos, Médicos veterinarios, Microbiólogos, Odontólogos, Psicólogos, Químicos, Químicos farmaceutas, Sociólogos, Técnicos de laboratorio.</p>'
                  break
                case '4':
                  $qx =
                    '<p align="justify">4) Interés persuasivo: Agentes de publicidad, Comunicadores sociales, Escritores, Juristas (Abogados, Jueces, Consejeros Jurídicos), Maestros - Profesores, Vendedores.</p>'
                  break
                case '5':
                  $qx =
                    '<p align="justify">5) Interés Artístico-plástico: Arquitectos, Decoradores de interiores, Delineantes de arquitectura, Dibujantes, Escultores, Pintores, Técnicos en Diseño Textil.</p>'
                  break
                case '6':
                  $qx =
                    '<p align="justify">6) Interés literario: Bibliotecarios, Educadores, Escritores, Juristas, Periodistas, Profesores de filosofía, Profesores de letras.</p>'
                  break
                case '7':
                  $qx =
                    '<p align="justify">7) Interés musical: Actores y Actrices, Compositores, Directores de orquesta, Músicos, Profesores de música.</p>'
                  break
                case '8':
                  $qx =
                    '<p align="justify">8) Interés por el servicio social: Bibliotecarios, Consejeros Vocacionales, Enfermeras (os), Fonoaudiólogas, Fisioterapeutas, Médicos y Cirujanos, Odontólogos, Pedagogos (en general), Psicólogos, Sacerdotes, Tecnólogos en educación preescolar, Terapista del lenguaje, Terapista ocupacional, Trabajadores Sociales.</p>'
                  break
                case '9':
                  $qx =
                    '<p align="justify">9) Interés en el trabajo de oficina: Almacenistas, Archivistas, Cajeros, Contadores, Mecanógrafas, Recepcionistas, Secretarias, Secretarias ejecutivas, Taquígrafos (as), Tenedores de libros.</p>'
                  break
                default:
                  break
              }
            }
          } else {
            if ($may3 == '') {
              const $f = ['', $may + $may2]

              switch ($f[1]) {
                case '01':
                  $qw =
                    '<p align="justify">01) Aire libre - Mecánico: Ingenieros agrónomos especializados en maquinaria agrícola, Ingenieros civiles especializados en hidráulica, Ingenieros en vías de comunicación, Ingenieros de minas, Ingenieros de petróleo, Ingenieros navales, Topógrafos.</p>'
                  break
                case '02':
                  $qw =
                    '<p align="justify">02) Aire libre - Cálculo: Ingenieros agrónomos especializados en economía, Ingenieros agrónomos especializados en estadística.</p>'
                  break
                case '03':
                  $qw =
                    '<p align="justify">03) Aire libre - Científico: Antropólogos, Arqueólogos, Biólogos marinos, Geólogos, Ingenieros agrónomos especializados en fitotécnica, Ingenieros agrónomos especializados en parasitología, Ingenieros forestales, Médicos veterinarios.</p>'
                  break
                case '08':
                  $qw =
                    '<p align="justify">08) Aire libre - Servicio Social: Profesores de educación física, Trabajadores Sociales. </p>'
                  break
                case '12':
                  $qw =
                    '<p align="justify">12) Mecánico - Cálculo: Ingenieros civiles, Ingenieros electricistas, Ingenieros mecánicos.</p>'
                  break
                case '13':
                  $qw =
                    '<p align="justify">13)Mecánico - Científico: Fisioterapeutas, Geólogos, Ingenieros civiles, Ingenieros de minas, Ingenieros electricistas, Ingenieros industriales, Ingenieros mecánicos, Ingenieros químicos, Odontólogos, Optómetras, Técnicos de laboratorio.</p>'
                  break
                case '14':
                  $qw =
                    '<p align="justify">14) Mecánico - Persuasivo: Ingenieros industriales, Vendedores.</p>'
                  break
                case '15':
                  $qw =
                    '<p align="justify">15) Mecánico - Artístico: Arquitectos, Decoradores de interiores, Delineantes de arquitectura, Dibujantes publicitarios, Profesores de artes manuales, Técnicos en diseño textil.</p>'
                  break
                case '17':
                  $qw =
                    '<p align="justify">17) Mecánico - Musical: Ingenieros de sonido.</p>'
                  break
                case '18':
                  $qw =
                    '<p align="justify">18) Mecánico - Servicio Social: Profesores de materias técnicas.</p>'
                  break
                case '23':
                  $qw =
                    '<p align="justify">23) Cálculo - Científico: Astrónomos, Actuarios, Economistas, Estadígrafos, Físicos, Ingenieros civiles, Ingenieros electricistas, Ingenieros industriales, Ingenieros mecánicos, Ingenieros de petróleos, Ingenieros químicos, Matemáticos, Profesores de matemáticas, Químicos. </p>'
                  break
                case '24':
                  $qw =
                    '<p align="justify">24) Cálculo - Persuasivo: Economistas</p>'
                  break
                case '25':
                  $qw =
                    '<p align="justify">25) Cálculo - Artístico-plástico: Arquitectos, Ingenieros civiles.</p>'
                  break
                case '28':
                  $qw =
                    '<p align="justify">28) Cálculo - Servicio Social: Economistas, Estadígrafos, Profesores de matemáticas, Profesores de materias comerciales.</p>'
                  break
                case '29':
                  $qw =
                    '<p align="justify">29) Cálculo - Trabajo de Oficina: Contadores públicos, Tenedores de libros. </p>'
                  break
                case '34':
                  $qw =
                    '<p align="justify">34) Científico - Persuasivo: Farmacéuticos, Ingenieros industriales, Profesores de ciencias.</p>'
                  break
                case '35':
                  $qw =
                    '<p align="justify">35) Científico - Artístico-plástico: Arquitectos, Médicos Cirujanos, Odontólogos. </p>'
                  break
                case '36':
                  $qw =
                    '<p align="justify">36) Científico - Literario: Filólogos, Médicos, Psicólogos. </p>'
                  break
                case '37':
                  $qw =
                    '<p align="justify">37) Científico - Musical: Ingenieros de sonido.</p>'
                  break
                case '38':
                  $qw =
                    '<p align="justify">38) Científico - Servicio Social: Consejeros vocacionales, Médicos, Nutricionistas, Odontólogos, Profesores de ciencias, Psicólogos clínicos.</p>'
                  break
                case '45':
                  $qw =
                    '<p align="justify">45) Persuasivo - Artístico-plástico: Decoradores de interiores, Dibujantes publicitarios.</p>'
                  break
                case '46':
                  $qw =
                    '<p align="justify">46) Persuasivo - Literario: Escritores, Juristas, Periodistas, Profesores (Letras). </p>'
                  break
                case '47':
                  $qw =
                    '<p align="justify">47) Persuasivo - Musical: Actores y Actrices, Directores de orquesta, Profesores de canto, Profesores de música.</p>'
                  break
                case '48':
                  $qw =
                    '<p align="justify">48) Persuasivo - Servicio Social: Comunicadores sociales, Consejeros vocacionales, Juristas (en general), Pedagogos (en general), Trabajadores sociales. </p>'
                  break
                case '56':
                  $qw =
                    '<p align="justify">56) Artístico-plástico - Literario: Actores y Actrices, Escritores.</p>'
                  break
                case '58':
                  $qw =
                    '<p align="justify">58) Artístico-plástico - Servicio Social: Profesores de artes manuales.</p>'
                  break
                case '67':
                  $qw =
                    '<p align="justify">67) Literario - Musical: Actores y Actrices, Profesores de música.</p>'
                  break
                case '68':
                  $qw =
                    '<p align="justify">68)Literario - Servicio Social: Escritores, Periodistas, Profesores de ciencias sociales, Profesores de filosofía, Profesores de letras, Trabajadores sociales. </p>'
                  break
                case '69':
                  $qw =
                    '<p align="justify">69) Literario - Trabajo de Oficina: Bibliotecarios, Secretarios.</p>'
                  break
                case '78':
                  $qw =
                    '<p align="justify">78) Músico - Servicio Social: Músicos, Profesores de música, Profesores de baile. </p>'
                  break
                case '89':
                  $qw =
                    '<p align="justify">89) Servicio Social - Oficina: Profesores de materias comerciales.</p>'
                  break
                default:
                  break
              }
            } else {
              const $f = ['', $may + $may2, $may2 + $may3, $may + $may3]

              $qw = ''

              for (let $I = 1; $I <= 3; $I++) {
                switch ($f[$I]) {
                  case '01':
                    $qw =
                      $qw +
                      '<p align="justify">01) Aire libre - Mecánico: Ingenieros agrónomos especializados en maquinaria agrícola, Ingenieros civiles especializados en hidráulica, Ingenieros en vías de comunicación, Ingenieros de minas, Ingenieros de petróleo, Ingenieros navales, Topógrafos. </p>'
                    break
                  case '02':
                    $qw =
                      $qw +
                      '<p align="justify">02) Aire libre - Cálculo: Ingenieros agrónomos especializados en economía, Ingenieros agrónomos especializados en estadística. </p>'
                    break
                  case '03':
                    $qw =
                      $qw +
                      '<p align="justify">03) Aire libre - Científico: Antropólogos, Arqueólogos, Biólogos marinos, Geólogos, Ingenieros agrónomos especializados en fitotécnica, Ingenieros agrónomos especializados en parasitología, Ingenieros forestales, Médicos veterinarios.</p>'
                    break
                  case '08':
                    $qw =
                      $qw +
                      '<p align="justify">08) Aire libre - Servicio Social: Profesores de educación física, Trabajadores Sociales. </p>'
                    break
                  case '12':
                    $qw =
                      $qw +
                      '<p align="justify">12) Mecánico - Cálculo: Ingenieros civiles, Ingenieros electricistas, Ingenieros mecánicos. </p>'
                    break
                  case '13':
                    $qw =
                      $qw +
                      '<p align="justify">13)Mecánico - Científico: Fisioterapeutas, Geólogos, Ingenieros civiles, Ingenieros de minas, Ingenieros electricistas, Ingenieros industriales, Ingenieros mecánicos, Ingenieros químicos, Odontólogos, Optómetras, Técnicos de laboratorio.</p>'
                    break
                  case '14':
                    $qw =
                      $qw +
                      '<p align="justify">14) Mecánico - Persuasivo: Ingenieros industriales, Vendedores.</p>'
                    break
                  case '15':
                    $qw =
                      $qw +
                      '<p align="justify">15) Mecánico - Artístico: Arquitectos, Decoradores de interiores, Delineantes de arquitectura, Dibujantes publicitarios, Profesores de artes manuales, Técnicos en diseño textil.</p>'
                    break
                  case '17':
                    $qw =
                      $qw +
                      '<p align="justify">17) Mecánico - Musical: Ingenieros de sonido.</p>'
                    break
                  case '18':
                    $qw =
                      $qw +
                      '<p align="justify">18) Mecánico - Servicio Social: Profesores de materias técnicas.</p>'
                    break
                  case '23':
                    $qw =
                      $qw +
                      '<p align="justify">23) Cálculo - Científico: Astrónomos, Actuarios, Economistas, Estadígrafos, Físicos, Ingenieros civiles, Ingenieros electricistas, Ingenieros industriales, Ingenieros mecánicos, Ingenieros de petróleos, Ingenieros químicos, Matemáticos, Profesores de matemáticas, Químicos. </p>'
                    break
                  case '24':
                    $qw =
                      $qw +
                      '<p align="justify">24) Cálculo - Persuasivo: Economistas</p>'
                    break
                  case '25':
                    $qw =
                      $qw +
                      '<p align="justify">25) Cálculo - Artístico-plástico: Arquitectos, Ingenieros civiles.</p>'
                    break
                  case '28':
                    $qw =
                      $qw +
                      '<p align="justify">28) Cálculo - Servicio Social: Economistas, Estadígrafos, Profesores de matemáticas, Profesores de materias comerciales.</p>'
                    break
                  case '29':
                    $qw =
                      $qw +
                      '<p align="justify">29) Cálculo - Trabajo de Oficina: Contadores públicos, Tenedores de libros. </p>'
                    break
                  case '34':
                    $qw =
                      $qw +
                      '<p align="justify">34) Científico - Persuasivo: Farmacéuticos, Ingenieros industriales, Profesores de ciencias.</p>'
                    break
                  case '35':
                    $qw =
                      $qw +
                      '<p align="justify">35) Científico - Artístico-plástico: Arquitectos, Médicos Cirujanos, Odontólogos. </p>'
                    break
                  case '36':
                    $qw =
                      $qw +
                      '<p align="justify">36) Científico - Literario: Filólogos, Médicos, Psicólogos. </p>'
                    break
                  case '37':
                    $qw =
                      $qw +
                      '<p align="justify">37) Científico - Musical: Ingenieros de sonido.</p>'
                    break
                  case '38':
                    $qw =
                      $qw +
                      '<p align="justify">38) Científico - Servicio Social: Consejeros vocacionales, Médicos, Nutricionistas, Odontólogos, Profesores de ciencias, Psicólogos clínicos.</p>'
                    break
                  case '45':
                    $qw =
                      $qw +
                      '<p align="justify">45) Persuasivo - Artístico-plástico: Decoradores de interiores, Dibujantes publicitarios.</p>'
                    break
                  case '46':
                    $qw =
                      $qw +
                      '<p align="justify">46) Persuasivo - Literario: Escritores, Juristas, Periodistas, Profesores (Letras). </p>'
                    break
                  case '47':
                    $qw =
                      $qw +
                      '<p align="justify">47) Persuasivo - Musical: Actores y Actrices, Directores de orquesta, Profesores de canto, Profesores de música.</p>'
                    break
                  case '48':
                    $qw =
                      $qw +
                      '<p align="justify">48) Persuasivo - Servicio Social: Comunicadores sociales, Consejeros vocacionales, Juristas (en general), Pedagogos (en general), Trabajadores sociales. </p>'
                    break
                  case '56':
                    $qw =
                      $qw +
                      '<p align="justify">56) Artístico-plástico - Literario: Actores y Actrices, Escritores.</p>'
                    break
                  case '58':
                    $qw =
                      $qw +
                      '<p align="justify">58) Artístico-plástico - Servicio Social: Profesores de artes manuales.</p>'
                    break
                  case '67':
                    $qw =
                      $qw +
                      '<p align="justify">67) Literario - Musical: Actores y Actrices, Profesores de música.</p>'
                    break
                  case '68':
                    $qw =
                      $qw +
                      '<p align="justify">68)Literario - Servicio Social: Escritores, Periodistas, Profesores de ciencias sociales, Profesores de filosofía, Profesores de letras, Trabajadores sociales. </p>'
                    break
                  case '69':
                    $qw =
                      $qw +
                      '<p align="justify">69) Literario - Trabajo de Oficina: Bibliotecarios, Secretarios.</p>'
                    break
                  case '78':
                    $qw =
                      $qw +
                      '<p align="justify">78) Músico - Servicio Social: Músicos, Profesores de música, Profesores de baile. </p>'
                    break
                  case '89':
                    $qw =
                      $qw +
                      '<p align="justify">89) Servicio Social - Oficina: Profesores de materias comerciales.</p>'
                    break
                  default:
                    break
                }

                $qw = $qw + ' * '
              }
            }
          }

          $t +=
            '<p align="justify"><b>PREFERENCIAS VOCACIONALES: </b></p>' +
            $qs +
            ' ' +
            $qt +
            ' ' +
            $qtt +
            ' ' +
            $qx +
            ' ' +
            $qw

          setTs($t)
        } else if (props.id.testId === 21) {
          setNamePrueba('Competencias COMPET EQ')

          response.data.testFactor.forEach((items) => {
            if (items.testsResultsTpm.score <= 25) {
              outFactor.push({
                id: items.testsResultsTpm.factorId,
                score: items.testsResultsTpm.score,
                name: items.factor.name.toUpperCase(),
                scale: 'RIESGO',
              })
            } else if (
              items.testsResultsTpm.score >= 26 &&
              items.testsResultsTpm.score <= 50
            ) {
              outFactor.push({
                id: items.testsResultsTpm.factorId,
                score: items.testsResultsTpm.score,
                name: items.factor.name.toUpperCase(),
                scale: 'VULNERABLE',
              })
            } else if (
              items.testsResultsTpm.score >= 51 &&
              items.testsResultsTpm.score <= 75
            ) {
              outFactor.push({
                id: items.testsResultsTpm.factorId,
                score: items.testsResultsTpm.score,
                name: items.factor.name.toUpperCase(),
                scale: 'HÁBIL',
              })
            } else if (items.testsResultsTpm.score >= 76) {
              outFactor.push({
                id: items.testsResultsTpm.factorId,
                score: items.testsResultsTpm.score,
                name: items.factor.name.toUpperCase(),
                scale: 'ÓPTIMO',
              })
            }
          })
        } else {
          setLoader(false)
          setAlertNotification(true)
        }

        setRows(outFactor)
        setLoader(false)
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setRows([])
        }
      })
  }, [urlServices, keyAplication])

  let optionsData = [],
    optionsData1 = [],
    optionsData2 = [],
    optionsData3 = []

  if (props.id.testId === 1) {
    if (rowsFactors.length > 0) {
      let outFactor = []
      let item = {}

      item = rowsFactors.find((f) => f.factorId === 84)
      // EXPRESIVO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [Number(item.testsResultsTpm.score)],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 170)
      // RESERVADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 123)
      // MAS INTELIGENTE
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', Number(item.testsResultsTpm.score)],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 124)
      // MENOS INTELIGENTE
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 62)
      // EMOCIONALMENTE ESTABLE
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', Number(item.testsResultsTpm.score)],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 99)
      // INESTABLE
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 7)
      // AFIRMATIVO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', Number(item.testsResultsTpm.score)],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 186)
      // SUMISO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 57)
      // DESPREOCUPADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', Number(item.testsResultsTpm.score)],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 182)
      // SOBRIO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 78)
      // ESCRUPULOSO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', '', Number(item.testsResultsTpm.score)],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 4)
      // ACTIVO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', '', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 17)
      // AVENTURADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', '', '', Number(item.testsResultsTpm.score)],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 165)
      // RESCATADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', '', '', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 6)
      // AFECTUOSO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', '', '', '', Number(item.testsResultsTpm.score)],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 19)
      // CALCULADOR
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', '', '', '', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 54)
      // DESCONFIADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 40)
      // CONFIADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 92)
      // IMAGINATIVO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 152)
      // PRACTICO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 14)
      // ASTUTO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 102)
      // INGENUO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 12)
      // APRENSIVO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 176)
      // SEGURO DE SI
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 46)
      // CONSERVADOR
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 82)
      // EXPERIMENTADOR
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 16)
      // AUTOSUFICIENTE
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 53)
      // DEPENDIENTE
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 50)
      // CONTROLADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 96)
      // INCONTROLADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 191)
      // TENSO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 168)
      // RELAJADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.score),
        ],
        color:
          Number(item.testsResultsTpm.score) <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 40 &&
              Number(item.testsResultsTpm.score) <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 60
            ? '#7ccd7c'
            : '',
      })

      optionsData = {
        chart: {
          type: 'column',
        },
        title: {
          text: '',
        },
        xAxis: {
          categories: [
            'A',
            'B',
            'C',
            'E',
            'F',
            'G',
            'H',
            'I',
            'L',
            'M',
            'N',
            'O',
            'Q1',
            'Q2',
            'Q3',
            'Q4',
          ],
          // crosshair: true
        },
        yAxis: {
          min: 0,
          max: 100,
          tickInterval: 10,
          title: {
            text: '',
          },
          labels: {
            style: {
              fontSize: '10px',
              fontFamily: 'Verdana, sans-serif',
            },
          },
          plotBands: [
            {
              // Promedio
              from: 40,
              to: 60,
              color: 'transparent',
              borderWidth: 1,
              borderColor: 'black',
              label: {
                text: '<b>GENTE PROMEDIO<b>',
                style: {
                  color: '#606060',
                },
              },
              zIndex: 5,
            },
          ],
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {
            return this.series.name + ': ' + this.y + '%'
          },
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: true,
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '10px',
                fontFamily: 'Verdana, sans-serif',
              },
              align: 'left',
              verticalAlign: 'bottom',
              y: -5,
              rotation: -90,
              formatter: function (e) {
                if (
                  this.series.name === 'EXPRESIVO' ||
                  this.series.name === 'MÁS INTELIGENTE' ||
                  this.series.name === 'EMOCIONALMENTE ESTABLE' ||
                  this.series.name === 'AFIRMATIVO' ||
                  this.series.name === 'DESPREOCUPADO' ||
                  this.series.name === 'ESCRUPULOSO' ||
                  this.series.name === 'AVENTURADO' ||
                  this.series.name === 'AFECTUOSO' ||
                  this.series.name === 'DESCONFIADO' ||
                  this.series.name === 'IMAGINATIVO' ||
                  this.series.name === 'ASTUTO' ||
                  this.series.name === 'APRENSIVO' ||
                  this.series.name === 'CONSERVADOR' ||
                  this.series.name === 'AUTOSUFICIENTE' ||
                  this.series.name === 'CONTROLADO' ||
                  this.series.name === 'TENSO'
                ) {
                  e.align = 'right'
                  e.verticalAlign = 'top'
                  e.y = 5
                }

                return this.series.name
              },
            },
          },
        },
        series: outFactor,
      }
    }
  } else if (props.id.testId === 3) {
    if (rowsFactors.length > 0) {
      const factors = [
        parseFloat(
          rowsFactors.find((f) => f.factorId === 185).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 158).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 159).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 45).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 83).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 44).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 107).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 51).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 61).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 39).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 55).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 63).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 148).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 118).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 162).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 150).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 106).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 172).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 20).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 30).testsResultsTpm.score
        ),
        parseFloat(
          rowsFactors.find((f) => f.factorId === 139).testsResultsTpm.score
        ),
      ]

      optionsData = {
        chart: {
          marginTop: 20,
          marginLeft: 60,
        },
        title: {
          text: '',
          y: -10,
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: '',
          },
          tickInterval: 10,
          plotBands: [
            {
              // Riesgo
              from: 0,
              to: 25,
              color: 'transparent',
              label: {
                text: '<b>RIESGO<b>',
                style: {
                  color: '#606060',
                },
                x: -40,
                rotation: 270,
                textAlign: 'center',
              },
              borderWidth: 1,
              borderColor: 'black',
              zIndex: 3,
            },
            {
              // Vulnerable
              from: 25,
              to: 50,
              color: 'transparent',
              label: {
                text: '<b>VULNERABLE<b>',
                style: {
                  color: '#606060',
                },
                x: -40,
                rotation: 270,
                textAlign: 'center',
              },
              borderWidth: 1,
              borderColor: 'black',
              zIndex: 3,
            },
            {
              // Hábil
              from: 50,
              to: 75,
              color: 'transparent',
              label: {
                text: '<b>HÁBIL<b>',
                style: {
                  color: '#606060',
                },
                x: -40,
                rotation: 270,
                textAlign: 'center',
              },
              borderWidth: 1,
              borderColor: 'black',
              zIndex: 3,
            },
            {
              // Óptimo
              from: 75,
              to: 100,
              color: 'transparent',
              label: {
                text: '<b>ÓPTIMO<b>',
                style: {
                  color: '#606060',
                },
                x: -40,
                rotation: 270,
                textAlign: 'center',
              },
              borderWidth: 1,
              borderColor: 'black',
              zIndex: 3,
            },
          ],
        },
        xAxis: {
          categories: rowsFactors.map((items, i) => '<b>' + (i + 1) + '</b>'),
          plotBands: [
            {
              // Ambiente actual
              from: -1,
              to: 2.5,
              color: 'transparent',
              label: {
                text: '<b>Ambiente actual<b>',
                style: {
                  color: '#606060',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
            {
              // Conocimiento
              from: 2.5,
              to: 5.5,
              color: 'transparent',
              label: {
                text: '<b>Conocimiento<b>',
                style: {
                  color: '#606060',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
            {
              // Competencias emocionales
              from: 5.5,
              to: 10.5,
              color: 'transparent',
              label: {
                text: '<b>Competencias emocionales<b>',
                style: {
                  color: '#606060',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
            {
              // Valores y creencias
              from: 10.5,
              to: 16.5,
              color: 'transparent',
              label: {
                text: '<b>Valores y creencias<b>',
                style: {
                  color: '#606060',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
            {
              // Resultados
              from: 16.5,
              to: 20.5,
              color: 'transparent',
              label: {
                text: '<b>Resultados',
                style: {
                  color: '#606060',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
          ],
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: 0,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
        },
        series: [
          {
            name: 'Escala',
            data: factors,
            showInLegend: false,
          },
        ],
      }
    }
  } else if (props.id.testId === 6) {
    if (rowsFactors.length > 0) {
      const $L = parseFloat(
        rowsFactors.find((f) => f.factorId === 77).testsResultsTpm.scoreBase
      )
      const $F = parseFloat(
        rowsFactors.find((f) => f.factorId === 75).testsResultsTpm.scoreBase
      )
      const $K = parseFloat(
        rowsFactors.find((f) => f.factorId === 76).testsResultsTpm.scoreBase
      )
      const $Dp = parseFloat(
        rowsFactors.find((f) => f.factorId === 69).testsResultsTpm.scoreBase
      )
      const $Pa = parseFloat(
        rowsFactors.find((f) => f.factorId === 71).testsResultsTpm.scoreBase
      )
      const $Ma = parseFloat(
        rowsFactors.find((f) => f.factorId === 74).testsResultsTpm.scoreBase
      )
      const $Si = parseFloat(
        rowsFactors.find((f) => f.factorId === 66).testsResultsTpm.scoreBase
      )
      const $Hs = parseFloat(
        rowsFactors.find((f) => f.factorId === 34).testsResultsTpm.scoreBase
      )
      const $D = parseFloat(
        rowsFactors.find((f) => f.factorId === 67).testsResultsTpm.scoreBase
      )
      const $Hi = parseFloat(
        rowsFactors.find((f) => f.factorId === 68).testsResultsTpm.scoreBase
      )
      const $Mf = parseFloat(
        rowsFactors.find((f) => f.factorId === 70).testsResultsTpm.scoreBase
      )
      const $Pt = parseFloat(
        rowsFactors.find((f) => f.factorId === 72).testsResultsTpm.scoreBase
      )
      const $Es = parseFloat(
        rowsFactors.find((f) => f.factorId === 73).testsResultsTpm.scoreBase
      )

      const $L_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 77).testsResultsTpm.score
      )
      const $F_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 75).testsResultsTpm.score
      )
      const $K_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 76).testsResultsTpm.score
      )
      const $Dp_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 69).testsResultsTpm.score
      )
      const $Pa_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 71).testsResultsTpm.score
      )
      const $Ma_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 74).testsResultsTpm.score
      )
      const $Si_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 66).testsResultsTpm.score
      )
      const $Hs_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 34).testsResultsTpm.score
      )
      const $D_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 67).testsResultsTpm.score
      )
      const $Hi_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 68).testsResultsTpm.score
      )
      const $Mf_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 70).testsResultsTpm.score
      )
      const $Pt_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 72).testsResultsTpm.score
      )
      const $Es_T = parseFloat(
        rowsFactors.find((f) => f.factorId === 73).testsResultsTpm.score
      )

      const $cir = [
        $L_T,
        $F_T,
        $K_T,
        $Hs_T,
        $D_T,
        $Hi_T,
        $Dp_T,
        $Mf_T,
        $Pa_T,
        $Pt_T,
        $Es_T,
        $Ma_T,
        $Si_T,
      ]

      optionsData = {
        title: {
          text: '',
        },
        yAxis: [
          {
            min: 0,
            max: 150,
            title: {
              text: 'Puntajes en bruto (T)',
            },
            tickInterval: 10,
            plotBands: [
              {
                // Promedio
                from: 50,
                to: 90,
                color: 'transparent',
                borderWidth: 1,
                borderColor: 'black',
                label: {
                  text: '',
                  style: {
                    color: '#606060',
                  },
                },
                zIndex: 5,
              },
            ],
          },
          {
            title: {
              text: '',
            },
            opposite: true,
          },
        ],
        xAxis: {
          categories: [
            'L',
            'F',
            'K',
            'Hs',
            'D',
            'Hi',
            'Dp',
            'Mf',
            'Pa',
            'Pt',
            'Es',
            'Ma',
            'Si',
          ],
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: true,
            },
            pointStart: 0,
          },
          scatter: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '9px',
              },
              color: 'black',
            },
          },
          columnrange: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y === this.point.low ? 0 : 100
              },
            },
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormatter: function () {
            const seriesName = this.series.name
            const pointY = this.y
            let label = ''

            if (seriesName === 'Valores de escalas') {
              if (pointY === $L_T - $L) label = $L
              else if (pointY === $F_T - $F) label = $F
              else if (pointY === $K_T - $K) label = $K
              else if (pointY === $Hs_T - $Hs) label = $Hs
              else if (pointY === $D_T - $D) label = $D
              else if (pointY === $Hi_T - $Hi) label = $Hi
              else if (pointY === $Dp_T - $Dp) label = $Dp
              else if (pointY === $Mf_T - $Mf) label = $Mf
              else if (pointY === $Pa_T - $Pa) label = $Pa
              else if (pointY === $Pt_T - $Pt) label = $Pt
              else if (pointY === $Es_T - $Es) label = $Es
              else if (pointY === $Ma_T - $Ma) label = $Ma
              else if (pointY === $Si_T - $Si) label = $Si
            } else {
              label = pointY
            }

            return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${label} puntos</b><br/>`
          },
          shared: true,
        },
        series: [
          {
            type: 'line',
            name: 'Puntajes en bruto',
            data: [
              $cir[0],
              $cir[1],
              $cir[2],
              $cir[3],
              $cir[4],
              $cir[5],
              $cir[6],
              $cir[7],
              $cir[8],
              $cir[9],
              $cir[10],
              $cir[11],
              $cir[12],
            ],
            showInLegend: true,
            zIndex: 1,
          },
          {
            type: 'columnrange',
            data: [
              [$L_T - $L, $L_T - $L + 100],
              [$F_T - $F, $F_T - $F + 100],
              [$K_T - $K, $K_T - $K + 100],
              [$Hs_T - $Hs, $Hs_T - $Hs + 100],
              [$D_T - $D, $D_T - $D + 100],
              [$Hi_T - $Hi, $Hi_T - $Hi + 100],
              [$Dp_T - $Dp, $Dp_T - $Dp + 100],
              [$Mf_T - $Mf, $Mf_T - $Mf + 100],
              [$Pa_T - $Pa, $Pa_T - $Pa + 100],
              [$Pt_T - $Pt, $Pt_T - $Pt + 100],
              [$Es_T - $Es, $Es_T - $Es + 100],
              [$Ma_T - $Ma, $Ma_T - $Ma + 100],
              [$Si_T - $Si, $Si_T - $Si + 100],
            ],
            name: 'Valores de escalas',
            yAxis: 0,
            color: '#e3e4e5',
          },
          {
            name: 'data',
            type: 'scatter',
            data: [
              {
                x: 0,
                y: $L_T,
                dataLabels: {
                  format: `${$L}`,
                },
              },
              {
                x: 1,
                y: $F_T,
                dataLabels: {
                  format: `${$F}`,
                },
              },
              {
                x: 2,
                y: $K_T,
                dataLabels: {
                  format: `${$K}`,
                },
              },
              {
                x: 3,
                y: $Hs_T,
                dataLabels: {
                  format: `${$Hs}`,
                },
              },
              {
                x: 4,
                y: $D_T,
                dataLabels: {
                  format: `${$D}`,
                },
              },
              {
                x: 5,
                y: $Hi_T,
                dataLabels: {
                  format: `${$Hi}`,
                },
              },
              {
                x: 6,
                y: $Dp_T,
                dataLabels: {
                  format: `${$Dp}`,
                },
              },
              {
                x: 7,
                y: $Mf_T,
                dataLabels: {
                  format: `${$Mf}`,
                },
              },
              {
                x: 8,
                y: $Pa_T,
                dataLabels: {
                  format: `${$Pa}`,
                },
              },
              {
                x: 9,
                y: $Pt_T,
                dataLabels: {
                  format: `${$Pt}`,
                },
              },
              {
                x: 10,
                y: $Es_T,
                dataLabels: {
                  format: `${$Es}`,
                },
              },
              {
                x: 11,
                y: $Ma_T,
                dataLabels: {
                  format: `${$Ma}`,
                },
              },
              {
                x: 12,
                y: $Si_T,
                dataLabels: {
                  format: `${$Si}`,
                },
              },
            ],
            accessibility: {
              exposeAsGroupOnly: true,
            },
            marker: {
              enabled: false,
              radius: 1.5,
            },
          },
        ],
      }
    }
  } else if (props.id.testId === 7) {
    if (rowsFactors.length > 0) {
      const $im = parseInt(
        rowsFactors.find((f) => f.factorId === 93).testsResultsTpm.score
      )
      const $co = parseInt(
        rowsFactors.find((f) => f.factorId === 32).testsResultsTpm.score
      )
      const $ap = parseInt(
        rowsFactors.find((f) => f.factorId === 11).testsResultsTpm.score
      )
      const $au = parseInt(
        rowsFactors.find((f) => f.factorId === 15).testsResultsTpm.score
      )
      const $orr = parseInt(
        rowsFactors.find((f) => f.factorId === 141).testsResultsTpm.score
      )
      const $PR = parseInt(
        rowsFactors.find((f) => f.factorId === 157).testsResultsTpm.score
      )
      const $cl = parseInt(
        rowsFactors.find((f) => f.factorId === 79).testsResultsTpm.score
      )
      const $cn = parseInt(
        rowsFactors.find((f) => f.factorId === 48).testsResultsTpm.score
      )
      const $inn = parseInt(
        rowsFactors.find((f) => f.factorId === 105).testsResultsTpm.score
      )
      const $cf = parseInt(
        rowsFactors.find((f) => f.factorId === 35).testsResultsTpm.score
      )

      const $cir = [$im, $co, $ap, $au, $orr, $PR, $cl, $cn, $inn, $cf]

      optionsData = {
        title: {
          text: '',
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: 'PERFIL',
          },
          tickInterval: 10,
        },
        xAxis: {
          categories: [
            'IMPLICACIÓN (IM)',
            'COHESIÓN (CO)',
            'APOYO (AP)',
            'AUTO-REALIZACIÓN (AU)',
            'ORGANIZACIÓN (OR)',
            'PRESIÓN (PR)',
            'ESTABILIDAD/CAMBIO (CL)',
            'CONTROL (CN)',
            'INNOVACIÓN (IN)',
            'COMODIDAD (CF)',
          ],
          labels: {
            rotation: -45,
          },
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: true,
            },
            pointStart: 0,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} puntos</b><br/>',
        },
        series: [
          {
            name: 'Escala',
            data: [
              $cir[0],
              $cir[1],
              $cir[2],
              $cir[3],
              $cir[4],
              $cir[5],
              $cir[6],
              $cir[7],
              $cir[8],
              $cir[9],
            ],
            showInLegend: false,
          },
        ],
      }
    }
  } else if (props.id.testId === 8) {
    if (rowsFactors.length > 0) {
      let outFactor = []
      let item = {}

      item = rowsFactors.find((f) => f.factorId === 80)
      // ESTIMULO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 25
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 25 &&
              Number(item.testsResultsTpm.score) <= 75
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 75
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 41)
      // CONFORMIDAD
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 25
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 25 &&
              Number(item.testsResultsTpm.score) <= 75
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 75
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 167)
      // RECONOCIMIENTO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 25
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 25 &&
              Number(item.testsResultsTpm.score) <= 75
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 75
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 97)
      // INDEPENDENCIA
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 25
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 25 &&
              Number(item.testsResultsTpm.score) <= 75
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 75
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 18)
      // BENEVOLENCIA
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 25
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 25 &&
              Number(item.testsResultsTpm.score) <= 75
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 75
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 121)
      // LIDERAZGO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', '', Number(item.testsResultsTpm.score)],
        color:
          Number(item.testsResultsTpm.score) <= 25
            ? '#d1b688'
            : Number(item.testsResultsTpm.score) > 25 &&
              Number(item.testsResultsTpm.score) <= 75
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.score) > 75
            ? '#7ccd7c'
            : '',
      })

      optionsData = {
        chart: {
          type: 'column',
          height: 355,
          marginRight: 30,
        },
        title: {
          text: '',
          // align: "center",
        },
        xAxis: {
          categories: [
            'Estímulo',
            'Conformidad',
            'Reconocimiento',
            'Independencia',
            'Benevolencia',
            'Liderazgo',
          ],
        },
        yAxis: {
          min: 0,
          max: 100,
          tickInterval: 10,
          title: {
            text: '',
          },
          plotBands: [
            {
              // VALORES BAJOS
              from: 0,
              to: 25,
              color: 'transparent',
              label: {
                text: '<b>VALORES BAJOS<b>',
                style: {
                  color: '#606060',
                },
                x: 20,
                rotation: 270,
                textAlign: 'center',
                align: 'right',
              },
              borderWidth: 1,
              borderColor: 'black',
              zIndex: 3,
            },
            {
              // Promedio
              from: 25,
              to: 75,
              color: 'transparent',
              borderWidth: 1,
              borderColor: 'black',
              label: {
                text: '<b>GENTE PROMEDIO<b>',
                style: {
                  color: '#606060',
                },
              },
              zIndex: 5,
            },
            {
              // VALORES ALTOS
              from: 75,
              to: 100,
              color: 'transparent',
              label: {
                text: '<b>VALORES ALTOS<b>',
                style: {
                  color: '#606060',
                },
                x: 20,
                rotation: 270,
                textAlign: 'center',
                align: 'right',
              },
              borderWidth: 1,
              borderColor: 'black',
              zIndex: 3,
            },
          ],
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {
            return this.series.name + ': ' + this.y + '%'
          },
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
          },
        },
        series: outFactor,
      }
    }
  } else if (props.id.testId === 13) {
    if (rows.length > 0) {
      let array = ['D', 'I', 'S', 'C']

      optionsData1 = {
        title: {
          text: 'Situación Motivante',
          style: {
            fontSize: '15px',
          },
        },
        yAxis: {
          min: 0,
          max: 100,
          tickInterval: 10,
          title: {
            text: '',
          },
        },
        xAxis: {
          categories: array,
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: 0,
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
        },
        series: [
          {
            name: '',
            data: [
              parseFloat(rows[2].m),
              parseFloat(rows[3].m),
              parseFloat(rows[1].m),
              parseFloat(rows[0].m),
            ],
            color: 'orange',
          },
        ],
      }

      optionsData2 = {
        title: {
          text: 'Situación Presionante',
          style: {
            fontSize: '15px',
          },
        },
        yAxis: {
          min: 0,
          max: 100,
          tickInterval: 10,
          title: {
            text: '',
          },
        },

        xAxis: {
          categories: array,
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: 0,
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
        },
        series: [
          {
            name: '',
            data: [
              parseFloat(rows[2].l),
              parseFloat(rows[3].l),
              parseFloat(rows[1].l),
              parseFloat(rows[0].l),
            ],
            color: 'green',
          },
        ],
      }

      optionsData3 = {
        title: {
          text: 'Situación Cotidiana',
          style: {
            fontSize: '15px',
          },
        },
        yAxis: {
          min: 0,
          max: 100,
          tickInterval: 10,
          title: {
            text: '',
          },
        },

        xAxis: {
          categories: array,
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: 0,
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
        },
        series: [
          {
            name: '',
            data: [
              parseFloat(rows[2].valueRestaML),
              parseFloat(rows[3].valueRestaML),
              parseFloat(rows[1].valueRestaML),
              parseFloat(rows[0].valueRestaML),
            ],
            color: 'red',
          },
        ],
      }
    }
  } else if (props.id.testId === 14) {
    if (rowsFactors.length > 0) {
      const $p1 = [
        rowsFactors.find((f) => f.factorId === 101).testsResultsTpm.scoreBase,
        rowsFactors.find((f) => f.factorId === 36).testsResultsTpm.scoreBase,
        rowsFactors.find((f) => f.factorId === 179).testsResultsTpm.scoreBase,
        rowsFactors.find((f) => f.factorId === 177).testsResultsTpm.scoreBase,
        rowsFactors.find((f) => f.factorId === 13).testsResultsTpm.scoreBase,
        rowsFactors.find((f) => f.factorId === 119).testsResultsTpm.scoreBase,
        rowsFactors.find((f) => f.factorId === 9).testsResultsTpm.scoreBase,
        rowsFactors.find((f) => f.factorId === 140).testsResultsTpm.scoreBase,
        rowsFactors.find((f) => f.factorId === 29).testsResultsTpm.scoreBase,
        rowsFactors.find((f) => f.factorId === 178).testsResultsTpm.scoreBase,
        rowsFactors.find((f) => f.factorId === 31).testsResultsTpm.scoreTC,
        rowsFactors.find((f) => f.factorId === 31).testsResultsTpm.scoreBase,
      ]

      const $cir = Array(10)

      //cont1
      switch (true) {
        case $p1[0] >= 0 && $p1[0] <= 5:
          $cir[0] = 10
          break
        case $p1[0] >= 6 && $p1[0] <= 7:
          $cir[0] = 20
          break
        case $p1[0] >= 8 && $p1[0] <= 9:
          $cir[0] = 30
          break
        case $p1[0] >= 10 && $p1[0] <= 13:
          $cir[0] = 40
          break
        case $p1[0] == 14:
          $cir[0] = 50
          break
        case $p1[0] == 15:
          $cir[0] = 60
          break
        case $p1[0] == 16:
          $cir[0] = 70
          break
        default:
          break
      }

      //cont2
      switch (true) {
        case $p1[1] >= 0 && $p1[1] <= 8:
          $cir[1] = 10
          break
        case $p1[1] == 10:
          $cir[1] = 20
          break
        case $p1[1] == 12:
          $cir[1] = 30
          break
        case $p1[1] >= 14 && $p1[1] <= 16:
          $cir[1] = 40
          break
        case $p1[1] == 18:
          $cir[1] = 50
          break
        case $p1[1] == 20:
          $cir[1] = 60
          break
        case $p1[1] == 22:
          $cir[1] = 70
          break
        default:
          break
      }

      //cont3
      switch (true) {
        case $p1[2] >= 0 && $p1[2] <= 4:
          $cir[2] = 10
          break
        case $p1[2] >= 5 && $p1[2] <= 11:
          $cir[2] = 20
          break
        case $p1[2] >= 12 && $p1[2] <= 18:
          $cir[2] = 30
          break
        case $p1[2] >= 19 && $p1[2] <= 25:
          $cir[2] = 40
          break
        case $p1[2] >= 26 && $p1[2] <= 27:
          $cir[2] = 50
          break
        case $p1[2] >= 28 && $p1[2] <= 29:
          $cir[2] = 60
          break
        case $p1[2] == 30:
          $cir[2] = 70
          break
        default:
          $cir[2] = 70
          break
      }

      //cont4
      switch (true) {
        case $p1[3] >= 0 && $p1[3] <= 3:
          $cir[3] = 10
          break
        case $p1[3] >= 4 && $p1[3] <= 5:
          $cir[3] = 20
          break
        case $p1[3] >= 6 && $p1[3] <= 7:
          $cir[3] = 30
          break
        case $p1[3] >= 8 && $p1[3] <= 12:
          $cir[3] = 40
          break
        case $p1[3] >= 13 && $p1[3] <= 14:
          $cir[3] = 50
          break
        case $p1[3] >= 15 && $p1[3] <= 16:
          $cir[3] = 60
          break
        case $p1[3] >= 17 && $p1[3] <= 18:
          $cir[3] = 70
          break
        default:
          break
      }

      //cont5
      switch (true) {
        case $p1[4] >= 0 && $p1[4] <= 3:
          $cir[4] = 10
          break
        case $p1[4] >= 4 && $p1[4] <= 7:
          $cir[4] = 20
          break
        case $p1[4] >= 8 && $p1[4] <= 10:
          $cir[4] = 30
          break
        case $p1[4] >= 12 && $p1[4] <= 14:
          $cir[4] = 40
          break
        case $p1[4] >= 16 && $p1[4] <= 18:
          $cir[4] = 50
          break
        case $p1[4] >= 20 && $p1[4] <= 22:
          $cir[4] = 60
          break
        case $p1[4] == 24:
          $cir[4] = 70
          break
        default:
          break
      }

      //cont6
      switch (true) {
        case $p1[5] >= 0 && $p1[5] <= 6:
          $cir[5] = 10
          break
        case $p1[5] >= 7 && $p1[5] <= 9:
          $cir[5] = 20
          break
        case $p1[5] >= 10 && $p1[5] <= 12:
          $cir[5] = 30
          break
        case $p1[5] >= 13 && $p1[5] <= 15:
          $cir[5] = 40
          break
        case $p1[5] >= 16 && $p1[5] <= 17:
          $cir[5] = 50
          break
        case $p1[5] >= 18 && $p1[5] <= 19:
          $cir[5] = 60
          break
        case $p1[5] == 20:
          $cir[5] = 70
          break
        default:
          $cir[5] = 10
          break
      }

      //cont7
      switch (true) {
        case $p1[6] >= 0 && $p1[6] <= 6:
          $cir[6] = 10
          break
        case $p1[6] >= 7 && $p1[6] <= 9:
          $cir[6] = 20
          break
        case $p1[6] >= 10 && $p1[6] <= 12:
          $cir[6] = 30
          break
        case $p1[6] >= 13 && $p1[6] <= 15:
          $cir[6] = 40
          break
        case $p1[6] >= 16 && $p1[6] <= 17:
          $cir[6] = 50
          break
        case $p1[6] >= 18 && $p1[6] <= 19:
          $cir[6] = 60
          break
        case $p1[6] == 20:
          $cir[6] = 70
          break
        default:
          break
      }

      //cont8
      switch (true) {
        case $p1[7] >= 0 && $p1[7] <= 1:
          $cir[7] = 10
          break
        case $p1[7] >= 2 && $p1[7] <= 4:
          $cir[7] = 20
          break
        case $p1[7] >= 5 && $p1[7] <= 7:
          $cir[7] = 30
          break
        case $p1[7] >= 8 && $p1[7] <= 11:
          $cir[7] = 40
          break
        case $p1[7] >= 12 && $p1[7] <= 13:
          $cir[7] = 50
          break
        case $p1[7] >= 14 && $p1[7] <= 15:
          $cir[7] = 60
          break
        case $p1[7] >= 16 && $p1[7] <= 17:
          $cir[7] = 70
          break
        default:
          $cir[7] = 10
          break
      }

      //cont9
      switch (true) {
        case $p1[8] >= 0 && $p1[8] <= 6:
          $cir[8] = 10
          break
        case $p1[8] >= 7 && $p1[8] <= 9:
          $cir[8] = 20
          break
        case $p1[8] >= 10 && $p1[8] <= 12:
          $cir[8] = 30
          break
        case $p1[8] >= 13 && $p1[8] <= 15:
          $cir[8] = 40
          break
        case $p1[8] == 16:
          $cir[8] = 50
          break
        case $p1[8] == 17:
          $cir[8] = 60
          break
        case $p1[8] == 18:
          $cir[8] = 70
          break
        default:
          break
      }

      //cont10
      switch (true) {
        case $p1[9] >= 0 && $p1[9] <= 4:
          $cir[9] = 10
          break
        case $p1[9] >= 6 && $p1[9] <= 8:
          $cir[9] = 20
          break
        case $p1[9] >= 10 && $p1[9] <= 12:
          $cir[9] = 30
          break
        case $p1[9] >= 14 && $p1[9] <= 16:
          $cir[9] = 40
          break
        case $p1[9] == 18:
          $cir[9] = 50
          break
        case $p1[9] == 20:
          $cir[9] = 60
          break
        case $p1[9] == 22:
          $cir[9] = 70
          break
        default:
          break
      }

      const $ci = $p1[10]
      switch (true) {
        case $ci >= 0 && $ci <= 80:
          $cir[10] = 10
          break
        case $ci >= 81 && $ci <= 88:
          $cir[10] = 20
          break
        case $ci >= 89 && $ci <= 96:
          $cir[10] = 30
          break
        case $ci >= 97 && $ci <= 111:
          $cir[10] = 40
          break
        case $ci >= 112 && $ci <= 119:
          $cir[10] = 50
          break
        case $ci >= 120 && $ci <= 127:
          $cir[10] = 60
          break
        case $ci >= 128 && $ci <= 300:
          $cir[10] = 70
          break
        default:
          break
      }

      optionsData = {
        title: {
          text: '',
        },
        yAxis: {
          min: 0,
          max: 80,
          title: {
            text: 'Rangos',
          },
          labels: {
            formatter: function () {
              var label = this.axis.defaultLabelFormatter.call(this)

              if (label === '70') return 'EXCEPCIONAL'
              else if (label === '60') return 'MUY SOBRESALIENTE'
              else if (label === '50') return 'SOBRESALIENTE'
              else if (label === '40') return 'SATISFACTORIO'
              else if (label === '30') return 'SUFICIENTE'
              else if (label === '20') return 'DEFICIENTE'
              else if (label === '10') return 'MUY DEFICIENTE'
              return ''
            },
          },
          tickInterval: 10,
        },
        xAxis: {
          categories: [
            'CONOCIMIENTOS',
            'COMPRENSIÓN',
            'SIGNIFICADO DE PALABRAS',
            'SELECCIÓN LÓGICA',
            'ARITMÉTICA',
            'JUICIO PRÁCTICO',
            'ANALOGÍAS',
            'ORDENAMIENTO DE FRASES',
            'CLASIFICACIÓN',
            'SERIACIÓN',
            'GENERAL',
          ],
          labels: {
            rotation: -45,
          },
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: true,
            },
            pointStart: 0,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormatter: function () {
            const pointY = this.y
            let label = ''

            if (pointY === 70) label = 'EXCEPCIONAL'
            else if (pointY === 60) label = 'MUY SOBRESALIENTE'
            else if (pointY === 50) label = 'SOBRESALIENTE'
            else if (pointY === 40) label = 'SATISFACTORIO'
            else if (pointY === 30) label = 'SUFICIENTE'
            else if (pointY === 20) label = 'DEFICIENTE'
            else if (pointY === 10) label = 'MUY DEFICIENTE'

            return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${label}</b><br/>`
          },
        },
        series: [
          {
            name: 'Escala',
            data: [
              $cir[0],
              $cir[1],
              $cir[2],
              $cir[3],
              $cir[4],
              $cir[5],
              $cir[6],
              $cir[7],
              $cir[8],
              $cir[9],
              $cir[10],
            ],
            showInLegend: false,
          },
        ],
      }
    }
  } else if (props.id.testId === 16) {
    if (rowsFactors.length > 0) {
      const $r1 = parseInt(
        rowsFactors.find((f) => f.factorId === 89).testsResultsTpm.score
      )
      const $r2 = parseInt(
        rowsFactors.find((f) => f.factorId === 25).testsResultsTpm.score
      )
      const $r3 = parseInt(
        rowsFactors.find((f) => f.factorId === 26).testsResultsTpm.score
      )
      const $r4 = parseInt(
        rowsFactors.find((f) => f.factorId === 91).testsResultsTpm.score
      )
      const $r5 = parseInt(
        rowsFactors.find((f) => f.factorId === 188).testsResultsTpm.score
      )
      const $r6 = parseInt(
        rowsFactors.find((f) => f.factorId === 22).testsResultsTpm.score
      )

      const $cir = [$r1, $r2, $r3, $r5, $r4, $r6]

      optionsData = {
        title: {
          text: '',
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: '',
          },
          tickInterval: 10,
          labels: {
            formatter: function () {
              var label = this.axis.defaultLabelFormatter.call(this)

              if (label === '100') return '<b>MUY SUPERIOR</b> 100'
              else if (label === '90') return '<b>SUPERIOR AL PROMEDIO</b> 90'
              else if (label === '80') return '<b>PROMEDIO</b> 80'
              else if (label === '70') return '70'
              else if (label === '60') return '60'
              else if (label === '50') return '50'
              else if (label === '40') return '<b>INFERIOR AL PROMEDIO</b> 40'
              else if (label === '30') return '30'
              else if (label === '20') return '<b>DEFICIENTE</b> 20'
              else if (label === '10') return '10'
              else if (label === '0') return '0'
              return ''
            },
          },
        },
        xAxis: {
          categories: [
            '1. Habilidad de <br>supervisión',
            '2. Capacidad de decisión <br>en las relaciones humanas',
            '3. Capacidad de evaluación <br>de problemas en las <br>relaciones interpersonales',
            '4. Habilidad para establecer <br>relaciones interpersonales',
            '5. Tacto y sentido común <br>en las relaciones humanas',
            '6. Capacidad de <br>adaptación',
          ],
          labels: {
            rotation: -45,
          },
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: true,
            },
            pointStart: 0,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
        },
        series: [
          {
            name: 'Escala',
            data: [
              $cir[0],
              $cir[1],
              $cir[2],
              $cir[3],
              $cir[4],
              $cir[5],
              $cir[6],
              $cir[7],
              $cir[8],
              $cir[9],
            ],
            showInLegend: false,
          },
        ],
      }
    }
  } else if (props.id.testId === 17) {
    if (rowsFactors.length > 0) {
      const $DGV = parseInt(
        rowsFactors.find((f) => f.factorId === 58).testsResultsTpm.score
      )
      const $R = parseInt(
        rowsFactors.find((f) => f.factorId === 166).testsResultsTpm.score
      )
      const $a = parseInt(
        rowsFactors.find((f) => f.factorId === 8).testsResultsTpm.score
      )
      const $I = parseInt(
        rowsFactors.find((f) => f.factorId === 36).testsResultsTpm.score
      )
      const $II = parseInt(
        rowsFactors.find((f) => f.factorId === 5).testsResultsTpm.score
      )
      const $III = parseInt(
        rowsFactors.find((f) => f.factorId === 49).testsResultsTpm.score
      )
      const $IV = parseInt(
        rowsFactors.find((f) => f.factorId === 194).testsResultsTpm.score
      )
      const $V = parseInt(
        rowsFactors.find((f) => f.factorId === 33).testsResultsTpm.score
      )
      const $VI = parseInt(
        rowsFactors.find((f) => f.factorId === 59).testsResultsTpm.score
      )
      const $VII = parseInt(
        rowsFactors.find((f) => f.factorId === 175).testsResultsTpm.score
      )
      const $VIII = parseInt(
        rowsFactors.find((f) => f.factorId === 2).testsResultsTpm.score
      )
      const $IX = parseInt(
        rowsFactors.find((f) => f.factorId === 183).testsResultsTpm.score
      )

      const $cir = [$DGV, $R, $a, $I, $II, $III, $IV, $V, $VI, $VII, $VIII, $IX]

      optionsData = {
        chart: {
          type: 'column',
          // width: 440,
          height: 355,
        },
        title: {
          text: '',
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: 'Escala',
          },
          tickInterval: 10,
          plotBands: [
            {
              // Promedio
              from: 45,
              to: 65,
              color: '#FCFFC5',
              label: {
                text: '<b>PROMEDIO<b>',
                style: {
                  color: '#606060',
                },
              },
            },
          ],
        },
        xAxis: {
          categories: [
            'DGV',
            'R',
            'A',
            'I',
            'II',
            'III',
            'IV',
            'V',
            'VI',
            'VII',
            'VIII',
            'IX',
          ],
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: true,
            },
            pointStart: 0,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
        },
        series: [
          {
            name: 'Escala',
            data: $cir,
            showInLegend: false,
          },
        ],
      }
    }
  } else if (props.id.testId === 18) {
    if (rowsFactors.length > 0) {
      let outFactor = []
      let item = {}

      item = rowsFactors.find((f) => f.factorId === 193)
      // TIPO ORGANIZADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [100 - Number(item.testsResultsTpm.scoreBase) * 10],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 56)
      //  DESORDENADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [Number(item.testsResultsTpm.scoreBase) * 10],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 195)
      // TRABAJA CON DETALLES
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', 100 - Number(item.testsResultsTpm.scoreBase) * 10],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 135)
      //  NO DETALLISTA
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', Number(item.testsResultsTpm.scoreBase) * 10],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 192)
      // TEORICO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', 100 - Number(item.testsResultsTpm.scoreBase) * 10],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 152)
      // PRACTICO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', Number(item.testsResultsTpm.scoreBase) * 10],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 126)
      // NECESIDAD DE AFECTO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', 100 - Number(item.testsResultsTpm.scoreBase) * 10],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 136)
      // NO NECESITA AFECTO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', Number(item.testsResultsTpm.scoreBase) * 10],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 133)
      // NECESITA PERTENENCIA
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 98)
      //  INDIVIDUALISTA
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', Number(item.testsResultsTpm.scoreBase) * 10],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 171)
      //  RETRAIDO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 85)
      //  EXTENSIÓN SOCIAL
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: ['', '', '', '', '', Number(item.testsResultsTpm.scoreBase) * 10],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 131)
      //  NECESITA RECONOCIMIENTO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 170)
      // RESERVADO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 199)
      // VIGOR FISICO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 196)
      //  TRABAJO DE OFICINA
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 4)
      // ACTIVO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 134)
      // NO ACTIVO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 94)
      // IMPULSIVO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 120)
      // LENTO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 128)
      // NECESIDAD DE CONTROLAR
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 180)
      // SIN DON DE MANDO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 121)
      // LIDERAZGO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 181)
      // SIN LIDERAZGO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 130)
      // NECESIDAD DE LOGRO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 42)
      // CONFORMISTA
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 190)
      // TENAZ E INTENSON
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 138)
      // NO SE ESFUERZA
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 147)
      // PERSEVERANTE
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 137)
      // NO PERSEVERA
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 132)
      // NECESITA DE REGLAS
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 174)
      // SE AJUSTA A CAMBIOS
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 186)
      // SUMISO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 164)
      // REBELDE
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 1)
      // A LA DEFENSIVA
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 146)
      // PASIVO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 103)
      // INHIBIDO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 84)
      // EXPRESIVO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      item = rowsFactors.find((f) => f.factorId === 127)
      // NECESIDAD DE CAMBIO
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          100 - Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color: '#f5f5f5',
      })

      item = rowsFactors.find((f) => f.factorId === 129)
      // NECESIDAD DE ESTABIILIDAD
      outFactor.push({
        name: item.factor.name.toUpperCase(),
        data: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          Number(item.testsResultsTpm.scoreBase) * 10,
        ],
        color:
          Number(item.testsResultsTpm.scoreBase) * 10 <= 40
            ? '#d1b688'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 40 &&
              Number(item.testsResultsTpm.scoreBase) * 10 <= 60
            ? '#7cb5ec'
            : Number(item.testsResultsTpm.scoreBase) * 10 > 60
            ? '#7ccd7c'
            : '',
      })

      optionsData = {
        chart: {
          type: 'column',
          marginTop: 20,
        },
        title: {
          text: '',
        },
        xAxis: {
          categories: [
            'C',
            'D',
            'R',
            'O',
            'B',
            'S',
            'X',
            'V',
            'T',
            'I',
            'P',
            'L',
            'A',
            'G',
            'N',
            'W',
            'F',
            'K',
            'E',
            'Z',
          ],
          plotBands: [
            {
              // Adaptación al trabajo
              from: -1,
              to: 2.5,
              color: 'transparent',
              label: {
                text: '<b>Adaptación al trabajo<b>',
                style: {
                  color: '#606060',
                  fontSize: '10px',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
            {
              // Naturaleza social
              from: 2.5,
              to: 6.5,
              color: 'transparent',
              label: {
                text: '<b>Naturaleza social<b>',
                style: {
                  color: '#606060',
                  fontSize: '10px',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
            {
              // Modo de vida
              from: 6.5,
              to: 8.5,
              color: 'transparent',
              label: {
                text: '<b>Modo de vida<b>',
                style: {
                  color: '#606060',
                  fontSize: '10px',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
            {
              // Liderazgo
              from: 8.5,
              to: 11.5,
              color: 'transparent',
              label: {
                text: '<b>Liderazgo<b>',
                style: {
                  color: '#606060',
                  fontSize: '10px',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
            {
              // Grado de energía
              from: 11.5,
              to: 14.5,
              color: 'transparent',
              label: {
                text: '<b>Grado de energía</b>',
                style: {
                  color: '#606060',
                  fontSize: '10px',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
            {
              // Subordinación
              from: 14.5,
              to: 16.5,
              color: 'transparent',
              label: {
                text: '<b>Subordinación</b>',
                style: {
                  color: '#606060',
                  fontSize: '10px',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
            {
              // Naturaleza emocional
              from: 16.5,
              to: 19.5,
              color: 'transparent',
              label: {
                text: '<b>Naturaleza emocional</b>',
                style: {
                  color: '#606060',
                  fontSize: '10px',
                },
                y: -7,
              },
              borderWidth: 1,
              borderColor: '#D5D8DC',
              zIndex: 3,
            },
          ],
        },
        yAxis: {
          min: 0,
          max: 100,
          tickInterval: 10,
          title: {
            text: '',
          },
          labels: {
            style: {
              fontSize: '10px',
              fontFamily: 'Verdana, sans-serif',
            },
          },
          plotBands: [
            {
              // Promedio
              from: 40,
              to: 60,
              color: 'transparent',
              borderWidth: 1,
              borderColor: 'black',
              label: {
                text: '<b>GENTE PROMEDIO<b>',
                style: {
                  color: '#606060',
                },
              },
              zIndex: 5,
            },
          ],
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {
            return this.series.name + ': ' + this.y + '%'
          },
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '10px',
                fontFamily: 'Verdana, sans-serif',
              },
              align: 'left',
              verticalAlign: 'bottom',
              y: -5,
              rotation: -90,
              formatter: function (e) {
                if (
                  this.series.name === 'TIPO ORGANIZADO' ||
                  this.series.name === 'TRABAJA CON DETALLES' ||
                  this.series.name === 'TEÓRICO' ||
                  this.series.name === 'NECESIDAD DE AFECTO' ||
                  this.series.name === 'NECESITA PERTENENCIA' ||
                  this.series.name === 'RETRAÍDO' ||
                  this.series.name === 'NECESIDAD DE RECONOCIMIENTO' ||
                  this.series.name === 'VIGOR FÍSICO' ||
                  this.series.name === 'ACTIVO' ||
                  this.series.name === 'IMPULSIVO' ||
                  this.series.name === 'NECESIDAD DE CONTROLAR' ||
                  this.series.name === 'LIDERAZGO' ||
                  this.series.name === 'NECESIDAD DE LOGRO' ||
                  this.series.name === 'TENAZ E INTENSO' ||
                  this.series.name === 'PERSEVERANTE' ||
                  this.series.name === 'NECESIDAD DE REGLAS' ||
                  this.series.name === 'SUMISO' ||
                  this.series.name === 'A LA DEFENSIVA' ||
                  this.series.name === 'INHIBIDO' ||
                  this.series.name === 'NECESIDAD DE CAMBIO'
                ) {
                  e.align = 'right'
                  e.verticalAlign = 'top'
                  e.y = 5
                }

                return this.series.name
              },
            },
          },
        },
        series: outFactor,
      }
    }
  } else if (props.id.testId === 20) {
    if (rowsFactors.length > 0) {
      const $sexo = props.id.userGender

      const $r0 = rowsFactors.find((f) => f.factorId === 3).testsResultsTpm
        .scoreBase
      const $r1 = rowsFactors.find((f) => f.factorId === 112).testsResultsTpm
        .scoreBase
      const $r2 = rowsFactors.find((f) => f.factorId === 108).testsResultsTpm
        .scoreBase
      const $r3 = rowsFactors.find((f) => f.factorId === 110).testsResultsTpm
        .scoreBase
      const $r4 = rowsFactors.find((f) => f.factorId === 114).testsResultsTpm
        .scoreBase
      const $r5 = rowsFactors.find((f) => f.factorId === 109).testsResultsTpm
        .scoreBase
      const $r6 = rowsFactors.find((f) => f.factorId === 111).testsResultsTpm
        .scoreBase
      const $r7 = rowsFactors.find((f) => f.factorId === 113).testsResultsTpm
        .scoreBase
      const $r8 = rowsFactors.find((f) => f.factorId === 115).testsResultsTpm
        .scoreBase
      const $r9 = rowsFactors.find((f) => f.factorId === 116).testsResultsTpm
        .scoreBase

      const $c = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

      switch ($r0) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
          $c[0] = 48
          break
        case 9:
          $c[0] = 60
          break
        case 10:
        case 11:
          $c[0] = 72
          break
        case 12:
        case 13:
          $c[0] = 96
          break
        case 14:
          $c[0] = 122
          break
        case 15:
          $c[0] = 148
          break
        case 16:
          $c[0] = 160
          break
        case 17:
        case 18:
          $c[0] = 178
          break
        case 19:
          $c[0] = 192
          break
        case 20:
        case 21:
          $c[0] = 208
          break
        case 22:
          $c[0] = 220
          break
        case 23:
        case 24:
          $c[0] = 235
          break
        case 25:
        case 26:
          $c[0] = 254
          break
        case 27:
        case 28:
          $c[0] = 276
          break
        case 29:
        case 30:
          $c[0] = 291
          break
        case 31:
        case 32:
          $c[0] = 308
          break
        case 33:
        case 34:
          $c[0] = 320
          break
        case 35:
        case 36:
          $c[0] = 336
          break
        case 37:
        case 38:
          $c[0] = 344
          break
        case 39:
        case 40:
          $c[0] = 356
          break
        case 41:
        case 42:
          $c[0] = 372
          break
        case 43:
        case 44:
          $c[0] = 388
          break
        case 45:
        case 46:
          $c[0] = 404
          break
        case 47:
          $c[0] = 420
          break
        case 48:
        case 49:
          $c[0] = 428
          break
        case 50:
        case 51:
          $c[0] = 440
          break
        case 52:
        case 53:
          $c[0] = 458
          break
        case 54:
        case 55:
          $c[0] = 475
          break
        case 56:
        case 57:
          $c[0] = 490
          break
        case 58:
          $c[0] = 508
          break
        case 59:
          $c[0] = 528
          break
        case 60:
        case 61:
          $c[0] = 548
          break
        case 62:
          $c[0] = 564
          break
        case 63:
          $c[0] = 580
          break
        case 64:
        case 65:
          $c[0] = 600
          break
        case 66:
        case 67:
          $c[0] = 618
          break
        case 68:
          $c[0] = 636
          break
        case 69:
          $c[0] = 650
          break
        case 70:
          $c[0] = 672
          break
        case 71:
          $c[0] = 684
          break
        case 72:
        case 73:
          $c[0] = 696
          break
        case 74:
          $c[0] = 710
          break
        case 75:
          $c[0] = 720
          break
        case 76:
          $c[0] = 732
          break
        case 77:
          $c[0] = 756
          break
        case 78:
          $c[0] = 780
          break
        case 79:
          $c[0] = 792
          break
        default:
          $c[0] = 804
          break
      }

      switch ($r1) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          $c[1] = 43
          break
        case 10:
          $c[1] = 55
          break
        case 11:
          $c[1] = 76
          break
        case 12:
          $c[1] = 96
          break
        case 13:
          $c[1] = 120
          break
        case 14:
          $c[1] = 148
          break
        case 15:
          $c[1] = 175
          break
        case 16:
          $c[1] = 192
          break
        case 17:
          $c[1] = 216
          break
        case 18:
        case 19:
          $c[1] = 234
          break
        case 20:
          $c[1] = 252
          break
        case 21:
        case 22:
          $c[1] = 268
          break
        case 23:
        case 24:
          $c[1] = 285
          break
        case 25:
        case 26:
          $c[1] = 302
          break
        case 27:
          $c[1] = 320
          break
        case 28:
        case 29:
          $c[1] = 336
          break
        case 30:
        case 31:
          $c[1] = 348
          break
        case 32:
          $c[1] = 368
          break
        case 33:
        case 34:
          $c[1] = 384
          break
        case 35:
          $c[1] = 392
          break
        case 36:
          $c[1] = 408
          break
        case 37:
        case 38:
          $c[1] = 428
          break
        case 39:
        case 40:
          $c[1] = 444
          break
        case 41:
        case 42:
          $c[1] = 456
          break
        case 43:
          $c[1] = 468
          break
        case 44:
          $c[1] = 482
          break
        case 45:
        case 46:
          $c[1] = 502
          break
        case 47:
        case 48:
          $c[1] = 516
          break
        case 49:
          $c[1] = 528
          break
        case 50:
          $c[1] = 546
          break
        case 51:
        case 52:
          $c[1] = 564
          break
        case 53:
          $c[1] = 576
          break
        case 54:
          $c[1] = 600
          break
        case 55:
          $c[1] = 612
          break
        case 56:
          $c[1] = 634
          break
        case 57:
          $c[1] = 650
          break
        case 58:
          $c[1] = 672
          break
        case 59:
          $c[1] = 648
          break
        case 60:
          $c[1] = 696
          break
        case 61:
          $c[1] = 708
          break
        case 62:
          $c[1] = 720
          break
        case 63:
          $c[1] = 732
          break
        case 64:
          $c[1] = 744
          break
        case 65:
          $c[1] = 756
          break
        case 66:
          $c[1] = 768
          break
        case 67:
          $c[1] = 780
          break
        case 68:
          $c[1] = 792
          break
        default:
          $c[1] = 804
          break
      }

      switch ($r2) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
          $c[2] = 43
          break

        case 5:
          $c[2] = 48
          break

        case 6:
          $c[2] = 60
          break

        case 7:
          $c[2] = 72
          break

        case 8:
          $c[2] = 84
          break

        case 9:
          $c[2] = 92
          break

        case 10:
          $c[2] = 108
          break

        case 11:
          $c[2] = 120
          break

        case 12:
          $c[2] = 132
          break

        case 13:
          $c[2] = 144
          break

        case 14:
          $c[2] = 156
          break

        case 15:
          $c[2] = 168
          break

        case 16:
          $c[2] = 180
          break

        case 17:
          $c[2] = 190
          break

        case 18:
          $c[2] = 204
          break

        case 19:
          $c[2] = 216
          break

        case 20:
          $c[2] = 228
          break

        case 21:
          $c[2] = 245
          break

        case 22:
          $c[2] = 215
          break

        case 23:
          $c[2] = 264
          break

        case 24:
          $c[2] = 286
          break

        case 25:
          $c[2] = 302
          break

        case 26:
          $c[2] = 320
          break

        case 27:
          $c[2] = 338
          break

        case 28:
          $c[2] = 360
          break

        case 29:
          $c[2] = 376
          break

        case 30:
          $c[2] = 386
          break

        case 31:
          $c[2] = 408
          break

        case 32:
          $c[2] = 428
          break

        case 33:
        case 34:
          $c[2] = 444
          break

        case 34:
          $c[2] = 464
          break

        case 35:
          $c[2] = 480
          break

        case 36:
          $c[2] = 500
          break

        case 37:
          $c[2] = 520
          break

        case 38:
          $c[2] = 548
          break

        case 39:
          $c[2] = 564
          break

        case 40:
          $c[2] = 588
          break

        case 41:
          $c[2] = 612
          break

        case 42:
          $c[2] = 636
          break

        case 43:
          $c[2] = 656
          break

        case 44:
          $c[2] = 672
          break

        case 45:
          $c[2] = 684
          break

        case 46:
          $c[2] = 704
          break

        case 47:
          $c[2] = 730
          break

        case 48:
          $c[2] = 744
          break

        case 49:
          $c[2] = 760
          break

        case 50:
          $c[2] = 780
          break

        case 51:
          $c[2] = 792
          break

        default:
          $c[2] = 804
          break
      }

      switch ($r3) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
          $c[3] = 43
          break

        case 9:
          $c[3] = 48
          break

        case 10:
          $c[3] = 60
          break

        case 11:
          $c[3] = 72
          break

        case 12:
          $c[3] = 92
          break

        case 13:
          $c[3] = 116
          break

        case 14:
          $c[3] = 132
          break

        case 15:
          $c[3] = 720
          break

        case 16:
        case 17:
          $c[3] = 168
          break

        case 18:
        case 19:
          $c[3] = 188
          break

        case 20:
        case 21:
          $c[3] = 204
          break

        case 22:
        case 23:
          $c[3] = 220
          break

        case 24:
        case 25:
          $c[3] = 246
          break

        case 26:
          $c[3] = 270
          break

        case 27:
          $c[3] = 291
          break

        case 28:
          $c[3] = 316
          break

        case 29:
          $c[3] = 335
          break

        case 30:
          $c[3] = 348
          break

        case 31:
          $c[3] = 360
          break

        case 32:
          $c[3] = 372
          break

        case 33:
          $c[3] = 390
          break

        case 34:
          $c[3] = 408
          break

        case 35:
          $c[3] = 420
          break

        case 36:
          $c[3] = 432
          break

        case 37:
          $c[3] = 444
          break

        case 38:
          $c[3] = 456
          break

        case 39:
          $c[3] = 468
          break

        case 40:
        case 41:
          $c[3] = 475
          break

        case 42:
          $c[3] = 488
          break

        case 43:
          $c[3] = 500
          break

        case 44:
        case 45:
          $c[3] = 516
          break

        case 46:
        case 47:
          $c[3] = 528
          break

        case 48:
          $c[3] = 540
          break

        case 49:
          $c[3] = 552
          break

        case 50:
          $c[3] = 564
          break

        case 51:
        case 52:
          $c[3] = 576
          break

        case 53:
        case 54:
          $c[3] = 588
          break

        case 55:
        case 56:
          $c[3] = 600
          break

        case 57:
        case 58:
          $c[3] = 618
          break

        case 59:
        case 60:
          $c[3] = 642
          break

        case 61:
          $c[3] = 660
          break

        case 62:
          $c[3] = 684
          break

        case 63:
          $c[3] = 700
          break

        case 64:
          $c[3] = 720
          break

        case 65:
          $c[3] = 732
          break

        case 66:
          $c[3] = 744
          break

        case 67:
          $c[3] = 756
          break

        case 68:
          $c[3] = 768
          break

        case 69:
          $c[3] = 788
          break

        case 70:
          $c[3] = 794
          break

        default:
          $c[3] = 804
          break
      }

      switch ($r4) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
          $c[4] = 43
          break

        case 12:
          $c[4] = 48
          break

        case 13:
          $c[4] = 60
          break

        case 14:
          $c[4] = 72
          break

        case 15:
          $c[4] = 84
          break

        case 16:
        case 17:
          $c[4] = 96
          break

        case 18:
        case 19:
          $c[4] = 106
          break

        case 20:
        case 21:
          $c[4] = 120
          break

        case 22:
          $c[4] = 132
          break

        case 23:
          $c[4] = 144
          break

        case 24:
        case 25:
          $c[4] = 156
          break

        case 26:
        case 27:
          $c[4] = 168
          break

        case 28:
          $c[4] = 180
          break

        case 29:
          $c[4] = 192
          break

        case 30:
          $c[4] = 204
          break

        case 31:
          $c[4] = 216
          break

        case 32:
          $c[4] = 228
          break

        case 33:
          $c[4] = 240
          break

        case 34:
          $c[4] = 252
          break

        case 35:
          $c[4] = 250
          break

        case 36:
          $c[4] = 276
          break

        case 37:
          $c[4] = 288
          break

        case 38:
          $c[4] = 312
          break

        case 39:
          $c[4] = 324
          break

        case 40:
          $c[4] = 340
          break

        case 41:
          $c[4] = 358
          break

        case 42:
        case 43:
          $c[4] = 370
          break

        case 44:
          $c[4] = 386
          break

        case 45:
          $c[4] = 402
          break

        case 46:
          $c[4] = 420
          break

        case 47:
        case 48:
          $c[4] = 432
          break

        case 49:
          $c[4] = 444
          break

        case 50:
          $c[4] = 456
          break

        case 51:
        case 52:
          $c[4] = 468
          break

        case 53:
          $c[4] = 480
          break

        case 54:
        case 55:
          $c[4] = 492
          break

        case 56:
          $c[4] = 516
          break

        case 57:
        case 58:
          $c[4] = 532
          break

        case 59:
          $c[4] = 548
          break

        case 60:
        case 61:
          $c[4] = 564
          break

        case 62:
        case 63:
          $c[4] = 576
          break

        case 64:
          $c[4] = 588
          break

        case 65:
        case 66:
          $c[4] = 612
          break

        case 67:
          $c[4] = 636
          break

        case 68:
        case 69:
          $c[4] = 656
          break

        case 70:
          $c[4] = 672
          break

        case 71:
        case 72:
          $c[4] = 684
          break

        case 73:
        case 74:
          $c[4] = 704
          break

        case 75:
          $c[4] = 730
          break

        case 76:
          $c[4] = 750
          break

        case 77:
        case 78:
          $c[4] = 760
          break

        case 79:
          $c[4] = 778
          break

        case 80:
          $c[4] = 792
          break

        default:
          $c[4] = 804
          break
      }

      switch ($r5) {
        case 0:
        case 1:
        case 2:
        case 3:
          $c[5] = 48
          break

        case 4:
          $c[5] = 68
          break

        case 5:
        case 6:
          $c[5] = 84
          break

        case 7:
          $c[5] = 108
          break

        case 8:
          $c[5] = 125
          break

        case 9:
          $c[5] = 148
          break

        case 10:
          $c[5] = 166
          break

        case 11:
          $c[5] = 180
          break

        case 12:
          $c[5] = 192
          break

        case 13:
          $c[5] = 206
          break

        case 14:
          $c[5] = 228
          break

        case 15:
          $c[5] = 240
          break

        case 16:
        case 17:
          $c[5] = 252
          break

        case 18:
          $c[5] = 270
          break

        case 19:
          $c[5] = 288
          break

        case 20:
          $c[5] = 300
          break

        case 21:
          $c[5] = 315
          break

        case 22:
          $c[5] = 336
          break

        case 23:
          $c[5] = 348
          break

        case 24:
          $c[5] = 360
          break

        case 25:
          $c[5] = 372
          break

        case 26:
          $c[5] = 390
          break

        case 27:
          $c[5] = 404
          break

        case 28:
          $c[5] = 420
          break

        case 29:
          $c[5] = 428
          break

        case 30:
          $c[5] = 444
          break

        case 31:
          $c[5] = 456
          break

        case 32:
          $c[5] = 478
          break

        case 33:
        case 34:
          $c[5] = 490
          break

        case 35:
          $c[5] = 504
          break

        case 36:
          $c[5] = 524
          break

        case 37:
        case 38:
          $c[5] = 540
          break

        case 39:
          $c[5] = 574
          break

        case 40:
          $c[5] = 600
          break

        case 41:
        case 42:
          $c[5] = 612
          break

        case 43:
        case 44:
          $c[5] = 632
          break

        case 45:
          $c[5] = 648
          break

        case 46:
          $c[5] = 660
          break

        case 47:
          $c[5] = 678
          break

        case 48:
          $c[5] = 696
          break

        case 49:
          $c[5] = 710
          break

        case 50:
          $c[5] = 732
          break

        case 51:
          $c[5] = 756
          break

        case 52:
          $c[5] = 788
          break

        default:
          $c[5] = 804
          break
      }

      switch ($r6) {
        case 0:
        case 1:
          $c[6] = 48
          break

        case 2:
          $c[6] = 60
          break

        case 3:
          $c[6] = 84
          break

        case 4:
          $c[6] = 96
          break

        case 5:
          $c[6] = 116
          break

        case 6:
          $c[6] = 138
          break

        case 7:
          $c[6] = 156
          break

        case 8:
          $c[6] = 176
          break

        case 9:
          $c[6] = 192
          break

        case 10:
          $c[6] = 216
          break

        case 11:
          $c[6] = 228
          break

        case 12:
          $c[6] = 246
          break

        case 13:
          $c[6] = 258
          break

        case 14:
          $c[6] = 274
          break

        case 15:
          $c[6] = 288
          break

        case 16:
          $c[6] = 305
          break

        case 17:
          $c[6] = 324
          break

        case 18:
          $c[6] = 336
          break

        case 19:
          $c[6] = 375
          break

        case 20:
          $c[6] = 372
          break

        case 21:
          $c[6] = 384
          break

        case 22:
          $c[6] = 408
          break

        case 23:
          $c[6] = 420
          break

        case 24:
          $c[6] = 438
          break

        case 25:
          $c[6] = 456
          break

        case 26:
          $c[6] = 480
          break

        case 27:
          $c[6] = 500
          break

        case 28:
          $c[6] = 528
          break

        case 29:
          $c[6] = 552
          break

        case 30:
          $c[6] = 572
          break

        case 31:
        case 32:
          $c[6] = 600
          break

        case 33:
          $c[6] = 624
          break

        case 34:
        case 35:
          $c[6] = 636
          break

        case 36:
          $c[6] = 670
          break

        case 37:
        case 38:
          $c[6] = 684
          break

        case 39:
          $c[6] = 732
          break

        case 40:
          $c[6] = 756
          break

        case 41:
          $c[6] = 775
          break

        default:
          $c[6] = 804
          break
      }

      switch ($r7) {
        case 0:
          $c[7] = 48
          break

        case 1:
          $c[7] = 84
          break

        case 2:
          $c[7] = 132
          break

        case 3:
          $c[7] = 180
          break

        case 4:
          $c[7] = 204
          break

        case 5:
          $c[7] = 216
          break

        case 6:
          $c[7] = 236
          break

        case 7:
          $c[7] = 252
          break

        case 8:
          $c[7] = 264
          break

        case 9:
          $c[7] = 284
          break

        case 10:
          $c[7] = 300
          break

        case 11:
          $c[7] = 312
          break

        case 12:
          $c[7] = 324
          break

        case 13:
          $c[7] = 342
          break

        case 14:
          $c[7] = 360
          break

        case 15:
          $c[7] = 372
          break

        case 16:
          $c[7] = 390
          break

        case 17:
          $c[7] = 408
          break

        case 18:
          $c[7] = 420
          break

        case 19:
          $c[7] = 440
          break

        case 20:
          $c[7] = 456
          break

        case 21:
          $c[7] = 480
          break

        case 22:
          $c[7] = 500
          break

        case 23:
          $c[7] = 520
          break

        case 24:
          $c[7] = 552
          break

        case 25:
          $c[7] = 576
          break

        case 26:
          $c[7] = 612
          break

        case 27:
          $c[7] = 640
          break

        case 28:
          $c[7] = 672
          break

        case 29:
          $c[7] = 708
          break

        case 30:
          $c[7] = 768
          break

        default:
          $c[7] = 804
          break
      }

      switch ($r8) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
          $c[8] = 48
          break

        case 12:
          $c[8] = 60
          break

        case 13:
          $c[8] = 80
          break

        case 14:
          $c[8] = 90
          break

        case 15:
          $c[8] = 102
          break

        case 16:
          $c[8] = 120
          break

        case 17:
        case 18:
          $c[8] = 140
          break

        case 19:
          $c[8] = 154
          break

        case 20:
          $c[8] = 168
          break

        case 21:
          $c[8] = 180
          break

        case 22:
          $c[8] = 192
          break

        case 23:
        case 24:
          $c[8] = 212
          break

        case 25:
          $c[8] = 236
          break

        case 26:
          $c[8] = 248
          break

        case 27:
          $c[8] = 264
          break

        case 28:
          $c[8] = 280
          break

        case 29:
          $c[8] = 295
          break

        case 30:
        case 31:
          $c[8] = 315
          break

        case 31:
          $c[8] = 334
          break

        case 32:
          $c[8] = 348
          break

        case 33:
          $c[8] = 360
          break

        case 34:
          $c[8] = 372
          break

        case 35:
          $c[8] = 384
          break

        case 36:
          $c[8] = 396
          break

        case 37:
          $c[8] = 412
          break

        case 38:
          $c[8] = 428
          break

        case 39:
          $c[8] = 440
          break

        case 40:
          $c[8] = 456
          break

        case 41:
          $c[8] = 468
          break

        case 42:
          $c[8] = 480
          break

        case 43:
          $c[8] = 500
          break

        case 44:
          $c[8] = 636
          break

        case 45:
        case 46:
          $c[8] = 516
          break

        case 47:
          $c[8] = 538
          break

        case 48:
          $c[8] = 552
          break

        case 49:
          $c[8] = 564
          break

        case 50:
          $c[8] = 576
          break

        case 51:
        case 52:
          $c[8] = 592
          break

        case 53:
        case 54:
          $c[8] = 610
          break

        case 55:
        case 56:
          $c[8] = 632
          break

        case 57:
          $c[8] = 646
          break

        case 58:
          $c[8] = 665
          break

        case 59:
        case 60:
          $c[8] = 675
          break

        case 61:
          $c[8] = 692
          break

        case 62:
        case 63:
          $c[8] = 708
          break

        case 64:
          $c[8] = 732
          break

        case 65:
          $c[8] = 744
          break

        case 66:
          $c[8] = 768
          break

        case 67:
          $c[8] = 780
          break

        default:
          $c[8] = 804
          break
      }

      switch ($r9) {
        case 0:
        case 17:
          $c[9] = 48
          break

        case 18:
          $c[9] = 58
          break

        case 19:
          $c[9] = 70
          break

        case 20:
          $c[9] = 84
          break

        case 21:
          $c[9] = 100
          break

        case 22:
          $c[9] = 116
          break

        case 23:
        case 24:
          $c[9] = 162
          break

        case 25:
        case 26:
          $c[9] = 144
          break

        case 27:
        case 28:
          $c[9] = 156
          break

        case 29:
          $c[9] = 168
          break

        case 30:
          $c[9] = 182
          break

        case 31:
          $c[9] = 192
          break

        case 32:
          $c[9] = 208
          break

        case 33:
          $c[9] = 228
          break

        case 34:
          $c[9] = 240
          break

        case 35:
          $c[9] = 254
          break

        case 36:
          $c[9] = 274
          break

        case 37:
          $c[9] = 290
          break

        case 38:
          $c[9] = 310
          break

        case 39:
          $c[9] = 336
          break

        case 40:
          $c[9] = 336
          break

        case 41:
        case 42:
          $c[9] = 360
          break

        case 43:
        case 44:
          $c[9] = 372
          break

        case 45:
          $c[9] = 392
          break

        case 46:
        case 47:
          $c[9] = 406
          break

        case 48:
          $c[9] = 428
          break

        case 49:
        case 50:
          $c[9] = 442
          break

        case 51:
          $c[9] = 465
          break

        case 52:
        case 53:
          $c[9] = 488
          break

        case 54:
          $c[9] = 500
          break

        case 54:
        case 55:
          $c[9] = 516
          break

        case 56:
        case 57:
          $c[9] = 528
          break

        case 58:
          $c[9] = 540
          break

        case 59:
        case 60:
          $c[9] = 558
          break

        case 61:
          $c[9] = 574
          break

        case 62:
        case 63:
          $c[9] = 588
          break

        case 64:
        case 65:
          $c[9] = 600
          break

        case 66:
        case 67:
          $c[9] = 612
          break

        case 68:
        case 69:
          $c[9] = 626
          break

        case 70:
          $c[9] = 648
          break

        case 71:
        case 72:
          $c[9] = 672
          break

        case 73:
          $c[9] = 684
          break

        case 74:
        case 75:
          $c[9] = 708
          break

        case 76:
        case 77:
          $c[9] = 732
          break

        case 78:
        case 79:
          $c[9] = 744
          break

        case 80:
          $c[9] = 760
          break

        case 81:
        case 82:
          $c[9] = 780
          break

        case 83:
        case 84:
          $c[9] = 792
          break

        default:
          $c[9] = 804
          break
      }

      optionsData = {
        title: {
          text: '',
        },
        yAxis: [
          {
            min: 0,
            max: 1000,
            title: {
              text: '',
            },
            tickInterval: 100,
            plotBands: [
              {
                // Promedio
                from: 250,
                to: 750,
                color: 'transparent',
                borderWidth: 1,
                borderColor: 'black',
                label: {
                  text: '',
                  style: {
                    color: '#606060',
                  },
                },
                zIndex: 5,
              },
            ],
          },
          {
            title: {
              text: '',
            },
            opposite: true,
          },
        ],
        xAxis: {
          title: {
            text: 'ESCALA VOCACIONAL',
          },
          categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: true,
            },
            pointStart: 0,
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'top',
          floating: true,
        },
        credits: {
          enabled: false,
        },
        // tooltip: {
        //   pointFormatter: function () {
        //     const seriesName = this.series.name
        //     const pointY = this.y
        //     let label = ''

        //     if (seriesName === 'Valores de escalas') {
        //       if (pointY === $L_T - $L) label = $L
        //       else if (pointY === $F_T - $F) label = $F
        //       else if (pointY === $K_T - $K) label = $K
        //       else if (pointY === $Hs_T - $Hs) label = $Hs
        //       else if (pointY === $D_T - $D) label = $D
        //       else if (pointY === $Hi_T - $Hi) label = $Hi
        //       else if (pointY === $Dp_T - $Dp) label = $Dp
        //       else if (pointY === $Mf_T - $Mf) label = $Mf
        //       else if (pointY === $Pa_T - $Pa) label = $Pa
        //       else if (pointY === $Pt_T - $Pt) label = $Pt
        //       else if (pointY === $Es_T - $Es) label = $Es
        //       else if (pointY === $Ma_T - $Ma) label = $Ma
        //       else if (pointY === $Si_T - $Si) label = $Si
        //     } else {
        //       label = pointY
        //     }

        //     return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${label} puntos</b><br/>`
        //   },
        //   shared: true,
        // },
        series: [
          {
            type: 'line',
            name: 'Puntaje del candidato',
            data: $c,
            showInLegend: true,
            zIndex: 1,
          },
          {
            type: 'line',
            name: 'Valores de referencia',
            data:
              $sexo == 2
                ? [356, 336, 338, 444, 402, 504, 438, 520, 632, 626]
                : [508, 576, 376, 552, 420, 456, 408, 420, 500, 488],
            showInLegend: true,
            zIndex: 1,
            color: '#FF0000',
          },
        ],
      }
    }
  } else if (props.id.testId === 21) {
    /* 0 <= 25 "RIESGO. ";
      25 <= 50 "VULNERABLE. ";
      50 <= 75 "HÁBIL. ";
      75 <= 100 "ÓPTIMO. ";
    */

    /*let i = 0;
    let labels123 = ["0", "25", "50", "75", "100"];*/

    optionsData = {
      chart: {
        type: 'column',
        // width: 440,
        height: 355,
      },
      title: {
        text: '',
        // align: "center",
      },
      tooltip: {
        enabled: false,
      },
      xAxis: {
        categories: rowsFactors.map((items, i) => '<b>' + (i + 1) + '</b>'),
      },
      yAxis: {
        title: {
          text: '<b style="font-size: 8px">RIESGO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VULNERABLE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HÁBIL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ÓPTIMO</b>',
        },
        /*labels: {
        formatter: function () {
          if (this.isFirst) {
            i = -1;
          }
          i++;
          return labels123[i];
        },
      },*/
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            // format: "<b>{point.name}</b>: {point.y:1f} Horas ({point.percentage:.1f}%) ",
            format: '<b>{point.y:1f}</b>% ',
            style: {
              color:
                (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                'black',
            },
          },
        },
      },
      series: [
        {
          name: 'Escala',
          data: rowsFactors.map((items) =>
            parseFloat(items.testsResultsTpm.score)
          ),
        },
      ],
    }
  }

  const handleCloseDialog = () => (event) => {
    props.exit()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Grid container spacing={1} className={classes.containerProfile}>
            {alertNotification ? (
              <Grid item xs={12} sm={12}>
                <Alert severity="warning">
                  No se puede mostrar el resultado de la prueba para este
                  candidato.
                </Alert>
              </Grid>
            ) : (
              <Fragment>
                {loader ? (
                  <Grid item xs={12} sm={12}>
                    <center>
                      <div
                        style={{
                          margin: '100px 0',
                        }}
                      >
                        <img
                          src={img}
                          alt="loader"
                          style={{
                            width: 60,
                          }}
                        />
                        <br></br>
                        <b style={{ fontSize: 10 }}>Cargando resultados</b>
                      </div>
                    </center>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.titleCardFilter}>
                        {namePrueba}
                      </div>
                      <div className={classes.lineBottom}></div>
                      <br></br>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {props.id.testId === 1 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <b>Para análisis de la personalidad </b>
                              <br></br>
                              <br></br>
                              El diagnóstico para <b>{props.id.userNames}</b> es
                              el siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData}
                              />
                            </Grid>
                          </Grid>
                          <div className={classes.fontParagraph}>
                            {ReactHtmlParser(ts)}
                          </div>
                        </Fragment>
                      ) : props.id.testId === 3 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              El reporte del Test de Inteligencia Emocional de{' '}
                              <b>{props.id.userNames}</b> es el siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData}
                              />
                            </Grid>
                          </Grid>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>* AMBIENTE ACTUAL:</b> Aquí se representa tu
                            ambiente y circunstancias actuales, tanto en casa
                            como en el trabajo. <br></br>
                            <b>(1)</b> Los sucesos de la vida cotidiana
                            presentan un estado: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[0].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[0].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(2)</b> as presiones de trabajo se manifiestan en
                            un estado: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[1].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[1].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(3)</b> y las presiones personales se encuentran
                            en un nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[2].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[2].score)}%
                            </b>
                            <br></br>
                            <b>* CONOCIMIENTO</b> Es tu conocimiento básico de
                            emociones dentro de tí mismo y de otros, significa
                            tener un profundo entendimiento de nuestras
                            emociones, fortalezas, debilidades, necesidades e
                            impulsos. Las personas con una fuerte autoconciencia
                            no son demasiado críticas ni tampoco tienen
                            esperanzas irreales. Más bien, son honestos consigo
                            mismas y con los demás. Son seguros de sí. Aquellos
                            con un alto grado de autoconciencia reconocen cómo
                            sus sentimientos los afectan a sí mismos, a otras
                            personas y al desempeño en el trabajo. Esta sección
                            trata sobre el contenido y la fuente de las
                            emociones y habilidades propias para articularlas o
                            direccionarlas exitosamente. &nbsp;
                            <br></br>
                            <b>(4)</b> El conocimiento emocional de sí mismo se
                            encuentra en nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[3].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[3].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(5)</b> la expresión emocional se manifiesta en
                            el nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[4].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[4].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(6)</b> y el conocimiento emocional de otros se
                            encuentran en un nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[5].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[5].score)}%
                            </b>{' '}
                            <br></br>
                            <b>* COMPETENCIAS EMOCIONALES</b> La Inteligencia
                            Emocional determina la capacidad potencial que
                            dispone cada persona para adquirir ciertas
                            habilidades prácticas denominadas competencias
                            emocionales. La Competencia Emocional en sí,
                            demuestra hasta qué punto una persona ha sabido o
                            podido trasladar ese potencial a las acciones de su
                            vida cotidiana. Las 5 escalas de competencia
                            emocional exploran habilidades fundamentales y
                            patrones de conducta que has desarrollado en el
                            tiempo para responder a eventos, gente y
                            circunstancias de la vida. &nbsp;
                            <br></br>
                            <b>(7)</b> La intención - la idea clara dirigida
                            hacia un objetivo definido, preludio de la acción,
                            basado en el esquema de valores personales - se
                            presenta en un nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[6].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[6].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(8)</b> la creatividad se manifiesta en un nivel:
                            &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[7].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[7].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(9)</b> la elasticidad - la capacidad para
                            modificar nuestras formas de reaccionar a
                            situaciones fuera de nuestro control, así como Ia
                            utilización de estrategias para recuperar y renovar
                            nuestra energía emocional - se encuentra en un
                            nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[8].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[8].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(10)</b> las conexiones interpersonales - estilo
                            o forma de relacionarse con los demás - presentan un
                            nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[9].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[9].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(11)</b> El descontento productivo - significa
                            canalizar los sentimientos de descontento provocado
                            por una situación hacia una dirección en la que se
                            puedan obtener beneficios y lograr el cambio deseado
                            - se manifiestan en un nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[10].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[10].score)}%
                            </b>{' '}
                            <br></br>
                            <b>* VALORES Y CREENCIAS</b> Los valores y creencias
                            emocionales son patrones de pensamientos y
                            sentimientos de los cuales tus acciones fluyen.
                            Estas 6 escalas exploran tus creencias (principios y
                            valores personales que guían tus acciones y marco de
                            vida) aquellas profundas formas de sentir que marcan
                            tu manera de actuar congruentemente con lo que dices
                            y con lo que es para ti más importante en tu vida
                            personal y laboral. &nbsp;
                            <br></br>
                            <b>(12)</b> La empatía - capacidad de reconocer los
                            sentimientos de los demás - se presenta en un nivel:
                            &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[11].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[11].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(13)</b> La perspectiva - la habilidad para
                            mantener apertura mental y emocional que facilite
                            tomar conciencia de las oportunidades que se
                            presentan en los ámbitos en que participarnos - se
                            manifiestan en un nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[12].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[12].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(14)</b> La intuición - se relaciona con la
                            habilidad para identificar las emociones y/o
                            sentimientos que genera una situación y utilizarlos
                            en la toma de decisiones - se encuentra en un nivel:
                            &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[13].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[13].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(15)</b> El radio de confianza - incluye la
                            confianza en nosotros mismos así como Ia damos y
                            recibimos de los demás - se presenta en un nivel:
                            &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[14].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[14].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(16)</b> El poder personal - Es Ia capacidad para
                            influir sobre los demás sin ejercer poder externo,
                            control, maniobras o manipulación, sino a partir de
                            ̈irradiar ̈ nuestros sentimientos y pensamientos hacia
                            los demás - se manifiestan en un nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[15].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[15].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(17)</b> La integridad (aceptar plenamente las
                            responsabilidades, comunicarse clara y abiertamente,
                            cumplir lo que se promete, evitar agendas ocultas y
                            ser fiel a los principios) se encuentra en un nivel:
                            &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[16].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[16].score)}%
                            </b>{' '}
                            <br></br>
                            <b>* RESULTADOS</b> Las escalas en esta sección
                            miden el impacto que la inteligencia emocional está
                            actualmente teniendo en nuestra vida. El coeficiente
                            emocional se hace palpable en nuestra salud física,
                            emocional y psicológica; la calidad de vida es
                            afectada totalmente por el Coeficiente Emocional.
                            &nbsp;
                            <br></br>
                            <b>(18)</b> La salud general - manera adecuada en
                            que se relacionan de manera sintónica el cuerpo
                            físico, mental y emocional - presenta un estado:
                            &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[17].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[17].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(19)</b> La calidad de vida se manifiesta en un
                            estado: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[18].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[18].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(20)</b> El cociente de relaciones - beneficio
                            obtenido de la forma de relacionarnos con los demás
                            - se manifiesta en un nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[19].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[19].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(21)</b> y el óptimo rendimiento - se obtiene al
                            nivelar habilidades y emociones en la vida laboral y
                            personal - se encuentran en un nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[20].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[20].score)}%
                            </b>{' '}
                          </div>
                        </Fragment>
                      ) : props.id.testId === 6 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              El resultado de la prueba con enfoque a la
                              selección de personal de{' '}
                              <b>{props.id.userNames}</b>, es el siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData}
                              />
                            </Grid>
                          </Grid>
                          <div className={classes.fontParagraph}>
                            {ReactHtmlParser(ts)}
                          </div>
                        </Fragment>
                      ) : props.id.testId === 7 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              El resultado del Test de MOOS de{' '}
                              <b>{props.id.userNames}</b>, para su empresa o
                              centro laboral, es el siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData}
                              />
                            </Grid>
                          </Grid>
                          <div className={classes.fontParagraph}>
                            {ReactHtmlParser(ts)}
                          </div>
                        </Fragment>
                      ) : props.id.testId === 8 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              El resultado del test GORDON de Competencias
                              Interpersonales para <b>{props.id.userNames}</b>{' '}
                              es el siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData}
                              />
                            </Grid>
                          </Grid>
                          <div className={classes.fontParagraph}>
                            En la competencia interpersonal{' '}
                            <b style={{ color: 'blue' }}>(S) ESTIMULO</b>, se
                            encuentra{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[2].title}
                            </span>{' '}
                            con un porcentaje del{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[2].value}
                            </span>
                            %. Es el ser tratado con comprensión, recibiendo
                            apoyo por parte de los demás; ser tratado con
                            amabilidad y consideración. {rows[2].text}
                            <br></br>
                            En la competencia interpersonal{' '}
                            <b style={{ color: 'blue' }}>(C) CONFORMIDAD</b>, se
                            encuentra{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[1].title}
                            </span>{' '}
                            con un porcentaje del{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[1].value}
                            </span>
                            %. Es hacer lo que es socialmente correcto,
                            siguiendo estrictamente las normas. {rows[1].text}
                            <br></br>
                            En la competencia interpersonal{' '}
                            <b style={{ color: 'blue' }}>(R) RECONOCIMIENTO</b>,
                            se encuentra{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[5].title}
                            </span>{' '}
                            con un porcentaje del{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[5].value}
                            </span>
                            %. Es el ser bien visto y admirado, ser considerado
                            como persona importante, llamar favorablemente la
                            atención y conseguir el reconocimiento de los demás.{' '}
                            {rows[5].text}
                            <br></br>
                            En la competencia interpersonal{' '}
                            <b style={{ color: 'blue' }}>(I) INDEPENDENCIA</b>,
                            se encuentra{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[3].title}
                            </span>{' '}
                            con un porcentaje del{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[3].value}
                            </span>
                            %. Es apreciado el tener derecho a hacer lo que uno
                            quiere hacer, ser libre para decidir por sí mismo,
                            ser capaz de actuar según el propio criterio.{' '}
                            {rows[3].text}
                            <br></br>
                            En la competencia interpersonal{' '}
                            <b style={{ color: 'blue' }}>(B) BENEVOLENCIA</b>,
                            se encuentra{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[0].title}
                            </span>{' '}
                            con un porcentaje del{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[0].value}
                            </span>
                            %. Es cuando se considera importante hacer cosas por
                            los demás y compartirlas con ellos, ayudar a los
                            pocos afortunados, ser generoso. {rows[0].text}
                            <br></br>
                            En la competencia interpersonal{' '}
                            <b style={{ color: 'blue' }}>(L) LIDERAZGO</b>, se
                            encuentra{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[4].title}
                            </span>{' '}
                            con un porcentaje del{' '}
                            <span style={{ color: 'blue' }}>
                              {rows[4].value}
                            </span>
                            %. Cuanto más alto el nivel, mayor el deseo y gusto
                            por las actitudes de mando y por toda situación que
                            implique tomar decisiones con autoridad sobre otras
                            personas. Un nivel bajo indica poca tendencia a
                            imponer la propia voluntad y autoridad.(El puntaje
                            aumenta en los grupos sujetos a fuertes tensiones
                            competitivas o cuando el liderazgo es una exigencia
                            profesional. Baja en las muestras para cuya
                            situación, presente o futura, no es necesario este
                            rasgo).
                            {rows[4].text}
                          </div>
                        </Fragment>
                      ) : props.id.testId === 13 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              El reporte Cleaver para{' '}
                              <b>{props.id.userNames}</b> es el siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData3}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData1}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2}></Grid>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              style={{
                                fontSize: 10,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                border: '1px solid #ccc',
                              }}
                            >
                              <b>{'D = Empuje'}</b>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              style={{
                                fontSize: 10,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                border: '1px solid #ccc',
                              }}
                            >
                              <b>{'I = Influencia'}</b>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              style={{
                                fontSize: 10,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                border: '1px solid #ccc',
                              }}
                            >
                              <b>{'S = Constancia'}</b>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              style={{
                                fontSize: 10,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                border: '1px solid #ccc',
                              }}
                            >
                              <b>{'C = Apego a normas'}</b>
                            </Grid>
                          </Grid>
                          <br></br>
                          <div className={classes.fontParagraph}>
                            {rowsTextTitleCL1.map((row) => {
                              if (row.title !== '')
                                return (
                                  <Fragment>
                                    <b>{row.title}</b> {row.text}
                                    <br></br>
                                  </Fragment>
                                )
                            })}
                          </div>
                          <br></br>
                          <center>
                            <b style={{ color: 'blue', fontSize: 16 }}>
                              Bajo una situación normal
                            </b>
                          </center>
                          <br></br>
                          <div className={classes.fontParagraph}>
                            {rowsTextTitleCL2.map((row) => {
                              if (row.text !== '')
                                return (
                                  <Fragment>
                                    {row.text}
                                    <br></br>
                                    <br></br>
                                  </Fragment>
                                )
                            })}
                          </div>
                        </Fragment>
                      ) : props.id.testId === 14 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              El reporte del Test de TERMAN de{' '}
                              <b>{props.id.userNames}</b> es el siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData}
                              />
                            </Grid>
                          </Grid>
                          <div className={classes.fontParagraph}>
                            {ReactHtmlParser(ts)}
                          </div>
                        </Fragment>
                      ) : props.id.testId === 16 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              El reporte del Test de Ambiente Laboral de{' '}
                              <b>{props.id.userNames}</b> es el siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData}
                              />
                            </Grid>
                          </Grid>
                          <div className={classes.fontParagraph}>
                            {ReactHtmlParser(ts)}
                          </div>
                        </Fragment>
                      ) : props.id.testId === 17 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <b>Para análisis de la personalidad </b>
                              <br></br>
                              <br></br>
                              El reporte del Test de Inventario Profesional de
                              Ventas de <b>{props.id.userNames}</b> es el
                              siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData}
                              />
                            </Grid>
                          </Grid>
                          <div className={classes.fontParagraph}>
                            {ReactHtmlParser(ts)}
                          </div>
                        </Fragment>
                      ) : props.id.testId === 18 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              El diagnóstico para <b>{props.id.userNames}</b> es
                              el siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData}
                              />
                            </Grid>
                          </Grid>
                          <div className={classes.fontParagraph}>
                            {ReactHtmlParser(ts)}
                          </div>
                        </Fragment>
                      ) : props.id.testId === 20 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              El perfil KUDER para <b>{props.id.userNames}</b>{' '}
                              es el siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData}
                              />
                            </Grid>
                          </Grid>
                          <div className={classes.fontParagraph}>
                            {ReactHtmlParser(ts)}
                          </div>
                        </Fragment>
                      ) : props.id.testId === 21 ? (
                        <Fragment>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              El reporte del Test de Inteligencia Emocional de{' '}
                              <b>{props.id.userNames}</b> es el siguiente:
                              <br></br>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <HighchartsCode
                                highcharts={Highcharts}
                                options={optionsData}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: 'green',
                              }}
                            >
                              <b>{'Escalas'}</b>
                            </Grid>
                            <Grid item xs={2} sm={2}></Grid>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              style={{
                                fontSize: 10,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                border: '1px solid #ccc',
                              }}
                            >
                              <b>{'RIESGO: 0 <= 25'}</b>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              style={{
                                fontSize: 10,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                border: '1px solid #ccc',
                              }}
                            >
                              <b>{'VULNERABLE: 25 <= 50'}</b>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              style={{
                                fontSize: 10,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                border: '1px solid #ccc',
                              }}
                            >
                              <b>{'HÁBIL: 50 <= 75'}</b>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              style={{
                                fontSize: 10,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                border: '1px solid #ccc',
                              }}
                            >
                              <b>{'ÓPTIMO: 75 <= 100'}</b>
                            </Grid>
                          </Grid>
                          <div className={classes.fontParagraph}>
                            <br></br>
                            <b>* COMPETENCIAS EMOCIONALES:</b> La Inteligencia
                            Emocional determina la capacidad potencial que
                            dispone cada persona para adquirir ciertas
                            habilidades prácticas denominadas competencias
                            emocionales. La Competencia Emocional en sí,
                            demuestra hasta qué punto una persona ha sabido o
                            podido trasladar ese potencial a las acciones de su
                            vida cotidiana. Las 11 escalas de competencia
                            emocional exploran habilidades fundamentales y
                            patrones de conducta que has desarrollado en el
                            tiempo para responder a eventos, gente y
                            circunstancias de la vida. <br></br>
                            <b>(1) Iniciativa</b> - la idea clara dirigida hacia
                            un objetivo definido, preludio de la acción, basado
                            en el esquema de valores personales - se presenta en
                            un nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[0].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[0].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(2) la orientación a los resultados</b> - se
                            manifiesta en un nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[1].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[1].score)}%
                            </b>{' '}
                            <br></br>
                            <b>(3) la flexibilidad</b> - la capacidad para
                            modificar nuestras formas de reaccionar a
                            situaciones fuera de nuestro control, así como Ia
                            utilización de estrategias para recuperar y renovar
                            nuestra energía emocional - se encuentra en un
                            nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[2].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[2].score)}%
                            </b>
                            <br></br>
                            <b>(4) el trabajo en equipo</b> - estilo o forma de
                            relacionarse con los demás - presentan un nivel:
                            &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[3].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[3].score)}%
                            </b>
                            <br></br>
                            <b>
                              (5) El conocimiento del entorno organizacional
                            </b>{' '}
                            - significa canalizar los sentimientos de
                            descontento provocado por una situación hacia una
                            dirección en la que se puedan obtener beneficios y
                            lograr el cambio deseado - se manifiestan en un
                            nivel:&nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[4].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[4].score)}%
                            </b>
                            <br></br>
                            <b>(6) la orientación al servicio</b> - se
                            manifiestan en un nivel: &nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[5].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[5].score)}%
                            </b>
                            <br></br>
                            <b>(7) La capacidad de aprendizaje</b> - la
                            habilidad para mantener apertura mental y emocional
                            que facilite tomar conciencia de las oportunidades
                            que se presentan en los ámbitos en que participarnos
                            - se manifiestan en un nivel:&nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[6].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[6].score)}%
                            </b>
                            <br></br>
                            <b>(8) La capacidad de toma de decisiones</b> - se
                            relaciona con la habilidad para identificar las
                            emociones y/o sentimientos que genera una situación
                            y utilizarlos en la toma de decisiones - se
                            encuentra en un nivel:&nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[7].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[7].score)}%
                            </b>
                            <br></br>
                            <b>
                              (9) La capacidad de construcción de relaciones
                            </b>{' '}
                            - incluye la confianza en nosotros mismos así como
                            Ia damos y recibimos de los demás - se presenta en
                            un nivel:&nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[8].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[8].score)}%
                            </b>
                            <br></br>
                            <b>(10) El liderazgo</b> - Es Ia capacidad para
                            influir sobre los demás sin ejercer poder externo,
                            control, maniobras o manipulación, sino a partir de
                            ̈irradiar ̈ nuestros sentimientos y pensamientos hacia
                            los demás - se manifiestan en un nivel:&nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[9].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[9].score)}%
                            </b>
                            <br></br>
                            <b>(11) La integridad</b> (aceptar plenamente las
                            responsabilidades, comunicarse clara y abiertamente,
                            cumplir lo que se promete, evitar agendas ocultas y
                            ser fiel a los principios) se encuentra en un
                            nivel:&nbsp;
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {rows[10].scale}
                            </b>
                            . Tiene un{' '}
                            <b style={{ color: 'blue', fontSize: 12 }}>
                              {Math.trunc(rows[10].score)}%
                            </b>
                          </div>
                        </Fragment>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </>
                )}
              </Fragment>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
