import React from 'react'

import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { GetApp } from '@material-ui/icons'

const ExportReportGeneralJobsExcel = ({ excelData, fileName, className }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

  const fileExtension = '.xlsx'

  // CODE OLD
  /*if (excelData.length > 0) {
    // Iterar sobre cada objeto en el array
    for (var i = 0; i < excelData.length; i++) {
      // Cambiar el nombre de la propiedad 'document' a 'Cedula'
      excelData[i].cedula = excelData[i].document
      delete excelData[i].document
      delete excelData[i].name
      delete excelData[i].equivalence
      delete excelData[i].total

      // Agregar nuevas propiedades
      excelData[i].puesto = ''
      excelData[i].observacion = 'prueba'
    }

    // Iterar sobre cada objeto en el array
    for (var i = 0; i < excelData.length; i++) {
      // Extraer las propiedades dinámicas
      var { puesto, cedula, observacion, ...propsDinamicas } = excelData[i]

      // Crear un nuevo objeto con el orden deseado
      excelData[i] = {
        puesto: puesto,
        cedula: cedula,
        ...propsDinamicas,
        observacion: observacion,
      }
    }
  }*/

  // CODE NEW
  if (excelData.length > 0) {
    // Iterar sobre cada objeto en el array
    for (var i = 0; i < excelData.length; i++) {
      // Cambiar el nombre de la propiedad 'document' a 'Cedula'
      excelData[i].cedula = excelData[i].document
      delete excelData[i].document
      delete excelData[i].name
      delete excelData[i].equivalence
      delete excelData[i].total

      // Agregar nuevas propiedades
      excelData[i].puesto = ''
      excelData[i].observacion = 'prueba'
    }

    // Iterar sobre cada objeto en el array
    for (var i = 0; i < excelData.length; i++) {
      // Extraer las propiedades dinámicas
      var { puesto, cedula, observacion, ...propsDinamicas } = excelData[i]

      // Crear un nuevo objeto con el orden deseado
      if (
        propsDinamicas.CEQ !== undefined &&
        propsDinamicas.personalidad !== undefined &&
        propsDinamicas.inteligencia !== undefined
      ) {
        excelData[i] = {
          puesto: puesto,
          cedula: cedula,
          test1: propsDinamicas.CEQ,
          test2: propsDinamicas.personalidad,
          test3: propsDinamicas.inteligencia,
          observacion: observacion,
        }
      }
    }
  }

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <>
      <Tooltip title={fileName}>
        <Button
          onClick={(e) => exportToExcel(fileName)}
          className={className}
          startIcon={<GetApp style={{ fontSize: 14 }} />}
        >
          {' '}
          ARCHIVO MDT{' '}
        </Button>
      </Tooltip>
    </>
  )
}

export default ExportReportGeneralJobsExcel
