import React, { Fragment, useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import { UrlServicesContext } from '../../components/UrlServicesContext'
import { RestoreFromTrash, Cancel } from '@material-ui/icons'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function DeleteTest(props) {
  const classes = useStyles()

  const [error, setError] = useState(false)
  const [mensaje_error, setMensaje_error] = useState('')
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [mensaje_success, setMensaje_success] = useState('')
  const [disabled, setdisabled] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let id = Number(props.id.id)
  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleChangeDelete = (event) => {
    const dataValue = {
      status: 1,
    }

    axios
      .put(`${urlServices}users/${id}`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        setdisabled(true)
        props.callBackRefresh()
        setOpenAlertSuccess(true)
        setMensaje_success('Reactivado con éxito.')
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setError(true)
          setMensaje_error('Error al reactivar')
        }
      })
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <center>
                  <RestoreFromTrash className={classes.iconDelete} />
                  <div className={classes.titleCardFilter}>
                    ¿Desea reactivar este usuario?
                  </div>

                  <Button
                    className={classes.buttonSubmit2}
                    onClick={handleChangeDelete}
                    disabled={disabled}
                  >
                    Reactivar
                  </Button>
                </center>
              </Grid>
            </Grid>
            <br></br>
            <center>
              {error ? <Alert severity="error">{mensaje_error}</Alert> : ''}
              {openAlertSuccess ? (
                <Alert severity="success">{mensaje_success}</Alert>
              ) : (
                ''
              )}
            </center>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
