import React, { useEffect, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons
import ExitToApp from '@material-ui/icons/ExitToApp'
// core components
import { Redirect } from 'react-router-dom'
import Button from 'components/CustomButtons/Button.js'
import PopupLogout from 'views/dialog/PopupLogout'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import axios from 'axios'

import styles from 'assets/jss/material-dashboard-react/components/headerLinksStyle.js'

import img from 'assets/img/loading.gif'

const useStyles = makeStyles(styles)

export default function AdminNavbarLinks() {
  const classes = useStyles()
  const [popupLogout, setpopupLogout] = React.useState(false)
  const [account, setAccount] = React.useState('')
  const [loader, setloader] = React.useState(true)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    axios
      .get(`${urlServices}accounts/${localStorage.getItem('account_id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAccount(response.data.companyName)
          setloader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }, [])

  const handleOpenpopupLogout = () => {
    setpopupLogout(true)
  }

  const handleClosepopupLogout = () => {
    setpopupLogout(false)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <div className={classes.manager}>
      <GridContainer style={{ marginRight: '5px' }}>
        <GridItem xs={12} md={7}>
          {loader ? (
            <center>
              <img
                alt="loader"
                src={img}
                style={{ width: 40, marginTop: 20 }}
              />
            </center>
          ) : (
            <div className={classes.linkText}>
              <b>
                {localStorage.getItem('user') === 'null'
                  ? 'Super administrador'
                  : localStorage.getItem('user') + ' (' + account + ')'}
              </b>
            </div>
          )}
        </GridItem>
        <GridItem xs={12} md={5}>
          <ButtonGroup
            aria-label="outlined primary button group"
            color="default"
          >
            <Button
              size={'sm'}
              color={'transparent'}
              // onClick={handleClickProfile}
              onClick={handleOpenpopupLogout}
              className={classes.buttonSubmit2}
            >
              <ExitToApp className={classes.icons} />
              Salir
            </Button>
          </ButtonGroup>
        </GridItem>
      </GridContainer>
      {popupLogout ? (
        <PopupLogout open={popupLogout} exit={handleClosepopupLogout} />
      ) : (
        ''
      )}
    </div>
  )
}
