import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  forwardRef,
} from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Search, Person, Cancel } from "@material-ui/icons";
import axios from "axios";
import MaterialTable, { MTableBodyRow } from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const useStyles2 = makeStyles({
  tableRow: { "&:hover": { backgroundColor: "rgb(237, 247, 237)!important" } },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ViewApplicantTestPsychometrics(props) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [totalApplicants, setTotalApplicants] = useState(0);
  const [returnLogin, setReturnLogin] = React.useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  };

  const [state] = useState({
    columns: [
      {
        title: "Nombres y apellidos",
        field: "applicant.name",
        render: (rowData) => {
          return (
            <span>
              {rowData.applicant.name + " " + rowData.applicant.lastName}
            </span>
          );
        },
        width: "60%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
        },
      },
      {
        title: "Cédula",
        field: "applicant.documentId",
        width: "10%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
          textAlign: "center",
        },
      },
      {
        title: "Correo electrónico",
        field: "user.email",
        render: (rowData) => {
          return (
            <Fragment>
              <span style={{ fontSize: 12 }}>
                {rowData.applicant.email !== ""
                  ? rowData.applicant.email
                  : "SIN REGISTRAR"}
              </span>
            </Fragment>
          );
        },
        width: "30%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
          textAlign: "center",
        },
      },
      {
        title: "Pruebas",
        render: (rowData) => {
          return (
            <Fragment>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>
                {rowData.jobtesttpm.test.name}
              </span>
            </Fragment>
          );
        },
        width: "30%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
          textAlign: "center",
        },
      },
    ],
  });

  useEffect(() => {
    if (props.refresh) {
      TableData();
      props.refreshReturn();
    } else {
      TableData();
    }
  }, [props.refresh]);

  const TableData = () => {
    axios
      .get(`${urlServices}jobs-users-tpm/job/${props.jobsId}`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then((response) => {
        setTotalApplicants(response.data.length);
        setRows(response.data);
        setLoader(false);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRows([]);
          setLoader(false);
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 200);
        } else {
          setRows([]);
          setError(true);
          console.log(e);
        }
      });
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleCloseDialog(true)}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      keepMounted
      style={{ zIndex: 100 }}
      //scroll="body"
      PaperProps={{
        classes: { root: classes.DialogStyle },
      }}
    >
      <DialogContent dividers className={classes.styleDialogContent}>
        <Grid container spacing={1} className={classes.containerProfile}>
          {error ? (
            <Grid item xs={12} sm={12}>
              <Alert severity="error">
                Error en la consulta con sel servidor.
              </Alert>
            </Grid>
          ) : (
            ""
          )}
          {loader ? (
            <Grid item xs={12} sm={12}>
              <Box sx={{ width: "100%" }}>
                <Skeleton width="100%" height={300} />
              </Box>
            </Grid>
          ) : (
            <Fragment>
              <Grid item xs={12} sm={12}>
                <Alert severity="success" icon={<Person fontSize="inherit" />}>
                  <b>
                    CANDIDATO{totalApplicants > 1 ? "S" : ""} ASIGNADO
                    {totalApplicants > 1 ? "S" : ""} CON PRUEBAS
                    <span style={{ marginLeft: 5, fontSize: 12 }}>
                      (Total registro: {totalApplicants})
                    </span>
                  </b>
                </Alert>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MaterialTable
                  title=""
                  columns={state.columns}
                  style={{
                    boxShadow: "unset",
                    border: "1px solid rgb(241, 241, 241)",
                  }}
                  data={rows}
                  localization={{
                    toolbar: {
                      // nRowsSelected: '{0} row(s) selected',
                      searchTooltip: "Filtrar",
                      searchPlaceholder: "Buscar",
                    },
                    pagination: {
                      labelRowsSelect: "Filas",
                      labelDisplayedRows: " {from}-{to} de {count}",
                      firstTooltip: "Primera página",
                      previousTooltip: "Previo",
                      nextTooltip: "Siguiente",
                      lastTooltip: "Ultima página",
                    },
                    header: {
                      actions: " --- ",
                    },
                    body: {
                      emptyDataSourceMessage: "No hay datos.",
                      filterRow: {
                        filterTooltip: "Filtro",
                      },
                    },
                  }}
                  actions={
                    [
                      /* {
                              icon: "edit",
                              tooltip: "Editar",
                              onClick: (event, rowData) => {
                                setOpenEditQuestion(true);
                                setIdRow(rowData);
                              },
                            },*/
                    ]
                  }
                  icons={tableIcons}
                  options={{
                    search: true,
                    padding: 1,
                    filtering: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    headerStyle: {
                      background: "#F2F2F2",
                      padding: "1%",
                      fontWeight: "bold",
                      textAlign: "center",
                    },
                    cellStyle: { border: "1px solid #DDDDDD" },
                  }}
                  components={{
                    Row: (props) => (
                      <MTableBodyRow className={classes2.tableRow} {...props} />
                    ),
                  }}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog(true)}>
          <Cancel />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
