import React, { Fragment, useState, forwardRef, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from '@material-ui/core/styles'
// core components
import {
  cardBodyStyle,
  themeCss,
} from 'assets/jss/material-dashboard-react/components/cardBodyStyle'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, Layers, Print, Today } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import axios from 'axios'
import MaterialTable from 'material-table'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import format from 'date-fns/format'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import EnableResultsJobsIndividual from 'views/dialog/EnableResultsJobsIndividual'
import LoadUsersAccount from 'components/services/LoadUsersAccount'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Moment from 'moment'
const defaultMaterialTheme = createTheme(themeCss)

const useStyles = makeStyles(cardBodyStyle)

export default function ReportindIvidualFactors() {
  const classes = useStyles()

  const [rows, setRows] = useState([])
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(false)
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [openResults, setOpenResults] = useState(false)
  const [idRow, setIdRow] = useState('')
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [disabledTo, setdisabledTo] = useState(true)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const columns = [
    {
      title: 'Usuario',
      field: 'user.name',
      render: (rowData) => {
        return <b>{rowData.user.lastName + ' ' + rowData.user.name}</b>
      },
      width: '30%',
      cellStyle: {
        // whiteSpace: "nowrap",
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
      },
    },
    {
      title: 'Documento',
      field: 'user.documentId',
      width: '35%',
      cellStyle: {
        // whiteSpace: "nowrap",
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
      },
    },
    {
      title: 'Correo',
      field: 'user.email',
      width: '35%',
      cellStyle: {
        // whiteSpace: "nowrap",
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
      },
    },
    {
      title: 'IP',
      field: 'publicIp',
      width: '35%',
      cellStyle: {
        // whiteSpace: "nowrap",
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
      },
    },
    {
      title: 'Fecha',
      render: (rowData) =>
        rowData.createdAt !== null
          ? format(new Date(rowData.createdAt), 'dd-MM-yyyy kk:mm:ss')
          : '',
      width: '10%',
      cellStyle: {
        // whiteSpace: "nowrap",
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
        textAlign: 'center',
        color: 'green',
        fontWeight: 'bold',
      },
    },
  ]

  const [values, setValues] = useState({
    user: null,
    userData: [],
    errorUser: false,
    fromDate: null,
    toDate: null,
    yearFrom: null,
    yearTo: null,
  })

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' })

  //   const dataValue = {
  //     userId: values.user,
  //     fromDate: startDate,
  //     toDate: endDate,
  //   }
  //   axios
  //     .post(
  //       `${urlServices}reports/users-sessions/account/${localStorage.getItem(
  //         'account_id'
  //       )}`,
  //       dataValue,
  //       {
  //         headers: {
  //           application: keyAplication,
  //           Authorization: 'Bearer ' + keyAuthorization,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setRows(response.data)
  //         setLoader(false)
  //       }
  //     })
  //     .catch((e) => {
  //       if (e.response.status === 404) {
  //         setRows([])
  //         setLoader(false)
  //       } else if (e.response.status === 401) {
  //         setTimeout(() => {
  //           localStorage.clear()
  //           setReturnLogin(true)
  //         }, 200)
  //       } else {
  //         setRows([])
  //         setError(true)
  //         console.log(e)
  //       }
  //     })
  // }, [keyAplication, urlServices, keyAuthorization])

  const RefreshTable = () => {
    setoOpenBackdrop(true)

    const dataValue = {
      userId: values.user,
      fromDate: startDate,
      toDate: endDate,
    }
    axios
      .post(
        `${urlServices}reports/users-sessions/account/${localStorage.getItem(
          'account_id'
        )}`,
        dataValue,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setoOpenBackdrop(false)

          setRows(response.data)
          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRows([])
          setLoader(false)
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setRows([])
          setError(true)
          console.log(e)
        }
      })
  }

  const handleCloseOpenResults = () => {
    setOpenResults(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const changeUser = (pro) => {
    if (pro !== null) {
      setValues({
        ...values,
        user: pro.id,
        userData: pro,
        errorUser: false,
      })
    } else {
      setValues({
        ...values,
        user: null,
        userData: null,
        errorUser: false,
      })
    }
  }

  const handleDateChangeFrom = (event) => {
    if (event !== null) {
      setValues({
        ...values,
        yearFrom: event,
        yearToMinDate: event,
        yearTo: null,
        errorYearFrom: false,
        errorYearTo: false,
      })

      setStartDate(Moment(event).format('YYYY-MM-DD'))
      setdisabledTo(false)
    } else {
      setValues({
        ...values,
        yearFrom: null,
        yearTo: null,
        errorYearFrom: false,
        errorYearTo: false,
      })
      setStartDate(null)
      setEndDate(null)
      setdisabledTo(true)
    }
  }

  const handleDateChangeTo = (event) => {
    if (event !== null) {
      setValues({
        ...values,
        yearTo: event,
        errorYearTo: false,
      })

      setEndDate(Moment(event).format('YYYY-MM-DD'))
    } else {
      setValues({
        ...values,
        yearTo: null,
        errorYearFrom: false,
      })
      setEndDate(null)
    }
  }

  const submit = (e) => {
    e.preventDefault()
    if (values.yearFrom !== null && values.yearTo === null) {
      setValues({ ...values, errorYearTo: true })
      setMensaje('Seleccione una fecha de fin')
      setOpenAlert(true)
      setError('error')
      setoOpenBackdrop(false)
    } else {
      setoOpenBackdrop(true)

      const dataValue = {
        userId: values.user,
        fromDate: startDate,
        toDate: endDate,
      }
      axios
        .post(
          `${urlServices}reports/users-sessions/account/${localStorage.getItem(
            'account_id'
          )}`,
          dataValue,
          {
            headers: {
              application: keyAplication,
              Authorization: 'Bearer ' + keyAuthorization,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setoOpenBackdrop(false)

            setRows(response.data)
            setLoader(false)
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            setRows([])
            setLoader(false)
          } else if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            setRows([])
            setError(true)
            console.log(e)
          }
        })
    }
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <div className={classes.cardTitleWhite}>
                <Layers className={classes.iconWhite} /> Reporte de auditoría -
                Sesión de Usuarios
              </div>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <LoadUsersAccount
                    refresh={changeUser}
                    value={values.userData}
                    error={values.errorUser}
                    all={true}
                    required={false}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <br></br>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <KeyboardDatePicker
                        //id="date-picker-dialog"
                        label="Desde"
                        onChange={handleDateChangeFrom}
                        value={values.yearFrom}
                        format="yyyy-MM-dd"
                        // views={["year"]}
                        animateYearScrolling={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        variant="inline"
                        keyboardIcon={<Today className={classes.iconTheme} />}
                        autoComplete="off"
                        //InputProps={{ readOnly: true }}
                        autoOk
                        //minDate={new Date()}
                        minDateMessage={false}
                        maxDateMessage={false}
                        error={values.errorYearFrom}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <br></br>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <KeyboardDatePicker
                        //id="date-picker-dialog"
                        label="Hasta"
                        onChange={handleDateChangeTo}
                        value={values.yearTo}
                        format="yyyy-MM-dd"
                        // views={["year"]}
                        animateYearScrolling={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        variant="inline"
                        keyboardIcon={
                          <Today
                            className={
                              disabledTo ? classes.iconWhite : classes.iconTheme
                            }
                          />
                        }
                        minDate={values.yearToMinDate}
                        autoComplete="off"
                        //InputProps={{ readOnly: true }}
                        autoOk
                        minDateMessage={false}
                        maxDateMessage={false}
                        disabled={disabledTo}
                        error={values.errorYearTo}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} md={2}>
                  <br></br>
                  <Button
                    className={classes.buttonLeft}
                    onClick={submit}
                    startIcon={<Search />}
                  >
                    Buscar
                  </Button>
                </GridItem>
              </GridContainer>
              {loader ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Box sx={{ width: '100%' }}>
                      <Skeleton
                        width="40%"
                        height={40}
                        style={{ float: 'right' }}
                      />
                      <Skeleton width="100%" height={300} />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Fragment>
                  <MaterialTable
                    title=""
                    columns={columns}
                    style={{
                      boxShadow: 'unset',
                      border: '1px solid rgb(241, 241, 241)',
                    }}
                    data={rows}
                    localization={{
                      pagination: {
                        labelRowsSelect: 'Filas',
                        labelDisplayedRows: ' {from}-{to} de {count}',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Previo',
                        nextTooltip: 'Siguiente',
                        lastTooltip: 'Ultima página',
                      },
                      toolbar: {
                        // nRowsSelected: '{0} row(s) selected',
                        searchTooltip: 'Filtrar',
                        searchPlaceholder: 'Buscar',
                      },
                      header: {
                        actions: ' --- ',
                      },
                      body: {
                        emptyDataSourceMessage: 'No hay datos.',
                        filterRow: {
                          filterTooltip: 'Filtro',
                        },
                      },
                    }}
                    actions={[
                      {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => RefreshTable(),
                      },
                    ]}
                    icons={tableIcons}
                    options={{
                      search: true,
                      padding: 'default',
                      filtering: false,
                      actionsColumnIndex: -1,
                      pageSize: 10,
                      headerStyle: {
                        background: '#F2F2F2',
                        padding: '1%',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      },
                      cellStyle: { border: '1px solid #DDDDDD' },
                    }}
                  />
                </Fragment>
              )}
            </CardBody>
          </Card>
        </GridItem>

        {openResults ? (
          <EnableResultsJobsIndividual
            open={openResults}
            exit={handleCloseOpenResults}
            id={idRow}
          />
        ) : (
          ''
        )}

        <Backdrop
          style={{ zIndex: '9999', color: '#FFFFFF' }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </GridContainer>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
          <Alert
            onClose={handleClose}
            severity={error === 'error' ? 'error' : 'success'}
            elevation={6}
            variant="filled"
          >
            {mensaje}
          </Alert>
        </Slide>
      </Snackbar>
    </Fragment>
  )
}
