import React, { Fragment, useState, useEffect, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { Alert, AlertTitle } from '@material-ui/lab'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import {
  Cancel,
  AddCircle,
  FiberManualRecord,
  Delete,
  SaveAlt,
  Save,
  List,
} from '@material-ui/icons'
import TextField from '@material-ui/core/TextField'
import { green } from '@material-ui/core/colors'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import FormControl from '@material-ui/core/FormControl'
import axios from 'axios'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Papa from 'papaparse'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import TestsQuestions from 'views/AdminInstitution/TestsQuestions'
import LoadQuestionTypes from 'components/services/LoadQuestionTypes'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FAFAFA',
    },
  },
}))(TableRow)

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  }
}

export default function Questions(props) {
  const classes = useStyles()
  const theme = useTheme()
  const [valueTabs, setValueTabs] = React.useState(0)

  const [error, setError] = useState(false)
  const [errorServer, setErrorServer] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [disabledQuestion, setDisabledQuestion] = useState(true)
  const [openQuestion, setOpenQuestion] = useState(false)
  const [rowsAnswer, setrowsAnswer] = useState([])
  const [rowsImport, setRowsImport] = useState([])
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  const [values, setValues] = useState({
    name: '',
    questionTypes: '',
    questionTypesData: '',
    answers: '',
    answerName: '',
    errorName: false,
    errorQuestionTypes: false,
    errorAnswerName: false,
  })

  let dataTest = props.id
  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorAnswerName: false,
    })
    setOpenAlert(false)
  }

  const changeJob = (data) => {
    setValues({
      ...values,
      questionTypes: data !== null ? data.id : '',
      questionTypesData: data !== null ? data : '',
      answers: '',
      errorJob: false,
    })

    setrowsAnswer([])
    setDisabledQuestion(true)
    setOpenAlert(false)
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleRadioChange = (event) => {
    setValues({
      ...values,
      answers: event.target.value,
    })

    setDisabledQuestion(false)
  }

  const saveQuestion = () => {
    setError('')
    setoOpenBackdrop(true)

    if (valueTabs === 0) {
      if (values.name.length === 0) {
        setValues({ ...values, errorName: true })
        setMensaje('El campo "Nombre de la pregunta" es obligatorio.')
        setOpenAlert(true)
        setError('error')
        setoOpenBackdrop(false)
      } else if (values.questionTypes === '') {
        setValues({ ...values, errorQuestionTypes: true })
        setMensaje('Seleccione un tipo de prueba')
        setOpenAlert(true)
        setError('error')
        setoOpenBackdrop(false)
      } else {
        const dataValue = {
          statement: values.name.toUpperCase(),
          questiontypeId: values.questionTypes,
          testId: dataTest.id,
        }

        axios
          .post(`${urlServices}questions`, dataValue, {
            headers: {
              application: keyAplication,
              Authorization: 'Bearer ' + keyAuthorization,
            },
          })
          .then((response) => {
            ;(async function () {
              await AnswersSave(response.data.id)
            })()

            setOpenAlert(true)
            setMensaje('Pregunta guardada con éxito.')
            props.callBackRefresh()
            setoOpenBackdrop(false)

            setValues({
              name: '',
              questionTypes: '',
              questionTypesData: '',
              answers: '',
            })

            setrowsAnswer([])
            setDisabledQuestion(true)
          })
          .catch((e) => {
            if (e.response.status === 401) {
              setTimeout(() => {
                localStorage.clear()
                setReturnLogin(true)
              }, 200)
            } else {
              setErrorServer(true)
              setoOpenBackdrop(false)
            }
          })
      }
    } else if (valueTabs === 1) {
      let dataQuestion = rowsImport.length,
        n = 0

      if (dataQuestion > 0) {
        rowsImport.map((row1) => {
          const dataValue = {
            statement: row1.statement.toUpperCase(),
            questiontypeId: row1.questiontypeId,
            testId: dataTest.id,
          }

          const insert = async () => {
            await axios
              .post(`${urlServices}questions`, dataValue, {
                headers: {
                  application: keyAplication,
                  Authorization: 'Bearer ' + keyAuthorization,
                },
              })
              .then((response) => {
                ;(async function () {
                  if (row1.options.length > 0) {
                    await OpcionsSaveMasivo(response.data.id, row1.options)
                  }
                  await AnswersSaveMasivo(response.data.id, row1.answers)
                })()
              })
              .catch((e) => {
                if (e.response.status === 401) {
                  setTimeout(() => {
                    localStorage.clear()
                    setReturnLogin(true)
                  }, 200)
                } else {
                  setErrorServer(true)
                }
              })
          }
          insert()
          n++
        })

        if (n === dataQuestion) {
          setOpenAlert(true)
          setMensaje('Pregunta guardada con éxito.')

          setTimeout(() => {
            props.callBackRefresh()
          }, 800)

          setoOpenBackdrop(false)

          setRowsImport([])
          setValueTabs(0)
          setrowsAnswer([])
          setDisabledQuestion(true)
        }
      }
    }
  }

  function AnswersSave(id) {
    if (rowsAnswer.length === 0) {
      const dataAnswers = {
        statement: values.answers === 'true' ? 'Verdadero' : 'Falso',
        rightAnswer: values.answers,
        questionId: id,
      }

      insert(dataAnswers)
    } else {
      rowsAnswer.map((row1) => {
        const dataAnswers = {
          statement: row1.statement,
          rightAnswer: row1.rightAnswer,
          questionId: id,
        }

        insert(dataAnswers)
      })
    }
  }

  function AnswersSaveMasivo(id, row) {
    row.map((row1) => {
      const dataAnswers = {
        statement: row1.statement,
        rightAnswer: row1.rightAnswer,
        questionId: id,
      }

      axios
        .post(`${urlServices}answers`, dataAnswers, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then(
          (response3) => {},
          (error) => {
            console.log(error)
          }
        )
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            console.log(e)
          }
        })
    })
  }

  function OpcionsSaveMasivo(id, row) {
    row.map((row1) => {
      const dataOptions = {
        statement: row1.statement,
        questionId: id,
      }

      axios
        .post(`${urlServices}questions-options`, dataOptions, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then(
          (response4) => {},
          (error) => {
            console.log(error)
          }
        )
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            console.log(e)
          }
        })
    })
  }

  const insert = (dataAnswers) => {
    axios
      .post(`${urlServices}answers`, dataAnswers, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response3) => {},
        (error) => {
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleAdd = () => {
    setError('')

    if (values.answerName === '') {
      setValues({ ...values, errorAnswerName: true })
      setMensaje('Agregue una respuesta.')
      setOpenAlert(true)
      setError('error')
    } else if (values.answers.length === 0) {
      setMensaje('Seleccion una opción de verdadero o falso.')
      setOpenAlert(true)
      setError('error')
    } else {
      setrowsAnswer([
        ...rowsAnswer,
        {
          statement: values.answerName,
          rightAnswer: values.answers,
        },
      ])

      setValues({
        ...values,
        answerName: '',
        answers: '',
      })
    }
  }

  const handleRemoveClick = (i) => {
    const list = [...rowsAnswer]
    list.splice(i, 1)
    setrowsAnswer(list)
  }

  const handleChangeTabs = (event, newValue) => {
    setValues({
      name: '',
      questionTypes: '',
      questionTypesData: '',
      answers: '',
    })

    setrowsAnswer([])
    setDisabledQuestion(true)

    setValueTabs(newValue)
  }

  const handleChangeIndex = (index) => {
    setValueTabs(index)
  }

  const changeHandler = (event) => {
    if (event.target.files[0] !== undefined) {
      // Passing file data (event.target.files[0]) to parse using Papa.parse
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = []
          const valuesArray = []
          const out = []

          // Iterating data to get column name and their values
          results.data.map((d) => {
            rowsArray.push(Object.keys(d))
            valuesArray.push(Object.values(d))
          })

          // Parsed Data Response in array format
          // setParsedData(results.data);

          // Filtered Column Names
          // setTableRows(rowsArray[0]);

          // Filtered Values
          // setValues(valuesArray);

          results.data.map((row1) => {
            if (
              row1.Secuencia !== undefined &&
              row1.PreguntaRespuesta !== undefined &&
              row1.TipoPregunta !== undefined &&
              row1.Postulado !== undefined &&
              row1.OpcionCorrecta !== undefined
            ) {
              if (row1.PreguntaRespuesta.toLowerCase() === 'p') {
                /*const str = row1.Postulado.toUpperCase();
              const newStr = str.replace("{", "<b>").replace("}", "</b>");*/

                out.push({
                  secuencia: row1.Secuencia,
                  statement: row1.Postulado.toUpperCase(),
                  questiontypeId:
                    row1.TipoPregunta.toLowerCase() === 's'
                      ? 1
                      : row1.TipoPregunta.toLowerCase() === 'l'
                      ? 2
                      : row1.TipoPregunta.toLowerCase() === 'b'
                      ? 3
                      : 4,
                  options: [],
                  answers: [],
                })
              }

              if (row1.PreguntaRespuesta.toLowerCase() === 'o') {
                /*const str = row1.Postulado;
              const newStr = str.replace("{", "<b>").replace("}", "</b>");*/

                out.map((rowO) => {
                  if (rowO.secuencia === row1.Secuencia) {
                    rowO.options.push({
                      statement: row1.Postulado,
                    })
                  }
                })
              }

              if (row1.PreguntaRespuesta.toLowerCase() === 'r') {
                out.map((row2) => {
                  if (row2.secuencia === row1.Secuencia) {
                    row2.answers.push({
                      statement: row1.Postulado,
                      rightAnswer:
                        row1.OpcionCorrecta.toLowerCase() === 'v'
                          ? 'true'
                          : 'false',
                    })
                  }
                })
              }

              setRowsImport(out)
              setDisabledQuestion(false)
            } else {
              setMensaje('Error en la cabecera del formato del csv (Revisar).')
              setOpenAlert(true)
              setError('error')
              setDisabledQuestion(true)
            }
          })
        },
      })
    }
  }

  const handleCloseQuestions = () => {
    setOpenQuestion(false)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>
                  Agregar preguntas y respuestas
                  <span style={{ float: 'right' }}>
                    <Button
                      className={classes.buttonSubmit2}
                      onClick={() => setOpenQuestion(true)}
                      startIcon={<List />}
                    >
                      Lista de preguntas
                    </Button>
                  </span>
                </div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={classes.cardTitle}>
                  <FiberManualRecord className={classes.iconTheme} />{' '}
                  {dataTest.name}
                </div>
                <br></br>
              </Grid>
              {errorServer ? (
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">
                    Error en la consulta con sel servidor.
                  </Alert>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} md={12}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={valueTabs}
                    onChange={handleChangeTabs}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="action tabs example"
                  >
                    <Tab
                      label="Carga manual"
                      {...a11yProps(0)}
                      style={{ color: '#000' }}
                    />
                    <Tab
                      label="Carga masiva"
                      {...a11yProps(1)}
                      style={{ color: '#000' }}
                    />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={valueTabs}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={valueTabs} index={0} dir={theme.direction}>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        backgroundColor: '#f8f8f8',
                        borderRadius: 5,
                        padding: 10,
                      }}
                    >
                      <Grid item xs={4} md={4}>
                        <LoadQuestionTypes
                          value={values.questionTypesData}
                          refresh={changeJob}
                          error={values.errorQuestionTypes}
                        />
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <FormControl style={{ width: '100%' }}>
                          <TextField
                            name="name"
                            value={values.name}
                            error={values.errorName}
                            onChange={handleChange('name')}
                            fullWidth
                            required
                            label="Enunciado"
                            margin="dense"
                            autoComplete="off"
                            variant="outlined"
                            rows={3}
                            multiline
                            style={{ background: 'white' }}
                          />
                        </FormControl>
                      </Grid>
                      {values.questionTypes === 1 ? (
                        <Fragment>
                          {/*<Fragment>
                          <Grid
                            item
                            xs={6}
                            md={4}
                            style={{ paddingTop: 30, paddingRight: 20 }}
                          >
                            <span className={classes.cardPrice}>
                              Respuesta correcta
                            </span>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <RadioGroup
                              aria-label="answers"
                              name="answers"
                              value={values.answers}
                              onChange={handleRadioChange}
                            >
                              <FormControlLabel
                                value="true"
                                control={<GreenRadio />}
                                label="Verdadero"
                                style={{ color: "dimgray" }}
                              />
                              <FormControlLabel
                                value="false"
                                control={<GreenRadio />}
                                label="Falso"
                                style={{ color: "dimgray" }}
                              />
                            </RadioGroup>
                          </Grid>
                        </Fragment>*/}

                          <Grid
                            item
                            xs={4}
                            md={2}
                            style={{ paddingTop: 8, paddingRight: 5 }}
                          >
                            <span className={classes.cardPrice}>
                              Respuestas
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              name="name"
                              value={values.answerName}
                              error={values.errorAnswerName}
                              onChange={handleChange('answerName')}
                              fullWidth
                              required
                              label="Respuesta"
                              margin="dense"
                              autoComplete="off"
                              variant="outlined"
                              style={{ background: 'white' }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl
                              component="fieldset"
                              style={{ paddingTop: 8, paddingLeft: 25 }}
                            >
                              <RadioGroup
                                row
                                aria-label="position"
                                name="answers"
                                value={values.answers}
                                onChange={handleRadioChange}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<GreenRadio />}
                                  label="Verdadero"
                                  style={{ color: 'dimgray' }}
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<GreenRadio />}
                                  label="Falso"
                                  style={{ color: 'dimgray' }}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Button
                              className={classes.buttonLeft}
                              onClick={handleAdd}
                              startIcon={<AddCircle />}
                            >
                              Agregar
                            </Button>
                          </Grid>
                        </Fragment>
                      ) : values.questionTypes === 2 ? (
                        <Fragment>
                          <Grid
                            item
                            xs={4}
                            md={2}
                            style={{ paddingTop: 8, paddingRight: 5 }}
                          >
                            <span className={classes.cardPrice}>
                              Respuestas multiples
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              name="name"
                              value={values.answerName}
                              error={values.errorAnswerName}
                              onChange={handleChange('answerName')}
                              fullWidth
                              required
                              label="Respuesta"
                              margin="dense"
                              autoComplete="off"
                              variant="outlined"
                              style={{ background: 'white' }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl
                              component="fieldset"
                              style={{ paddingTop: 8, paddingLeft: 25 }}
                            >
                              <RadioGroup
                                row
                                aria-label="position"
                                name="answers"
                                value={values.answers}
                                onChange={handleRadioChange}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<GreenRadio />}
                                  label="Verdadero"
                                  style={{ color: 'dimgray' }}
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<GreenRadio />}
                                  label="Falso"
                                  style={{ color: 'dimgray' }}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Button
                              className={classes.buttonLeft}
                              onClick={handleAdd}
                              startIcon={<AddCircle />}
                            >
                              Agregar
                            </Button>
                          </Grid>
                        </Fragment>
                      ) : (
                        ''
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ paddingLeft: 70, paddingRight: 70 }}
                      >
                        {rowsAnswer.length > 0 ? (
                          <Table
                            className={classes.table}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableBody>
                              <TableCell component="th" scope="row">
                                <b>Respuesta</b>{' '}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <center>
                                  <b>Opción</b>{' '}
                                </center>
                              </TableCell>
                              <TableCell component="th" scope="row"></TableCell>
                              {rowsAnswer.map((row1, i1) => {
                                return (
                                  <StyledTableRow>
                                    <TableCell
                                      // align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      {row1.statement}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      {row1.rightAnswer === 'true'
                                        ? 'Verdadero'
                                        : 'Falso'}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      <Delete
                                        style={{
                                          cursor: 'pointer',
                                          float: 'right',
                                        }}
                                        onClick={() => handleRemoveClick(i1)}
                                      />
                                    </TableCell>
                                  </StyledTableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={valueTabs} index={1} dir={theme.direction}>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        backgroundColor: '#f8f8f8',
                        borderRadius: 5,
                        padding: 10,
                      }}
                    >
                      <Grid item xs={12} md={12}>
                        <Alert severity="warning">
                          <AlertTitle>IMPORTANTE</AlertTitle>- La columna&nbsp;
                          <strong>Secuencia</strong> es obligatoria y siempre
                          debe respetar el número de la pregunta con la
                          respuesta que te esté asociado.<br></br> - La columna{' '}
                          <strong>PreguntaRespuesta</strong> es obligatoria y
                          debe contener{' '}
                          <strong>P (pregunta) o R (Respuesta)</strong>.{' '}
                          <br></br>- La columna <strong>TipoPregunta</strong> es
                          obligatoria sólo cuando es una pregunta y debe
                          contener{' '}
                          <strong>S (verdadero y falso) o L (multiple)</strong>.
                          <br></br> - La columna <strong>Postulado</strong> es
                          obligatoria sólo cuando es una pregunta o una
                          respuesta. <br></br>- La columna{' '}
                          <strong>OpcionCorrecta</strong> es obligatoria sólo
                          cuando es una respuesta y debe contener como opción
                          correcta los{' '}
                          <strong>valores V (verdadero) o F (falso)</strong>.{' '}
                          <br></br>- Al finalizar con el archivo excel, deberá
                          ir a <strong>"ARCHIVO y GUARDAR COMO"</strong>,
                          seleccionar el formato del archivo tipo&nbsp;
                          <strong>
                            "(CSV CODIFICADO EN UTF-8 (DELIMITADO POR COMAS))"{' '}
                          </strong>
                          y <strong>"GUARDAR"</strong>.
                        </Alert>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <center>
                          <input
                            accept=".csv"
                            style={{ display: 'none' }}
                            onChange={changeHandler}
                            id="contained-button-file"
                            multiple
                            type="file"
                            name="filecsv"
                          />
                          <a
                            href={'/documents/csvmasivo_v3.csv'}
                            download="csvmasivo.csv"
                          >
                            <Button
                              className={classes.buttonLeftSteps2}
                              startIcon={<SaveAlt />}
                            >
                              Descargar formato
                            </Button>
                          </a>
                          <label htmlFor="contained-button-file">
                            <Button
                              className={classes.buttonContactGreen}
                              component="span"
                            >
                              Subir documento
                            </Button>
                          </label>
                        </center>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </SwipeableViews>
                <br></br>
                <center>
                  <Button
                    className={classes.buttonSubmit2}
                    onClick={saveQuestion}
                    disabled={disabledQuestion}
                    startIcon={<Save />}
                  >
                    GUARDAR
                  </Button>
                </center>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === 'error' ? 'error' : 'success'}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
            <Backdrop
              style={{ zIndex: '9999', color: '#FFFFFF' }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {openQuestion ? (
              <TestsQuestions
                open={openQuestion}
                exit={handleCloseQuestions}
                id={dataTest}
              />
            ) : (
              ''
            )}
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
