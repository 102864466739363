import React, { useContext, useState, useEffect, Fragment } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from '@react-pdf/renderer'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { Backdrop, CircularProgress } from '@material-ui/core'
import font from 'assets/font/Roboto-Bold.ttf'

import unchecked from 'assets/img/unchecked-checkbox.png'
import checkbox from 'assets/img/checkbox.jpeg'

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  body: {
    padding: 25,
  },
  table: {
    display: 'table',
    width: '100%',
    borderWidth: 0,
    marginTop: 5,
  },
  tableHeader: {
    display: 'table',
    width: '100%',
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableDinamic: {
    display: 'table',
    borderLeftColor: 'rgba(255, 255, 255, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(255, 255, 255, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
    marginTop: 25,
  },
  tableRow: {
    flexDirection: 'row',
    width: '100%',
  },
  tableRowTop: {
    marginTop: 20,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
  },
  tableRowBottom: {
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(255, 255, 255, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
  },
  tableCol1Header: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 200,
  },
  tableCol1HeaderAll: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: '100%',
  },
  colHeader: {
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(255, 255, 255, 1)',
    borderWidth: 1,
    width: 150,
  },
  tableCol1HeaderDinamic: {
    width: '100%',
    borderWidth: 0,
    padding: 10,
    backgroundColor: 'rgb(242, 242, 242)',
  },
  tableCol1HeaderLeft: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 250,
  },
  tableCellHeaderTitles: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 350,
  },
  tableCellHeaderTitles2: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 200,
  },
  tableCol1HeaderRight: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 150,
  },
  tableCol1: {
    borderLeftColor: 'rgba(255, 255, 255, 1)',
    borderTopColor: 'rgba(255, 255, 255, 1)',
    borderRightColor: 'rgba(255, 255, 255, 1)',
    borderBottomColor: 'rgba(255, 255, 255, 1)',
    borderWidth: 1,
  },
  tableCol: {
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: 'Roboto-Bold',
  },
  tableCellHeaderDinamic: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#000000',
    fontFamily: 'Roboto-Bold',
  },
  tableCellHeaderDinamicCenter: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#000000',
    fontFamily: 'Roboto-Bold',
    textAlign: 'center',
    width: '100%',
    marginBottom: '-10px',
    marginTop: 15,
  },
  tableCellHeaderDinamicRight: {
    fontSize: 8,
    textAlign: 'right',
    padding: 5,
  },
  tableCellHeaderDinamicResult: {
    padding: 3,
    width: '100%',
    textAlign: 'justify',
  },
  tableResult: {
    marginBottom: 10,
  },
  lineBottom: {
    marginTop: 5,
    backgroundColor: '#000',
    padding: 1,
    borderRadius: 10,
    width: 100,
  },
  titlePage: {
    fontSize: 14,
    marginBottom: 5,
    fontFamily: 'Roboto-Bold',
  },
})

Font.register({
  family: 'Roboto-Bold',
  src: font,
})

function PrintPDFApplicantsTestPsychometrics(props) {
  const [rows, setRows] = useState({ sections: [] })
  const [openBackdrop, setOpenBackdrop] = useState(true)
  const [returnLogin, setReturnLogin] = React.useState(false)

  const keyAuthorization = localStorage.getItem('Session')
  const { urlServices, keyAplication } = useContext(UrlServicesContext)
  const params = queryString.parse(props.location.search)

  useEffect(() => {
    axios
      .get(
        `${urlServices}jobs-tpm/${params.jobtpm}/test/${params.testId}/applicant/${params.userId}`,
        {
          headers: {
            application: keyAplication,
            Authorization: `Bearer ${keyAuthorization}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // Construye un mapa de respuestas seleccionadas
          const selectedAnswersMap = response.data.testResultTpm.reduce(
            (map, result) => {
              map[result.questionId] = map[result.questionId] || {}
              map[result.questionId][result.answerId] = result.selectedAnswer
              return map
            },
            {}
          )

          // Actualiza las respuestas con las seleccionadas
          const updatedSections =
            response.data.jobTpm.jobTestTpm.test.sections.map((section) => ({
              ...section,
              scales: section.scales.map((scale) => ({
                ...scale,
                questions: scale.questions.map((question) => ({
                  ...question,
                  answers: question.answers.map((answer) => ({
                    ...answer,
                    selectedAnswer:
                      selectedAnswersMap[question.id]?.[answer.id] || false,
                  })),
                })),
              })),
            }))

          setRows({
            ...response.data.jobTpm.jobTestTpm.test,
            sections: updatedSections,
          })

          setTimeout(() => {
            setOpenBackdrop(false)
          }, 3000)
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }, [urlServices, keyAplication, keyAuthorization, params])

  if (returnLogin) {
    return <Redirect to="/" />
  }

  if (openBackdrop) {
    return (
      <Fragment>
        <Backdrop
          style={{ zIndex: '9999', color: '#FFFFFF' }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
          <b style={{ fontSize: 12, marginLeft: 10 }}>Puede tardar un poco</b>
        </Backdrop>
      </Fragment>
    )
  } else {
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page style={styles.body} size="A4" wrap>
            <View style={{ marginBottom: 10 }}>
              <Text style={styles.titlePage}>
                {' '}
                Prueba realizada por el candidato
              </Text>
              <Text style={styles.lineBottom}></Text>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCellHeaderTitles}>
                <Text style={styles.tableCellHeader}>
                  Candidat@:
                  <Text style={styles.tableCell}> {' ' + params.userName}</Text>
                </Text>
              </View>
              <View style={styles.tableCellHeaderTitles2}>
                <Text style={styles.tableCellHeader}>
                  Identificación:
                  <Text style={styles.tableCell}>
                    {' ' + params.userDocument}
                  </Text>
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Prueba:
                  <Text style={styles.tableCell}>{' ' + params.testName}</Text>
                </Text>
              </View>
              <View style={styles.tableCol1HeaderLeft}>
                <Text style={styles.tableCellHeader}>
                  Cargo:
                  <Text style={styles.tableCell}>{' ' + params.jobName}</Text>
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableCellHeaderDinamicCenter}>
                PREGUNTAS Y RESPUESTAS
              </Text>
            </View>

            <View style={styles.tableDinamic}>
              {rows.sections && rows.sections.length > 0 ? (
                rows.sections.map((section, index2) => (
                  <View key={index2}>
                    <View style={styles.tableCol1HeaderDinamic}>
                      <Text style={styles.tableCellHeaderDinamic}>
                        {section.name}
                      </Text>
                    </View>
                    {section.scales &&
                      section.scales.map((scale, index3) => (
                        <View key={index3} style={{ marginTop: 10 }}>
                          <View>
                            <Text
                              style={{
                                fontWeight: 'bold',
                                fontSize: 13,
                                color: 'green',
                              }}
                            >
                              {scale.name}
                            </Text>
                          </View>
                          {scale.questions &&
                            scale.questions.map((question, index4) => (
                              <View
                                key={index4}
                                style={{
                                  padding: 5,
                                }}
                              >
                                <Text style={[styles.tableCellHeaderDinamic]}>
                                  {index4 + 1 + '-. ' + question.statement}
                                </Text>
                                {question.answers &&
                                  question.answers.map((answer, index5) => {
                                    let select = unchecked

                                    if (answer.selectedAnswer === true) {
                                      select = checkbox
                                    }
                                    return (
                                      <View>
                                        <View
                                          key={index5}
                                          style={styles.tableRow}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 10,
                                              margin: '1px 0',
                                            }}
                                          >
                                            <Image
                                              src={select}
                                              style={{ width: 15 }}
                                            />{' '}
                                            {answer.statement}
                                          </Text>
                                        </View>
                                      </View>
                                    )
                                  })}
                              </View>
                            ))}
                        </View>
                      ))}
                  </View>
                ))
              ) : (
                <View>
                  <Text>No hay datos disponibles.</Text>
                </View>
              )}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    )
  }
}

export default PrintPDFApplicantsTestPsychometrics
