import React, { Fragment, useState, useEffect, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/core/styles'
import { Button, Dialog, Grid } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { Cancel, Wifi } from '@material-ui/icons'
import axios from 'axios'
import Moment from 'moment'

import img from 'assets/img/loading.gif'
import EnableValidateTestUserStop from 'views/dialog/EnableValidateTestUserStop'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function EnableInformationTestUser(props) {
  const classes = useStyles()

  const [ipSearch, setIpSearch] = useState('')
  const [loader, setLoader] = useState(true)
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [openValidateSTOP, setOpenValidateSTOP] = React.useState(false)
  const [connection, setConnection] = React.useState('')

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    const dataValue = {
      userId: props.id.userId,
      //fromDate: '2024-07-31',
      // toDate: '2024-07-31',
    }
    axios
      .post(
        `${urlServices}reports/users-sessions/account/${localStorage.getItem(
          'account_id'
        )}`,
        dataValue,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            // Ordenar el array por fecha `createdAt`
            const sortedData = response.data.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt)
            })

            // Tomar el último registro (más reciente)
            const latestRecord = sortedData[0]
            setConnection(
              Moment(latestRecord.createdAt).format('YYYY-MM-DD HH:mm:ss')
            )

            SearchIpInfo(latestRecord.publicIp)
          }
          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const SearchIpInfo = (info) => {
    axios
      .get('https://ipinfo.io/' + info + '/json')
      .then((response) => {
      //  console.log('Datos de IP:', response.data)
        setIpSearch(response.data)
      })
      .catch((e) => {
        console.error('Error al obtener información de IP:', e)
      })
  }

  const handleCloseOpenValidateSTOP = () => {
    setOpenValidateSTOP(false)
  }

  const RefreshTableReturn = () => {
    props.callBackRefresh()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container className={classes.containerProfile}>
              {loader ? (
                <Grid item xs={12} md={12}>
                  <center>
                    <img
                      src={img}
                      alt="loader"
                      style={{
                        width: 40,
                      }}
                    />
                  </center>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <div className={classes.titleCardFilter}>Información</div>
                    <div className={classes.lineBottom}></div>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingTop: 15 }}>
                    <b style={{ fontSize: 12 }}>Nombre de la prueba:</b>{' '}
                    {props.id.testName}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <b style={{ fontSize: 12 }}>Candidato:</b>{' '}
                    {props.id.userNames}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <b style={{ fontSize: 12 }}>Ultima conexión:</b>{' '}
                    {connection}
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingTop: 18 }}>
                    <b style={{ fontSize: 16 }}>
                      <Wifi style={{ fontSize: 18 }} />
                      &nbsp;&nbsp;Conexión de red:
                    </b>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <b style={{ fontSize: 12 }}>IP pública:</b> {ipSearch.ip}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <b style={{ fontSize: 12 }}>Org. único de red:</b>{' '}
                    {ipSearch ? ipSearch.org.split(' ')[0] : ''}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <b style={{ fontSize: 12 }}>Región:</b> {ipSearch.region}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <b style={{ fontSize: 12 }}>Ciudad:</b> {ipSearch.city}
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingTop: 10 }}>
                    <Alert severity="warning">
                      Actualmente el candidato está presentando, al pausar la
                      prueba, inmediatamente el sistema sacará al candidato de
                      la prueba.
                    </Alert>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit5}
                        style={{
                          width: 150,
                          fontSize: 14,
                          marginTop: 15,
                          padding: '7px 0',
                        }}
                        onClick={() => setOpenValidateSTOP(true)}
                      >
                        Pausar la prueba
                      </Button>
                    </center>
                  </Grid>
                </>
              )}
            </Grid>
            {openValidateSTOP ? (
              <EnableValidateTestUserStop
                open={openValidateSTOP}
                exit={handleCloseOpenValidateSTOP}
                id={props.id.id}
                callBackRefresh={RefreshTableReturn}
                path={props.path}
              />
            ) : (
              ''
            )}
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
