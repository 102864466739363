import React, { Fragment, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function SelfDescription(props) {
  const classes = useStyles();

  const [values, setValues] = useState({
    name: "Hector Daniel",
    lastname: "Echeverria",
    age: "20",
    mail: "test@polygraph.com",
    errorName: false,
    errorLastName: false,
    errorAge: false,
    errorMail: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorLastName: false,
      errorAge: false,
      errorMail: false,
    });
  };

  return (
    <Fragment>
      <Grid container spacing={1} className={classes.containerProfile}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <div className={classes.titleCardFilter}>
                Autodescripción y metas (Conteste brevemente)
              </div>
              <div className={classes.lineBottom}></div>
              <br></br>
              <br></br>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: "100%" }}>
                <TextField
                  name="name"
                  value=""
                  error={values.errorName}
                  onChange={handleChange("name")}
                  fullWidth
                  required
                  label="Es una persona que se autodescribe como:"
                  margin="dense"
                  autoComplete="off"
                  variant="outlined"
                  rows={3}
                  multiline
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: "100%" }}>
                <TextField
                  name="lastname"
                  value=""
                  // error={values.errorLatasName}
                  onChange={handleChange("lastname")}
                  fullWidth
                  required
                  label="Siente gran aprecio por:"
                  margin="dense"
                  autoComplete="off"
                  variant="outlined"
                  rows={3}
                  multiline
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: "100%" }}>
                <TextField
                  name="mail"
                  value=""
                  error={values.errorMail}
                  onChange={handleChange("mail")}
                  fullWidth
                  required
                  label="Lo que más le disgusta:"
                  margin="dense"
                  autoComplete="off"
                  variant="outlined"
                  rows={3}
                  multiline
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: "100%" }}>
                <TextField
                  name="mail"
                  value=""
                  error={values.errorMail}
                  onChange={handleChange("mail")}
                  fullWidth
                  required
                  label="Tiene como metas personales y profesionales:"
                  margin="dense"
                  autoComplete="off"
                  variant="outlined"
                  rows={3}
                  multiline
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <br></br>
              <center>
                <Button
                  type="submit"
                  className={classes.buttonSubmit}
                  //  onClick={submitForm}
                >
                  Guardar
                </Button>
              </center>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
