import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'
import { Cancel, Delete, AddCircle } from '@material-ui/icons'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import NumberFormat from 'react-number-format'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FAFAFA',
    },
  },
}))(TableRow)

export default function NewModuleAdmin(props) {
  const classes = useStyles()

  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [rowsSubs, setRowsSubs] = useState([])
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    name: '',
    icon: 'Remove',
    order: '',
    errorName: false,
    errorIcon: false,
    errorOrder: false,
  })

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorOrder: false,
      errorIcon: false,
    })
    setOpenAlert(false)
  }

  const submitForm = (e) => {
    e.preventDefault()
    setError('')
    setOpenAlert(false)

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true })
      setMensaje('Agregar un nombre.')
      setOpenAlert(true)
      setError('error')
    } else if (values.icon.length === 0) {
      setValues({ ...values, errorIcon: true })
      setMensaje('Agregar un icono.')
      setOpenAlert(true)
      setError('error')
    } else if (values.order.length === 0) {
      setValues({ ...values, errorOrder: true })
      setMensaje('Agregar un número de orden.')
      setOpenAlert(true)
      setError('error')
    } else {
      if (rowsSubs.length > 0) {
        const dataValue = {
          name: values.name,
          iconName: values.icon,
          order: values.order,
        }

        axios
          .post(`${urlServices}modules`, dataValue, {
            headers: {
              application: keyAplication,
              Authorization: 'Bearer ' + keyAuthorization,
            },
          })
          .then((response) => {
            saveSubs(response.data.id) // SEND ID
            setMensaje('Registro guardado con éxito.')
            setOpenAlert(true)
            setError('success')

            props.callBackRefresh()

            setValues({
              name: '',
              icon: '',
              order: '',
            })

            setRowsSubs([])
          })
          .catch((e) => {
            if (e.response.status === 401) {
              setTimeout(() => {
                localStorage.clear()
                setReturnLogin(true)
              }, 200)
            } else {
              setMensaje('Error de conexión, intente más tarde.')
              setOpenAlert(true)
              setError('error')
            }
          })
      } else {
        setMensaje('Debe agregar acciones de submódulos.')
        setOpenAlert(true)
        setError('error')
      }
    }
  }

  const saveSubs = (ID) => {
    if (rowsSubs.length > 0) {
      rowsSubs.map((items, i) => {
        const data = {
          name: items.name,
          path: '/' + items.path,
          order: items.order,
          moduleId: ID,
        }

        axios
          .post(`${urlServices}resources`, data, {
            headers: {
              application: keyAplication,
              Authorization: 'Bearer ' + keyAuthorization,
            },
          })
          .then(
            (response) => {},
            (error) => {}
          )
          .catch((e) => {
            if (e.response.status === 401) {
              setTimeout(() => {
                localStorage.clear()
                setReturnLogin(true)
              }, 200)
            } else {
              console.log(e)
            }
          })

        if (rowsSubs.length === i + 1) {
          setRowsSubs([
            {
              name: '',
              path: '',
              order: '',
            },
          ])
        }
      })
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleAdd = () => {
    setRowsSubs([...rowsSubs, { name: '', path: '', order: '' }])
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...rowsSubs]
    list[index][name] = value
    setRowsSubs(list)
  }

  const handleRemoveSubs = (i) => {
    const list = [...rowsSubs]
    list.splice(i, 1)
    setRowsSubs(list)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2} style={{ padding: 10 }}>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleCardFilter}>Nuevo módulo</div>
                    <div className={classes.lineBottom}></div>
                    <br></br>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: '100%' }}>
                      <TextField
                        name="name"
                        value={values.name}
                        error={values.errorName}
                        onChange={handleChange('name')}
                        fullWidth
                        required
                        label="Nombre módulo"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>

                  {/*<Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="icon"
                        value={values.icon}
                        error={values.errorIcon}
                        onChange={handleChange("icon")}
                        fullWidth
                        required
                        label="Nombre icon"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
      </Grid>*/}

                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel htmlFor="formatted-text-mask-input">
                        Orden en el menú
                      </InputLabel>
                      <Input
                        value={values.order}
                        onChange={handleChange('order')}
                        name="order"
                        id="formatted-text-mask-input"
                        error={values.errorOrder}
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            fontSize: 14,
                            marginTop: 10,
                          },
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                        }}
                        required
                        margin="dense"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <div className={classes.titleCardFilter}>Submódulos</div>
                    <div className={classes.lineBottom}></div>
                    <br></br>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      className={classes.buttonLeft}
                      onClick={handleAdd}
                      startIcon={<AddCircle />}
                    >
                      Agregar submódulo
                    </Button>
                  </Grid>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      {rowsSubs.map((row1, i1) => {
                        return (
                          <StyledTableRow>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <TextField
                                name="name"
                                value={row1.name}
                                onChange={(e) => handleInputChange(e, i1)}
                                fullWidth
                                label="Nombre"
                                margin="dense"
                                autoComplete="off"
                                helperText="Ej: Perfil"
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <TextField
                                name="path"
                                value={row1.path}
                                onChange={(e) => handleInputChange(e, i1)}
                                fullWidth
                                label="Url"
                                margin="dense"
                                autoComplete="off"
                                helperText="Ej: lista"
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <NumberFormat
                                customInput={TextField}
                                name="order"
                                value={row1.order}
                                onChange={(e) => handleInputChange(e, i1)}
                                //prefix={"$"}
                                // thousandSeparator="."
                                //  decimalSeparator=","
                                allowNegative={false}
                                inputProps={{
                                  style: {
                                    textAlign: 'center',
                                    fontSize: 14,
                                    width: 50,
                                  },
                                }}
                                label="Orden"
                                fullWidth
                                autoComplete="off"
                                helperText="Ej: 1"
                                variant="outlined"
                                margin="dense"
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <Delete
                                style={{
                                  cursor: 'pointer',
                                  float: 'right',
                                }}
                                onClick={() => handleRemoveSubs(i1)}
                              />
                            </TableCell>
                          </StyledTableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={submitForm}
                      >
                        Guardar
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === 'error' ? 'error' : 'success'}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
