import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

import { makeStyles, withStyles } from '@material-ui/core/styles'

// core components
import { Redirect } from 'react-router-dom'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, Cancel, Assignment, GetApp } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import axios from 'axios'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import Alert from '@material-ui/lab/Alert'
import { CSVLink } from 'react-csv'

import LoadTestAccount from 'components/services/LoadTestAccount'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const useStyles2 = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(237, 247, 237)!important' } },
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function EnableResultsReport(props) {
  const classes = useStyles()

  const [rows, setRows] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [resultTotal, setResultTotal] = useState(0)
  const [account, setAccount] = useState('')
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [csvHeaders, setCsvHeaders] = useState([
    { label: 'Candidato', key: 'name' },
    { label: 'Documento', key: 'document' },
    { label: 'Puntaje', key: 'totalScore' },
  ])
  const [csvData, setCsvData] = useState([])

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    testAccount: '',
    testAccountData: [],
    testName: '',
    testTime: '',
    testTotalScore: '',
    created: Date('now'),
    errorTestAccount: false,
  })

  const submitAdd = (e) => {
    e.preventDefault()
    setoOpenBackdrop(true)

    if (values.testAccount !== '') {
      axios
        .get(`${urlServices}tests-results/test/${values.testAccount}/results`, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            //console.log('response.data', response.data)
            accountSearch()
            setRows(response.data)
            setResultTotal(response.data.length)
            setoOpenBackdrop(false)

            handleCSV(response.data)
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            console.log(e)
          }
        })
    } else {
      setMensaje('Debe seleccionar una prueba.')
      setOpenAlert(true)
      setError('error')
      setoOpenBackdrop(false)
    }
  }

  const accountSearch = () => {
    axios
      .get(`${urlServices}accounts/${localStorage.getItem('account_id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAccount(response.data.companyName)
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const changeTestAccount = (pro) => {
    setValues({
      ...values,
      testAccount: pro.id,
      testName: pro.name,
      testTime: pro.time,
      testTotalScore: pro.totalScore,
      testAccountData: pro,
    })
  }

  const handleCSV = (data) => {
    const newData = data.map((result) => {
      return {
        name: `${result.lastName} ${result.name}`,
        document: result.documentId,
        totalScore: parseFloat(result.totalScore).toFixed(2),
      }
    })
    setCsvData(newData)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info">
                    <div className={classes.cardTitleWhite}>
                      <Assignment className={classes.iconWhite} /> Reporte
                      general de candidatos
                    </div>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={6}>
                        <LoadTestAccount
                          refresh={changeTestAccount}
                          value={values.testAccountData}
                          error={values.errorTestAccount}
                        />
                      </GridItem>

                      <GridItem xs={12} md={2}>
                        <Button
                          className={classes.buttonLeft}
                          onClick={submitAdd}
                          startIcon={<Search />}
                        >
                          Buscar
                        </Button>
                      </GridItem>
                    </GridContainer>

                    {rows.length > 0 ? (
                      <Fragment>
                        <br></br>
                        <Table
                          className={classes.table}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableBody>
                            <TableCell
                              style={{
                                fontWeight: 'bold',
                                textAlign: 'center',
                                backgroundColor: '#F2F2F2',
                              }}
                            >
                              Candidatos que rindieron la prueba: {resultTotal}{' '}
                              registro
                              {resultTotal > 1 ? 's' : ''}
                              &nbsp;&nbsp;
                              <Button
                                type="submit"
                                className={classes.buttonSubmit1}
                                href={
                                  '/reportapplicants?test=' +
                                  values.testAccount +
                                  '&account=' +
                                  account +
                                  '&testname=' +
                                  values.testName +
                                  '&created=' +
                                  values.created +
                                  '&testTime=' +
                                  values.testTime +
                                  '&testTotalScore=' +
                                  values.testTotalScore
                                }
                                startIcon={<GetApp style={{ fontSize: 14 }} />}
                                component="a"
                                target="_blank"
                              >
                                PDF
                              </Button>
                              &nbsp;&nbsp;
                              <CSVLink
                                data={csvData}
                                headers={csvHeaders}
                                filename={'reporte_general_candidatos.csv'}
                              >
                                <Button
                                  type="button"
                                  className={classes.buttonSubmit1}
                                  startIcon={
                                    <GetApp style={{ fontSize: 14 }} />
                                  }
                                >
                                  CSV
                                </Button>
                              </CSVLink>
                            </TableCell>
                          </TableBody>
                        </Table>
                        <br></br>
                        <Table
                          className={classes.table}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableBody>
                            <TableRow className={classes.titleFilterAction}>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  width: 350,
                                  backgroundColor: '#F2F2F2',
                                }}
                              >
                                Candidato
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  backgroundColor: '#F2F2F2',
                                }}
                              >
                                Documento
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  fontSize: 14,
                                  backgroundColor: '#F2F2F2',
                                }}
                              >
                                Puntaje
                              </TableCell>
                            </TableRow>
                            {rows.map((row1, i1) => {
                              return (
                                <TableRow className={classes.titleFilterAction}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      fontSize: 12,
                                      width: 350,
                                    }}
                                  >
                                    {row1.lastName + ' ' + row1.name}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      fontSize: 12,
                                      width: 350,
                                    }}
                                  >
                                    {row1.documentId}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      fontWeight: '600',
                                      textAlign: 'center',
                                      fontSize: 12,
                                    }}
                                  >
                                    {parseFloat(row1.totalScore).toFixed(2)}
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </Fragment>
                    ) : (
                      <div
                        style={{
                          color: '#C5C5C5',
                          padding: '60px 0',
                          fontWeight: 'bold',
                        }}
                      >
                        <center> No hay resultados </center>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Fragment>
          <Backdrop
            style={{ zIndex: '9999', color: '#FFFFFF' }}
            open={openBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
          >
            <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
              <Alert
                onClose={handleClose}
                severity={error === 'error' ? 'error' : 'success'}
                elevation={6}
                variant="filled"
              >
                {mensaje}
              </Alert>
            </Slide>
          </Snackbar>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
