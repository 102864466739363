import React, { useState, forwardRef, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

import { makeStyles } from '@material-ui/core/styles'

// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import MaterialTable, { MTableBodyRow } from 'material-table'
import Alert from '@material-ui/lab/Alert'
import format from 'date-fns/format'
import Button from '@material-ui/core/Button'

//import EnableSinResultsPsychometrics from 'views/dialog/EnableSinResultsPsychometrics'
import EnableResultsJobsIndividual from 'views/dialog/EnableResultsTpmApplicant'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles1 = makeStyles(cardBodyStyle)

const useStyles = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(237, 247, 237)!important' } },
})

export default function TestsDonetPsychometrics(props) {
  const classes = useStyles()
  const classes1 = useStyles1()
  const tableRef = React.createRef()

  const [idRow, setIdRow] = useState('')
  const [openResults, setOpenResults] = useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Prueba',
        field: 'jobtesttpm.test.name',
        width: '80%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
      },
      {
        title: 'Cargo',
        field: 'jobtesttpm.job.name',
        width: '45%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
      },
      {
        title: 'Fecha finalizada',
        render: (rowData) => format(new Date(rowData.endDate), 'dd-MM-yyyy'),
        width: '20%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
          textAlign: 'center',
        },
      },
      {
        title: '---',
        render: (rowData) => {
          return (
            rowData.jobtesttpm.job.showApplicantreport && (
              <Button
                className={classes1.buttonSubmit6}
                onClick={() => OpenResult(rowData)}
              >
                Reporte
              </Button>
            )
          )
        },
        width: '5%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
        sorting: false,
      },
      /* {
        title: "---",
        render: (rowData) => {
          return (
            <Button
              className={classes1.buttonSubmit6}
              onClick={() => OpenResult(rowData)}
            >
              Ver
            </Button>
          );
        },
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
        sorting: false,
      },*/
    ],
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    if (props.RefreshTestDonePsychometrics) {
      RefreshTablePsychometrics()
    }
  }, [props])

  const RefreshTablePsychometrics = () => {
    tableRef.current && tableRef.current.onQueryChange()
    props.refreshPsychometrics()
  }

  const handleCloseOpenResults = () => {
    setOpenResults(false)
  }

  const OpenResult = (data) => {
    // let out = {
    //   testId: data.jobtesttpm.testId,
    //   cargoName: data.jobtesttpm.job.name,
    //   testName: data.jobtesttpm.test.name,
    //   testTime: data.jobtesttpm.time,
    //   userId: data.applicant.id,
    //   userNames: data.applicant.name + ' ' + data.applicant.lastName,
    //   userEmail: data.applicant.email,
    //   userDocument: data.applicant.documentId,
    // }
    let out = {
      jobId: data.jobtesttpm.jobId,
      userId: data.applicantId,
    }

    setIdRow(out)
    setOpenResults(true)
  }

  let url = ''

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Alert variant="filled" severity="success">
              <b style={{ fontSize: 12, marginTop: 5 }}>PRUEBAS FINALIZADAS</b>
            </Alert>
          </CardHeader>
          <CardBody>
            <MaterialTable
              title=""
              tableRef={tableRef}
              columns={state.columns}
              style={{
                boxShadow: 'unset',
                border: '1px solid rgb(241, 241, 241)',
              }}
              data={(query) =>
                new Promise((resolve, reject) => {
                  url = `${urlServices}jobs-users-tpm/user/${localStorage.getItem(
                    'id'
                  )}`
                  fetch(url, {
                    method: 'get',
                    headers: {
                      application: keyAplication,
                      Authorization: 'Bearer ' + keyAuthorization,
                    },
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      //console.log(result.data.filter((obj) => obj.status === 3))
                      resolve({
                        //data: result.data,
                        data:
                          result.data !== undefined
                            ? result.data.filter((obj) => obj.status === 3)
                            : [],
                        page: result.page !== undefined ? result.page - 1 : 0,
                        totalCount:
                          result.total !== undefined ? result.total : 0,
                      })
                    })
                })
              }
              localization={{
                toolbar: {
                  // nRowsSelected: '{0} row(s) selected',
                  searchTooltip: 'Filtrar',
                  searchPlaceholder: 'Buscar',
                },
                pagination: {
                  labelRowsSelect: 'Filas',
                  labelDisplayedRows: ' {from}-{to} de {count}',
                  firstTooltip: 'Primera página',
                  previousTooltip: 'Previo',
                  nextTooltip: 'Siguiente',
                  lastTooltip: 'Ultima página',
                },
                header: {
                  actions: ' --- ',
                },
                body: {
                  emptyDataSourceMessage: 'No hay pruebas realizadas.',
                  filterRow: {
                    filterTooltip: 'Filtro',
                  },
                },
              }}
              actions={
                [
                  /*{
                    icon: "search",
                    tooltip: "Ver",
                    onClick: (event, rowData) => {
                      setopenView(true);
                      setIdRow(rowData);
                    },
                  },*/
                ]
              }
              icons={tableIcons}
              options={{
                search: false,
                filtering: false,
                actionsColumnIndex: -1,
                pageSize: 20,
                paging: false,
                headerStyle: {
                  background: '#F2F2F2',
                  padding: '1%',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 12,
                },
                cellStyle: { border: '1px solid #DDDDDD' },
                toolbar: false,
              }}
              components={{
                Row: (props) => (
                  <MTableBodyRow className={classes.tableRow} {...props} />
                ),
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      {openResults ? (
        // <EnableSinResultsPsychometrics
        //   open={openResults}
        //   exit={handleCloseOpenResults}
        //   id={idRow}
        // />
        <EnableResultsJobsIndividual
          open={openResults}
          exit={handleCloseOpenResults}
          id={idRow}
        />
      ) : (
        ''
      )}
    </GridContainer>
  )
}
