import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Search, Layers, AddCircle } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import NewGestor from "views/dialog/NewGestor";
import EditApplicant from "views/dialog/EditApplicant";
import ViewUser from "views/dialog/ViewUser";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function Gestores() {
  const classes = useStyles();
  const tableRef = React.createRef();

  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [idRow, setIdRow] = useState("");
  const [openViewUser, setopenViewUser] = useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  };

  const [state] = useState({
    columns: [
      {
        title: "Nombres y apellidos",
        field: "name,lastName",
        render: (rowData) => {
          return <b>{rowData.name + " " + rowData.lastName}</b>;
        },
        width: "50%",
        cellStyle: {
          whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
        },
      },
      {
        title: "Cédula",
        field: "documentId",
        width: "30%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
          textAlign: "center",
        },
      },
      {
        title: "Correo electrónico",
        field: "email",
        render: (rowData) => {
          return (
            <Fragment>
              <span style={{ fontSize: 12 }}>
                {rowData.email !== "" ? rowData.email : "SIN REGISTRAR"}
              </span>
            </Fragment>
          );
        },
        width: "30%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
          // textAlign: "center",
        },
      },
      {
        title: "Perfil",
        render: (rowData) => {
          const text = rowData.status === 5 ? "Gestor" : "Administrador";
          return <b>{text}</b>;
        },
        width: "1%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },
      {
        title: "Estado",
        field: "status",
        render: (rowData) => {
          const text =
            rowData.status === 1
              ? "ACTIVO"
              : rowData.status === 99
              ? "Pendiente"
              : "INACTIVO";
          const color =
            rowData.status === 1
              ? "green"
              : rowData.status === 99
              ? "orange"
              : "red";
          return <b style={{ color: color }}>{text}</b>;
        },
        width: "1%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },
    ],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const RefreshTable = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseView = () => {
    setopenViewUser(false);
  };

  let url = "";

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Layers className={classes.iconWhite} /> Listado de operadores
            </div>
          </CardHeader>
          <CardBody>
            <Fragment>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Button
                    className={classes.buttonRight}
                    onClick={(event) => {
                      setOpenNew(true);
                    }}
                    startIcon={<AddCircle />}
                  >
                    Agregar operador
                  </Button>
                </Grid>
              </Grid>
              <MaterialTable
                title=""
                tableRef={tableRef}
                columns={state.columns}
                style={{
                  boxShadow: "unset",
                  border: "1px solid rgb(241, 241, 241)",
                }}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    if (query.search === "") {
                      url = `${urlServices}users/account/${localStorage.getItem(
                        "account_id"
                      )}/operators?limit=${query.pageSize}&page=${
                        query.page + 1
                      }`;
                    } else {
                      url = `${urlServices}users/account/${localStorage.getItem(
                        "account_id"
                      )}/operators`;
                    }
                    fetch(url, {
                      method: "get",
                      headers: {
                        application: keyAplication,
                        Authorization: "Bearer " + keyAuthorization,
                      },
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        resolve({
                          //data: result.data,
                          data:
                            result.data !== undefined
                              ? result.data.filter(function (obj) {
                                  return Object.keys(obj).some(function (key) {
                                    return obj[key]
                                      ? obj[key]
                                          .toString()
                                          .toLowerCase()
                                          .includes(query.search)
                                      : false;
                                  });
                                })
                              : [],
                          page: result.page !== undefined ? result.page - 1 : 0,
                          totalCount:
                            result.total !== undefined ? result.total : 0,
                        });
                      });
                  })
                }
                localization={{
                  pagination: {
                    labelRowsSelect: "Filas",
                    labelDisplayedRows: " {from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Previo",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Ultima página",
                  },
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected',
                    searchTooltip: "Filtrar",
                    searchPlaceholder: "Buscar",
                  },
                  header: {
                    actions: " --- ",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos.",
                    filterRow: {
                      filterTooltip: "Filtro",
                    },
                  },
                }}
                actions={[
                  {
                    icon: "search",
                    tooltip: "Ver",
                    onClick: (event, rowData) => {
                      setopenViewUser(true);
                      setIdRow(rowData);
                    },
                  },
                  {
                    icon: "edit",
                    tooltip: "Editar",
                    onClick: (event, rowData) => {
                      setOpenEdit(true);
                      setIdRow(rowData);
                    },
                  },
                  /*{
                          icon: "delete",
                          tooltip: "Eliminar",
                          onClick: (event, rowData) => {
                            setOpenDelete(true);
                            setIdRow(rowData);
                          },
                        },*/
                  /*(rowData1) => ({
                    icon: () => <Cancel />,
                    tooltip: "Deshabilitar",
                    onClick: (event, rowData) => {
                        setOpenDelete(true);
                        setIdRow(rowData);
                      },
                    hidden: rowData1.status === 1 ? false : true,
                  }),*/
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () =>
                      tableRef.current && tableRef.current.onQueryChange(),
                  },
                ]}
                icons={tableIcons}
                options={{
                  search: true,
                  padding: "default",
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    background: "#F2F2F2",
                    padding: "1%",
                    fontWeight: "bold",
                    textAlign: "center",
                  },
                  cellStyle: { border: "1px solid #DDDDDD" },
                }}
              />
            </Fragment>
          </CardBody>
        </Card>
      </GridItem>
      {openNew ? (
        <NewGestor
          open={openNew}
          exit={handleCloseNew}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
      {openEdit ? (
        <EditApplicant
          open={openEdit}
          exit={handleCloseEdit}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}

      {openViewUser ? (
        <ViewUser open={openViewUser} exit={handleCloseView} id={idRow} />
      ) : (
        ""
      )}
    </GridContainer>
  );
}
