import React, { Fragment, useState, useEffect, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  Dialog,
  Snackbar,
  Slide,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import axios from 'axios'
import Alert from '@material-ui/lab/Alert'
import { Cancel, AddCircle, Save, Delete } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'

import NewAnswer from 'views/dialog/NewAnswer'
import DeleteAnswer from 'views/dialog/DeleteAnswer'

import img from 'assets/img/undraw_towing_6yy4.png'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export default function EditAnswers(props) {
  const classes = useStyles()

  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [returnLogin, setReturnLogin] = useState(false)
  const [openEditCheckAnswers, setOpenEditCheckAnswers] = useState(false)
  const [openDeleteAnswer, setOpenDeleteAnswer] = useState(false)
  const [idRow, setIdRow] = useState('')

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  let data = props.id

  const [values, setValues] = useState({
    answers: [
      {
        statement: '',
        rightAnswer: '',
        id: '',
      },
    ],
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    let out = []

    if (data.answer.length > 0) {
      // Filtrar el array de respuestas para excluir el elemento con status 0
      const updatedAnswers = data.answer.filter((row1) => row1.status !== 0)

      updatedAnswers.map((row1) => {
        out.push({
          statement: row1.statement.toString(),
          rightAnswer: row1.rightAnswer.toString(),
          id: row1.id,
        })
      })
    }

    setValues({
      ...values,
      answers: out,
    })

    /*axios
      .get(`${urlServices}answers`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let out = []
          let result = response.data.rows.filter(
            (filtered) => Number(filtered.questionId) === data.id
          )

          if (result.length > 0) {
            result.map((row1) => {
              out.push({
                statement: row1.statement.toString(),
                rightAnswer: row1.rightAnswer.toString(),
                id: row1.id,
              })
            })
          }

          setValues({
            ...values,
            answers: out,
          })
        }

        setLoader(true)
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setError(true)
        }
      })*/
  }, [])
  // }, [keyAplication, urlServices, keyAuthorization])

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleSaveAnswers = async () => {
    if (values.answers.length > 0) {
      try {
        // Crear un array de promesas con las llamadas a UpdateAnswer
        const updatePromises = values.answers.map((row1) => {
          const dataAnswers = {
            statement: row1.statement,
            rightAnswer: row1.rightAnswer,
          }

          return UpdateAnswer(row1.id, dataAnswers)
        })

        // Esperar a que todas las promesas se resuelvan
        await Promise.all(updatePromises)

        // Ejecución de código después de que todas las respuestas hayan sido actualizadas
        setOpenAlert(true)
        setMensaje('Respuesta actualizada con éxito.')
        setError('success')
        props.callBackRefresh()
      } catch (error) {
        setOpenAlert(true)
        setMensaje('Error al actualizar respuestas.')
        setError('error')
        console.error('Error al actualizar respuestas:', error)
      }
    } else {
      setOpenAlert(true)
      setMensaje('Debe Haber respuestas registradas.')
      setError('error')
    }
  }

  const UpdateAnswer = async (id, dataValue) => {
    try {
      await axios.put(`${urlServices}answers/${id}`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        setTimeout(() => {
          localStorage.clear()
          setReturnLogin(true)
        }, 200)
      } else {
        console.log(e)
      }
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleRadioChange = (rowId) => {
    // Actualizar los valores de rightAnswer para que solo el seleccionado sea 'true'
    const updatedAnswers = values.answers.map((row1) => {
      if (row1.id === rowId) {
        return { ...row1, rightAnswer: 'true' }
      } else {
        return { ...row1, rightAnswer: 'false' }
      }
    })

    // Actualizar el estado con los nuevos valores
    setValues({ ...values, answers: updatedAnswers })
  }

  const handleCheckChange = (event, row) => {
    values.answers.map((row1) => {
      if (row === row1.id) {
        row1.rightAnswer = event.target.checked.toString()
      }
    })

    setValues({ ...values, answers: values.answers })
  }

  const handleCloseDialogEditCheckAnswers = () => {
    setOpenEditCheckAnswers(false)
  }

  const handleCloseDeleteAnswer = () => {
    setOpenDeleteAnswer(false)
  }

  const OpenDelete = (id) => () => {
    setIdRow(id)
    setOpenDeleteAnswer(true)
  }

  const Refresh = (data) => {
    let out2 = []

    data.map((row1) => {
      out2.push({
        statement: row1.statement.toString(),
        rightAnswer: row1.rightAnswer.toString(),
        id: row1.id,
      })
    })

    setValues({
      ...values,
      answers: out2,
    })

    props.callBackRefresh()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        // style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={2} className={classes.containerProfile}>
              <Grid item xs={12} sm={6}>
                <div className={classes.titleCardFilter}>
                  Respuestas registradas
                </div>
                <div className={classes.lineBottom}></div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  className={classes.buttonRight}
                  onClick={() => setOpenEditCheckAnswers(true)}
                  startIcon={<AddCircle />}
                  disabled={props.statusTest === 1 ? true : false}
                >
                  Nueva respuesta
                </Button>
              </Grid>
              <Fragment>
                {data.questiontypeId === 1 ? (
                  <Grid item xs={12} sm={12}>
                    <Fragment>
                      {values.answers.length > 0 ? (
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ width: '90%' }}>
                                  Respuesta
                                </TableCell>
                                <TableCell style={{ width: '5%' }}>
                                  ---
                                </TableCell>
                                <TableCell
                                  style={{ fontSize: 12, width: '5%' }}
                                >
                                  Eliminar
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.answers.map((row) => {
                                return (
                                  <TableRow
                                    key={row.id}
                                    sx={{
                                      '&:last-child td, &:last-child th': {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      <RadioGroup
                                        aria-label="answers"
                                        name={`answers-${row.id}`}
                                        value={
                                          row.rightAnswer === 'true'
                                            ? 'true'
                                            : 'false'
                                        }
                                        onChange={(event) => {
                                          handleRadioChange(row.id)
                                        }}
                                      >
                                        <FormControlLabel
                                          control={<GreenRadio />}
                                          label={row.statement}
                                          style={{ color: 'dimgray' }}
                                          value="true"
                                        />
                                      </RadioGroup>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <b style={{ fontSize: 12 }}>
                                        {row.rightAnswer === 'true'
                                          ? 'VERDADERO'
                                          : 'FALSO'}
                                      </b>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <center>
                                        <Delete
                                          style={{
                                            fontSize: 22,
                                            cursor: 'pointer',
                                            color:
                                              props.statusTest === 1
                                                ? '#e9ebee'
                                                : '',
                                          }}
                                          onClick={
                                            props.statusTest === 1
                                              ? ''
                                              : OpenDelete(row.id)
                                          }
                                        />
                                      </center>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            style={{ fontSize: 14, fontWeight: 'bold' }}
                          >
                            <center>
                              <img
                                alt="loader"
                                src={img}
                                style={{ width: 100, marginTop: 50 }}
                              />
                              <br />
                              No hay respuestas
                            </center>
                          </Grid>
                        </Grid>
                      )}
                    </Fragment>
                  </Grid>
                ) : data.questiontypeId === 2 ||
                  data.questiontypeId === 3 ||
                  data.questiontypeId === 4 ? (
                  <Fragment>
                    {values.answers.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: '90%' }}>
                                Respuesta
                              </TableCell>
                              <TableCell style={{ width: '5%' }}>---</TableCell>
                              <TableCell style={{ fontSize: 12, width: '5%' }}>
                                Eliminar
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.answers.map((row1) => {
                              return (
                                <TableRow
                                  key={row1.id}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <FormControlLabel
                                      control={
                                        <GreenCheckbox
                                          checked={
                                            row1.rightAnswer === 'true'
                                              ? true
                                              : false
                                          }
                                          onClick={(event) => {
                                            handleCheckChange(event, row1.id)
                                          }}
                                          name={row1.statement}
                                        />
                                      }
                                      label={row1.statement}
                                      style={{ color: 'dimgray' }}
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <b style={{ fontSize: 12 }}>
                                      {row1.rightAnswer === 'true'
                                        ? 'VERDADERO'
                                        : 'FALSO'}
                                    </b>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <center>
                                      <Delete
                                        style={{
                                          fontSize: 22,
                                          cursor: 'pointer',
                                          color:
                                            props.statusTest === 1
                                              ? '#e9ebee'
                                              : '',
                                        }}
                                        onClick={
                                          props.statusTest === 1
                                            ? ''
                                            : OpenDelete(row1.id)
                                        }
                                      />
                                    </center>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={{ fontSize: 14, fontWeight: 'bold' }}
                        >
                          <center>
                            <img
                              alt="loader"
                              src={img}
                              style={{ width: 100, marginTop: 50 }}
                            />
                            <br />
                            No hay respuestas
                          </center>
                        </Grid>
                      </Grid>
                    )}
                  </Fragment>
                ) : (
                  ''
                )}
              </Fragment>
            </Grid>
            {openEditCheckAnswers && (
              <NewAnswer
                open={openEditCheckAnswers}
                exit={handleCloseDialogEditCheckAnswers}
                data={data}
                callBackRefresh={Refresh}
              />
            )}
            {openDeleteAnswer && (
              <DeleteAnswer
                open={openDeleteAnswer}
                exit={handleCloseDeleteAnswer}
                data={data}
                id={idRow}
                callBackRefresh={Refresh}
              />
            )}
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === 'error' ? 'error' : 'success'}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className={classes.buttonSubmit}
            onClick={handleSaveAnswers}
            startIcon={<Save />}
            disabled={props.statusTest === 1 ? true : false}
          >
            Guardar
          </Button>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
